import React from "react";
import Aside from "../comp/Aside";
import Footer from "../comp/Footer";
import Navbar from "../comp/Navbar";
import { Link } from "react-router-dom";
import api from "../servicios/api";
import ListIcon from "@mui/icons-material/List";
import ErrorIcon from "@mui/icons-material/Error";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ClassIcon from "@mui/icons-material/Class";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FactCheckIcon from "@mui/icons-material/FactCheck";

import "../css/users.css";
import { useParams } from "react-router-dom";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class NewPresList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Prestamos: [],
    };
  }

  cargarDatos() {
    const IdUser = this.props.match.params.id;

    fetch(
      api + "Reportes/new_prestamos.php?total_tip_d=0&total_tip_d2=" + IdUser
    )
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        this.setState({ Prestamos: datosRespuesta.data });
        //this.setState({ estado: datosRespuesta[0].estado });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    const { Prestamos } = this.state;

    //console.log(Pres_Ase);
    return (
      <div>
        <Navbar />
        <Aside />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>NUEVOS PRESTAMOS</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container-fluid">
              <div className="cont">
                <div className="table-responsive cont-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Cliente</th>
                        <th>Detalle</th>
                        <th>Riesgo</th>
                        <th>Historial</th>
                        <th>Juntas</th>
                        <th>Tipo</th>
                        <th>Evaluar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Prestamos.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>
                            {Datos.cliente_ape1} {Datos.cliente_ape2}{" "}
                            {Datos.cliente_name}{" "}
                          </td>
                          <td>
                            <div
                              className="btn btn-primary"
                              onClick={() => this.Detalle(Datos.id)}
                            >
                              <ListAltIcon />
                            </div>
                          </td>
                          <td>
                            <div className="btn btn-danger">
                              <ErrorIcon /> {Datos.c_riesgo}
                            </div>
                          </td>
                          <td>
                            <ListIcon />
                          </td>
                          <td>
                            <LocalAtmIcon /> {Datos.j_total}/{Datos.jp_total}{" "}
                            <br></br>Cobertura:{" "}
                            {(
                              (Number(Datos.j_total) + Number(Datos.jp_total)) /
                              Number(Datos.monto)
                            ).toFixed(2)}
                          </td>
                          <td>
                            <ClassIcon /> {Datos.p_tip}
                          </td>
                          <td>
                            <div
                              className="btn btn-success"
                              onClick={() => this.Evaluar(Datos.id)}
                            >
                              <FactCheckIcon />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(NewPresList);
