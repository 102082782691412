import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../servicios/api";
import "../../css/form.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Menu from "../../comp/Menu";
import { FechaActual, UserCod } from "../../common/Function";
import SaveIcon from "@mui/icons-material/Save";
import { tiposUsuariosPermitidos } from "../Common/utils";
class Egresos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos: [],
      Caja: [],
      Asesor: [],
      id_caja: 1,
      id_clie: 0,
      id_ases: null,
      monto: 0,
      detalle: "",
      error: null,
      success: null,
      Egresos: [],
      UserId: UserCod(),
      fecha: FechaActual(),
      tipoUsuario: localStorage.getItem("TIPUSER"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }

  cargarDatos() {
    const IdUser = this.state.UserId;
    const datosEnviar = {};

    fetch(api + "Cartera/", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            Caja: datosRespuesta.data,
          });
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);

    //--------- ASESORES ----------
    fetch(api + "Users/?user_asesor=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          Asesor: datosRespuesta,
        });
        //console.log(datosRespuesta);
      })
      .catch(console.log);

    //--------- EGRESOS ----------
    fetch(api + "Egresos/?id_asesor=" + IdUser, {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            Egresos: datosRespuesta.data,
          });
        } else {
          this.setState({
            Egresos: [],
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const datosEnviar = {
      id_user: this.state.UserId,
      monto: this.state.monto,
      id_caja: this.state.id_caja,
      detalle: this.state.detalle,
      fecha: this.state.fecha,
    };

    fetch(api + "Egresos/?insertar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
          });
          this.cargarDatos();
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  BorrarRegistro = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, bórralo!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(api + "Egresos/?borrar=" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "¡Eliminado!",
            "Su archivo ha sido eliminado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Tu archivo está a salvo :)",
            "error"
          );
        }
      });

    this.cargarDatos();
  };

  render() {
    const { Caja, error } = this.state;

    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/home">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          <section className="cont">
            <div className="container-fluid">
              <div className="">
                <div className="">
                  <h4>INGRESAR EGRESO</h4>
                  <hr></hr>
                  <div className="row">
                    {this.state.success && (
                      <div className="alert alert-success" role="alert">
                        SE REGISTRO PAGO CORRECTAMENTE
                      </div>
                    )}
                    {this.state.error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                    <form className="formx" onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-9 row">
                          <div className="col-4">
                            <label htmlFor="monto">Monto:</label>
                            <input
                              type="number"
                              name="monto"
                              id="capital"
                              className="form form-control"
                              placeholder="Monto"
                              step="0.01"
                              onChange={this.handleChange}
                              value={this.state.monto}
                            />
                          </div>
                          <div className="col-4">
                            <label htmlFor="detalle">Detalle:</label>
                            <input
                              type="text"
                              name="detalle"
                              id="detalle"
                              className="form form-control"
                              placeholder="Detalle"
                              onChange={this.handleChange}
                              value={this.state.detalle}
                            />
                          </div>
                          <div className="col-4">
                            <label htmlFor="id_caja">Caja:</label>
                            <select
                              className="form form-control"
                              name="id_caja"
                              id="id_caja"
                              required
                              onChange={this.handleChange}
                              value={this.state.id_caja}
                            >
                              {Caja.map((Datos) => (
                                <option value={Datos.id} key={Datos.id}>
                                  {Datos.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-4">
                            <label htmlFor="fecha">fecha:</label>
                            <input
                              type="date"
                              name="fecha"
                              id="fecha"
                              className="form form-control"
                              placeholder="fecha"
                              onChange={this.handleChange}
                              value={this.state.fecha}
                            />
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="col">
                            <br></br>
                            <button className="btn btn-primary w-100">
                              <SaveIcon />
                              REGISTRAR
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="cont">
                  <h4>LISTA DE EGRESOS</h4>
                  <hr></hr>
                  <table className="table">
                    <thead>
                      <tr>
                        <td>DESCRIPCIÓN</td>
                        <td>MONTO</td>
                        <td>CARTERA</td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.Egresos.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.descrip}</td>
                          <td>s/ {Datos.monto}</td>
                          <td>s/ {Datos.monto}</td>
                          {tiposUsuariosPermitidos.btnEgresosEliminarPagos.includes(
                            this.state.tipoUsuario
                          ) && (
                            <td>
                              <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => this.BorrarRegistro(Datos.id)}
                              >
                                <DeleteForeverIcon />
                              </button>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>DESCRIPCIÓN</td>
                        <td>MONTO</td>
                        <td>CARTERA</td>
                        {tiposUsuariosPermitidos.btnEgresosEliminarPagos.includes(
                          this.state.tipoUsuario
                        ) && <td></td>}
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Egresos;
