import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

export default function BuscarPrestamo() {
  const [inputValues, setInputValues] = useState({
    codigo: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  return (
    <div className="buscar-prestamo">
      <div className="row">
        <div className="">
          <form className="row">
            <div className="col">
              <label>Buscar por nombre o DNI</label>
              <input
                type="number"
                className="form-control"
                placeholder="Codigo de prestamo"
                name="codigo"
                onChange={handleChange}
                value={inputValues.codigo}
                required
              ></input>
            </div>
            <div className="col">
              <br></br>
              
              <Link
                className="btn btn-success"
                //to={}
                to={inputValues.codigo === "" ? "#" : "/Prestamo/" + inputValues.codigo}
              >
                <SearchIcon />
                BUSCAR
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
