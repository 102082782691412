import React from "react";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
//import Cookies from "universal-cookie";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import "../../css/users.css";
import { useParams } from "react-router-dom";
import Menu from "../../comp/Menu";
//import Swal from "sweetalert2";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class PagosTodos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      estado: 15206,
      id_press: 1,
      error: null,
      success: null,
      error2: null,
      success2: null,
      error3: null,
      success3: null,
      name_asesor: "",
      fecha: "",
      name_cliente: "",
      dni: "",
      telefono: "",
      negocio: "",
      tip_press: "",
      monto: "",
      plazo: "",
      monto_c: "",
      ahorro: "",
      total_junta: "",
      total_juntap: "",
      cuotas_r: "",
      desgrav: "",
      formapago: "",
      tinteres: "",
      interes: "",
      id_cliente: 0,
      Prestamos: [],
      Documentos: [],
      d_estado: null,
      pdf_ruta: "",
      pdf_name: "",
      password: "",
      otros: 0,
      Simu_cronograma: [],
      Documentos_cli: [],
      c_riesgo: 0,
      box_cliente: null,
      box_cliente_doc: null,
      box_conyugue: null,
      box_negocio: null,
      box_info_familiar: null,
      box_prestamo: null,
      box_prestamo_doc: null,
      box_cronograma: null,
      hist_prestamos: null,
      Pagos: [],
    };
  }

  cargarDatos() {
    const IdUser = this.props.match.params.id;

    fetch(api + "Prestamos/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta.data[0]);
        if (datosRespuesta.success === true) {
          this.setState({
            estado: datosRespuesta.data[0].estado,
            id_press: IdUser,
            name_asesor: datosRespuesta.data[0].asesor_name,
            fecha: datosRespuesta.data[0].fechapago,
            name_cliente:
              datosRespuesta.data[0].cliente_name +
              " " +
              datosRespuesta.data[0].cliente_ape1 +
              " " +
              datosRespuesta.data[0].cliente_ape2,
            dni: datosRespuesta.data[0].cliente_dni,
            telefono: datosRespuesta.data[0].cliente_phone,
            negocio: datosRespuesta.data[0].negocio,
            tip_press: datosRespuesta.data[0].p_tip,
            monto: datosRespuesta.data[0].monto,
            plazo: datosRespuesta.data[0].cuotas,
            monto_c: datosRespuesta.data[0].cuota_pago,
            ahorro: datosRespuesta.data[0].plan_junta,
            total_junta: datosRespuesta.data[0].j_total,
            total_juntap: datosRespuesta.data[0].jp_total,
            cuotas_r: datosRespuesta.data[0].cuotasr,
            desgrav: datosRespuesta.data[0].desgrav,
            formapago: datosRespuesta.data[0].formapago,
            tinteres: datosRespuesta.data[0].tinteres,
            interes: datosRespuesta.data[0].interes,
            id_cliente: datosRespuesta.data[0].id_cliente,
            c_riesgo: datosRespuesta.data[0].c_riesgo,
          });
        }
      })
      .catch(console.log);
  }

  cargarPagos() {
    const IdParam = this.props.match.params.id;

    fetch(api + "Pagos/?lista_pagos=" + IdParam + "&estado=todos")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            Pagos: datosRespuesta.data,
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarPagos();
  }

  render() {
    const {
      name_asesor,
      fecha,
      name_cliente,
      dni,
      telefono,
      negocio,
      monto,
      plazo,
      total_junta,
      formapago,
    } = this.state;

    function BtnPago(estado, pago) {
      if (estado === "2") {
        return (
          <Link to={"/realizar-pago/" + pago} className="btn btn-danger">
            Pagado
          </Link>
        );
      } else {
        return (
          <Link to={"/realizar-pago/" + pago} className="btn btn-info">
            Pagar
          </Link>
        );
      }
    }

    return (
      <div>
        <Menu />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>PAGOS DEL PRESTAMO</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <Link
                    to={"/prestamo/" + this.state.id_press}
                    className="btn btn-info"
                  >
                    <ChevronLeftIcon /> IR AL PRESTAMO
                  </Link>
                </div>
              </div>
              <br></br>

              <div className="">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={"/pagos/" + this.state.id_press}
                    >
                      POR PAGAR
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      to={"/pagos-todos/" + this.state.id_press}
                    >
                      TODOS LOS PAGOS
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={"/pagos-varios/" + this.state.id_press}
                    >
                      PAGAR VARIAS CUOTAS
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={"/pagos-historial/" + this.state.id_press}
                    >
                      HISTORIAL
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="cont table-responsive">
                <h2>PAGOS POR PAGAR</h2>
                <table className="table table-striped">
                  <thead>
                    <tr class="table-primary">
                      <td>Periodo</td>
                      <td>Día</td>
                      <td>Capital</td>
                      <td>Interes</td>
                      <td>Cuota</td>
                      <td>Pagado</td>
                      <td>Por Pagar</td>
                      <td>Mora</td>
                      <td>Junta</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.Pagos.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>{Datos.periodo}</td>
                        <td>{Datos.fecha_pago2}</td>
                        <td>{Datos.capital}</td>
                        <td>{Datos.interes}</td>
                        <td>{Datos.monto}</td>
                        <td>{Datos.pago}</td>
                        <td>{Datos.pago_res}</td>
                        <td>{Datos.mora}</td>
                        <td>{Datos.junta}</td>
                        <td>{BtnPago(Datos.estado, Datos.id)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr class="table-primary">
                      <td>Periodo</td>
                      <td>Día</td>
                      <td>Capital</td>
                      <td>Interes</td>
                      <td>Cuota</td>
                      <td>Pagado</td>
                      <td>Por Pagar</td>
                      <td>Mora</td>
                      <td>Junta</td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div className="cont">
                <h2>DETALLE:</h2>
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <b>ASESOR:</b>
                        </td>
                        <td>
                          <i>{name_asesor}</i>
                        </td>
                        <td>
                          <b>FECHA:</b>
                        </td>
                        <td>
                          <i>{fecha}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>CLIENTE:</b>
                        </td>
                        <td>
                          <i>{name_cliente}</i>
                        </td>
                        <td>
                          <b>DNI</b>
                        </td>
                        <td>
                          <i>{dni}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>DIECCIÓN</b>
                        </td>
                        <td>
                          <i>{negocio}</i>
                        </td>
                        <td>
                          <b>CELULAR</b>
                        </td>
                        <td>
                          <i>{telefono}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>JUNTA:</b>
                        </td>
                        <td>
                          <i>{total_junta}</i>
                        </td>
                        <td>
                          <b>JUNTA PLAN:</b>
                        </td>
                        <td>
                          <i>{total_junta}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>MONTO:</b>
                        </td>
                        <td>
                          <i>{monto}</i>
                        </td>
                        <td>
                          <b>PLAZO:</b>
                        </td>
                        <td>
                          <i>{plazo}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>TIPO:</b>
                        </td>
                        <td>
                          <i>{formapago}</i>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(PagosTodos);
