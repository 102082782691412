import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../servicios/api";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "../../css/table.css";
import Menu from "../../comp/Menu";

class CuadreCaja extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Prestamos: [],
      Cuadre: [],
    };
  }

  cargarDatos() {
    fetch(api + "Cuadre/")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({ Cuadre: datosRespuesta.data });
        } else {
          this.setState({ Cuadre: [] });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  BorrarRegistro = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, bórralo!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(api + "Cuadre/?borrar=" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "¡Eliminado!",
            "Su archivo ha sido eliminado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Tu archivo está a salvo :)",
            "error"
          );
        }
      });

    this.cargarDatos();
  };

  render() {
    const { Cuadre } = this.state;
    return (
      <div>
        <Menu />
        <div className="content">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>CUADRE DE CAJA DIARIO</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link className="nav-link active" to="/cuadre-caja">
                        <AssignmentIcon />
                        Ultimos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/cuadre-caja-agregar">
                        <PersonAddAltIcon />
                        Agregar
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="table-responsive cont-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>FECHA</th>
                        <th>VER</th>
                        <th>EDITAR</th>
                        <th>ELIMINAR</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Cuadre.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.id}</td>
                          <td>{Datos.fecha}</td>
                          <td>
                            <Link
                              to={"/cuadre-caja/" + Datos.id}
                              className="btn btn-primary"
                            >
                              <RemoveRedEyeIcon />
                            </Link>
                          </td>
                          <td>
                            <div
                              className="btn btn-success"
                              onClick={() => this.Evaluar(Datos.id)}
                            >
                              <FactCheckIcon />
                            </div>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-warning"
                              onClick={() => this.BorrarRegistro(Datos.id)}
                            >
                              <DeleteForeverIcon />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default CuadreCaja;
