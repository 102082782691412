import React from "react";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/form.css";
import Menu from "../../comp/Menu";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddIcon from '@mui/icons-material/Add';
import { MonedaPen, QuitarRegistrosCero, UrlHeader, cuadreDiarioUrl, baseUrlOld } from "../../common/Function";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Carga from "../Common/Carga";

class ReportesCuadreCaja extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reporte: [],
      asesor: "",
      fechaini: "",
      fechafin: "",
      error: null,
      success: null,
      carga: null,
      cuadreDiario: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const datosEnviar = {
      fechaini: this.state.fechaini,
      fechafin: this.state.fechafin,
    };
    this.setState({
      carga: 1,
    });
    fetch(api + "Reportes/?carteraactiva=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          success: 1,
          error: null,
          reporte: QuitarRegistrosCero(datosRespuesta),
          carga: null,
        });
      })
      .catch(console.log);
    event.preventDefault();
  }
  Listarcuadre() {
    var requestOptions = {
      method: 'GET',
      headers: UrlHeader(),
      redirect: 'follow'
    };

    fetch(cuadreDiarioUrl + "?orderby=id&ordermode=DESC", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.success === true) {
          this.setState({
            cuadreDiario: result.data,
          });
        } else {
          this.setState({
            cuadreDiario: [],
          });
        }

      })
      .catch(error => console.log('error', error));
  }
  componentDidMount() {
    this.Listarcuadre();
  }
  render() {
    const { reporte, cuadreDiario } = this.state;
    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/reportes">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          {" "}
          <Link className="btn btn-success" to="/reporte-cuadre-caja-new">
            <AddIcon /> AGREGAR
          </Link>

          <section className="cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>REPORTE CUADRE DE CAJA</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/#/inicio">Inicio</Link>
                      </li>
                      <li className="breadcrumb-item active">home</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <div className="container-fluid">
              <div className="reporte-form">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <label>Fecha Inicio:</label>
                      <input
                        className="form-control"
                        name="fechaini"
                        type="date"
                        required
                        onChange={this.handleChange}
                        value={this.state.fechaini}
                      ></input>
                    </div>
                    <div className="col">
                      <label>Fecha Fin:</label>
                      <input
                        className="form-control"
                        name="fechafin"
                        type="date"
                        required
                        onChange={this.handleChange}
                        value={this.state.fechafin}
                      ></input>
                    </div>
                    <div className="col">
                      <br></br>
                      <button className="btn btn-primary">Consultar</button>
                    </div>
                  </div>
                </form>
              </div>
              {this.state.carga ? (
                <Carga />
              ) : (
                <div className="reporte-cont">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        {reporte.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.asesor}</td>
                            <td>{Datos.wilmer}</td>
                            <td>{Datos.liza}</td>
                            <td>{Datos.rb}</td>
                            <td>{Datos.diego}</td>
                            <td>{Datos.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>

          </section>
          <div className="cont table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>ACCIONES</th>
                  <th>FECHA</th>
                  <th>VIENEN</th>
                  <th>TOTAL INGRESOS</th>
                  <th>TOTAL EGRESOS</th>
                  <th>SALDO TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {cuadreDiario.map(item => (
                  <tr key={item.id}>
                    <td><a className="btn btn-info" href={baseUrlOld + "/oldreport/prestamos/descargarcuadrediario.php?id_cuadre="+item.id} target="_blanck"> <CloudDownloadIcon /></a></td>
                    <td>{item.fecha}</td>
                    <td>{MonedaPen(item.vienen)}</td>
                    <td>{MonedaPen(item.total_ingresos)}</td>
                    <td>{MonedaPen(item.total_egresos)}</td>
                    <td>{MonedaPen(item.total_saldo)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportesCuadreCaja;
