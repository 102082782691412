import React from "react";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "../../css/users.css";
import { useParams } from "react-router-dom";
import Menu from "../../comp/Menu";
import { UrlHeader, baseUrlOld, historialPagosUrl } from "../../common/Function";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class PagosHistorial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos_Prestamo: [],
      id_press: 0,
      Pagos: [],
      Hist_Pagos: [],
    };
  }

  cargarPagos() {
    const IdParam = this.props.match.params.id;
    fetch(historialPagosUrl + "?id_pres=" + IdParam, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            Hist_Pagos: datosRespuesta.data,
          });
        } else {
          this.setState({
            Hist_Pagos: [],
          });
        }
      })
      .catch((error) => console.log("error", error));
  }

  cargarDatos() {
    const IdUser = this.props.match.params.id;

    fetch(api + "Prestamos/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta.data[0]);
        if (datosRespuesta.success === true) {
          this.setState({
            Datos_Prestamo: datosRespuesta.data[0],
            id_press: IdUser,
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarPagos();
  }

  render() {
    const { Datos_Prestamo, Pagos, Hist_Pagos } = this.state;
    function BtnPago(estado, pago) {
      if (estado === "2") {
        return (
          <Link to={"/realizar-pago/" + pago} className="btn btn-danger">
            Pagado
          </Link>
        );
      } else {
        return (
          <Link to={"/realizar-pago/" + pago} className="btn btn-info">
            Pagar
          </Link>
        );
      }
    }

    return (
      <div>
        <Menu />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>PAGOS DEL PRESTAMO</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <Link
                    to={"/prestamo/" + this.state.id_press}
                    className="btn btn-info"
                  >
                    <ChevronLeftIcon /> IR AL PRESTAMO
                  </Link>
                </div>
                <div className="col">
                  <a className="btn btn-success" href={
                    baseUrlOld + "/oldreport/prestamos/descargarcronograma.php?id_pres=" +
                    this.state.id_press
                  }>CRONOGRAMA</a>
                </div>

              </div>
              <br></br>

              <div className="">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link
                      className="nav-link "
                      to={"/pagos/" + this.state.id_press}
                    >
                      POR PAGAR
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={"/pagos-todos/" + this.state.id_press}
                    >
                      TODOS LOS PAGOS
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={"/pagos-varios/" + this.state.id_press}
                    >
                      PAGAR VARIAS CUOTAS
                    </Link>
                  </li>
                  <li className="nav-item ">
                    <Link
                      className="nav-link active"
                      to={"/pagos-historial/" + this.state.id_press}
                    >
                      HISTORIAL
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="cont">
                <h4>HISTORIAL DE PAGO</h4>
                <hr></hr>
                <div className="table table-responsive">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>FECHA</td>
                        <td>SEGURO</td>
                        <td>MORA</td>
                        <td>JUNTA</td>
                        <td>GASTOS ADMIN</td>
                        <td>MONTO</td>
                      </tr>
                      {Hist_Pagos.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.fecha}</td>
                          <td>{Datos.seguro}</td>
                          <td>{Datos.mora}</td>
                          <td>{Datos.junta}</td>
                          <td>{Datos.gastos_admin}</td>
                          <td>{(parseFloat(Datos.monto) - parseFloat(Datos.gastos_admin)).toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="cont">
                <h2>DETALLE:</h2>
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <b>ASESOR:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.asesor_name}</i>
                        </td>
                        <td>
                          <b>FECHA:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.fecha}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>CLIENTE:</b>
                        </td>
                        <td>
                          <i>
                            {Datos_Prestamo.cliente_name +
                              " " +
                              Datos_Prestamo.cliente_ape1 +
                              " " +
                              Datos_Prestamo.cliente_ape2}
                          </i>
                        </td>
                        <td>
                          <b>DNI</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.cliente_dni}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>DIECCIÓN</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.negocio}</i>
                        </td>
                        <td>
                          <b>CELULAR</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.cliente_phone}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>JUNTA:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.j_total}</i>
                        </td>
                        <td>
                          <b>JUNTA PLAN:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.jp_total}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>MONTO:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.monto}</i>
                        </td>
                        <td>
                          <b>PLAZO:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.cuotas}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>TIPO:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.formapago}</i>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(PagosHistorial);
