import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import api from "../../servicios/api";

import "../../css/form.css";
import Menu from "../../comp/Menu";
import { MonedaPen } from "../../common/Function";

class Calculadora extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      error1: null,
      success: null,
      success1: null,
      monto: "",
      plazo: "",
      forma: "",
      cuota: 0,
      interes: 0,
      gatos_admin: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }

  cargarDatos() {
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    this.setState({ error: null, success: null });
    const datosEnviar = {
      monto: this.state.monto,
      plazo: this.state.plazo,
      forma: this.state.forma,
    };

    fetch(api + "Prestamos/?simular=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          console.log(datosRespuesta);
          this.setState({
            success: 1,
            error: null,
            cuota: MonedaPen(datosRespuesta.monto),
            interes: datosRespuesta.interes,
            gatos_admin: MonedaPen(datosRespuesta.gastos_admin),
          });
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    const { error } = this.state;

    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/home">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          <section className="">
            <div className="container-fluid">
              <div className="cont">
                <div className="">
                  {this.state.success && (
                    <div className="alert alert-success" role="alert">
                      SU CUOTA SERIA DE {this.state.cuota} CON UNA TASA DE
                      INTERES DE {this.state.interes} % , INCLUYE GASTOS
                      ADMINISSTRATIVO DE {this.state.gatos_admin}
                    </div>
                  )}
                  {this.state.error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  <div className="row">
                    <form className="formx" onSubmit={this.handleSubmit}>
                      <h4>SIMULA TU PRESTAMO</h4>
                      <hr></hr>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="monto">MONTO:</label>
                          <input
                            type="number"
                            name="monto"
                            id="monto"
                            className="form form-control"
                            placeholder="MONTO"
                            required
                            onChange={this.handleChange}
                            value={this.state.monto}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="plazo">PLAZO:</label>
                          <input
                            type="number"
                            name="plazo"
                            id="plazo"
                            className="form form-control"
                            placeholder="PLAZO"
                            required
                            onChange={this.handleChange}
                            value={this.state.plazo}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="forma">FORMA DE PAGO</label>
                          <select
                            className="form form-control"
                            required
                            name="forma"
                            id="forma"
                            onChange={this.handleChange}
                          >
                            <option></option>
                            <option value="dia">DIARIO</option>
                            <option value="sem">SEMANAL</option>
                          </select>
                        </div>
                      </div>
                      <div className="fbtnx">
                        <button className="btn btn-primary">CALCULAR</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Calculadora;
