import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PrintIcon from "@mui/icons-material/Print";
import "../../css/users.css";
import Menu from "../../comp/Menu";
import {
  UrlHeader,
  UrlPost,
  UserCod,
  pagosUrl,
  userUrl,
} from "../../common/Function";
import { tiposUsuariosPermitidos } from "../Common/utils";
class CobranzaDiaria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Usuarios: [],
      tip_user: "",
      Dia: [],
      Sem: [],
      Qui: [],
      Men: [],
      JuntaPlan: [],
      fecha: "",
      userId: UserCod(),
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }
  cargarDatos() {
    this.setState({ tip_user: localStorage.getItem("TIPUSER") });

    fetch(userUrl + "?estado=1", {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Usuarios: result.data,
        });
      })
      .catch((error) => console.log("error", error));
  }
  MicobranzaDiaria() {
    var dataform = {
      listapagar: 1,
      id_asesor: UserCod(),
    };

    fetch(pagosUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: UrlPost(dataform),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            Dia: result.dia,
            Sem: result.sem,
            Qui: result.qui,
            Men: result.men,
            JuntaPlan: result.ListaPorPagarJuntaPlan,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }
  Navigate() {
    sessionStorage.setItem("navigate", "/cobranza-diaria");
  }
  NavigateExist() {
    sessionStorage.removeItem("navigate");
  }

  componentDidMount() {
    this.cargarDatos();
    this.MicobranzaDiaria();
    this.NavigateExist();
  }
  render() {
    const { Usuarios, Dia, Sem, Qui, Men, JuntaPlan } = this.state;
    const DataUser = [];
    for (var i = 0; i < Usuarios.length; i++) {
      var tip_u = parseInt(Usuarios[i].tip_user);
      var t_estado = parseInt(Usuarios[i].estado);
      var tip_u_name = "";
      if (tip_u === 1) {
        tip_u_name = "Administrador";
      } else if (tip_u === 2) {
        tip_u_name = "Gerente";
      } else if (tip_u === 3) {
        tip_u_name = "Jefe de Operaciones";
      } else if (tip_u === 4) {
        tip_u_name = "Asesor";
      } else if (tip_u === 5) {
        tip_u_name = "Supervisor";
      } else if (tip_u === 6) {
        tip_u_name = "Jefe de Recuperaciones";
      } else if (tip_u === 7) {
        tip_u_name = "Auxiliar de Operaciones";
      }

      var n_estado = "";
      if (t_estado === 1 || t_estado === "1") {
        n_estado = "Activo";
      } else {
        n_estado = "Inactivo";
      }
      if (t_estado === 1 && tip_u === 4) {
        DataUser.push({
          id: Usuarios[i].id,
          name: Usuarios[i].name,
          ape1: Usuarios[i].apellido1,
          ape2: Usuarios[i].apellido2,
          dni: Usuarios[i].dni,
          phone: Usuarios[i].phone,
          email: Usuarios[i].email,
          cumple: Usuarios[i].cumple,
          tip_user: Usuarios[i].tip_user,
          cargo: tip_u_name,
          estado: n_estado,
        });
      }
    }
    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/home">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          {tiposUsuariosPermitidos.pantallaAdminCobranzaDiaria.includes(
            this.state.tip_user
          ) && (
            <section className="cont">
              <div className="container-fluid">
                <div className="row">
                  <div className="table-responsive table-stilos">
                    <h4>Lista de Usuarios</h4>
                    <hr></hr>
                    <section className="content">
                      <form className="row">
                        <div className="col-4">
                          <label>Buscar por fecha: {this.state.fecha}</label>
                          <input
                            type="date"
                            name="fecha"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.fecha}
                          ></input>
                        </div>
                      </form>
                    </section>
                    <hr></hr>
                    <table className="table">
                      <thead>
                        <tr>
                          <td>Apellidos y Nombres</td>
                          <td>DNI</td>
                          <td>Telefono</td>
                          <td>Email</td>
                          <td>IMPRIMIR</td>
                        </tr>
                      </thead>
                      <tbody>
                        {DataUser.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>
                              {Datos.ape1} {Datos.ape2} {Datos.name}
                            </td>
                            <td>{Datos.dni}</td>
                            <td>{Datos.phone}</td>
                            <td>{Datos.email}</td>
                            <td>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                  this.state.fecha === ""
                                    ? api +
                                      "DescargarPdf/Reportes/descargardiario.php?id_user=" +
                                      Datos.id
                                    : api +
                                      "DescargarPdf/Reportes/descargardiario.php?id_user=" +
                                      Datos.id +
                                      "&fini=" +
                                      this.state.fecha +
                                      "&ffin=" +
                                      this.state.fecha
                                }
                                className="btn btn-primary"
                              >
                                <PrintIcon />
                              </a>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                  this.state.fecha === ""
                                    ? api +
                                      "oldreport/prestamos/descargardetalle.php?id_user=" +
                                      Datos.id
                                    : api +
                                      "oldreport/prestamos/descargardetalle.php?id_user=" +
                                      Datos.id +
                                      "&fini=" +
                                      this.state.fecha +
                                      "&ffin=" +
                                      this.state.fecha
                                }
                                className="btn btn-success"
                              >
                                <RemoveRedEyeIcon />
                              </a>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={
                                  this.state.fecha === ""
                                    ? api +
                                      "oldreport/prestamos/descargardetalle_0.php?id_user=" +
                                      Datos.id
                                    : api +
                                      "oldreport/prestamos/descargardetalle_0.php?id_user=" +
                                      Datos.id +
                                      "&fini=" +
                                      this.state.fecha +
                                      "&ffin=" +
                                      this.state.fecha
                                }
                                className="btn btn-warning"
                              >
                                <RemoveRedEyeIcon />
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          )}
          {tiposUsuariosPermitidos.pantallaAsesorCobranzaDiaria.includes(
            this.state.tip_user
          ) && (
            <div className="cont">
              <h4>MI COBRANZA DIARIA</h4>
              <div className="row">
                <div className="col-4">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      api +
                      "DescargarPdf/Reportes/descargardiario.php?id_user=" +
                      this.state.userId
                    }
                    className="btn btn-primary w-100"
                  >
                    <PrintIcon />
                  </a>
                </div>
                <div className="col-4">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      api +
                      "oldreport/prestamos/descargardetalle.php?id_user=" +
                      this.state.userId
                    }
                    className="btn btn-success w-100"
                  >
                    <RemoveRedEyeIcon />
                  </a>
                </div>
                <div className="col-4">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      api +
                      "oldreport/prestamos/descargardetalle_0.php?id_user=" +
                      this.state.userId
                    }
                    className="btn btn-warning w-100"
                  >
                    <RemoveRedEyeIcon />
                  </a>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>CLIENTE</th>
                      <th>CUOTA</th>
                      <th>TELEFONO</th>
                      <th>DIRECCION</th>
                      <th>MONTO</th>
                      <th>TIPO</th>
                      <th>PLAZO</th>
                      <th>DIAS VENCIDO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Men.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>
                          <Link
                            className="btn btn-warning"
                            to={"/realizar-pago/" + Datos.id}
                            onClick={() => this.Navigate()}
                          >
                            <RemoveRedEyeIcon />
                          </Link>
                        </td>
                        <td>
                          {Datos.ape1} {Datos.ape2} {Datos.name}
                        </td>
                        <td>{Datos.cuota_total}</td>
                        <td>{Datos.telefonos}</td>
                        <td>{Datos.direccion}</td>
                        <td>{Datos.monto}</td>
                        <td>Mensual</td>
                        <td>
                          {Number(Datos.cuotas) -
                            Number(Datos.cuotasr) +
                            "/" +
                            Datos.cuotas}
                        </td>
                        <td>{Datos.diferencia_en_dias}</td>
                      </tr>
                    ))}
                    {Qui.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>
                          <Link
                            className="btn btn-warning"
                            to={"/realizar-pago/" + Datos.id}
                            onClick={() => this.Navigate()}
                          >
                            <RemoveRedEyeIcon />
                          </Link>
                        </td>
                        <td>
                          {Datos.ape1} {Datos.ape2} {Datos.name}
                        </td>
                        <td>{Datos.cuota_total}</td>
                        <td>{Datos.telefonos}</td>
                        <td>{Datos.direccion}</td>
                        <td>{Datos.monto}</td>
                        <td>Quincenal</td>
                        <td>
                          {Number(Datos.cuotas) -
                            Number(Datos.cuotasr) +
                            "/" +
                            Datos.cuotas}
                        </td>
                        <td>{Datos.diferencia_en_dias}</td>
                      </tr>
                    ))}
                    {Sem.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>
                          <Link
                            className="btn btn-warning"
                            to={"/realizar-pago/" + Datos.id}
                            onClick={() => this.Navigate()}
                          >
                            <RemoveRedEyeIcon />
                          </Link>
                        </td>
                        <td>
                          {Datos.ape1} {Datos.ape2} {Datos.name}
                        </td>
                        <td>{Datos.cuota_total}</td>
                        <td>{Datos.telefonos}</td>
                        <td>{Datos.direccion}</td>
                        <td>{Datos.monto}</td>
                        <td>Semanal</td>
                        <td>
                          {Number(Datos.cuotas) -
                            Number(Datos.cuotasr) +
                            "/" +
                            Datos.cuotas}
                        </td>
                        <td>{Datos.diferencia_en_dias}</td>
                      </tr>
                    ))}
                    {Dia.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>
                          <Link
                            className="btn btn-warning"
                            to={"/realizar-pago/" + Datos.id}
                            onClick={() => this.Navigate()}
                          >
                            <RemoveRedEyeIcon />
                          </Link>
                        </td>
                        <td>
                          {Datos.ape1} {Datos.ape2} {Datos.name}
                        </td>
                        <td>{Datos.cuota_total}</td>
                        <td>{Datos.telefonos}</td>
                        <td>{Datos.direccion}</td>
                        <td>{Datos.monto}</td>
                        <td>Diario</td>
                        <td>
                          {Number(Datos.cuotas) -
                            Number(Datos.cuotasr) +
                            "/" +
                            Datos.cuotas}
                        </td>
                        <td>{Datos.diferencia_en_dias}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="table-responsive">
                <h4>JUNTA PLAN</h4>
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>CLIENTE</th>
                      <th>TIPO</th>
                      <th>PLAN</th>
                      <th>CUOTAS</th>
                      <th>TOTAL</th>
                      <th>TELEFONO</th>
                      <th>DIRECCION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {JuntaPlan.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>
                          <Link
                            className="btn btn-info"
                            to={"/juntaplan-ver/" + Datos.id}
                            onClick={() => this.Navigate()}
                          >
                            <RemoveRedEyeIcon />
                          </Link>
                        </td>
                        <td>
                          {Datos.ape1} {Datos.ape2} {Datos.name}
                        </td>
                        <td>{Datos.tipo}</td>
                        <td>{Datos.plan}</td>
                        <td>{Datos.coutas}</td>
                        <td>{Datos.monto}</td>
                        <td>{Datos.telefonos}</td>
                        <td>{Datos.direccion}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CobranzaDiaria;
