import React from "react";
import Aside from "../../comp/Aside";
import Footer from "../../comp/Footer";
import Navbar from "../../comp/Navbar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../servicios/api";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ListAltIcon from "@mui/icons-material/ListAlt";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ListIcon from "@mui/icons-material/List";
import ErrorIcon from "@mui/icons-material/Error";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ClassIcon from "@mui/icons-material/Class";
import "../../css/table.css";

class PrestamosCanceladosHoy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Prestamos: [],
    };
  }

  cargarDatos() {

    fetch(api + "Prestamos/?cancelados_hoy=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({ Prestamos: datosRespuesta.data });
        }
        console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  Evaluar = (id) => {
    Swal.fire({
      title: "Extornar Prestamo",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Aprobar",
      denyButtonText: `Desaprobar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        //Cerrar Sesion
        Swal.fire(
          "Aprobar Extorno!",
          "Felicidades, aprobo este credito.",
          "success"
        );

        fetch(api + "Prestamos/?extornar="+ id)
          .then((respuesta) => respuesta.json())
          .then((datosRespuesta) => {
            //console.log(datosRespuesta);
          })
          .catch(console.log);

        //console.log("aprobado" + id);
      } else if (result.isDenied) {
        Swal.fire("Extorno Cancelado!", "Quizá la proxima.", "warning");
        //console.log("Desaprobado" + id);
      }

      this.cargarDatos();
    });
  };

  Detalle = (id) => {
    console.log(id);
    fetch(api + "Prestamos/?consultar=" + id)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          Swal.fire({
            title: "<strong>SOLICITUD DE CREDITO</strong>",
            icon: "info",
            html:
              "<div><div class='table-responsive'><table class='table'> " +
              "<tr><td><b>Asesor</b></td><td>" +
              datosRespuesta.data[0].id_user +
              "</td>" +
              "<td><b>Fecha</b></td><td>" +
              datosRespuesta.data[0].fecha +
              "</td></tr>" +
              "<tr><td><b>Nombre de cliente</b></td><td>" +
              datosRespuesta.data[0].cliente_name +
              " " +
              datosRespuesta.data[0].cliente_ape1 +
              "</td>" +
              "<td><b>DNI</b></td><td>" +
              datosRespuesta.data[0].cliente_dni +
              " </td></tr>" +
              "<tr><td><b>DIRECCION DE NEGOCIO</b></td><td>" +
              datosRespuesta.data[0].cliente_name +
              " " +
              datosRespuesta.data[0].cliente_ape1 +
              "</td>" +
              "<td><b>CELULAR</b></td><td>" +
              datosRespuesta.data[0].cliente_dni +
              " </td></tr>" +
              "</table></div></div>",
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Estupendo!!",
            confirmButtonAriaLabel: "Thumbs up, great!",
            cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
            cancelButtonAriaLabel: "Thumbs down",
          });
        }
        console.log(datosRespuesta);
      })
      .catch(console.log);
  };
  render() {
    const { Prestamos } = this.state;
    return (
      <div>
        <Navbar />
        <Aside />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>PRESTAMOS</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link className="nav-link" to="/prestamos">
                        <AssignmentIcon />
                        Pendientes
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/prestamosactivos">
                        <PlaylistAddCheckIcon />
                        Activos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/prestamosadd">
                        <PersonAddAltIcon />
                        Agregar
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        to="/prestamos-cancelados-hoy"
                      >
                        <FormatListNumberedIcon />
                        Cancelados Hoy
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="table-responsive cont-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Codigo</th>
                        <th>Cliente</th>
                        <th>Monto</th>
                        <th>Detalle</th>
                        <th>Riesgo</th>
                        <th>Historial</th>
                        <th>Juntas</th>
                        <th>Tipo</th>
                        <th>Extorno</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Prestamos.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.id}</td>
                          <td>
                            {Datos.cliente_ape1} {Datos.cliente_ape2}{" "}
                            {Datos.cliente_name}{" "}
                          </td>
                          <td>s/{Datos.monto}</td>
                          <td>
                            <a
                              className="btn btn-primary"
                              onClick={() => this.Detalle(Datos.id)}
                            >
                              <ListAltIcon />
                            </a>
                          </td>
                          <td>
                            <a className="btn btn-danger">
                              <ErrorIcon /> {Datos.c_riesgo}
                            </a>
                          </td>
                          <td>
                            <ListIcon />
                          </td>
                          <td>
                            <LocalAtmIcon /> {Datos.j_total}/{Datos.jp_total}{" "}
                            <br></br>Cobertura:{" "}
                            {(
                              (Number(Datos.j_total) + Number(Datos.jp_total)) /
                              Number(Datos.monto)
                            ).toFixed(2)}
                          </td>
                          <td>
                            <ClassIcon /> {Datos.p_tip}
                          </td>
                          <td>
                            <a
                              className="btn btn-success"
                              onClick={() => this.Evaluar(Datos.id)}
                            >
                              <FactCheckIcon />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PrestamosCanceladosHoy;
