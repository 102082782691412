import React from "react";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
import "../../css/users.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { apiToken, reportesUrl, FechaActual } from "../../common/Function";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import ReportesGeneralInfocorp from "./ReportesGeneralInfocorp";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
class ReportesGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.hijoRef = React.createRef();
    this.state = {
      Datos: [],
      currentPage: 1,
      starat: 0,
      endat: 10,
      total: 0,
      total_paginas: 0,
      totalPages: [],
      busqueda: "",
      fecha_ini: FechaActual(),
      fecha_ini_state: null,
      fecha_fin: FechaActual(),
      fecha_fin_state: null,
      TipReporte: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
    if (t_name === "TipReporte") {
      this.ValidadorTipo(target.value);
    }
  }
  ValidadorTipo(tipo) {
    if (tipo === "") {
      this.setState({
        fecha_ini_state: null,
        fecha_fin_state: null,
      });
    } else if (tipo === "infocorp") {
      this.setState({
        fecha_ini_state: null,
        fecha_fin_state: 1,
      });
    }
  }
  BuscarDatos(pagine) {
    if (this.state.TipReporte !== "") {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", apiToken);
      var formdata = new FormData();
      formdata.append(this.state.TipReporte, "1");
      formdata.append("fecha_ini", this.state.fecha_ini);
      formdata.append("fecha_fin", this.state.fecha_fin);
      formdata.append("starat", pagine);
      formdata.append("endat", this.state.endat);

      fetch(reportesUrl, {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          this.setState({
            Datos: result.data,
            total: result.total,
            total_paginas: Math.ceil(result.total / this.state.endat),
          });
        })
        .catch((error) => console.log("error", error));
      this.hijoRef.current.CargarTabla();
    }
  }

  componentDidMount() {}
  NumeroPaginas(numPages) {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push({ id: i - 1, name: i });
    }
    this.setState({
      totalPages: pages,
    });
  }
  CambiarPagina(page) {
    this.setState({ starat: page });
    page = page * this.state.endat;
    this.BuscarDatos(page);
  }
  LimpiarFormulario() {
    this.setState({
      Datos: [],
      currentPage: 1,
      starat: 0,
      endat: 10,
      total: 0,
      total_paginas: 0,
      totalPages: [],
      busqueda: "",
      fecha_ini: FechaActual(),
      fecha_ini_state: null,
      fecha_fin: FechaActual(),
      fecha_fin_state: null,
      TipReporte: "",
    });
  }
  DescargarDatos() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", apiToken);

    var formdata = new FormData();
    formdata.append(this.state.TipReporte, "1");
    formdata.append("fecha_ini", this.state.fecha_ini);
    formdata.append("fecha_fin", this.state.fecha_fin);
    formdata.append("download", "1");
    fetch(reportesUrl, {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = this.nameDoc();
        link.click();
      })
      .catch((error) => console.log("error", error));
  }
  nameDoc() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    const hours = today.getHours().toString().padStart(2, "0");
    const minutes = today.getMinutes().toString().padStart(2, "0");
    const seconds = today.getSeconds().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;
    return this.state.TipReporte + "_" + formattedDate + ".xls";
  }
  render() {
    return (
      <div id="prestamos">
        <Menu />

        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/home">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          <div className="content-wrapper cont">
            <section className="content-body">
              <div className="form row">
                <div className="col-9">
                  <h4>FORMULARIO DE REPORTES</h4>
                  <hr></hr>
                  <div className="row">
                    <div className="col-4">
                      <label>Tipo de Reporte</label>
                      <select
                        className="form-control"
                        name="TipReporte"
                        onChange={this.handleChange}
                        required
                        value={this.state.TipReporte}
                      >
                        <option value="">Selecionar Reporte</option>
                        <option value="infocorp">Infocorp</option>
                        <option defaultValue="mora">Mora</option>
                      </select>
                    </div>
                    <div className="col-4">
                      {this.state.fecha_ini_state ? (
                        <div>
                          <label>Fecha Inicio:</label>
                          <input
                            type="date"
                            name="fecha_ini"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.fecha_ini}
                          ></input>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-4">
                      {this.state.fecha_fin_state ? (
                        <div>
                          <label>Fecha Fin:</label>
                          <input
                            type="date"
                            name="fecha_fin"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.fecha_fin}
                          ></input>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  {this.state.TipReporte === "" ? null : (
                    <div>
                      <button
                        className="btn btn-info w-100 mb-1"
                        onClick={() => this.BuscarDatos(this.state.starat)}
                      >
                        <SearchIcon />
                        Buscar
                      </button>
                      <button
                        className="btn btn-success w-100 mb-1"
                        onClick={() => this.DescargarDatos()}
                      >
                        <FileDownloadIcon /> Exportar
                      </button>
                      <button
                        className="btn btn-danger w-100 mb-1"
                        onClick={() => this.LimpiarFormulario()}
                      >
                        <CleaningServicesIcon />
                        Limpiar
                      </button>
                    </div>
                  )}
                </div>
              </div>
              {this.state.TipReporte === "infocorp" ? (
                <ReportesGeneralInfocorp
                  data={this.state.Datos}
                  fechapago={this.state.fecha_fin}
                  ref={this.hijoRef}
                />
              ) : null}

              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={
                      this.state.starat === 0
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      onClick={() => this.CambiarPagina(this.state.starat - 1)}
                    >
                      <NavigateBeforeIcon />
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link">
                      {this.state.starat + 1 + "/" + this.state.total_paginas}
                    </a>
                  </li>
                  <li
                    className={
                      this.state.starat === this.state.total_paginas - 1
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      onClick={() => this.CambiarPagina(this.state.starat + 1)}
                    >
                      <NavigateNextIcon />
                    </a>
                  </li>
                </ul>
              </nav>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default ReportesGeneral;
