import React from "react";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
import "../../css/users.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SearchIcon from "@mui/icons-material/Search";
import {
  prestamosUrl,
  UrlGet,
  UrlHeader,
  MisDatos,
  FechaActual,
  userUrl,
  cajaUrl,
  redondearADosDecimales,
  estadosPrestamoName,
} from "../../common/Function";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { exportToExcel } from "../Common/MaterialExportToExel";
import ArticleIcon from "@mui/icons-material/Article";
import { Riesgo, tiposUsuariosPermitidos } from "../Common/utils";
import { columnsPrestamosList } from "../../common/ExportColums";
import Carga from "../Common/Carga";
import Autocomplete from "../Common/MaterialAutocomplete";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import EditIcon from "@mui/icons-material/Edit";
class PrestamosSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Prestamos: [],
      currentPage: 1,
      starat: 0,
      endat: 10,
      total: 0,
      total_paginas: 0,
      totalPages: [],
      busqueda: "",
      Asesores: [],
      Carteras: [],

      name: "",
      dni: "",
      email: "",
      cargo: "",
      pass1: "",
      pass2: "",

      asesor: "",
      cartera: "",
      filter: "",
      formapago: "",
      estado: "3",
      castigado: "No",
      base_negativa: "No",
      fechaini: FechaActual(),
      fechafin: FechaActual(),
      tipoUsuario: localStorage.getItem("TIPUSER"),
    };
    this.handleChange = this.handleChange.bind(this);
    this.filterPagina = this.filterPagina.bind(this);
    this.AutocompleteAsesores = this.AutocompleteAsesores.bind(this);
    this.AutocompleteCarteras = this.AutocompleteCarteras.bind(this);
    this.autocompleteRefAsesores = React.createRef();
    this.autocompleteRefCarteras = React.createRef();
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }
  AutocompleteAsesores(event) {
    this.setState({
      asesor: event,
    });
  }
  AutocompleteCarteras(event) {
    this.setState({
      cartera: event,
    });
  }
  cargarDatos(data) {
    fetch(prestamosUrl + UrlGet(data), {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Prestamos: result.data,
          total: result.total,
          total_paginas: Math.ceil(result.total / this.state.endat),
        });
      })
      .catch((error) => console.log("error", error));
  }

  CambiarPagina(page) {
    this.setState({ starat: page });
    page = page * this.state.endat;
    var data = this.DatosForm();
    data.starat = page;
    this.cargarDatos(data);
  }
  filterPagina(event) {
    this.BuscarDatos();
    event.preventDefault();
  }
  componentDidMount() {
    this.cargaInicial();
    this.BuscarDatos();
    var data = MisDatos();
  }
  DatosForm() {
    var data = {
      starat: this.state.starat,
      endat: this.state.endat,
      asesor: this.state.asesor,
      cartera: this.state.cartera,
      filter: this.state.filter,
      formapago: this.state.formapago,
      estado: this.state.estado,
      castigado: this.state.castigado,
      fechaini: this.state.fechaini,
      fechafin: this.state.fechafin,
      base_negativa: this.state.base_negativa,
    };
    return data;
  }
  BuscarDatos() {
    var data = this.DatosForm();
    this.cargarDatos(data);
  }
  LimpiarFormulario() {
    this.setState({
      Prestamos: [],
      total: 0,
      total_paginas: 0,
      fechaini: "",
      fechafin: "",
      filter: "",
      formapago: "",
      estado: "TODOS",
      castigado: "No",
      cartera: "",
      asesor: "",
      base_negativa: "No",
    });
    this.autocompleteRefAsesores.current.clearAutocomplete();
    this.autocompleteRefCarteras.current.clearAutocomplete();
  }

  DescargarDatos() {
    this.setState({
      carga: 1,
    });
    var data = this.DatosForm();
    data.endat = null;
    data.starat = null;

    fetch(prestamosUrl + UrlGet(data), {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          exportToExcel(result.data, columnsPrestamosList, "Prestamos_Lista");
        }
        this.setState({
          carga: null,
        });
      })
      .catch((error) => console.log("error", error));
  }
  cargaInicial() {
    this.cargarUser();
    this.carterasUser();
  }
  cargarUser() {
    fetch(userUrl + "?estado=1", {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Asesores: result.data,
        });
      })
      .catch((error) => console.log("error", error));
  }

  carterasUser() {
    fetch(cajaUrl + "?estado=1", {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Carteras: result.data,
        });
      })
      .catch((error) => console.log("error", error));
  }

  render() {
    const { Prestamos } = this.state;
    return (
      <div id="prestamos">
        {this.state.carga ? <Carga /> : null}
        <Menu />

        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/home">
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          {tiposUsuariosPermitidos.btnPrestamoAgregar.includes(
            this.state.tipoUsuario
          ) && (
            <Link className="btn btn-primary" to="/prestamosadd">
              <AddCircleIcon />
              AGREGAR
            </Link>
          )}{" "}
          {tiposUsuariosPermitidos.btnPrestamoReasignar.includes(
            this.state.tipoUsuario
          ) && (
            <Link className="btn btn-primary" to="/prestamos-reasignar">
              <FlipCameraAndroidIcon />
              REASIGNAR MASIVO
            </Link>
          )}{" "}
          <div className="content-wrapper cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <h1>PRESTAMOS ({this.state.total})</h1>
                </div>
              </div>
            </section>

            <section className="row">
              <div className="col-9">
                <div className="row">
                  <div className="col-4">
                    <label>Busqueda:</label>
                    <input
                      type="text"
                      name="filter"
                      className="form-control"
                      placeholder="Bussqueda por nombre,dni"
                      onChange={this.handleChange}
                      value={this.state.filter}
                    ></input>
                  </div>
                  <div className="col-4">
                    <label>Desde:</label>
                    <input
                      type="date"
                      name="fechaini"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.fechaini}
                    ></input>
                  </div>
                  <div className="col-4">
                    <div>
                      <label>Hasta:</label>
                      <input
                        type="date"
                        name="fechafin"
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.fechafin}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <label>Forma de Pago:</label>
                    <select
                      name="formapago"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.formapago}
                    >
                      <option>Todos</option>
                      <option value="dia">Diario</option>
                      <option value="sem">Semanal</option>
                      <option value="quin">Quincenal</option>
                      <option value="men">Mensual</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <label>Estado:</label>
                    <select
                      name="estado"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.estado}
                    >
                      <option value="TODOS">Todos</option>
                      <option value="0">PRE APROBADO</option>
                      <option value="1">POR DESEMBOLAR</option>
                      <option value="2">NEGADO</option>
                      <option value="3">ACTIVO</option>
                      <option value="4">CANCELADO</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <label>Castigado:</label>
                    <select
                      name="castigado"
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.castigado}
                    >
                      <option>No</option>
                      <option value="1">si</option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <label>Asesor:</label>
                    <Autocomplete
                      allOptions={this.state.Asesores}
                      onSelect={this.AutocompleteAsesores}
                      ref={this.autocompleteRefAsesores}
                    />
                  </div>
                  <div className="col-4">
                    <label>Cartera:</label>
                    <Autocomplete
                      allOptions={this.state.Carteras}
                      onSelect={this.AutocompleteCarteras}
                      ref={this.autocompleteRefCarteras}
                    />
                  </div>
                  <div className="col-4"></div>
                </div>
              </div>
              <div className="col-3">
                <div>
                  <button
                    className="btn btn-info w-100 mb-1"
                    onClick={() => this.BuscarDatos()}
                  >
                    <SearchIcon />
                    Buscar
                  </button>
                  <button
                    className="btn btn-success w-100 mb-1"
                    onClick={() => this.DescargarDatos()}
                  >
                    <FileDownloadIcon /> Exportar
                  </button>
                  <button
                    className="btn btn-danger w-100 mb-1"
                    onClick={() => this.LimpiarFormulario()}
                  >
                    <CleaningServicesIcon />
                    Limpiar
                  </button>
                </div>
              </div>
            </section>
            <br></br>
            <section className="content-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead className="bg-info">
                    <tr>
                      <th>CÓDIGO</th>
                      <th>ESTADO</th>
                      <th>RIESGO</th>
                      <th>FECHA</th>
                      <th>CLIENTE</th>
                      <th>MONTO</th>
                      <th>CA</th>
                      <th>PLAZO</th>
                      <th>SEGURO</th>
                      <th>JUNTAS</th>
                      <th>TIPO</th>
                      <th>ASESOR</th>
                      <th>CAJA</th>
                      <th>ACCIONES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Prestamos.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td className="text-center">{Datos.id}</td>
                        <td className="text-center">
                          {estadosPrestamoName(Datos.estado)}
                        </td>
                        <td className="text-center">
                          {Riesgo(Datos.riesgo, Datos.formapago)}
                        </td>
                        <td className="text-center">{Datos.fecha}</td>
                        <td className="text-center">
                          {Datos.name + " " + Datos.ape1 + " " + Datos.ape2}
                        </td>
                        <td className="text-center">
                          {redondearADosDecimales(Datos.monto)}
                        </td>
                        <td className="text-center">
                          {redondearADosDecimales(Datos.cartera_Activa)}
                        </td>
                        <td className="text-center">{Datos.cuotas}</td>
                        <td className="text-center">{Datos.desgrav}</td>
                        <td className="text-center">
                          {Datos.junta + "/" + Datos.junta_plan}
                        </td>
                        <td className="text-center">{Datos.formapago}</td>
                        <td className="text-center">{Datos.asesor_name}</td>
                        <td className="text-center">{Datos.caja_name}</td>
                        <td className="text-center">
                          {Datos.estado === "3" || Datos.estado === "4" ? (
                            <Link
                              to={"/pagos/" + Datos.id}
                              className="btn btn-warning"
                              title="Pagos"
                            >
                              <PointOfSaleIcon />
                            </Link>
                          ) : null}
                          <a
                            href={
                              "https://altrisa.site/apiold/oldreport/prestamos/descargarcronograma.php?id_pres=" +
                              Datos.id
                            }
                            target="_blank"
                            rel="noopener"
                            className="btn btn-success"
                            title="Cronograma"
                          >
                            <ArticleIcon />
                          </a>
                          <Link
                            to={"/prestamo/" + Datos.id}
                            className="btn btn-info"
                            title="detalle de prestamo"
                          >
                            <RemoveRedEyeIcon />
                          </Link>
                          <Link
                            to={"/prestamo/edit/" + Datos.id}
                            className="btn btn-warning"
                            title="editar de prestamo"
                          >
                            <EditIcon />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={
                      this.state.starat === 0
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      onClick={() => this.CambiarPagina(this.state.starat - 1)}
                    >
                      <NavigateBeforeIcon />
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link">
                      {this.state.starat + 1 + "/" + this.state.total_paginas}
                    </a>
                  </li>
                  <li
                    className={
                      this.state.starat === this.state.total_paginas - 1
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      onClick={() => this.CambiarPagina(this.state.starat + 1)}
                    >
                      <NavigateNextIcon />
                    </a>
                  </li>
                </ul>
              </nav>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default PrestamosSearch;
