import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
//import Swal from "sweetalert2";
import api from "../../servicios/api";
import "../../css/form.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { FechaActual } from "../../common/Function";
class ReportesInfoCont extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Caja: [],
      fecha_ini: FechaActual(),
      fecha_fin: FechaActual(),
      formapago: "dia",
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }
  cargarDatos() {
    fetch(api + "Cartera/?activo=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({ Caja: datosRespuesta.data });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    const { Caja } = this.state;
    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/reportes">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>REPORTE CONTADORA DEL MES</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="cont">
              <h4>CONDICIONES</h4>
              <hr></hr>
              <ul>
                <li>No cuenta los prestamos Diarios.</li>
                <li>Separado por Cartera.</li>
                <li>
                  (2024-02-06) Se agrego las fechas para que se puedan descargar
                  por un rango.
                </li>
              </ul>
            </div>
            <div className="cont">
              <h4>CARTERAS</h4>
              <hr></hr>
              <div className="row">
                <div className="col-4">
                  <label>Fecha Desde:</label>
                  <input
                    className="form-control"
                    name="fecha_ini"
                    type="date"
                    required
                    onChange={this.handleChange}
                    value={this.state.fecha_ini}
                  ></input>
                </div>
                <div className="col-4">
                  <label>Fecha Hasta:</label>
                  <input
                    className="form-control"
                    name="fecha_fin"
                    type="date"
                    required
                    onChange={this.handleChange}
                    value={this.state.fecha_fin}
                  ></input>
                </div>
                <div className="col-4">
                  <label>Tipo</label>
                  <select
                    className="form-control"
                    value={this.state.formapago}
                    name="formapago"
                    onChange={this.handleChange}
                  >
                    {/* <option value={null}>Todos</option> */}
                    <option value="dia">Diario</option>
                    <option value="sem">Semanal</option>
                    <option value="quin">Quincenal</option>
                    <option value="men">Mensual</option>
                  </select>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>NOMBRE</th>
                      <th>DESCARGAR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Caja.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>{Datos.name}</td>
                        <td>
                          {" "}
                          <a
                            href={
                              api +
                              "Excel/create_excel.php?caja=" +
                              Datos.id +
                              "&fecha_ini=" +
                              this.state.fecha_ini +
                              "&fecha_fin=" +
                              this.state.fecha_fin+
                              "&formapago=" +
                              this.state.formapago
                            }
                            className="btn btn-primary"
                          >
                            {" "}
                            <SaveAltIcon /> Exportar{" "}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <th>NOMBRE</th>
                    <th>DESCARGAR</th>
                  </tfoot>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ReportesInfoCont;
