import React from "react";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/users.css";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Menu from "../../comp/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  UrlHeader,
  UrlPost,
  esFechaHoy,
  juntaUrl,
} from "../../common/Function";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { tiposUsuariosPermitidos } from "../Common/utils";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class JuntaView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosJunta: [],
      nombre: "",
      dni: "",
      monto: "",
      asesor: "",
      fecha: "",
      Asesor: [],
      junta_plan: "",
      junta: "",
      Historial: [],
      asesor_id: 26,
      error: null,
      success: null,
      monto_a: "",
      success2: null,
      cargando2: null,
      error2: null,
      respuesta: "",
      tipoUsuario: localStorage.getItem("TIPUSER"),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  cargarDatos() {
    const IdUser = this.props.match.params.id;
    this.setState({
      id_cliente: IdUser,
    });

    fetch(api + "Junta/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.cargarJuntas(datosRespuesta.data[0].id);
      })
      .catch(console.log);
  }

  cargarJuntas(id) {
    fetch(juntaUrl + id, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          datosJunta: result.data,
        });
      })
      .catch((error) => console.log("error", error));
  }

  cargarHistorial() {
    const IdUser = this.props.match.params.id;
    fetch(api + "Junta/?historial=" + IdUser, {
      method: "POST",
      body: JSON.stringify(),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          const historialData = datosRespuesta.data.map((elemento) => ({
            id: elemento.id,
            fecha: elemento.fecha,
            id_junta: elemento.id_junta,
            id_juntap: elemento.id_juntap,
            monto: parseFloat(elemento.monto).toFixed(2),
            data: JSON.parse(elemento.data),
          }));
          this.setState({
            Historial: historialData,
          });
        } else {
          this.setState({
            Historial: [],
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarHistorial();
  }

  BaseNegativa = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          var valor = this.state.base;
          if (valor === "0") {
            valor = 1;
          } else {
            valor = 0;
          }
          fetch(api + "Cliente/?base_nega=" + id + "&valor=" + valor)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              this.cargarDatos();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "Actualizado!",
            "Su estado fue actualizado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Solicitud cancelada :)",
            "error"
          );
        }
      });

    this.cargarDatos();
  };

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const IdJunta = this.props.match.params.id;
    const datosEnviar = {
      id: IdJunta,
      monto: this.state.monto_a,
      pagar: 1,
    };
    this.setState({
      cargando2: 1,
    });

    fetch(juntaUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: UrlPost(datosEnviar),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success2: 1,
            error2: null,
            cargando2: null,
            respuesta: "actualizacion correcta",
          });
          this.cargarDatos();
          this.cargarHistorial();
        } else {
          this.setState({
            error2: datosRespuesta.error,
            success2: null,
            cargando2: null,
          });
        }
      })
      .catch((error) => console.log("error", error));

    event.preventDefault();
  }
  CancelarPago(id) {
    Swal.fire({
      title: "Esta seguro de cancelar el pago?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "SI QUIERO CONTINUAR",
      denyButtonText: `NO`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(juntaUrl, {
          method: "POST",
          headers: UrlHeader(),
          body: UrlPost({ cancelar: 1, id_pago: id }),
          redirect: "follow",
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.success === true) {
              Swal.fire("Se cancelo el pago correctamente!", "", "success");
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: result.msg,
              });
            }
            this.cargarDatos();
            this.cargarHistorial();
          })
          .catch((error) => console.log("error", error));
      }
    });
  }

  render() {
    const { datosJunta, Historial } = this.state;
    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link to={"/junta"} className="btn btn-primary">
            <ChevronLeftIcon /> Volver
          </Link>

          <section className="cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>JUNTA</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/#/inicio">Inicio</Link>
                      </li>
                      <li className="breadcrumb-item active">home</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="">
                    <h4>DATOS DE JUNTA</h4>
                    <hr></hr>
                    <div className="row">
                      <div className="col">
                        <h4>DATOS DE CLIENTE</h4>
                        <hr></hr>
                        <p>
                          <b>NOMBRE:</b>{" "}
                          {datosJunta.cli_name +
                            " " +
                            datosJunta.cli_ape1 +
                            " " +
                            datosJunta.cli_ape2}
                        </p>
                        <p>
                          <b>DNI:</b> {datosJunta.cli_dni}
                        </p>
                      </div>
                      <div className="col">
                        <h4>DATOS DE JUNTA</h4>
                        <hr></hr>
                        <p>
                          <b>MONTO:</b> {datosJunta.monto}
                        </p>
                        <p>
                          <b>ASESOR:</b> {datosJunta.user_name}{" "}
                          {datosJunta.user_ape1} {datosJunta.user_ape2}
                        </p>
                        <p>
                          <b>FECHA INICIO:</b> {datosJunta.fecha}
                        </p>
                      </div>
                    </div>
                    <div className="col">
                      <div className="thead">
                        <div className="row">
                          <div
                            className="col rounded p-2"
                            style={{ backgroundColor: "#f0f8ff" }}
                          >
                            <b className="text-muted">Junta</b>
                          </div>
                          <div
                            className="col rounded p-2"
                            style={{ backgroundColor: "#f5ffef" }}
                          >
                            <b className="text-muted">Junta Plan</b>
                          </div>
                        </div>
                      </div>
                      <div className="tbody">
                        <div className="row">
                          <div
                            className="col rounded p-2"
                            style={{ backgroundColor: "#f0f8ff" }}
                          >
                            <span className="text-muted">
                              {datosJunta.junta}
                            </span>
                          </div>
                          <div
                            className="col rounded p-2"
                            style={{ backgroundColor: "#f5ffef" }}
                          >
                            <span className="text-muted">
                              {datosJunta.junta_plan}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="cont p-3 mb-2 bg-secondary text-white">
                    <form className="form" onSubmit={this.handleSubmit}>
                      <h4>AÑADIR O RETIRAR FONDOS</h4>
                      <input
                        type="number"
                        name="monto_a"
                        required
                        className="form-control"
                        placeholder="Monto"
                        step="0.01"
                        onChange={this.handleChange}
                      ></input>
                      <i>
                        (El número positivo para añadir y negativo para
                        retirar.)
                      </i>
                      <br></br>
                      <button className="btn btn-info">Procesar</button>
                    </form>
                    {this.state.success2 && (
                      <div className="alert alert-success" role="alert">
                        {this.state.respuesta}
                      </div>
                    )}
                    {this.state.cargando2 && (
                      <div className="alert alert-info" role="alert">
                        ESPERANDO RESPUESTA DE SERVIDOR
                      </div>
                    )}
                    {this.state.error2 && (
                      <div className="alert alert-danger" role="alert">
                        {this.state.error2}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="cont">
                <h4>DETALLE DE JUNTA</h4>
                <hr></hr>
                <table className="table table-striped">
                  <thead>
                    <tr className="text-center bg-dark text-white">
                      <td>FECHA</td>
                      <td>MONTO INICIO</td>
                      <td>MONTO</td>
                      <td>MONTO ACUMULADO</td>
                      {tiposUsuariosPermitidos.btnJuntaEliminarPagos.includes(
                        this.state.tipoUsuario
                      ) && <td></td>}
                    </tr>
                  </thead>
                  <tbody>
                    {Historial.map((Datos) => (
                      <tr
                        key={Datos.id}
                        className={
                          Datos.monto > 0
                            ? "text-center bg-success text-white"
                            : "text-center bg-danger text-white"
                        }
                      >
                        <td>{Datos.fecha}</td>
                        <td>
                          s/ {Datos.data ? Datos.data.monto_actual : null}
                        </td>
                        <td>s/ {Datos.monto}</td>
                        <td>s/ {Datos.data ? Datos.data.monto_nuevo : null}</td>
                        {tiposUsuariosPermitidos.btnJuntaEliminarPagos.includes(
                          this.state.tipoUsuario
                        ) && (
                          <td>
                            <button
                              className="btn btn-warning"
                              onClick={() => this.CancelarPago(Datos.id)}
                            >
                              <DeleteForeverIcon />
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(JuntaView);
