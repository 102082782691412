import React from "react";
import Aside from "../../comp/Aside";
import Footer from "../../comp/Footer";
import Navbar from "../../comp/Navbar";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import EventIcon from "@mui/icons-material/Event";
import "../../css/users.css";

class JuntaHoy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos: [],
      Asesor: [],
      tablaBusqueda: [],
      busqueda: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  cargarDatos() {
    fetch(api + "Junta/?hoy=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            Datos: datosRespuesta.data,
            tablaBusqueda: datosRespuesta.data,
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
    this.filtrar(target.value);
    //console.log(target.value);
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  filtrar(termino) {
    console.log(termino);
    var resultadoBusqueda = this.state.tablaBusqueda.filter((elemento) => {
      if (
        elemento.name
          .toString()
          .toLowerCase()
          .includes(termino.toLowerCase()) ||
        elemento.dni.toString().toLowerCase().includes(termino.toLowerCase()) ||
        elemento.ape1.toString().toLowerCase().includes(termino.toLowerCase())
      ) {
        return elemento;
      }
    });
    this.setState({
      Datos: resultadoBusqueda,
    });
  }

  render() {
    const { Usuarios, Datos } = this.state;

    return (
      <div>
        <Navbar />
        <Aside />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>JUNTA</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link className="nav-link" to="/junta">
                        <PersonIcon />
                        Junta
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link active" to="/junta-hoy">
                        <EventIcon />
                        Juntas Hoy
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/junta-agregar">
                        <PersonAddAltIcon />
                        Crear Junta
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="table-responsive table-stilos">
                  <div>
                    <form
                      onSubmit={this.handleSubmit}
                      className="containerInput"
                    >
                      <input
                        type="search"
                        placeholder="Búsqueda por Codigo,Nombre, Apellido, DNI."
                        className="form-control inputBuscar"
                        name="busqueda"
                        onChange={this.handleChange}
                        value={this.state.busqueda}
                      ></input>
                      <button className="btn btn-success">
                        <SearchIcon />
                      </button>
                    </form>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <td>Apellidos y Nombres</td>
                        <td>DNI</td>
                        <td>MONTO</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </thead>
                    <tbody>
                      {Datos.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>
                            {Datos.ape1} {Datos.ape2} {Datos.name}
                          </td>
                          <td>{Datos.dni}</td>
                          <td>s/ {Datos.monto}</td>
                          <td>
                            <Link
                              to={"/junta-ver/" + Datos.id}
                              className="btn btn-primary"
                            >
                              <RemoveRedEyeIcon />
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={"/junta-editar/" + Datos.id}
                              className="btn btn-success"
                            >
                              <EditIcon />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>Apellidos y Nombres</td>
                        <td>DNI</td>
                        <td>MONTO</td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default JuntaHoy;
