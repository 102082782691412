import React from "react";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/form.css";
import Menu from "../../comp/Menu";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  FechaActual,
  MonedaPen,
  UrlHeader,
  cuadreDiarioUrl,
  userUrl,
} from "../../common/Function";
import Swal from "sweetalert2";
import Carga from "../Common/Carga";

class ReportesCuadreCajaNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      asesor: "",
      fechaini: "",
      fechafin: "",
      error: null,
      success: null,
      carga: null,
      fecha_hoy: FechaActual(),
      Depositos: [],
      DevolucionJunta: [],
      DevolucionJuntaPlan: [],
      Desembolsos: [],
      PagosDiversos: [],
      EntregaEfectivo: [],
      IngresosInicial: [],
      IngresosResto: [],
      Sueldos: [],
      Notas: [],
      cuadre_anterior: [],
      recuperacion_dia: 0,
      saldo_inicial: 0,
      total_resto: 0,
      total_egresos: 0,
      total_saldo: 0,

      monto: 0,
      nota: "",
      userid: "",
      Usuarios: [],
      tipo: "",
      carga: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }
  EliminarData(objeto, tipo, id) {
    objeto = objeto.filter((item) => item.id !== id);
    switch (tipo) {
      case "SALDO_INICIAL":
        this.setState({
          IngresosInicial: objeto,
        });
        break;
      case "OTROS_INGRESOS":
        this.setState({
          IngresosResto: objeto,
        });
        break;
      case "DEPOSITOS":
        this.setState({
          Depositos: objeto,
        });
        break;
      case "PAGOS_DIVERSOS":
        this.setState({
          PagosDiversos: objeto,
        });
        break;
      case "ENTREGA_EFECTIVO":
        this.setState({
          EntregaEfectivo: objeto,
        });
        break;
      case "NOTAS":
        this.setState({
          Notas: objeto,
        });
        break;
      default:
        break;
    }
  }

  CargarUsuarios() {
    fetch(userUrl + "?estado=1", {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            Usuarios: result.data,
          });
        } else {
          this.setState({
            Usuarios: [],
          });
        }
      })
      .catch((error) => console.log("error", error));
  }

  CuadroDiarioDetalle(fecha_hoy) {
    var formdata = new FormData();
    formdata.append("obtenerdetalle", "1");
    formdata.append("fecha_hoy", fecha_hoy);

    fetch(cuadreDiarioUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          const devolucion_junta_positivos =
            result.data.egresos.devolucion_junta.map((item) => ({
              ...item,
              monto: Math.abs(parseFloat(item.monto)).toFixed(2),
            }));
          const devolucion_junta_plan_positivos =
            result.data.egresos.devolucion_junta_plan.map((item) => ({
              ...item,
              monto: Math.abs(parseFloat(item.monto)).toFixed(2),
            }));
          this.setState({
            cuadre_anterior: result.data.cuadre_anterior,
            Depositos: this.validarItems(
              sessionStorage.getItem("CC_DEPOSITO"),
              result.data.egresos.depositos
            ),
            DevolucionJunta: devolucion_junta_positivos,
            DevolucionJuntaPlan: devolucion_junta_plan_positivos,
            Desembolsos: result.data.egresos.desembolsos,
            PagosDiversos: result.data.egresos.pagos_diversos,
            EntregaEfectivo: this.validarItems(
              sessionStorage.getItem("CC_ENTREGA_E"),
              result.data.egresos.entrega_efectivo
            ),
            IngresosInicial: this.validarItems(
              sessionStorage.getItem("CC_INGRESO_I"),
              result.data.ingresos.inicial
            ),
            IngresosResto: this.validarItems(
              sessionStorage.getItem("CC_INGRESO_R"),
              result.data.ingresos.resto
            ),
            Notas: this.validarItems(
              sessionStorage.getItem("CC_NOTAS"),
              result.data.ConsultaNotas
            ),
            Sueldos: result.data.egresos.sueldos,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }

  validarItems(datasession, data) {
    debugger;
    if (typeof datasession !== "undefined" && datasession !== null) {
      return JSON.parse(datasession);
    } else {
      return data;
    }
  }

  RecuperacionesDia(fecha_hoy) {
    this.setState({
      recuperacion_dia: [],
      carga: 1,
    });
    fetch(api + "Reportes/?recuperaciones=1", {
      method: "POST",
      body: JSON.stringify({
        fechaini: fecha_hoy,
        fechafin: fecha_hoy,
      }),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        const total = datosRespuesta[datosRespuesta.length - 1].total;
        const totalSinComa = total.replace(/,/g, "");

        this.setState({
          recuperacion_dia: totalSinComa,
          carga: null,
        });
        var Datos = this.state.IngresosResto;
        const nuevoObjeto = {};
        nuevoObjeto.id = Date.now();
        nuevoObjeto.fecha = this.state.fecha_hoy;
        nuevoObjeto.descrip = "RECUPERACIONES DEL DIA";
        nuevoObjeto.monto = totalSinComa;

        const indiceRecuperaciones = Datos.findIndex(
          (objeto) => objeto.descrip === "RECUPERACIONES DEL DIA"
        );

        if (indiceRecuperaciones !== -1) {
          Datos[indiceRecuperaciones] = nuevoObjeto;
        } else {
          Datos.push(nuevoObjeto);
        }

        this.setState({
          IngresosResto: Datos,
        });
      })
      .catch(console.log);
  }

  CalcularTotalEgresos() {
    const tMDepositos = this.CalculatSubTotal(this.state.Depositos);
    const tMDevolucionJ = this.CalculatSubTotal(this.state.DevolucionJunta);
    const tMDevolucionJP = this.CalculatSubTotal(
      this.state.DevolucionJuntaPlan
    );
    const tMDesembolsos = this.CalculatSubTotal(this.state.Desembolsos);
    const tMPagosDiversos = this.CalculatSubTotal(this.state.PagosDiversos);
    const tMEntregaEfectivo = this.CalculatSubTotal(this.state.EntregaEfectivo);
    const tMSueldos = this.CalculatSubTotal(this.state.Sueldos);

    const total_egresos =
      (parseFloat(tMDepositos) ?? 0) +
      (parseFloat(tMDevolucionJ) ?? 0) +
      (parseFloat(tMDevolucionJP) ?? 0) +
      (parseFloat(tMDesembolsos) ?? 0) +
      (parseFloat(tMPagosDiversos) ?? 0) +
      (parseFloat(tMEntregaEfectivo) ?? 0) +
      (parseFloat(tMSueldos) ?? 0);
    this.setState({
      total_egresos: total_egresos.toFixed(2),
    });
  }

  CalculatSubTotal(Data) {
    const existeRecuperacion = Data.find((item) => item.name === "SUB TOTAL");
    let totalData = 0;

    if (!existeRecuperacion) {
      totalData = Data.reduce(
        (total, item) => total + parseFloat(item.monto),
        0
      );
      Data.push({
        monto: totalData.toFixed(2),
        name: "SUB TOTAL",
        apellido1: "",
        apellido2: "",
      });
      return totalData.toFixed(2);
    }

    return existeRecuperacion.monto;
  }
  GuardarCuadre() {
    var cuadre_anterior = this.state.cuadre_anterior;
    delete cuadre_anterior.detalle;

    var detalle = {
      cuadre_anterior: cuadre_anterior,
      IngresosInicial: this.state.IngresosInicial,
      IngresosResto: this.state.IngresosResto,
      DevolucionJunta: this.state.DevolucionJunta,
      DevolucionJuntaPlan: this.state.DevolucionJuntaPlan,
      Depositos: this.state.Depositos,
      Desembolsos: this.state.Desembolsos,
      PagosDiversos: this.state.PagosDiversos,
      EntregaEfectivo: this.state.EntregaEfectivo,
      Notas: this.state.Notas,
      Sueldos: this.state.Sueldos,
    };

    Swal.fire({
      title: "Seguro de guardar?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Si",
      denyButtonText: `Don't save`,
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          carga: 1,
        });
        var formdata = new FormData();
        formdata.append("guardar", "1");
        formdata.append("detalle", JSON.stringify(detalle));
        formdata.append("recuperacion_dia", this.state.recuperacion_dia);
        formdata.append("fecha_hoy", this.state.fecha_hoy);

        var requestOptions = {
          method: "POST",
          headers: UrlHeader(),
          body: formdata,
          redirect: "follow",
        };

        fetch(cuadreDiarioUrl, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            this.setState({
              carga: null,
            });
            if (result.success === true) {
              Swal.fire("Guardado correctamente", "", "success");
              setTimeout(function () {
                window.location.href = "#/reporte-cuadre-caja";
              }, 500);
            } else {
              Swal.fire("Error", "", "danger");
            }
          })
          .catch((error) => console.log("error", error));
      }
    });
  }
  CambiarFecha() {
    this.RecuperacionesDia(this.state.fecha_hoy);
    this.CuadroDiarioDetalle(this.state.fecha_hoy);
  }

  componentDidMount() {
    this.RecuperacionesDia(this.state.fecha_hoy);
    this.CargarUsuarios();
    this.CuadroDiarioDetalle(this.state.fecha_hoy);
  }

  render() {
    const {
      Usuarios,
      Depositos,
      DevolucionJunta,
      DevolucionJuntaPlan,
      Desembolsos,
      PagosDiversos,
      EntregaEfectivo,
      IngresosInicial,
      IngresosResto,
      Notas,
      cuadre_anterior,
      Sueldos,
    } = this.state;

    //sumar totales aqui
    const monto_IngresosInicial = IngresosInicial.reduce(
      (total, item) => total + parseFloat(item.monto),
      0
    );
    const monto_IngresosResto = IngresosResto.reduce(
      (total, item) => total + parseFloat(item.monto),
      0
    );
    const saldo_inicial =
      parseFloat(monto_IngresosInicial) +
      parseFloat(cuadre_anterior.total_saldo);
    const total_ingresos =
      parseFloat(saldo_inicial) + parseFloat(monto_IngresosResto);

    const monto_Depositos = Depositos.reduce(
      (total, item) => total + parseFloat(item.monto),
      0
    );
    const monto_DevolucionJunta = DevolucionJunta.reduce(
      (total, item) => total + parseFloat(item.monto),
      0
    );
    const monto_DevolucionJuntaPlan = DevolucionJuntaPlan.reduce(
      (total, item) => total + parseFloat(item.monto),
      0
    );
    const monto_Desembolsos = Desembolsos.reduce(
      (total, item) => total + parseFloat(item.monto),
      0
    );
    const monto_PagosDiversos = PagosDiversos.reduce(
      (total, item) => total + parseFloat(item.monto),
      0
    );
    const monto_EntregaEfectivo = EntregaEfectivo.reduce(
      (total, item) => total + parseFloat(item.monto),
      0
    );
    const monto_sueldos = Sueldos.reduce(
      (total, item) => total + parseFloat(item.monto),
      0
    );

    const total_egresos =
      parseFloat(monto_Depositos) +
      parseFloat(monto_DevolucionJunta) +
      parseFloat(monto_DevolucionJuntaPlan) +
      parseFloat(monto_Desembolsos) +
      parseFloat(monto_PagosDiversos) +
      parseFloat(monto_EntregaEfectivo) +
      parseFloat(monto_sueldos);

    const total_saldo = parseFloat(total_ingresos) - parseFloat(total_egresos);

    return (
      <div>
        {this.state.carga ? <Carga /> : null}
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/reporte-cuadre-caja">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          <div className="cont">
            <p>CALCULAR DE OTRO DÍA</p>
            <div className="form row">
              <div className="col">
                <input
                  type="date"
                  className="form-control"
                  name="fecha_hoy"
                  onChange={this.handleChange}
                  value={this.state.fecha_hoy}
                ></input>
              </div>
              <div className="col">
                <div
                  className="btn btn-primary w-100"
                  onClick={() => this.CambiarFecha()}
                >
                  CALCULAR NUEVAMENTE
                </div>
              </div>
            </div>
            <i>Aviso "VIENEN" Siempre recibira el monto del ultimo registro.</i>
          </div>
          <section className="cont">
            <section className="content-header">
              <div className="container-fluid">
                <h1>REPORTE CUADRE DE CAJA | {this.state.fecha_hoy}</h1>
              </div>
            </section>

            <hr></hr>
            <div className="container-fluid row">
              <div className="col-6">
                <h4>INGRESOS</h4>
                <hr></hr>
                <table className="table">
                  <tbody>
                    <tr>
                      <td>Vienen</td>
                      <td>{MonedaPen(cuadre_anterior.total_saldo)}</td>
                    </tr>
                    {IngresosInicial.map((item) => (
                      <tr key={item.id}>
                        <td>{item.descrip}</td>
                        <td>{MonedaPen(item.monto)}</td>
                      </tr>
                    ))}
                    <tr className="bg-warning">
                      <td>Saldo Inicial</td>
                      <td>{MonedaPen(saldo_inicial)}</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table">
                  <tbody>
                    {IngresosResto.map((item) => (
                      <tr key={item.id}>
                        <td>{item.descrip}</td>
                        <td>{MonedaPen(item.monto)}</td>
                      </tr>
                    ))}
                    <tr className="bg-secondary">
                      <td>TOTAL DE INGRESOS</td>
                      <td>{MonedaPen(total_ingresos)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-6">
                <h4>EGRESOS</h4>
                <hr></hr>
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-center bg-info">
                        DEPOSITOS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Depositos.map((item) => (
                      <tr key={item.name}>
                        <td className="bg-light">{item.name}</td>
                        <td
                          className={
                            item.name === "SUB TOTAL"
                              ? "bg-light"
                              : "bg-success"
                          }
                        >
                          {MonedaPen(item.monto)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-light">
                      <td colSpan="1">SUB TOTAL</td>
                      <td>{MonedaPen(monto_Depositos)}</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-center bg-info">
                        DEVOLUCION MJ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {DevolucionJunta.map((item) => (
                      <tr key={item.name}>
                        <td className="bg-light">{item.name}</td>
                        <td
                          className={
                            item.name === "SUB TOTAL"
                              ? "bg-light"
                              : "bg-success"
                          }
                        >
                          {MonedaPen(item.monto)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-light">
                      <td colSpan="1">SUB TOTAL</td>
                      <td>{MonedaPen(monto_DevolucionJunta)}</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-center bg-info">
                        DEVOLUCION MJP
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {DevolucionJuntaPlan.map((item) => (
                      <tr key={item.name}>
                        <td className="bg-light">{item.name}</td>
                        <td
                          className={
                            item.name === "SUB TOTAL"
                              ? "bg-light"
                              : "bg-success"
                          }
                        >
                          {MonedaPen(item.monto)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-light">
                      <td colSpan="1">SUB TOTAL</td>
                      <td>{MonedaPen(monto_DevolucionJuntaPlan)}</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-center bg-info">
                        DESEMBOLSOS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Desembolsos.map((item) => (
                      <tr key={item.name}>
                        <td className="bg-light">{item.name}</td>
                        <td
                          className={
                            item.name === "SUB TOTAL"
                              ? "bg-light"
                              : "bg-success"
                          }
                        >
                          {MonedaPen(item.monto)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-light">
                      <td colSpan="1">SUB TOTAL</td>
                      <td>{MonedaPen(monto_Desembolsos)}</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-center bg-info">
                        PAGOS DIVERSOS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {PagosDiversos.map((item) => (
                      <tr key={item.name}>
                        <td className="bg-light">{item.descrip}</td>
                        <td
                          className={
                            item.name === "SUB TOTAL"
                              ? "bg-light"
                              : "bg-success"
                          }
                        >
                          {MonedaPen(item.monto)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-light">
                      <td colSpan="1">SUB TOTAL</td>
                      <td>{MonedaPen(monto_PagosDiversos)}</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-center bg-info">
                        ENTREGA EFECTIVO
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {EntregaEfectivo.map((item) => (
                      <tr key={item.name}>
                        <td className="bg-light">{item.name}</td>
                        <td
                          className={
                            item.name === "SUB TOTAL"
                              ? "bg-light"
                              : "bg-success"
                          }
                        >
                          {MonedaPen(item.monto)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-light">
                      <td>SUB TOTAL</td>
                      <td>{MonedaPen(monto_EntregaEfectivo)}</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-center bg-info">
                        SUELDOS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Sueldos.map((item) => (
                      <tr key={item.name}>
                        <td className="bg-light">{item.name}</td>
                        <td
                          className={
                            item.name === "SUB TOTAL"
                              ? "bg-light"
                              : "bg-success"
                          }
                        >
                          {MonedaPen(item.monto)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-light">
                      <td>SUB TOTAL</td>
                      <td>{MonedaPen(monto_sueldos)}</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table">
                  <tbody>
                    <tr className="bg-secondary">
                      <td>TOTAL DE EGRESOS</td>
                      <td>{MonedaPen(total_egresos)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <table className="table">
              <tbody>
                <tr className="bg-secondary">
                  <td>SALDO EFECTIVO</td>
                  <td>{MonedaPen(total_saldo)}</td>
                </tr>
              </tbody>
            </table>
            <div className="row">
              <div className="col-6">
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="3" className="text-center bg-warning">
                        NOTAS
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Notas.map((item) => (
                      <tr key={item.id}>
                        <td
                          className={
                            item.descrip === "SUB TOTAL"
                              ? "bg-warning"
                              : "bg-light"
                          }
                        >
                          {item.descrip}
                        </td>
                        <td
                          className={
                            item.descrip === "SUB TOTAL"
                              ? "bg-warning"
                              : "bg-success"
                          }
                        >
                          {MonedaPen(item.monto)}
                        </td>
                      </tr>
                    ))}
                    <tr className="bg-secondary">
                      <td>SALDO EFECTIVO</td>
                      <td>{MonedaPen(total_saldo)}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-6"></div>
            </div>
            <br></br>

            <div className="row">
              <div className="col-6 card border-primary mb-3"></div>
              <div className="col-6">
                <button
                  className="btn btn-success w-100"
                  onClick={() => this.GuardarCuadre()}
                >
                  VALIDAR Y GUARDAR REGISTRO
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ReportesCuadreCajaNew;
