import React from "react";
import Aside from "../../comp/Aside";
import Footer from "../../comp/Footer";
import Navbar from "../../comp/Navbar";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import Cookies from "universal-cookie";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import KeyIcon from "@mui/icons-material/Key";
import "../../css/perfil.css";

class PerfilEditPass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      ape1: "",
      ape2: "",
      dni: "",
      phone: "",
      direccion: "",
      email: "",
      cumple: "",
      cargo: "",
      pass1: "",
      pass2: "",
      pass3: "",
      error: null,
      success: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }

  cargarDatos() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");

    fetch(api + "Users/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        this.setState({ nombre: datosRespuesta[0].name });
        this.setState({ ape1: datosRespuesta[0].apellido1 });
        this.setState({ ape2: datosRespuesta[0].apellido2 });
        this.setState({ dni: datosRespuesta[0].dni });
        this.setState({ phone: datosRespuesta[0].phone });
        this.setState({ direccion: datosRespuesta[0].direccion });
        this.setState({ email: datosRespuesta[0].email });
        this.setState({ cumple: datosRespuesta[0].cumple });
        this.setState({ cargo: datosRespuesta[0].tip_user });
      })
      .catch(console.log);
  }
  componentDidMount() {
    this.cargarDatos();
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");

    const datosEnviar = {
      id: IdUser,
      pass1: this.state.pass1,
      pass2: this.state.pass2,
      pass3: this.state.pass3,
    };
    //console.log(datosEnviar);

    fetch(api + "Users/?password=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({ success: 1, error: null });
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    const { error } = this.state;

    return (
      <div>
        <Navbar />
        <Aside />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Perfil</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link className="nav-link" to="/miperfil">
                        <PersonIcon />
                        Mi Perfil
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/miperfiledit">
                        <EditIcon />
                        Editar
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link active" to="/miperfileditpass">
                        <KeyIcon />
                        Cambiar Contraseña
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="cont">
                  <div className="cont formupass">
                    {this.state.success && (
                      <div className="alert alert-success" role="alert">
                        Se cambio la Contraseña Correctamente
                      </div>
                    )}
                    {this.state.error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                    <form className="row" onSubmit={this.handleSubmit}>
                      <div className="col">
                        <label>Contraseña Actual:</label>
                        <input
                          type="password"
                          name="pass1"
                          placeholder="Contraseña Actual"
                          required
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.pass1}
                        ></input>
                        <hr></hr>
                        <label>Nueva Contraseña:</label>
                        <input
                          type="password"
                          name="pass2"
                          placeholder="Nueva Contraseña"
                          required
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.pass2}
                        ></input>
                        <label>Verificar Contraseña:</label>
                        <input
                          type="password"
                          name="pass3"
                          placeholder="Verificar Contraseña"
                          required
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.pass3}
                        ></input>
                      </div>
                      <hr></hr>
                      <div className="boton-actualizar">
                        <button className="btn btn-success">Actualizar</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PerfilEditPass;
