import React from "react";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import Cookies from "universal-cookie";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../../css/users.css";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Menu from "../../comp/Menu";
import ArticleIcon from "@mui/icons-material/Article";
import {
  UrlPost,
  UrlHeader,
  prestamosUrl,
  UserCod,
  OrdenarPorFecha,
  UrlGet,
  baseUrlOld,
  clienteUrl,
  userUrl,
} from "../../common/Function";
import { EstadosPrestamos, Riesgo } from "../Common/utils";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class PrestamoRcc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      estado: 15206,
      UserId: UserCod(),
      Prestamo: [],
      id_press: 1,
      error: null,
      success: null,
      error2: null,
      success2: null,
      error3: null,
      success3: null,
      name_asesor: "",
      fecha: "",
      name_cliente: "",
      dni: "",
      telefono: "",
      negocio: "",
      tip_press: "",
      monto: "",
      plazo: "",
      monto_c: "",
      ahorro: "",
      total_junta: "",
      total_juntap: "",
      cuotas_r: "",
      desgrav: "",
      formapago: "",
      tinteres: "",
      interes: "",
      id_cliente: 0,
      Prestamos: [],
      Documentos: [],
      d_estado: null,
      pdf_ruta: "",
      pdf_name: "",
      password: "",
      otros: 0,
      Simu_cronograma: [],
      Documentos_cli: [],
      c_riesgo: 0,
      box_cliente: null,
      box_cliente_doc: null,
      box_conyugue: null,
      box_negocio: null,
      box_info_familiar: null,
      box_prestamo: null,
      box_prestamo_doc: null,
      box_cronograma: null,
      hist_prestamos: null,
      castigado: 0,
      espera2: null,
      DatosCliente: [],
      DatosAsesor: [],
      tipoUsuario: localStorage.getItem("TIPUSER"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.handleSubmit3 = this.handleSubmit3.bind(this);
    this.conexion = false;
  }

  cargarDatos() {
    const IdPress = this.props.match.params.id;
    fetch(prestamosUrl + IdPress, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            Prestamo: result.data,
            id_press: IdPress,
            desgrav: result.data.desgrav,
          });
          this.cargarPrestamos(result.data.id_cliente);
          this.CargarDatosCliente(result.data.id_cliente);
          this.CargarDatosAsesor(result.data.id_cobrador);
        }
      })
      .catch((error) => console.log("error", error));
  }
  CargarDatosCliente(IdCliente) {
    fetch(clienteUrl + IdCliente, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            DatosCliente: result.data,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }
  CargarDatosAsesor(IdAsesor) {
    fetch(userUrl + IdAsesor, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            DatosAsesor: result.data,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }

  cargarDatos2() {
    const IdUser = this.props.match.params.id;
    fetch(api + "Cliente/documents.php?id_press=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            d_estado: 1,
            Documentos: datosRespuesta.data,
          });
        } else {
          this.setState({
            d_estado: null,
            Documentos: [],
          });
        }
      })
      .catch(console.log);
  }

  cargarSimuladorCronograma() {
    const Id = this.props.match.params.id;
    fetch(api + "Prestamos/simulacion_cronograma0.php?calcular=" + Id)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            Simu_cronograma: datosRespuesta.data,
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarDatos2();
    this.cargarSimuladorCronograma();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const IdUser = this.props.match.params.id;

    const datosEnviar = {
      id: IdUser,
      estado: this.state.estado,
    };
    //console.log(datosEnviar);

    fetch(api + "Users/?estado=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
            //estado: datosRespuesta.estado,
          });
          this.cargarDatos();
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);
    event.preventDefault();
  }

  handleSubmit2(event) {
    this.setState({ error2: null, success2: null, espera2: 1 });

    const datosEnviar = {
      desembolsar: true,
      password: this.state.password,
      desgrav: this.state.desgrav,
      otros: this.state.otros,
      id_press: this.state.id_press,
      id_user: this.state.UserId,
    };

    fetch(prestamosUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: UrlPost(datosEnviar),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({ error2: null, success2: 1, espera2: null });
        } else {
          this.setState({
            error2: result.error,
            success2: null,
            espera2: null,
          });
        }
        this.cargarDatos();
        console.log(result);
      })
      .catch((error) => console.log("error", error));
    event.preventDefault();
  }

  handleSubmit3(event) {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const datosEnviar = {
      monto: this.state.monto,
      plazo: this.state.plazo,
      interes: this.state.interes,
      id_press: this.state.id_press,
      id_user: IdUser,
    };
    //console.log(datosEnviar);
    fetch(api + "Prestamos/?modificar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({ success3: 1 });
        } else {
          this.setState({ error3: datosRespuesta.error });
        }
        this.cargarDatos();
        this.cargarSimuladorCronograma();
        //console.log(datosRespuesta);
      })
      .catch(console.log);
    event.preventDefault();
  }

  PAprobar = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Aprobar Prestamo?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          var formdata = {
            actualizar: true,
            id: id,
            estado: 1,
            userId: this.state.UserId,
          };

          var requestOptions = {
            method: "POST",
            headers: UrlHeader(),
            body: UrlPost(formdata),
            redirect: "follow",
          };

          fetch(prestamosUrl, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              if (result.success === true) {
                swalWithBootstrapButtons.fire(
                  "Aprobado!",
                  "Felicidades Aprobo el Prestamo.",
                  "success"
                );
                this.cargarDatos();
              } else {
                swalWithBootstrapButtons.fire(
                  "No autorizado",
                  result.msg,
                  "error"
                );
              }
            })
            .catch((error) => console.log("error", error));
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Quiza la proxima :)",
            "error"
          );
        }
      });

    this.cargarDatos();
  };

  PDesaprobar = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Desaprobar Solicitud?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          var formdata = {
            actualizar: true,
            id: id,
            estado: 2,
          };

          var requestOptions = {
            method: "POST",
            headers: UrlHeader(),
            body: UrlPost(formdata),
            redirect: "follow",
          };

          fetch(prestamosUrl, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              this.cargarDatos();
            })
            .catch((error) => console.log("error", error));
          swalWithBootstrapButtons.fire(
            "Desaprobado!",
            "Su Prestamo fue desaprobado correctamente.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Cancelado", ":)", "error");
        }
      });

    this.cargarDatos();
  };

  cargarPrestamos(IdUser) {
    var data = { id_cliente: IdUser };
    fetch(prestamosUrl + UrlGet(data), {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Prestamos: OrdenarPorFecha(result.data),
        });
      })
      .catch((error) => console.log("error", error));
  }

  Extornar = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Desea Extornar Credito?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(api + "Prestamos/?extornar_desembolso=" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "Extornado!",
            "Su Prestamo fue extornado correctamente.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Cancelado", ":)", "error");
        }
      });

    this.cargarDatos();
  };

  PreAprobado = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Desea Volver a estado Pre-Aprobado?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(api + "Prestamos/?prestamo_pre_aprobado=" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "Extornado!",
            "El estado fue actualizado correctamente.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Cancelado", ":)", "error");
        }
      });

    this.cargarDatos();
  };

  BorrarRegistro = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, bórralo!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(api + "Cliente/documents.php?borrar=" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
              this.cargarDatos2();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "¡Eliminado!",
            "Su archivo ha sido eliminado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Tu archivo está a salvo :)",
            "error"
          );
        }
      });

    this.cargarDatos();
    this.cargarDatos2();
  };

  AbrirPrf = (ruta, name) => {
    var URL = "https://altrisa.site/";
    this.setState({
      pdf_name: name,
      pdf_ruta: URL + ruta,
    });
  };

  AbrirDatosCliente() {
    var boxes = this.ViewBoxes();
    if (this.state.box_cliente !== 1) {
      boxes.box_cliente = 1;
    }
    this.setState(boxes);
  }
  AbrirDocumentosCliente() {
    var boxes = this.ViewBoxes();
    if (this.state.box_cliente_doc !== 1) {
      boxes.box_cliente_doc = 1;
    }
    this.setState(boxes);
  }
  AbrirDatosPrestamo() {
    var boxes = this.ViewBoxes();
    if (this.state.box_prestamo !== 1) {
      boxes.box_prestamo = 1;
    }
    this.setState(boxes);
  }
  AbrirDocumentosPrestamo() {
    var boxes = this.ViewBoxes();
    if (this.state.box_prestamo_doc !== 1) {
      boxes.box_prestamo_doc = 1;
    }
    this.setState(boxes);
  }
  AbrirCronograma() {
    var boxes = this.ViewBoxes();
    if (this.state.box_cronograma !== 1) {
      boxes.box_cronograma = 1;
    }
    this.setState(boxes);
  }
  AbrirHistorialPrestamos() {
    var boxes = this.ViewBoxes();
    if (this.state.hist_prestamos !== 1) {
      boxes.hist_prestamos = 1;
    }
    this.setState(boxes);
  }

  ViewBoxes() {
    return {
      box_cliente: null,
      box_cliente_doc: null,
      box_conyugue: null,
      box_negocio: null,
      box_info_familiar: null,
      box_prestamo: null,
      box_prestamo_doc: null,
      box_cronograma: null,
      hist_prestamos: null,
    };
  }

  render() {
    const {
      monto,
      plazo,
      id_press,
      tinteres,
      interes,
      Prestamos,
      d_estado,
      Simu_cronograma,
      Prestamo,
      DatosAsesor,
      DatosCliente,
      tipoUsuario,
    } = this.state;

    /*CALCULAR CUOTA*/
    function pagoE(v_interes, v_i, v_cuotas, v_monto) {
      var resp = 0;
      v_interes = new Number(v_interes);
      v_monto = new Number(v_monto);
      v_cuotas = new Number(v_cuotas);
      v_i = new Number(v_i);
      resp = v_monto * (v_interes / 100);
      resp = resp / (1 - Math.pow(1 + v_interes / 100, -1 * v_cuotas * v_i));
      return resp;
    }

    function pagoE1(v_interes, v_cuotas, v_monto) {
      var resp = 0;
      v_interes = new Number(v_interes);
      v_monto = new Number(v_monto);
      v_cuotas = new Number(v_cuotas);
      resp = (v_monto + (v_monto * v_interes) / 100) / v_cuotas;
      return resp;
    }

    var monto_cuota = 0;

    if (tinteres === "2") {
      monto_cuota = pagoE(interes, 1, plazo, monto);
    } else {
      monto_cuota = pagoE1(interes, plazo, monto);
    }
    /*CARTERA */
    function name_carter(id_cartera) {
      if (id_cartera === "1") {
        return "WILMER";
      } else if (id_cartera === "2") {
        return "LIZA";
      } else if (id_cartera === "3") {
        return "RB";
      } else if (id_cartera === "4") {
        return "DIEGO";
      } else {
        return "[X] CARTERNA NO EXISTE EN LA FUNCION";
      }
    }

    return (
      <div id="prestamo">
        <Menu />
        <div className="content">
          <br></br>
          <Link
            to={"/Prestamo/" + this.state.id_press}
            className="btn btn-light"
          >
            <KeyboardBackspaceIcon /> REGRESAR
          </Link>
          <div className="cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <h1>REPORTES CUADRE DE CAJA</h1>
                </div>
              </div>
            </section>
            <hr></hr>
            <div className="cont">
              <h4>DATOS DE CLIENTE</h4>
              <hr></hr>
              <div className="row">
                <div className="col">
                  <p>
                    <b>NOMBRE DE CLIENTE:</b>{" "}
                    {DatosCliente.name +
                      " " +
                      DatosCliente.ape1 +
                      " " +
                      DatosCliente.ape2}
                  </p>
                </div>
                <div className="col">
                  <p>
                    <b>DNI:</b> {DatosCliente.dni}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p>
                    <b>DIRECCIÓN:</b> {DatosCliente.direccion}
                  </p>
                </div>
                <div className="col">
                  <p>
                    <b>REFERENCIA:</b> {DatosCliente.direc_re}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p>
                    <b>TELEFONO:</b> {DatosCliente.telefonos}
                  </p>
                </div>
                <div className="col">
                  <p>
                    <b>CONDICIÓN VIVIENDA:</b> {DatosCliente.v_condicion}
                  </p>
                </div>
                <div className="col">
                  <p>
                    <b>MATERIAL VIVIENDA:</b> {DatosCliente.v_material}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p>
                    <b>DEPARTAMENTO:</b> {DatosCliente.dep}
                  </p>
                </div>
                <div className="col">
                  <p>
                    <b>PROVINCIA:</b> {DatosCliente.pro}
                  </p>
                </div>
                <div className="col">
                  <p>
                    <b>DISTRITO:</b> {DatosCliente.dis}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p>
                    <b>ESTADO CIVIL:</b> {DatosCliente.estado_civil}
                  </p>
                </div>
                <div className="col">
                  <p>
                    <b>HIJOS DEPENDIENTES:</b> {DatosCliente.hijos_d}
                  </p>
                </div>
                <div className="col">
                  <p>
                    <b>HIJOS INDEPENDIENTES:</b> {DatosCliente.hijos_i}
                  </p>
                </div>
              </div>
              <hr></hr>
              <div className="cont">
                <h4>HISTORIAL DE PRESTAMOS</h4>
                <hr></hr>
                <table className="table">
                  <thead>
                    <tr>
                      <td>Fecha</td>
                      <td>Monto</td>
                      <td>Cuotas</td>
                      <td>Forma de Pago</td>
                      <td>Cartera</td>
                      <td>Riesgo</td>
                      <td>Estado</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Prestamos.slice(0, 5).map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>{Datos.fecha}</td>
                        <td>{Datos.monto}</td>
                        <td>{Datos.cuotas}</td>
                        <td>{Datos.formapago}</td>
                        <td>{name_carter(Datos.cartera)}</td>
                        <td>{Riesgo(Datos.riesgo)}</td>
                        <td>
                          {EstadosPrestamos(Datos.estado, Datos.id)}
                          <a
                            href={
                              baseUrlOld +
                              "/oldreport/prestamos/descargarcronograma.php?id_pres=" +
                              Datos.id
                            }
                            target="_blank"
                            rel="noopener"
                            className="btn btn-success"
                            title="Cronograma"
                          >
                            <ArticleIcon />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr></hr>
              <h4>JUNTAS</h4>
              <hr></hr>
              <p className="text-center h4 font-weight-bold">
                <b>TOTAL:</b> {(parseFloat(Prestamo.junta) + parseFloat(Prestamo.junta_plan)).toFixed(2)}
              </p>
              <hr></hr>
              <h4>SENTINEL</h4>
              <p>Doc sentinel --------- Color Sentinel</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PrestamoRcc);
