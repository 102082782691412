import React from "react";
import Menu from "../../comp/Menu";
import { Link, useParams } from "react-router-dom";
import "../../css/users.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  prestamosUrl,
  UrlHeader,
  FechaActual,
  userUrl,
  UrlPost,
} from "../../common/Function";
import Carga from "../Common/Carga";
import Autocomplete from "../Common/MaterialAutocomplete";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import Swal from "sweetalert2";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}
class PrestamoEditar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Prestamos: [],
      currentPage: 1,
      starat: 0,
      endat: 10,
      total: 0,
      total_paginas: 0,
      totalPages: [],
      busqueda: "",
      Asesores: [],
      Carteras: [],

      name: "",
      dni: "",
      email: "",
      cargo: "",
      pass1: "",
      pass2: "",

      asesorde: "",
      asesora: "",
      cartera: "",
      filter: "",
      formapago: "",
      estado: "3",
      castigado: "",
      fechaini: FechaActual(),
      fechafin: FechaActual(),
    };
    this.AutocompleteAsesoresDe = this.AutocompleteAsesoresDe.bind(this);
    this.AutocompleteAsesoresA = this.AutocompleteAsesoresA.bind(this);
    this.autocompleteRefAsesores = React.createRef();
  }

  AutocompleteAsesoresDe(event) {
    this.setState({
      asesorde: event,
    });
  }
  AutocompleteAsesoresA(event) {
    this.setState({
      asesora: event,
    });
  }
  cargaInicial() {
    this.cargarUser();
  }
  ReasignarPrestamos() {
    Swal.fire({
      title: "Esta seguro de reasignar todos los prestamos a otro asesor?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "SI",
      denyButtonText: `NO`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.ReasignarMasivo();
      }
    });
  }
  ReasignarMasivo() {
    if (
      this.state.asesorde &&
      this.state.asesora &&
      this.state.asesorde !== "" &&
      this.state.asesora !== ""
    ) {
      const data = {
        asesorde: this.state.asesorde,
        asesora: this.state.asesora,
        ReasignarMasivo: 1,
      };
      fetch(prestamosUrl, {
        method: "POST",
        headers: UrlHeader(),
        body: UrlPost(data),
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Se reasignaro correctamente los campos.",
              showConfirmButton: false,
              timer: 1500,
            });
          } else {
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: result.msg,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Debe seleccionar ambos asesores.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  cargarUser() {
    fetch(userUrl + "?estado=1", {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Asesores: result.data,
        });
      })
      .catch((error) => console.log("error", error));
  }
  componentDidMount() {
    this.cargaInicial();
  }

  render() {
    return (
      <div id="prestamos">
        {this.state.carga ? <Carga /> : null}
        <Menu />

        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/prestamos-search">
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          <div className="content-wrapper cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <h1>EDITAR PRESTAMO</h1>
                </div>
              </div>
            </section>

            <section className="row">
              <div className="col-9">
                <div className="row">
                  <div className="col-4">
                    <label>De:</label>
                    <Autocomplete
                      allOptions={this.state.Asesores}
                      onSelect={this.AutocompleteAsesoresDe}
                      ref={this.autocompleteRefAsesores}
                    />
                  </div>
                  <div className="col-4">
                    <label>A:</label>
                    <Autocomplete
                      allOptions={this.state.Asesores}
                      onSelect={this.AutocompleteAsesoresA}
                      ref={this.autocompleteRefAsesores}
                    />
                  </div>
                  <div className="col-4"></div>
                </div>
              </div>
              <div className="col-3">
                <div>
                  <button
                    className="btn btn-info w-100 mb-1"
                    onClick={() => this.ReasignarPrestamos()}
                  >
                    <FlipCameraAndroidIcon />
                    Reasignar
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(PrestamoEditar);
