import React from "react";
import { Link } from "react-router-dom";
import Menu from "../../comp/Menu";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
class Reportes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      estado: null,
    };
  }
  render() {
    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          <section className="content">
            <br></br>
            <div className="container-fluid">
              <div className="row">
                <div className="col-3">
                  <Link to="/reportes-ingresos-financieros">
                    <div
                      className="card text-white bg-primary mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-body">
                        <h4>INGRESOS FINANCIEROS</h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-3">
                  <Link to="/reportes-recuperaciones">
                    <div
                      className="card text-white bg-secondary mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-body">
                        <h4>RECUPERACIONES</h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-3">
                  <Link to="/reportes-junta">
                    <div
                      className="card text-white bg-success mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-body">
                        <h4>REPORTE DE JUNTA</h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-3">
                  <Link to="/reporte-colocacion">
                    <div
                      className="card text-white bg-danger mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-body">
                        <h4>COLOCACIONES</h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-3">
                  <Link to="/reportes-clientes-activos-date">
                    <div
                      className="card text-white bg-warning mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-body">
                        <h4>CLIENTES ACTIVOS</h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-3">
                  <Link to="/reportes-pagos-otros">
                    <div
                      className="card text-white bg-dark mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-body">
                        <h4>PAGOS OTROS</h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-3">
                  <Link to="/reporte-mora">
                    <div
                      className="card text-white bg-success mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-body">
                        <h4>MORA</h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-3">
                  <Link to="/reporte-cartera-activa">
                    <div
                      className="card text-white bg-warning mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-body">
                        <h4>CARTERA ACTIVA</h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-3">
                  <Link to="/reportes-contadora">
                    <div
                      className="card text-white bg-primary mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-body">
                        <h4>INFO CONTADORA</h4>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-3">
                  <Link to="/reporte-cuadre-caja">
                    <div
                      className="card text-white bg-dark mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-body">
                        <h4>REPORTE CUADRE CAJA</h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-3">
                  <Link to="/reporte-new-cierre">
                    <div
                      className="card text-white bg-dark mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-body">
                        <h4>NUEVO CIERRE</h4>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-3">
                  <Link to="/reporte/infocorp">
                    <div
                      className="card text-white bg-dark mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-body">
                        <h4>REPORTE IFOCORP</h4>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Reportes;
