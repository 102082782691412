import React from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/form.css";
import Menu from "../../comp/Menu";
import { UserCod } from "../../common/Function";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class ClienteNotas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos: [],
      id_clie: 0,
      error: null,
      success: null,
      procesando: null,
      Notas: [],
      codigoUsuario: UserCod(),
      nota: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }

  cargarDatos() {
    const IdCliente = this.props.match.params.id;
    this.setState({
      id_clie: IdCliente,
    });
    const datosEnviar = {};

    fetch(api + "Cliente/?consultar=" + IdCliente, {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          Datos: datosRespuesta[0],
        });
        console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  cargarDatos2() {
    const IdCliente = this.props.match.params.id;
    const datosEnviar = {};

    fetch(api + "Notas/?cliente=" + IdCliente, {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            Notas: datosRespuesta.data,
          });
        } else {
          this.setState({
            Notas: [],
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarDatos2();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const datosEnviar = {
      id_cliente: this.state.id_clie,
      nota: this.state.nota,
      id_asesor: this.state.codigoUsuario,
      id_press: "",
    };

    //console.log(datosEnviar);
    fetch(api + "Notas/?insertar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
          });
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        this.cargarDatos();
        this.cargarDatos2();
      })
      .catch(console.log);
    event.preventDefault();
  }

  render() {
    const { Datos, Notas } = this.state;

    function castigado(xd_castigado) {
      if (xd_castigado === 1) {
        return (
          <div class="alert alert-danger" role="alert">
            CLIENTE CASTIGADO
          </div>
        );
      } else {
        return "";
      }
    }

    //console.log(Datos);
    return (
      <div>
        <Menu />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>ESCRIBIR NOTA A CLIENTE </h1>
                  <i>
                    {Datos.name + " " + Datos.ape1 + " " + " " + Datos.ape2}
                  </i>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="">
            <div className="container-fluid">
              <div className="">
                <div>
                  <Link
                    to={"/Cliente/" + this.state.id_clie}
                    className="btn btn-primary"
                  >
                    Volver
                  </Link>
                </div>
                <div className="cont">
                  <div className="row">
                    <form className="formx" onSubmit={this.handleSubmit}>
                      <h4>CLIENTE</h4>
                      <hr></hr>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="nota">DESCRIPCIÓN:</label>
                          <input
                            type="text"
                            name="nota"
                            id="nota"
                            className="form form-control"
                            placeholder="DETALLE O RAZÓN DEL CASTIGO"
                            onChange={this.handleChange}
                            value={this.state.nota}
                          />
                        </div>
                      </div>

                      <div className="fbtnx">
                        <button className="btn btn-success">GUARDAR NOTA</button>
                      </div>
                    </form>
                    {this.state.success && (
                      <div className="alert alert-success" role="alert">
                        SE REGISTRO PAGO CORRECTAMENTE
                      </div>
                    )}
                    {this.state.error && (
                      <div className="alert alert-danger" role="alert">
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
                <div className="cont">
                  <h4>HISTORIAL</h4>
                  <hr></hr>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <td>FEECHA</td>
                          <td>NOTA</td>
                        </tr>
                      </thead>
                      <tbody>
                        {Notas.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.fecha}</td>
                            <td>{Datos.nota}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(ClienteNotas);
