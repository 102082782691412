import React from "react";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import SearchIcon from "@mui/icons-material/Search";
import "../../css/users.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import DeleteIcon from "@mui/icons-material/Delete";
import { UrlHeader, pagosOtrosUrl } from "../../common/Function";
class PagosOtros extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos: [],
      currentPage: 1,
      starat: 0,
      endat: 7,
      total: 0,
      total_paginas: 0,
      totalPages: [],
      busqueda: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.filterPagina = this.filterPagina.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  cargarDatos(pagine, filter) {
    var requestOptions = {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    };
    var BeUrl =
      pagosOtrosUrl + "?starat=" + pagine + "&endat=" + this.state.endat;
    if (filter !== null) {
      BeUrl =
        pagosOtrosUrl +
        "?starat=" +
        pagine +
        "&endat=" +
        this.state.endat +
        "&filter=" +
        filter;
    }
    fetch(BeUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Datos: result.data,
          total: result.total,
          total_paginas: Math.ceil(result.total / this.state.endat),
        });
        this.NumeroPaginas(Math.ceil(result.total / this.state.endat));
      })
      .catch((error) => console.log("error", error));
  }

  componentDidMount() {
    this.cargarDatos(this.state.starat, null);
  }

  NumeroPaginas(numPages) {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push({ id: i - 1, name: i });
    }
    this.setState({
      totalPages: pages,
    });
  }

  CambiarPagina(page) {
    this.setState({ starat: page });
    page = page * this.state.endat;
    this.cargarDatos(page, this.state.busqueda);
  }

  filterPagina(event) {
    if (this.state.busqueda === "") {
      this.setState({ startat: 0 });
    } else {
      this.cargarDatos(0, this.state.busqueda);
    }
    event.preventDefault();
  }

  render() {
    const { Datos } = this.state;

    return (
      <div id="PagosOtros">
        <Menu />
        <br></br>
        <div className="content">
          <Link className="btn btn-light" to="/home">
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          <Link to="/pagos-otros-agregar" className="btn btn-primary">
            <PersonAddAltIcon />
            Agregar PagosOtros
          </Link>
          <div className="content-wrapper cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <h1>PAGOS OTROS</h1>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="table-responsive">
                  <div className="barra-busqueda">
                    <form className="containerInput">
                      <input
                        type="search"
                        placeholder="Búsqueda por Nombre o DNI."
                        className="form-control inputBuscar"
                        name="busqueda"
                        onChange={this.handleChange}
                        value={this.state.busqueda}
                      ></input>
                      <button
                        className="btn btn-success"
                        onClick={this.filterPagina}
                      >
                        <SearchIcon />
                      </button>
                    </form>
                  </div>
                  <table className="table table-striped my-4">
                    <thead className="bg-info">
                      <tr>
                        <th className="text-center">FECHA</th>
                        <th className="text-center">CAPITAL</th>
                        <th className="text-center">INTERES</th>
                        <th className="text-center">CLIENTE</th>
                        <th className="text-center">ASESOR</th>
                        <th className="text-center">CAJA</th>
                        <th className="text-center">ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Datos.map((data) => (
                        <tr className="text-center" key={data.id}>
                          <td className="text-center">{data.fecha}</td>
                          <td className="text-center">{data.capital}</td>
                          <td className="text-center">{data.interes}</td>
                          <td className="text-center">
                            {data.nameClient} {data.ape1Client}{" "}
                            {data.ape2Client}
                          </td>
                          <td className="text-center">
                            {data.nameUser} {data.ape1User} {data.ape2User}
                          </td>
                          <td className="text-center">{data.nameCaja}</td>
                          <td className="text-center">
                            <div
                              data-toggle="modal"
                              data-target="#EditarPagosOtros"
                              className="btn btn-warning m-2"
                              title="Editar"
                            >
                              <EditIcon />
                            </div>
                            <div className="btn btn-danger m-2" title="Detalle">
                              <DeleteIcon />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li
                      className={
                        this.state.starat === 0
                          ? "page-item disabled"
                          : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        onClick={() =>
                          this.CambiarPagina(this.state.starat - 1)
                        }
                      >
                        <NavigateBeforeIcon />
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link">
                        {this.state.starat + 1 + "/" + this.state.total_paginas}
                      </a>
                    </li>
                    <li
                      className={
                        this.state.starat === this.state.total_paginas - 1
                          ? "page-item disabled"
                          : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        onClick={() =>
                          this.CambiarPagina(this.state.starat + 1)
                        }
                      >
                        <NavigateNextIcon />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </section>
          </div>
        </div>
        {/*----------------MODAL EDITAR USUARIO--------------------*/}
        <div className="modal fade" id="AgregarPagosOtros">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Agregar PagosOtros</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Aquí puedes agregar contenido personalizado para el modal.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <button type="button" className="btn btn-primary">
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*--------------------------------------------*/}
      </div>
    );
  }
}

export default PagosOtros;
