import { Link } from "react-router-dom";
export function RespuestasConsultas(data) {
  var resp = "";
  if (data.error !== null) {
    resp = (
      <div className="alert alert-danger" role="alert">
        {data.msg}
      </div>
    );
  } else if (data.success !== null) {
    resp = (
      <div className="alert alert-success" role="alert">
        {data.msg}
      </div>
    );
  } else if (data.wait !== null) {
    resp = (
      <div className="alert alert-info" role="alert">
        Esperando respuesta del Servidor
      </div>
    );
  }
  return resp;
}
export function EstadosPrestamos(estado, id) {
  var resp = "";
  estado = Number(estado);
  if (estado === 0) {
    resp = <Link className="btn btn-warning">En Revision</Link>;
  } else if (estado === 1) {
    resp = (
      <Link className="btn btn-info" to="">
        Por Desembolsar
      </Link>
    );
  } else if (estado === 2) {
    resp = (
      <Link className="btn btn-danger" to="">
        Negado
      </Link>
    );
  } else if (estado === 3) {
    resp = (
      <Link className="btn btn-success" to={"/pagos/" + id}>
        Activo
      </Link>
    );
  } else if (estado === 4) {
    resp = (
      <Link className="btn btn-danger" to={"/pagos/" + id}>
        Cancelado
      </Link>
    );
  }
  return resp;
}
export function Riesgo(riesgo, formapago) {
  var resp = "";
  riesgo = Number(riesgo);
  if (formapago === "dia") {
    if (riesgo <= 0) {
      resp = <div className="btn btn-success">{riesgo + " (Normal)"}</div>;
    } else if (riesgo <= 3) {
      resp = <div className="btn btn-success">{riesgo + " (Bajo)"}</div>;
    } else if (riesgo <= 5) {
      resp = <div className="btn btn-warning">{riesgo + " (Moderado)"}</div>;
    } else if (riesgo <= 6) {
      resp = <div className="btn btn-danger">{riesgo + " (Malo)"}</div>;
    } else {
      resp = <div className="btn btn-danger">{riesgo + " (Muy Malo)"}</div>;
    }
  } else {
    if (riesgo <= 0) {
      resp = <div className="btn btn-success">{riesgo + " (Normal)"}</div>;
    } else if (riesgo <= 2) {
      resp = <div className="btn btn-success">{riesgo + " (Bajo)"}</div>;
    } else if (riesgo <= 4) {
      resp = <div className="btn btn-warning">{riesgo + " (Moderado)"}</div>;
    } else if (riesgo <= 6) {
      resp = <div className="btn btn-danger">{riesgo + " (Malo)"}</div>;
    } else {
      resp = <div className="btn btn-danger">{riesgo + " (Muy Malo)"}</div>;
    }
  }
  return resp;
}

export function TipoRiesgo(riesgo, formapago) {
  var resp = "";
  riesgo = Number(riesgo);
  if (formapago === "dia") {
    if (riesgo <= 0) {
      resp = "Normal";
    } else if (riesgo <= 3) {
      resp = "Bajo";
    } else if (riesgo <= 5) {
      resp = "Moderado";
    } else if (riesgo <= 6) {
      resp = "Malo";
    } else {
      resp = "Muy Malo";
    }
  } else {
    if (riesgo <= 0) {
      resp = "Normal";
    } else if (riesgo <= 2) {
      resp = "Bajo";
    } else if (riesgo <= 4) {
      resp = "Moderado";
    } else if (riesgo <= 6) {
      resp = "Malo";
    } else {
      resp = "Muy Malo";
    }
  }
  return resp;
}

export const tiposUsuariosPermitidos = {
  btnCuadreCaja: ["1", "6"],

  btnPrestamoAgregar: ["1", "6","4"],
  btnPrestamoReasignar: ["1", "6"],
  btnPrestamoExportar: ["1"],

  btnClienteAgregar: ["1", "6"],
  btnClienteRevisarBase: ["1", "6"],

  btnJuntaAgregar: ["1", "6"],
  btnJuntaExportar: ["1", "6"],
  btnJuntaEliminarPagos: ["1"],

  btnJuntaPlanAgregar: ["1", "6"],
  btnJuntaPlanExportar: ["1", "6"],
  btnJuntaPlanEditar: ["1", "6"],
  btnJuntaPlanEliminarPagos: ["1"],

  btnEgresosEliminarPagos: ["1"],

  btnCuadreCajaIngreosEliminarPagos: ["1"],
  btnCuadreCajaNotasEliminarPagos: ["1"],
  btnCuadreCajaDepositosEliminarPagos: ["1"],
  btnCuadreCajaEntregaEfectivoEliminarPagos: ["1"],
  btnCuadreCajaSueldosEliminarPagos: ["1"],

  btnCuadrePagosEliminarPagos: ["1"],
  btnCuadrePagosEliminarInteresGastos: ["1"],

  moduloReportes: ["1"],
  moduloCuadreCaja: ["1", "6"],
  moduloUsuarios: ["1"],
  moduloFeriados: ["1"],
  moduloClientes: ["1", "2", "3", "4", "5", "6", "7"],
  moduloPrestamos: ["1", "2", "3", "4", "5", "6", "7"],
  moduloCalculadora: ["1", "2", "3", "4", "5", "6", "7"],
  moduloCobranzaDiaria: ["1", "2", "3", "4", "5", "6", "7"],
  moduloPagosOtros: ["1", "6"],
  moduloJunta: ["1", "2", "3", "4", "5", "6", "7"],
  moduloJuntaPlan: ["1", "2", "3", "4", "5", "6", "7"],
  moduloEgresos: ["1", "2", "3", "4", "5", "6", "7"],
  moduloPreEvaluacion: ["1", "2", "3", "4", "5", "6", "7"],
  pantallaAdminCobranzaDiaria: ["1", "6"],
  pantallaAsesorCobranzaDiaria: ["4"],
};
