import React from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../servicios/api";
import Cookies from "universal-cookie";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "../../css/table.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Menu from "../../comp/Menu";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class ClientesAsesorCastigados extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos: [],
      tablaBusqueda: [],
      busqueda: "",
      name: "",
      cargo: "5",
      id_asesor: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  cargarDatos() {
    const cookie = new Cookies();
    const TipUser = cookie.get("TipUser");
    const IdCaja = this.props.match.params.id;

    this.setState({ cargo: TipUser, id_asesor: IdCaja });

    fetch(api + "Cliente/?cliente_asesor2=" + IdCaja)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta[0].success === 0) {
          this.setState({
            Datos: [],
            tablaBusqueda: [],
          });
        } else {
          this.setState({
            Datos: datosRespuesta,
            tablaBusqueda: datosRespuesta,
          });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
    this.filtrar(target.value);
    //console.log(target.value);
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  filtrar(termino) {
    console.log(termino);
    var resultadoBusqueda = this.state.tablaBusqueda.filter((elemento) => {
      if (
        elemento.id.toString().toLowerCase().includes(termino.toLowerCase()) ||
        elemento.dni.toString().toLowerCase().includes(termino.toLowerCase()) ||
        elemento.ape1
          .toString()
          .toLowerCase()
          .includes(termino.toLowerCase()) ||
        elemento.name.toString().toLowerCase().includes(termino.toLowerCase())
      ) {
        return elemento;
      }
    });
    this.setState({
      Datos: resultadoBusqueda,
    });
  }

  render() {
    const { Datos, cargo } = this.state;
    //console.log(Datos);
    var btnVolver = "";
    if (cargo === "1") {
      btnVolver = (
        <Link to="/clientes" className="btn btn-light">
          <ChevronLeftIcon /> IR AL MENU DE CLIENTES
        </Link>
      );
    } else {
      btnVolver = (
        <Link to="/" className="btn btn-light">
          <ChevronLeftIcon /> IR A INICIO
        </Link>
      );
    }

    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          {btnVolver}{" "}
          <a
            href={
              "https://altrisa.site/apiold/DescargarPdf/clientes-asesor0.php?id=" +
              this.state.id_asesor
            }
            className="btn btn-info"
            target="_blank"
          >
            DESCARGAR
          </a>
          <section className="content">
            <div className="container-fluid">
              <ul class="nav nav-tabs">
                <li class="nav-item">
                  <Link
                    class="nav-link "
                    to={"/clientes-asesor/" + this.state.id_asesor}
                  >
                    Clientes Activos
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    class="nav-link active"
                    to={"/clientes-asesor-castigados/" + this.state.id_asesor}
                  >
                    Clientes Castigados
                  </Link>
                </li>
              </ul>
              <div className="row">
                <div className="table-responsive cont-table cont">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1>CLIENTES</h1>
                      </div>
                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item">
                            <Link to="/#/inicio">Inicio</Link>
                          </li>
                          <li className="breadcrumb-item active">home</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div>
                    <form
                      onSubmit={this.handleSubmit}
                      className="containerInput"
                    >
                      <input
                        type="search"
                        placeholder="Búsqueda por Codigo,Nombre, Apellido, DNI."
                        className="form-control inputBuscar"
                        name="busqueda"
                        onChange={this.handleChange}
                        value={this.state.busqueda}
                      ></input>
                      <button className="btn btn-success">
                        <SearchIcon />
                      </button>
                    </form>
                  </div>

                  <table className="table">
                    <thead>
                      <tr>
                        <th>Codigo Cliente</th>
                        <th>Apellidos y Nombre</th>
                        <th>DNI</th>
                        <th>Asesor</th>
                        <th>Detalle</th>
                        <th>Historial</th>
                        <th>Ver</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Datos.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.id}</td>
                          <td>
                            {Datos.ape1} {Datos.ape2} {Datos.name}
                          </td>
                          <td>{Datos.dni}</td>
                          <td></td>
                          <td>
                            {" "}
                            <button className="btn btn-primary">i</button>{" "}
                          </td>
                          <td>
                            {" "}
                            <button className="btn btn-primary">i</button>{" "}
                          </td>
                          <td>
                            <Link
                              className="btn btn-success"
                              to={"/cliente/" + Datos.id}
                            >
                              {" "}
                              <VisibilityIcon />{" "}
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
export default withRouter(ClientesAsesorCastigados);
