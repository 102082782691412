import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link, useParams } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/form.css";
import Menu from "../../comp/Menu";
import Swal from "sweetalert2";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class ClienteEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos: [],
      id_clie: 0,
      error: null,
      success: null,
      procesando: null,

      name: null,
      ape1: null,
      ape2: null,
      dni: null,
      telefonos: "",

      c_name: null,
      c_ap1: null,
      c_ap2: null,
      c_dni: null,

      n_sector: null,
      negocio: null,
      n_antig: null,
      n_condicion: null,

      n_telefonos: null,
      n_dep: null,
      n_dis: null,
      n_pro: null,

      n_id1: null,
      n_id2: null,
      n_pos_d: null,
      n_pos_s: null,

      in_ex1: null,
      in_ex2: null,
      v_condicion: null,
      v_material: null,

      direccion2: null,
      direc_re: null,
      dep: null,
      dis: null,
      pro: null,
      estado_civil: null,
      hijos_d: null,
      hijos_i: null,
      direccion: null,
      perdida: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }

  cargarDatos() {
    const IdCliente = this.props.match.params.id;
    this.setState({
      id_clie: IdCliente,
    });
    const datosEnviar = {};

    fetch(api + "Cliente/?consultar=" + IdCliente, {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          Datos: datosRespuesta[0],
          name: datosRespuesta[0].name,
          ape1: datosRespuesta[0].ape1,
          ape2: datosRespuesta[0].ape2,
          dni: datosRespuesta[0].dni,
          telefonos: datosRespuesta[0].telefonos,
          c_name: datosRespuesta[0].c_name,
          c_ap1: datosRespuesta[0].c_ap1,
          c_ap2: datosRespuesta[0].c_ap2,
          c_dni: datosRespuesta[0].c_dni,
          direccion: datosRespuesta[0].direccion,

          n_sector: datosRespuesta[0].n_sector,
          negocio: datosRespuesta[0].negocio,
          n_antig: datosRespuesta[0].n_antig,
          n_condicion: datosRespuesta[0].n_condicion,

          n_telefonos: datosRespuesta[0].n_telefonos,
          n_dep: datosRespuesta[0].n_dep,
          n_dis: datosRespuesta[0].n_dis,
          n_pro: datosRespuesta[0].n_pro,

          n_id1: datosRespuesta[0].n_id1,
          n_id2: datosRespuesta[0].n_id2,
          n_pos_d: datosRespuesta[0].n_pos_d,
          n_pos_s: datosRespuesta[0].n_pos_s,

          in_ex1: datosRespuesta[0].in_ex1,
          in_ex2: datosRespuesta[0].in_ex2,
          v_condicion: datosRespuesta[0].v_condicion,
          v_material: datosRespuesta[0].v_material,

          direccion2: datosRespuesta[0].direccion2,
          direc_re: datosRespuesta[0].direc_re,
          dep: datosRespuesta[0].dep,
          pro: datosRespuesta[0].pro,
          dis: datosRespuesta[0].dis,
          estado_civil: datosRespuesta[0].estado_civil,
          hijos_d: datosRespuesta[0].hijos_d,
          hijos_i: datosRespuesta[0].hijos_i,
          perdida: datosRespuesta[0].perdida,
        });
        //console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const datosEnviar = {
      id_clie: this.state.id_clie,
      name: this.state.name,
      ape1: this.state.ape1,
      ape2: this.state.ape2,
      dni: this.state.dni,
      c_name: this.state.c_name,
      c_ap1: this.state.c_ap1,
      c_ap2: this.state.c_ap2,
      c_dni: this.state.c_dni,

      n_sector: this.state.n_sector,
      negocio: this.state.negocio,
      n_antig: this.state.n_antig,
      n_condicion: this.state.n_condicion,
      direccion: this.state.direccion,

      n_telefonos: this.state.n_telefonos,
      n_dep: this.state.n_dep,
      n_dis: this.state.n_dis,
      n_pro: this.state.n_pro,

      n_id1: this.state.n_id1,
      n_id2: this.state.n_id2,
      n_pos_d: this.state.n_pos_d,
      n_pos_s: this.state.n_pos_s,

      in_ex1: this.state.in_ex1,
      in_ex2: this.state.in_ex2,
      v_condicion: this.state.v_condicion,
      v_material: this.state.v_material,

      direccion2: this.state.direccion2,
      direc_re: this.state.direc_re,
      dep: this.state.dep,
      pro: this.state.pro,
      dis: this.state.dis,

      estado_civil: this.state.estado_civil,
      hijos_d: this.state.hijos_d,
      hijos_i: this.state.hijos_i,

      telefonos: this.state.telefonos,
      perdida: this.state.perdida,
    };

    //console.log(datosEnviar);

    fetch(api + "Cliente/?actualizar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
          });
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Cliente actualizado correctamente.",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: datosRespuesta.error,
            showConfirmButton: false,
            timer: 1500,
          });
          this.setState({ error: datosRespuesta.error, success: null });
        }
        console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link to={"/Cliente/" + this.state.id_clie} className="btn btn-light">
            <ChevronLeftIcon /> VOLVER
          </Link>
          <section className="cont">
            <div className="container-fluid">
              <div className="">
                <div className="">
                  <div className="row">
                    {this.state.success && (
                      <div className="alert alert-success" role="alert">
                        SE REGISTRO PAGO CORRECTAMENTE
                      </div>
                    )}
                    {this.state.error && (
                      <div className="alert alert-danger" role="alert">
                        {this.state.error}
                      </div>
                    )}
                    <form className="formx" onSubmit={this.handleSubmit}>
                      <h4>DATOS DE CLIENTE</h4>
                      <hr></hr>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="name">Nombre:</label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="form form-control"
                            placeholder="Nombre"
                            onChange={this.handleChange}
                            value={this.state.name}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="ape1">Apellido Paterno:</label>
                          <input
                            type="text"
                            name="ape1"
                            id="ape1"
                            className="form form-control"
                            placeholder="Apellido Paterno"
                            onChange={this.handleChange}
                            value={this.state.ape1}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="ape2">Apellido Materno:</label>
                          <input
                            type="text"
                            name="ape2"
                            id="ape2"
                            className="form form-control"
                            placeholder="Apellido Paterno"
                            onChange={this.handleChange}
                            value={this.state.ape2}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="dni">DNI:</label>
                          <input
                            type="text"
                            name="dni"
                            id="dni"
                            className="form form-control"
                            placeholder="DNI"
                            onChange={this.handleChange}
                            value={this.state.dni}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="dtelefonosni">TELEFONO:</label>
                          <input
                            type="text"
                            name="telefonos"
                            id="telefonos"
                            className="form form-control"
                            placeholder="TELEFONO"
                            onChange={this.handleChange}
                            value={this.state.telefonos}
                          />
                        </div>
                        <div className="col">
                          <label>CON PERDIDA</label>
                          <select
                            className="form-control"
                            name="perdida"
                            onChange={this.handleChange}
                            defaultValue={this.state.perdida}
                            required
                          >
                            <option></option>
                            <option
                              value="SI"
                              selected={"SI" === this.state.perdida}
                            >
                              SI
                            </option>
                            <option
                              value="NO"
                              selected={"NO" === this.state.perdida}
                            >
                              NO
                            </option>
                          </select>
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>

                      <br></br>
                      <br></br>
                      <br></br>
                      <h4>DATOS DE CONYUGE O AVAL</h4>
                      <hr></hr>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="c_name">Nombre:</label>
                          <input
                            type="text"
                            name="c_name"
                            id="c_name"
                            className="form form-control"
                            placeholder="Nombre"
                            onChange={this.handleChange}
                            value={this.state.c_name}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="c_ap1">Apellido Paterno:</label>
                          <input
                            type="text"
                            name="c_ap1"
                            id="c_ap1"
                            className="form form-control"
                            placeholder="Apellido Paterno"
                            onChange={this.handleChange}
                            value={this.state.c_ap1}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="c_ap2">Apellido Materno:</label>
                          <input
                            type="text"
                            name="c_ap2"
                            id="c_ap2"
                            className="form form-control"
                            placeholder="Apellido Paterno"
                            onChange={this.handleChange}
                            value={this.state.c_ap2}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="c_dni">DNI:</label>
                          <input
                            type="text"
                            name="c_dni"
                            id="c_dni"
                            className="form form-control"
                            placeholder="DNI"
                            onChange={this.handleChange}
                            value={this.state.c_dni}
                          />
                        </div>
                      </div>
                      <br></br>
                      <br></br>
                      <br></br>
                      <h4>INFORMACIÓN DE NEGOCIO</h4>
                      <hr></hr>
                      <div className="row">
                        <div className="col">
                          <label>SECTOR</label>
                          <select
                            className="form-control"
                            name="n_sector"
                            onChange={this.handleChange}
                          >
                            <option></option>
                            <option
                              value="SERVICIO"
                              selected={"SERVICIO" === this.state.n_sector}
                            >
                              SERVICIO
                            </option>
                            <option
                              value="COMERCIO"
                              selected={"COMERCIO" === this.state.n_sector}
                            >
                              COMERCIO
                            </option>
                            <option
                              value="PRODUCCION"
                              selected={"PRODUCCION" === this.state.n_sector}
                            >
                              PRODUCCION
                            </option>
                          </select>
                        </div>
                        <div className="col">
                          <label htmlFor="negocio">Actividad:</label>
                          <input
                            type="text"
                            name="negocio"
                            id="negocio"
                            className="form form-control"
                            placeholder="Actividad"
                            onChange={this.handleChange}
                            value={this.state.negocio}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="n_antig">Antiguedad:</label>
                          <input
                            type="text"
                            name="n_antig"
                            id="n_antig"
                            className="form form-control"
                            placeholder="Antiguedad"
                            onChange={this.handleChange}
                            value={this.state.n_antig}
                          />
                        </div>
                        <div className="col">
                          <label>CONDICION DEL LOCAL</label>
                          <select
                            className="form-control"
                            name="n_condicion"
                            onChange={this.handleChange}
                          >
                            <option></option>
                            <option
                              value="ALQUILADO"
                              selected={"ALQUILADO" === this.state.n_condicion}
                            >
                              ALQUILADO
                            </option>
                            <option
                              value="PROPIO"
                              selected={"PROPIO" === this.state.n_condicion}
                            >
                              PROPIO
                            </option>
                            <option
                              value="FAMILIAR"
                              selected={"FAMILIAR" === this.state.n_condicion}
                            >
                              FAMILIAR
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="n_dep">Departamento:</label>
                          <input
                            type="text"
                            name="n_dep"
                            id="n_dep"
                            className="form form-control"
                            placeholder="Departamento"
                            onChange={this.handleChange}
                            value={this.state.n_dep}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="n_pro">Provincia:</label>
                          <input
                            type="text"
                            name="n_pro"
                            id="n_pro"
                            className="form form-control"
                            placeholder="Provincia"
                            onChange={this.handleChange}
                            value={this.state.n_pro}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="n_dis">Distrito:</label>
                          <input
                            type="text"
                            name="n_dis"
                            id="n_dis"
                            className="form form-control"
                            placeholder="Distrito"
                            onChange={this.handleChange}
                            value={this.state.n_dis}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="n_telefonos">Telefono:</label>
                          <input
                            type="text"
                            name="n_telefonos"
                            id="n_telefonos"
                            className="form form-control"
                            placeholder="Telefonos"
                            onChange={this.handleChange}
                            value={this.state.n_telefonos}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="n_id1">Ingreso díario desde:</label>
                          <input
                            type="number"
                            name="n_id1"
                            id="n_id1"
                            className="form form-control"
                            placeholder="Ingreso díario desde:"
                            onChange={this.handleChange}
                            value={this.state.n_id1}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="n_id2">Ingreso díario hasta:</label>
                          <input
                            type="number"
                            name="n_id2"
                            id="n_id2"
                            className="form form-control"
                            placeholder="Ingreso díario hasta:"
                            onChange={this.handleChange}
                            value={this.state.n_id2}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="n_pos_s">
                            Posibilidad de pago(sem):
                          </label>
                          <input
                            type="number"
                            name="n_pos_s"
                            id="n_pos_s"
                            className="form form-control"
                            placeholder="Posibilidad de pago(sem):"
                            onChange={this.handleChange}
                            value={this.state.n_pos_s}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="n_pos_d">
                            Posibilidad de pago(dia):
                          </label>
                          <input
                            type="number"
                            name="n_pos_d"
                            id="n_pos_d"
                            className="form form-control"
                            placeholder="Posibilidad de pago(dia):"
                            onChange={this.handleChange}
                            value={this.state.n_pos_d}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="in_ex1">Ingreso extra desde:</label>
                          <input
                            type="number"
                            name="in_ex1"
                            id="in_ex1"
                            className="form form-control"
                            placeholder="Ingreso extra desde:"
                            onChange={this.handleChange}
                            value={this.state.in_ex1}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="in_ex2">Ingreso extra hasta:</label>
                          <input
                            type="number"
                            name="in_ex2"
                            id="in_ex2"
                            className="form form-control"
                            placeholder="Ingreso extra hasta:"
                            onChange={this.handleChange}
                            value={this.state.in_ex2}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="direccion">Dirección:</label>
                          <input
                            type="text"
                            name="direccion"
                            id="direccion"
                            className="form form-control"
                            placeholder="Dirección"
                            onChange={this.handleChange}
                            value={this.state.direccion}
                          />
                        </div>
                        <div className="col"></div>
                      </div>
                      <br></br>
                      <br></br>
                      <br></br>
                      <h4>INFORMACIÓN FAMILIAR</h4>
                      <hr></hr>
                      <div className="row">
                        <div className="col">
                          <label>CONDICION DE VIVIENDA</label>
                          <select
                            className="form-control"
                            name="v_condicion"
                            onChange={this.handleChange}
                          >
                            <option></option>
                            <option
                              value="ALQUILADO"
                              selected={"ALQUILADO" === this.state.v_condicion}
                            >
                              ALQUILADO
                            </option>
                            <option
                              value="PROPIO"
                              selected={"PROPIO" === this.state.v_condicion}
                            >
                              PROPIO
                            </option>
                            <option
                              value="FAMILIAR"
                              selected={"FAMILIAR" === this.state.v_condicion}
                            >
                              FAMILIAR
                            </option>
                          </select>
                        </div>
                        <div className="col">
                          <label>MATERIAL DE VIVIENDA</label>
                          <select
                            className="form-control"
                            name="v_material"
                            onChange={this.handleChange}
                          >
                            <option></option>
                            <option
                              value="RUSTICA"
                              selected={"RUSTICA" === this.state.v_material}
                            >
                              RUSTICA
                            </option>
                            <option
                              value="MATERIAL NOBLE"
                              selected={
                                "MATERIAL NOBLE" === this.state.v_material
                              }
                            >
                              MATERIAL NOBLE
                            </option>
                          </select>
                        </div>
                        <div className="col">
                          <label htmlFor="direccion2">DIRECCIÓN:</label>
                          <input
                            type="text"
                            name="direccion2"
                            id="direccion2"
                            className="form form-control"
                            placeholder="DIRECCIÓN:"
                            onChange={this.handleChange}
                            value={this.state.direccion2}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="direc_re">REFERENCIA:</label>
                          <input
                            type="text"
                            name="direc_re"
                            id="direc_re"
                            className="form form-control"
                            placeholder="REFERENCIA:"
                            onChange={this.handleChange}
                            value={this.state.direc_re}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="dep">Departamento:</label>
                          <input
                            type="text"
                            name="dep"
                            id="dep"
                            className="form form-control"
                            placeholder="Departamento"
                            onChange={this.handleChange}
                            value={this.state.dep}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="pro">Provincia:</label>
                          <input
                            type="text"
                            name="pro"
                            id="pro"
                            className="form form-control"
                            placeholder="Provincia"
                            onChange={this.handleChange}
                            value={this.state.pro}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="dis">Distrito:</label>
                          <input
                            type="text"
                            name="dis"
                            id="dis"
                            className="form form-control"
                            placeholder="Distrito"
                            onChange={this.handleChange}
                            value={this.state.dis}
                          />
                        </div>
                        <div className="col">
                          <label>ESTADO CIVIL</label>
                          <select
                            className="form-control"
                            name="estado_civil"
                            onChange={this.handleChange}
                          >
                            <option></option>
                            <option
                              value="SOLTERO"
                              selected={"SOLTERO" === this.state.estado_civil}
                            >
                              SOLTERO
                            </option>
                            <option
                              value="CASADO"
                              selected={"CASADO" === this.state.estado_civil}
                            >
                              CASADO
                            </option>
                            <option
                              value="CONVIVIENTE"
                              selected={
                                "CONVIVIENTE" === this.state.estado_civil
                              }
                            >
                              CONVIVIENTE
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="hijos_d">HIJOS DEPENDIENTES:</label>
                          <input
                            type="number"
                            name="hijos_d"
                            id="hijos_d"
                            className="form form-control"
                            placeholder="HIJOS DEPENDIENTES"
                            onChange={this.handleChange}
                            value={this.state.hijos_d}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="hijos_i">HIJOS INDEPENDIENTES:</label>
                          <input
                            type="number"
                            name="hijos_i"
                            id="hijos_i"
                            className="form form-control"
                            placeholder="HIJOS INDEPENDIENTES"
                            onChange={this.handleChange}
                            value={this.state.hijos_i}
                          />
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                      <div className="fbtnx">
                        <button className="btn btn-primary">REGISTRAR</button>
                      </div>
                    </form>
                    {this.state.success && (
                      <div className="alert alert-success" role="alert">
                        SE REGISTRO PAGO CORRECTAMENTE
                      </div>
                    )}
                    {this.state.error && (
                      <div className="alert alert-danger" role="alert">
                        {this.state.error}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(ClienteEdit);
