import React from "react";
import Aside from "../../comp/Aside";
import Footer from "../../comp/Footer";
import Navbar from "../../comp/Navbar";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import Cookies from "universal-cookie";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import KeyIcon from "@mui/icons-material/Key";
import "../../css/perfil.css";

class PerfilEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      ape1: "",
      ape2: "",
      dni: "",
      phone: "",
      direccion: "",
      email: "",
      cumple: "",
      cargo: "",
      error: null,
      success: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }

  cargarDatos() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");

    fetch(api + "Users/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        this.setState({ nombre: datosRespuesta[0].name });
        this.setState({ ape1: datosRespuesta[0].apellido1 });
        this.setState({ ape2: datosRespuesta[0].apellido2 });
        this.setState({ dni: datosRespuesta[0].dni });
        this.setState({ phone: datosRespuesta[0].phone });
        this.setState({ direccion: datosRespuesta[0].direccion });
        this.setState({ email: datosRespuesta[0].email });
        this.setState({ cumple: datosRespuesta[0].cumple });
        this.setState({ cargo: datosRespuesta[0].tip_user });
      })
      .catch(console.log);
  }
  componentDidMount() {
    this.cargarDatos();
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");

    const datosEnviar = {
      id: IdUser,
      nombre: this.state.nombre,
      ape1: this.state.ape1,
      ape2: this.state.ape2,
      dni: this.state.dni,
      phone: this.state.phone,
      direccion: this.state.direccion,
      email: this.state.email,
      cumple: this.state.cumple,
    };
    //console.log(datosEnviar);
    fetch(api + "Users/?actualizar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({ success: 1 });
        } else {
          this.setState({ error: datosRespuesta.error });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);
    event.preventDefault();
  }

  render() {
    //const {} = this.state;

    return (
      <div>
        <Navbar />
        <Aside />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Perfil</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link className="nav-link" to="/miperfil">
                        <PersonIcon />
                        Mi Perfil
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link active" to="/miperfiledit">
                        <EditIcon />
                        Editar
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/miperfileditpass">
                        <KeyIcon />
                        Cambiar Contraseña
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="cont">
                  <div className="cont formuledit">
                    {this.state.success && (
                      <div className="alert alert-success" role="alert">
                        Se actualizo Correctamente
                      </div>
                    )}
                    <form className="row" onSubmit={this.handleSubmit}>
                      <div className="col">
                        <label>Nombre:</label>
                        <input
                          type="text"
                          name="nombre"
                          placeholder="Nombre"
                          required
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.nombre}
                        ></input>
                        <label>Apellido Paterno:</label>
                        <input
                          type="text"
                          name="ape1"
                          placeholder="Apellido Paterno"
                          required
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.ape1}
                        ></input>
                        <label>Apellido Materno:</label>
                        <input
                          type="text"
                          name="ape2"
                          placeholder="Apellido Materno"
                          required
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.ape2}
                        ></input>
                        <label>DNI:</label>
                        <input
                          type="number"
                          name="dni"
                          placeholder="DNI"
                          required
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.dni}
                        ></input>
                      </div>
                      <div className="col">
                        <label>Telefono:</label>
                        <input
                          type="number"
                          name="phone"
                          placeholder="Número de telefono"
                          required
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.phone}
                        ></input>
                        <label>DIRECCIÓN:</label>
                        <input
                          type="text"
                          name="direccion"
                          placeholder="Dirección"
                          required
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.direccion}
                        ></input>
                        <label>EMAIL:</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Correo electronico"
                          required
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.email}
                        ></input>
                        <label>CUMPLEAÑOS:</label>
                        <input
                          type="date"
                          name="cumple"
                          placeholder="Fecha de cumpleaños"
                          required
                          className="form-control"
                          onChange={this.handleChange}
                          value={this.state.cumple}
                        ></input>
                      </div>
                      <hr></hr>
                      <div className="boton-actualizar">
                        <button className="btn btn-success">Actualizar</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PerfilEdit;
