import React from "react";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/users.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import Menu from "../../comp/Menu";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class PrestamosDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_cliente: 0,
      estado: null,
      error: null,
      Datos: [],
      nombre: "",
      dni: "",
      telefono: "",
      direccion: "",
      email: "",
      negocio: "",
      fecha: "",
      detalle: "",
      c_name: "",
      c_dni: "",
      base: 0,
      exo: 0,
      ruta: "",
      name: "",
      estado2: null,
      error2: null,
      id_press: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
    if (target.name === "ruta") {
      this.setState({ ruta: target.files[0] });
    }
  }

  handleSubmit(event) {
    const IdPres = this.props.match.params.id;

    //Adding files to the formdata
    const formData = new FormData();
    formData.append("id_cliente", this.state.id_cliente);
    formData.append("id_press", IdPres);
    formData.append("ruta", this.state.ruta);
    formData.append("name", this.state.name);

    //console.log(this.state.ruta);
    //inicio
    const esto = this;
    axios({
      url: api + "Cliente/documents.php?insertar=1",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          esto.setState({
            estado2: 1,
            error2: null,
          });
        } else {
          esto.setState({
            error2: 1,
            estado2: null,
          });
        }
        //console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    //fin

    event.preventDefault();
  }

  cargarDatos() {
    const IdPress = this.props.match.params.id;
    this.setState({
      id_press: IdPress,
    });

    fetch(api + "Prestamos/?consultar=" + IdPress)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          id_cliente: datosRespuesta.data[0].id_cliente,
        });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    const {} = this.state;
    return (
      <div>
        <Menu />
        <div className="content-wrapper">
          <section className="content">
            <div className="container-fluid">
              <br></br>
              <Link
                to={"/Prestamo/" + this.state.id_press}
                className="btn btn-primary"
              >
                REGRESAR
              </Link>
              <div className="cont">
                {this.state.estado2 && (
                  <div className="alert alert-success" role="alert">
                    Registrado Correctamente
                  </div>
                )}
                {this.state.error2 && (
                  <div className="alert alert-danger" role="alert">
                    Error de Registro
                  </div>
                )}
                <h4>SUBIR DOCUMENTO</h4>
                <hr></hr>
                <form onSubmit={this.handleSubmit} className="form">
                  <div className="row">
                    <div className="col">
                      <label>Vigencia de Poder: </label>
                      <input
                        type="file"
                        required
                        name="ruta"
                        accept="application/pdf"
                        className="form-control-file"
                        onChange={this.handleChange}
                      ></input>
                    </div>
                    <div className="col">
                      <label>Nombre</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Nombre"
                        onChange={this.handleChange}
                        required
                      ></input>
                    </div>
                  </div>
                  <hr></hr>
                  <button type="submit" className="btn btn-success">
                    Cargar
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(PrestamosDocument);
