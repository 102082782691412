import React from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/form.css";
import Menu from "../../comp/Menu";
import { UserCod } from "../../common/Function";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class PrestamoCastigar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos: [],
      id_press: 0,
      error: null,
      success: null,
      procesando: null,
      Notas: [],
      castigado: "0",
      user_id: UserCod(),

      nota: "",
      Data_press: [],
      Data_clien: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }

  cargarDatos() {
    const IdPro = this.props.match.params.id;

    this.setState({
      id_press: IdPro,
    });
    fetch(api + "Prestamos/?consultar=" + IdPro)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta.data[0]);
        if (datosRespuesta.success === true) {
          this.setState({
            Data_press: datosRespuesta.data[0],
          });
          /*CONSULTAR PRESTAMOS*/
          fetch(api + "Cliente/?consultar=" + datosRespuesta.data[0].id_cliente)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta[0]);
              this.setState({
                Data_clien: datosRespuesta[0],
                castigado: datosRespuesta[0].castigado,
              });
            })
            .catch(console.log);
          /* FIN DE CONSULTAR PRESTAMOS */
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const datosEnviar = {
      id_cliente: "",
      nota: this.state.nota,
      id_asesor: this.state.user_id,
      id_press: this.state.id_press,
    };

    //console.log(datosEnviar);
    fetch(api + "Notas/?castigar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
          });
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);
    event.preventDefault();
  }

  render() {
    const { Data_press, Data_clien } = this.state;
    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link
            className="btn btn-light"
            to={"/prestamo/" + this.state.id_press}
          >
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          <section className="">
            <div className="container-fluid">
              <div className="">
                <div className="cont">
                  <div className="row">
                    <div className="col">
                      <h4>DETALLE</h4>
                      <hr></hr>
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            <tr>
                              <td>
                                <b>NOMBRE:</b>
                              </td>
                              <td>
                                <i>
                                  {Data_clien.name +
                                    " " +
                                    Data_clien.ape1 +
                                    " " +
                                    Data_clien.ape2}
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>DNI:</b>
                              </td>
                              <td>
                                <i>{Data_clien.dni}</i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>TIPO DE PRESTAMO:</b>
                              </td>
                              <td>
                                <i> {Data_press.formapago}</i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>CUOTAS:</b>
                              </td>
                              <td>
                                <i>
                                  {Data_press.cuotasr + "/" + Data_press.cuotas}
                                </i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>MONTO DE PRESTAMO:</b>
                              </td>
                              <td>
                                <i> {Data_press.monto}</i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>MONTO A CASTIGAR:</b>
                              </td>
                              <td>
                                <i>{Data_press.mora_monto}</i>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>DÍAS DE ATRASO:</b>
                              </td>
                              <td>
                                <i>{Data_press.mora_dias}</i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col">
                      {this.state.castigado === "0" ? (
                        <form className="formx" onSubmit={this.handleSubmit}>
                          <h4>RAZÓN DEL CASTIGO</h4>
                          <hr></hr>
                          <div className="row">
                            <div className="col">
                              <label htmlFor="nota">DESCRIPCIÓN:</label>
                              <input
                                type="text"
                                name="nota"
                                id="nota"
                                className="form form-control"
                                placeholder="DETALLE O RAZÓN DEL CASTIGO"
                                onChange={this.handleChange}
                                value={this.state.nota}
                              />
                            </div>
                          </div>

                          <div className="fbtnx">
                            <button className="btn btn-danger">CASTIGAR</button>
                          </div>
                        </form>
                      ) : (
                        <div className="alert alert-danger" role="alert">
                          Este prestamo ya esta castigado.
                        </div>
                      )}

                      {this.state.success && (
                        <div className="alert alert-success" role="alert">
                          SE CASTIGO A ESTE CLIENTE
                        </div>
                      )}
                      {this.state.error && (
                        <div className="alert alert-danger" role="alert">
                          {this.state.error}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(PrestamoCastigar);
