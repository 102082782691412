import React from "react";
import Aside from "../../comp/Aside";
import Footer from "../../comp/Footer";
import Navbar from "../../comp/Navbar";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Swal from "sweetalert2";

import "../../css/users.css";
import { useParams } from "react-router-dom";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class CuadreCajaView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos: [],
      Usuarios: [],
      Depositos: [],
      Devolucion: [],
      Desembolsos: [],
      EntregaEfectivo: [],
      Pagos: [],
      nombre: "",
      ape1: "",
      ape2: "",
      dni: "",
      phone: "",
      direccion: "",
      email: "",
      cumple: "",
      cargo: "",
      estado: "",
      error: null,
      error2: null,
      success: null,
      success2: null,
      cd_id: null,
      cd_tip: null,
      cd_monto: 0,
      cd_ase: null,
      tip_pd: "",
      des_pro: 0,
      pa_pro: 0,
      de_pro: 0,
      loca_id: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.conexion = false;
  }

  cargarDatos() {
    const IdUser = this.props.match.params.id;
    this.setState({ loca_id: IdUser });

    fetch(api + "Cuadre/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ Datos: datosRespuesta[0] });
        this.setState({
          des_pro: datosRespuesta[0].desem_pro,
          pa_pro: datosRespuesta[0].pagos_deu,
          de_pro: datosRespuesta[0].dep_pro,
        });
        //this.setState({ estado: datosRespuesta[0].estado });
      })
      .catch(console.log);

    fetch(api + "Cuadre/?mostrar_e=" + IdUser + "&mostrar_ee=dep")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        this.setState({ Depositos: datosRespuesta });
        //this.setState({ estado: datosRespuesta[0].estado });
      })
      .catch(console.log);

    fetch(api + "Cuadre/?mostrar_e=" + IdUser + "&mostrar_ee=dev")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ Devolucion: datosRespuesta });
        //this.setState({ estado: datosRespuesta[0].estado });
      })
      .catch(console.log);

    fetch(api + "Cuadre/?mostrar_e=" + IdUser + "&mostrar_ee=des")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        this.setState({ Desembolsos: datosRespuesta });
        //this.setState({ estado: datosRespuesta[0].estado });
      })
      .catch(console.log);

    fetch(api + "Cuadre/?mostrar_e=" + IdUser + "&mostrar_ee=pag")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        this.setState({ Pagos: datosRespuesta });
        //this.setState({ estado: datosRespuesta[0].estado });
      })
      .catch(console.log);

    fetch(api + "Users/")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        this.setState({ Usuarios: datosRespuesta });
      })
      .catch(console.log);
  }

  cargarEntregaEfectivo() {
    const IdUser = this.props.match.params.id;
    console.log(IdUser);

    fetch(api + "Cuadre/?mostrar_e=" + IdUser + "&mostrar_ee=enf")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({ EntregaEfectivo: datosRespuesta });
        //this.setState({ estado: datosRespuesta[0].estado });
      })
      .catch(console.log);
  }

  cargarAutomatico() {
    const IdUser = this.props.match.params.id;
    console.log(IdUser);

    fetch(api + "Cuadre/?automatico=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        //this.setState({ estado: datosRespuesta[0].estado });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarAutomatico();
    this.cargarEntregaEfectivo();
    this.cargarDatos();
  }

  BorrarRegistro = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, bórralo!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(api + "Cuadre/?borrar_e=" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
              this.cargarEntregaEfectivo();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "¡Eliminado!",
            "Su archivo ha sido eliminado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Tu archivo está a salvo :)",
            "error"
          );
        }
      });
  };

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const IdUser = this.props.match.params.id;

    const datosEnviar = {
      id_cd: IdUser,
      tip_po: this.state.cd_tip,
      monto: this.state.cd_monto,
      id_asesor: this.state.cd_ase,
      tip_pd: this.state.tip_pd,
    };
    console.log(datosEnviar);

    fetch(api + "Cuadre/?insertar_e=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
            estado: datosRespuesta.estado,
          });
          this.cargarDatos();
          this.cargarEntregaEfectivo();
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);
    event.preventDefault();
  }

  handleSubmit2(event) {
    const IdUser = this.props.match.params.id;

    const datosEnviar = {
      id_cd: IdUser,
      des_pro: this.state.des_pro,
      pa_pro: this.state.pa_pro,
      de_pro: this.state.de_pro,
    };
    console.log(datosEnviar);

    fetch(api + "Cuadre/?actualizar_e=" + IdUser, {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success2: 1,
            error2: null,
          });
          this.cargarDatos();
        } else {
          this.setState({ error2: datosRespuesta.error, success2: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);
    event.preventDefault();
  }

  render() {
    const {
      Datos,
      success,
      error,
      Usuarios,
      Desembolsos,
      Devolucion,
      Depositos,
      Pagos,
      loca_id,
      EntregaEfectivo,
    } = this.state;
    var fecha = Datos.fecha;
    console.log(EntregaEfectivo);
    /*SUMAR DATOS DE EGRESOS*/
    var tot_egresos =
      Desembolsos.reduce((sum, value) => sum + Number(value.monto), 0) +
      Devolucion.reduce((sum, value) => sum + Number(value.monto), 0) +
      Depositos.reduce((sum, value) => sum + Number(value.monto), 0) +
      Pagos.reduce((sum, value) => sum + Number(value.monto), 0) +
      EntregaEfectivo.reduce((sum, value) => sum + Number(value.monto), 0);
    console.log(tot_egresos);

    var vienen = Datos.vienen;
    var dep_real = Datos.dep_real;
    var sa_ini = Datos.vienen - Datos.dep_real;

    var reti_cue = Datos.reti_cue;
    var recupe_di = Datos.recupe_di;
    var ingre_efe = Datos.ingre_efe;
    var otros_in = Datos.otros_in;
    var tot_ing =
      Number(otros_in) +
      Number(ingre_efe) +
      Number(recupe_di) +
      Number(reti_cue) +
      Number(sa_ini);

    //console.log(Usuarios);
    return (
      <div>
        <Navbar />
        <Aside />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>CUADRE DE CAJA DIARIA</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link className="nav-link active" to="/cuadre-caja">
                        <AssignmentIcon />
                        Ultimos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/cuadre-caja-agregar">
                        <PersonAddAltIcon />
                        Agregar
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="table-responsive cont">
                  <a
                    href={
                      "https://altrisa.site/descagar/cuadre-caja.php?id=" +
                      loca_id
                    }
                    target="_blank"
                  >
                    {" "}
                    <b className="btn btn-success">DESCARGAR</b>
                  </a>
                  <h4>FECHA {fecha}</h4>
                  <hr></hr>
                  <h5>
                    <i>INGRESOS</i>
                  </h5>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>VIENEN</td>
                        <td>{vienen}</td>
                        <td>RETIRO DE CUENTA MI CAJA</td>
                        <td>{reti_cue}</td>
                      </tr>
                      <tr>
                        <td>DEPOSITO REAL MI CAJA</td>
                        <td>{dep_real}</td>
                        <td>RECUPERACIONES DEL DIA (SISTEMA)</td>
                        <td>{recupe_di}</td>
                      </tr>
                      <tr>
                        <td style={{ backgroundColor: "#F1EE14" }}>
                          SALDO INCIAL
                        </td>
                        <td style={{ backgroundColor: "#F1EE14" }}>{sa_ini}</td>
                        <td>INGRESO EN EFECTIVO</td>
                        <td>{ingre_efe}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td>OTROS INGRESOS</td>
                        <td>{otros_in}</td>
                      </tr>
                    </tbody>
                  </table>
                  <h4 className="alert alert-warning" role="alert">
                    TOTAL INGRESOS: {tot_ing}
                  </h4>
                  <hr></hr>
                  <div className="row">
                    <div className="col">
                      <h4>DEPOSITOS</h4>
                      <table className="table">
                        <thead>
                          <tr>
                            <td>ASESOR</td>
                            <td>MONTO</td>
                            <td>ELIMINAR</td>
                          </tr>
                        </thead>
                        <tbody>
                          {Depositos.map((Datos) => (
                            <tr key={Datos.id}>
                              <td>
                                {Datos.name} {Datos.apellido1}
                              </td>
                              <td>{Datos.monto}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                  onClick={() => this.BorrarRegistro(Datos.id)}
                                >
                                  <DeleteForeverIcon />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col">
                      <h4>DEVOLUCIÓN MJ</h4>
                      <table className="table">
                        <thead>
                          <tr>
                            <td>ASESOR</td>
                            <td>MONTO</td>
                            <td>ELIMINAR</td>
                          </tr>
                        </thead>
                        <tbody>
                          {Devolucion.map((Datos) => (
                            <tr key={Datos.id}>
                              <td>
                                {Datos.name} {Datos.apellido1}
                              </td>
                              <td>{Datos.monto}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                  onClick={() => this.BorrarRegistro(Datos.id)}
                                >
                                  <DeleteForeverIcon />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col">
                      <h4>DESEMBOLSOS</h4>
                      <table className="table">
                        <thead>
                          <tr>
                            <td>ASESOR</td>
                            <td>MONTO</td>
                            <td>ELIMINAR</td>
                          </tr>
                        </thead>
                        <tbody>
                          {Desembolsos.map((Datos) => (
                            <tr key={Datos.id}>
                              <td>
                                {Datos.name} {Datos.apellido1}
                              </td>
                              <td>{Datos.monto}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                  onClick={() => this.BorrarRegistro(Datos.id)}
                                >
                                  <DeleteForeverIcon />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col">
                      <h4>PAGOS DIVERSOS</h4>
                      <table className="table">
                        <thead>
                          <tr>
                            <td>TIPO</td>
                            <td>MONTO</td>
                            <td>ELIMINAR</td>
                          </tr>
                        </thead>
                        <tbody>
                          {Pagos.map((Datos) => (
                            <tr key={Datos.id}>
                              <td>{Datos.tip_padi}</td>
                              <td>{Datos.monto}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                  onClick={() => this.BorrarRegistro(Datos.id)}
                                >
                                  <DeleteForeverIcon />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="col">
                      <h4>ENTREGA EN EFECTIVO</h4>
                      <table className="table">
                        <thead>
                          <tr>
                            <td>TIPO</td>
                            <td>MONTO</td>
                            <td>ELIMINAR</td>
                          </tr>
                        </thead>
                        <tbody>
                          {EntregaEfectivo.map((Datos) => (
                            <tr key={Datos.id}>
                              <td>{Datos.tip_padi}</td>
                              <td>{Datos.monto}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-warning"
                                  onClick={() => this.BorrarRegistro(Datos.id)}
                                >
                                  <DeleteForeverIcon />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <h4 className="alert alert-warning" role="alert">
                    {" "}
                    Total Egresos: {tot_egresos}{" "}
                  </h4>
                  <hr></hr>

                  <form className="form" onSubmit={this.handleSubmit}>
                    {this.state.success && (
                      <div className="alert alert-success" role="alert">
                        Se creo el usuario correctamente
                      </div>
                    )}
                    {this.state.error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                    <div className="row">
                      <div className="col">
                        <label>Tip Egreso</label>
                        <select
                          className="form-control"
                          required
                          name="cd_tip"
                          onChange={this.handleChange}
                          value={this.state.cd_tip}
                        >
                          <option value="">Seleccione un Cargo</option>
                          <option value="dep">DEPOSITOS</option>
                          <option value="dev">DEVOLUCION MJ</option>
                          <option value="des">DESEMBOLSOS</option>
                          <option value="pag">PAGOS DIVERSOS</option>
                          <option value="enf">ENTREGA EFECTIVO</option>
                        </select>
                      </div>
                      <div className="col">
                        <label>ASESOR</label>
                        <select
                          className="form-control"
                          required
                          name="cd_ase"
                          onChange={this.handleChange}
                          value={this.state.cd_ase}
                        >
                          {Usuarios.map((Datos) => (
                            <option value={Datos.id} key={Datos.id}>
                              {Datos.ape1} {Datos.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col">
                        <label>
                          TIPO <i>(Solo Pagos diversos)</i>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="tip_pd"
                          onChange={this.handleChange}
                          value={this.state.tip_pd}
                        ></input>
                      </div>
                      <div className="col">
                        <label>MONTO</label>
                        <input
                          type="number"
                          className="form-control"
                          required
                          name="cd_monto"
                          onChange={this.handleChange}
                          value={this.state.cd_monto}
                        ></input>
                      </div>
                    </div>
                    <div className="col">
                      <br></br>
                      <button className="btn btn-primary">Actualizar</button>
                    </div>
                  </form>
                  <hr></hr>
                  <br></br>
                  <h4 className="alert alert-warning" role="alert">
                    SALDO EN EFECTIVO: {tot_ing - tot_egresos}
                  </h4>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="container-fluid">
              <div className="cont">
                <h2>NOTA:</h2>
                <hr></hr>
                <form className="form" onSubmit={this.handleSubmit2}>
                  {this.state.success2 && (
                    <div className="alert alert-success" role="alert">
                      Se creo el usuario correctamente
                    </div>
                  )}
                  {this.state.error2 && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  <div className="row">
                    <div className="col">
                      <label>DESEMBOLSO PROGRAMADO</label>
                      <input
                        type="number"
                        className="form-control"
                        name="des_pro"
                        required
                        onChange={this.handleChange}
                        value={this.state.des_pro}
                      ></input>
                    </div>
                    <div className="col">
                      <label>PAGOS DE DEUDAS PROGRAMADAS</label>
                      <input
                        type="number"
                        className="form-control"
                        name="pa_pro"
                        required
                        onChange={this.handleChange}
                        value={this.state.pa_pro}
                      ></input>
                    </div>
                    <div className="col">
                      <label>DEPOSITO PROGRAMADO</label>
                      <input
                        type="number"
                        className="form-control"
                        name="de_pro"
                        required
                        onChange={this.handleChange}
                        value={this.state.de_pro}
                      ></input>
                    </div>
                    <div className="col">
                      <br></br>
                      <button className="btn btn-primary">Actualizar</button>
                    </div>
                  </div>
                </form>
                <br></br>
                <h4 className="alert alert-warning" role="alert">
                  TOTAL SALDO EFECTIVO:
                  {Number(this.state.des_pro) +
                    Number(this.state.de_pro) +
                    Number(this.state.pa_pro)}
                </h4>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(CuadreCajaView);
