import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
//import Swal from "sweetalert2";
import api from "../../servicios/api";
//import Cookies from "universal-cookie";
import "../../css/form.css";

class ReportesCastigado extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reporte: [],
      reporte2: [],
      asesor: "",
      fechaini: "",
      fechafin: "",
      error: null,
      success: null,
      estado_cargano: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    this.setState({
      estado_cargano: 1,
    });
    const datosEnviar = {
      fechafin: this.state.fechafin,
    };
    //console.log(datosEnviar);
    fetch(
      api + "Reportes/?reporte_castigado=" + this.state.fechafin + " 23:59:59",
      {
        method: "POST",
        body: JSON.stringify(datosEnviar),
      }
    )
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          success: 1,
          error: null,
          reporte: datosRespuesta.data,
          estado_cargano: null,
        });
        console.log(datosRespuesta);
      })
      .catch(console.log);

    fetch(
      api + "Reportes/?reporte_mora2=" + this.state.fechafin + " 23:59:59",
      {
        method: "POST",
        body: JSON.stringify(datosEnviar),
      }
    )
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          success: 1,
          error: null,
          reporte2: datosRespuesta.data,
          estado_cargano: null,
        });
        console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    const { reporte, reporte2 } = this.state;
    return (
      <div>
        <Menu />
        <div className="content">
        <br></br>
          <Link className="btn btn-light" to="/reportes">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>REPORTE CATIGADOS</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="reporte-form">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <label>Fecha de Consulta:</label>
                      <input
                        className="form-control"
                        name="fechafin"
                        type="date"
                        required
                        onChange={this.handleChange}
                        value={this.state.fechafin}
                      ></input>
                    </div>
                    <div className="col">
                      <br></br>
                      <button className="btn btn-primary">Consultar</button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="cont">
                <div className="table-responsive">
                  <h4>MONTO CASTIGADOS</h4>
                  <hr></hr>
                  {this.state.estado_cargano ? (
                    <img src="http://altrisa.site/img/cargando.gif"></img>
                  ) : (
                    ""
                  )}
                  <table className="table">
                    <tbody>
                      {reporte.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.asesor}</td>
                          <td>{Datos.wilmer}</td>
                          <td>{Datos.liza}</td>
                          <td>{Datos.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ReportesCastigado;
