import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
import "../../css/form.css";
import {
  UrlHeader,
  resumenDiarioUrl,
  UrlGet,
  MonedaPen,
  FechaActual,
  UrlPost,
} from "../../common/Function";
import SaveIcon from "@mui/icons-material/Save";
import Swal from "sweetalert2";
import Carga from "../Common/Carga";
import { useParams } from "react-router-dom";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class ReportesCierreMesNewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reporte: [],
      asesor: "",
      fechaini: "",
      fechafin: "",
      error: null,
      success: null,
      estado_cargano: null,
      //fecha_actual: FechaActual(),
      fecha_actual: "2023-07-15",
      permiso_btn_guardar: null,
      carga: null,
      fecha_reporte: null,
    };
  }
  CargarResumen() {
    const fecha = this.props.match.params.id;
    //console.log(fecha);
    this.setState({
      reporte: [],
      estado_cargano: 1,
      fecha_reporte: fecha,
    });
    var filter = {
      orderby: "id",
      ordermode: "DESC",
      fecha: fecha,
      //endat: 20,
    };
    fetch(resumenDiarioUrl + UrlGet(filter), {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.success === true) {
          this.setState({
            reporte: result.data,
            estado_cargano: null,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }
  GuargarRegistro() {
    Swal.fire({
      title: "Guardaras el cierre de mes",
      text: "Este proceso puede tardar unos minutos, no cierres la pagina hasta que termine",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Okey",
    }).then((result) => {
      if (result.isConfirmed) {
        let formdata = {
          registrar: 1,
          fecha_fin: this.state.fecha_actual,
        };
        this.setState({
          carga: true,
        });

        fetch(resumenDiarioUrl, {
          method: "POST",
          headers: UrlHeader(),
          body: UrlPost(formdata),
          redirect: "follow",
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.success === true) {
              Swal.fire(
                "Guardado!",
                "Cierre de mes guardado correctamente",
                "success"
              );
            }
            this.setState({
              carga: null,
            });
            this.CargarResumen();
            this.ValidarCierre();
          })
          .catch((error) => console.log("error", error));
      }
    });
  }
  ValidarCierre() {
    this.setState({
      carga: true,
    });
    let formdata = {
      registrar_validar: 1,
      fecha_fin: this.state.fecha_actual,
    };

    fetch(resumenDiarioUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: UrlPost(formdata),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            permiso_btn_guardar: true,
            carga: null,
          });
        } else {
          this.setState({
            permiso_btn_guardar: null,
            carga: null,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }
  componentDidMount() {
    this.CargarResumen();
    this.ValidarCierre();
  }

  render() {
    const { reporte } = this.state;

    const Asesor = reporte.map((item) => item.name_asesor);

    const If_total = reporte.map((item) => item.ingre_fin);
    const Gasto_gen_total = reporte.map((item) => item.gasto_gen);

    const ca_total = reporte.map((item) => item.cartera_activa);
    const mo7_total = reporte.map((item) => item.mora7);

    const mo15_total = reporte.map((item) => item.mora15);

    const mo30_total = reporte.map((item) => item.mora30);

    const cli_a_total = reporte.map((item) => item.clientes_activos);

    const co_total = reporte.map((item) => item.colocacion_num);

    const m_co_total = reporte.map((item) => item.colocacion);

    const cr_new_total = reporte.map((item) => item.creditos_new);

    const m_cr_new_total = reporte.map((item) => item.creditos_new_m);
    const recu_total = reporte.map((item) => item.recuperacion);

    const j_total = reporte.map((item) => item.junta);

    const jp_total = reporte.map((item) => item.junta_plan);

    const junta_total = reporte.map((item) => item.junta_total);
    return (
      <div>
        {this.state.carga ? <Carga /> : null}
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/reporte-new-cierre">
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          {this.state.permiso_btn_guardar ? (
            <div
              className="btn btn-primary"
              onClick={() => this.GuargarRegistro()}
            >
              <SaveIcon />
              Guardar cierre
            </div>
          ) : null}
          <section className="content">
            <div className="container-fluid">
              <div className="cont">
                <div className="table-responsive">
                  <h4>RESUMEN {this.state.fecha_reporte}</h4>
                  <hr></hr>
                  {this.state.estado_cargano ? (
                    <img src="/img/progress.gif" alt="cargando imagen"></img>
                  ) : null}
                  <table className="table">
                    <thead>
                      <tr>
                        <th>CONCEPTO</th>
                        {Asesor.map((date) => (
                          <td key={date}>{date}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {/* INGRESOS FINANCIEROS */}
                      <tr className="bg-warning text-dark">
                        <td>INGRESO FINANCIERO TOTAL</td>
                        {If_total.map((if_total) => (
                          <td key={if_total}>{MonedaPen(if_total)}</td>
                        ))}
                      </tr>
                      {/* RENDIMIENTO CARTERA ACTIVA */}
                      
                      {/* GASTOS */}
                      <tr className="bg-warning text-dark">
                        <td>GASTOS TOTAL</td>
                        {Gasto_gen_total.map((gasto_gen_total) => (
                          <td key={gasto_gen_total}>
                            {MonedaPen(gasto_gen_total)}
                          </td>
                        ))}
                      </tr>
                      {/* CARTERA ACTIVA */}
                      <tr className="bg-warning text-dark">
                        <td>CARTERA ACTIVA TOTAL</td>
                        {ca_total.map((ca_total) => (
                          <td key={ca_total}>{MonedaPen(ca_total)}</td>
                        ))}
                      </tr>
                      {/* CARTERA EN MORA 7 */}
                      <tr className="bg-warning text-dark">
                        <td>CARTERA EN MORA 7 TOTAL</td>
                        {mo7_total.map((mo7_total) => (
                          <td key={mo7_total}>{MonedaPen(mo7_total)}</td>
                        ))}
                      </tr>
                      {/* CARTERA EN MORA 15 */}
                      <tr className="bg-warning text-dark">
                        <td>CARTERA EN MORA 15 TOTAL</td>
                        {mo15_total.map((mo15_total) => (
                          <td key={mo15_total}>{MonedaPen(mo15_total)}</td>
                        ))}
                      </tr>
                      {/* CARTERA EN MORA 30 */}
                      <tr className="bg-warning text-dark">
                        <td>CARTERA EN MORA 30 TOTAL</td>
                        {mo30_total.map((mo30_total) => (
                          <td key={mo30_total}>{MonedaPen(mo30_total)}</td>
                        ))}
                      </tr>
                      {/* CLIENTES ACTIVOS */}
                      <tr className="bg-warning text-dark">
                        <td>CLIENTES ACTIVOS TOTAL</td>
                        {cli_a_total.map((cli_a_total) => (
                          <td key={cli_a_total}>{cli_a_total}</td>
                        ))}
                      </tr>
                      {/* COLOCACIÓN */}
                      <tr className="bg-warning text-dark">
                        <td>COLOCACIÓN TOTAL</td>
                        {co_total.map((co_total) => (
                          <td key={co_total}>{co_total}</td>
                        ))}
                      </tr>
                      {/* MONTO COLOCACIÓN */}
                      <tr className="bg-warning text-dark">
                        <td>MONTO COLOCACIÓN TOTAL</td>
                        {m_co_total.map((m_co_total) => (
                          <td key={m_co_total}>{MonedaPen(m_co_total)}</td>
                        ))}
                      </tr>
                      {/* CREDITOS NUEVOS */}
                      <tr className="bg-warning text-dark">
                        <td>CREDITOS NUEVOS TOTAL</td>
                        {cr_new_total.map((cr_new_total) => (
                          <td key={cr_new_total}>{cr_new_total}</td>
                        ))}
                      </tr>
                      {/* MONTO CREDITOS NUEVOS  */}
                      <tr className="bg-warning text-dark">
                        <td>MONTO CREDITOS NUEVOS TOTAL</td>
                        {m_cr_new_total.map((m_cr_new_total) => (
                          <td key={m_cr_new_total}>
                            {MonedaPen(m_cr_new_total)}
                          </td>
                        ))}
                      </tr>
                      {/* RECUPERACIONES   */}
                      <tr className="bg-warning text-dark">
                        <td>RECUPERACIONES TOTAL</td>
                        {recu_total.map((recu_total) => (
                          <td key={recu_total}>{MonedaPen(recu_total)}</td>
                        ))}
                      </tr>
                      {/* JUNTA   */}
                      <tr className="bg-warning text-dark">
                        <td>JUNTA </td>
                        {j_total.map((j_total) => (
                          <td key={j_total}>{MonedaPen(j_total)}</td>
                        ))}
                      </tr>
                      {/* JUNTA PLAN  */}
                      <tr className="bg-warning text-dark">
                        <td>JUNTA PLAN</td>
                        {jp_total.map((jp_total) => (
                          <td key={jp_total}>{MonedaPen(jp_total)}</td>
                        ))}
                      </tr>
                      {/* JUNTA ACUMULADA TOTAL  */}
                      <tr className="bg-warning text-white">
                        <td>JUNTA TOTAL</td>
                        {junta_total.map((junta_total) => (
                          <td key={junta_total}>{MonedaPen(junta_total)}</td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(ReportesCierreMesNewUser);
