import React from "react";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import Swal from "sweetalert2";
import {
  ValidarSesionActiva,
  checkValidarSesionActiva,
} from "../common/Function";
class Menu extends React.Component {
  CerrarSession() {
    Swal.fire({
      title: "¿Estás seguro de que quieres cerrar la sesión?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "./";
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }
  componentDidMount() {
    checkValidarSesionActiva();
    ValidarSesionActiva();
  }
  render() {
    return (
      <div id="menu">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <Link className="navbar-brand" to="/home">
            <img
              src="./img/logo.png"
              width="30"
              height="30"
              alt="Logo de la empresa"
            />
            <span className="brand-text font-weight-light">ALTRISA</span>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/miperfil">
                  <AccountCircleIcon />
                  Perfil
                </Link>
              </li>
              <li className="nav-item">
                <div className="nav-link" onClick={() => this.CerrarSession()}>
                  <LogoutIcon />
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default Menu;
