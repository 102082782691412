import React, { useEffect, useState, useRef } from "react";
import api from "../servicios/api";
import Cookies from "universal-cookie";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

export default function navbar() {
  const cookie = new Cookies();
  const IdUser = cookie.get("UserId");
  const montoPEN = cookie.get("MontoPEN");
  const montoUSD = cookie.get("MontoUSD");

  //console.log(IdUser);
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <MenuIcon />
          </a>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        {/* Navbar Search */}
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" role="button">
            <MenuIcon />
          </a>
        </li>
        <li className="nav-item">
          <div className="navbar-search-block">
            <form className="form-inline">
              <div className="input-group input-group-sm">
                <input
                  className="form-control form-control-navbar"
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                />
                <div className="input-group-append">
                  <button className="btn btn-navbar" type="submit">
                    <SearchIcon />
                  </button>
                  <button
                    className="btn btn-navbar"
                    type="button"
                    data-widget="navbar-search"
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>
        {/* Messages Dropdown Menu */}

        <li className="nav-item">
          <a className="nav-link" data-widget="fullscreen" role="button">
            <AspectRatioIcon />
          </a>
        </li>
      </ul>
    </nav>
  );
}
