import React from "react";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/users.css";
import { useParams } from "react-router-dom";
import SyncIcon from "@mui/icons-material/Sync";
import Swal from "sweetalert2";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import Menu from "../../comp/Menu";
import { EstadosPrestamos, Riesgo } from "../Common/utils";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  OrdenarPorFecha,
  UrlGet,
  UrlHeader,
  prestamosUrl,
} from "../../common/Function";
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from "@mui/icons-material/Edit";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class Cliente extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_cliente: 0,
      estado: "",
      Datos: [],
      nombre: "",
      dni: "",
      telefono: "",
      direccion: "",
      email: "",
      negocio: "",
      fecha: "",
      detalle: "",
      c_name: "",
      c_dni: "",
      base: 0,
      exo: 0,
      Documentos: [],
      d_estado: null,
      pdf_ruta: "",
      pdf_name: "",
      Prestamos: [],
      e_conyugue: null,
      e_negocio: null,
      e_familiar: null,
      e_documentos: null,
      e_prestamos: null,
      tipoUsuario: localStorage.getItem("TIPUSER"),
    };
  }

  cargarDatos() {
    const IdUser = this.props.match.params.id;
    this.setState({
      id_cliente: IdUser,
    });

    fetch(api + "Cliente/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          estado: datosRespuesta[0].estado,
          Datos: datosRespuesta[0],
          nombre:
            datosRespuesta[0].name +
            " " +
            datosRespuesta[0].ape1 +
            " " +
            datosRespuesta[0].ape2,
          dni: datosRespuesta[0].dni,
          telefono: datosRespuesta[0].telefonos,
          direccion: datosRespuesta[0].direccion,
          email: datosRespuesta[0].email,
          negocio: datosRespuesta[0].negocio,
          fecha: datosRespuesta[0].registro,
          detalle: datosRespuesta[0].descrip,
          c_name:
            datosRespuesta[0].c_name +
            " " +
            datosRespuesta[0].c_ape1 +
            " " +
            datosRespuesta[0].c_ape2,
          c_dni: datosRespuesta[0].c_dni,
          base: datosRespuesta[0].b_negra,
          exo: datosRespuesta[0].exo,
        });
      })
      .catch(console.log);
  }

  cargarDatos2() {
    const IdUser = this.props.match.params.id;
    this.setState({
      id_cliente: IdUser,
    });

    fetch(api + "Cliente/documents.php?id_cliente=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            d_estado: 1,
            Documentos: datosRespuesta.data,
          });
        } else {
          this.setState({
            d_estado: null,
            Documentos: [],
          });
        }
      })
      .catch(console.log);
  }

  cargarDatos3() {
    const IdUser = this.props.match.params.id;
    var data = { id_cliente: IdUser };
    fetch(prestamosUrl + UrlGet(data), {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Prestamos: OrdenarPorFecha(result.data),
          total: result.total,
          total_paginas: Math.ceil(result.total / this.state.endat),
        });
      })
      .catch((error) => console.log("error", error));
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarDatos2();
    this.cargarDatos3();
  }

  BaseNegativa = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          var valor = this.state.base;
          if (valor === "0") {
            valor = 1;
          } else {
            valor = 0;
          }
          //console.log(valor);
          fetch(api + "Cliente/?base_nega=" + id + "&valor=" + valor)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              console.log(datosRespuesta);
              this.cargarDatos();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "Actualizado!",
            "Su estado fue actualizado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Solicitud cancelada :)",
            "error"
          );
        }
      });

    this.cargarDatos();
  };

  Exonerado = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          var valor = this.state.exo;
          if (valor === "0") {
            valor = 1;
          } else {
            valor = 0;
          }
          fetch(api + "Cliente/?exonerar=" + id + "&valor=" + valor)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "Actualizado!",
            "Su estado fue actualizado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Solicitud cancelada :)",
            "error"
          );
        }
      });

    this.cargarDatos();
  };

  BorrarRegistro = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, bórralo!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(api + "Cliente/documents.php?borrar=" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
              this.cargarDatos2();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "¡Eliminado!",
            "Su archivo ha sido eliminado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Tu archivo está a salvo :)",
            "error"
          );
        }
      });

    this.cargarDatos();
    this.cargarDatos2();
  };

  AbrirConyugue() {
    if (this.state.e_conyugue === 1) {
      this.setState({
        e_conyugue: null,
        e_negocio: null,
        e_familiar: null,
        e_documentos: null,
        e_prestamos: null,
      });
    } else {
      this.setState({
        e_conyugue: 1,
        e_negocio: null,
        e_familiar: null,
        e_documentos: null,
        e_prestamos: null,
      });
    }
  }
  AbrirNegocio() {
    if (this.state.e_negocio === 1) {
      this.setState({
        e_conyugue: null,
        e_negocio: null,
        e_familiar: null,
        e_documentos: null,
        e_prestamos: null,
      });
    } else {
      this.setState({
        e_conyugue: null,
        e_negocio: 1,
        e_familiar: null,
        e_documentos: null,
        e_prestamos: null,
      });
    }
  }
  AbrirFamiliar() {
    if (this.state.e_familiar === 1) {
      this.setState({
        e_conyugue: null,
        e_negocio: null,
        e_familiar: null,
        e_documentos: null,
        e_prestamos: null,
      });
    } else {
      this.setState({
        e_conyugue: null,
        e_negocio: null,
        e_familiar: 1,
        e_documentos: null,
        e_prestamos: null,
      });
    }
  }
  AbrirDocumentos() {
    if (this.state.e_documentos === 1) {
      this.setState({
        e_conyugue: null,
        e_negocio: null,
        e_familiar: null,
        e_documentos: null,
        e_prestamos: null,
      });
    } else {
      this.setState({
        e_conyugue: null,
        e_negocio: null,
        e_familiar: null,
        e_documentos: 1,
        e_prestamos: null,
      });
    }
  }
  AbrirPrestamos() {
    if (this.state.e_prestamos === 1) {
      this.setState({
        e_conyugue: null,
        e_negocio: null,
        e_familiar: null,
        e_documentos: null,
        e_prestamos: null,
      });
    } else {
      this.setState({
        e_conyugue: null,
        e_negocio: null,
        e_familiar: null,
        e_documentos: null,
        e_prestamos: 1,
      });
    }
  }

  render() {
    const {
      Datos,
      nombre,
      dni,
      telefono,
      email,
      negocio,
      fecha,
      detalle,
      base,
      exo,
      id_cliente,
      d_estado,
      Prestamos,
    } = this.state;
    //console.log(base);
    var botoon_n = "";
    if (base === "0") {
      botoon_n = (
        <button type="button" className="btn btn-success">
          {" "}
          NO ESTA EN BASE NEGATIVA{" "}
        </button>
      );
    } else {
      botoon_n = (
        <button type="button" className="btn btn-danger">
          {" "}
          ESTA EN BASE NEGATIVA{" "}
        </button>
      );
    }
    var botoon_n2 = "";
    if (exo === "0") {
      botoon_n2 = (
        <button type="button" className="btn btn-danger">
          NO ESTA EXONERADO{" "}
        </button>
      );
    } else {
      botoon_n2 = (
        <button type="button" className="btn btn-success">
          ESTA EXONERADO
        </button>
      );
    }

    function castigado(xd_castigado) {
      if (xd_castigado === "1") {
        return (
          <div class="alert alert-danger" role="alert">
            CLIENTE CASTIGADO
          </div>
        );
      } else {
        return "";
      }
    }

    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link to={"/clientes"} className="btn btn-light">
            <ChevronLeftIcon /> VOLVER
          </Link>{" "}
          {this.state.tipoUsuario === "1" ? (
            <Link
              to={"/ClienteEdit/" + this.state.id_cliente}
              className="btn btn-primary"
            >
              <EditIcon /> EDITAR
            </Link>
          ) : null}
          <section className="cont">
            <div className="container-fluid">
              <div className="">
                {castigado(Datos.castigado)}
                <h4>DATOS DE USUARIO</h4>
                <hr></hr>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>NOMBRE: </b>
                      {nombre}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>DNI: </b>
                      {dni}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>TELEFONO: </b>
                      {telefono}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>DIRECCIÓN: </b>
                      {Datos.direccion2}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>EMAIL: </b>
                      {email}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>NEGOCIO: </b>
                      {negocio}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>FECHA DE REGISTRO: </b>
                      {fecha}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>DETALLE: </b>
                      {detalle}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>BASE NEGATIVA: {botoon_n}</b>
                    </p>
                    {this.state.tipoUsuario === "1" ? (
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => this.BaseNegativa(id_cliente)}
                      >
                        <SyncIcon />
                      </button>
                    ) : null}
                  </div>
                  <div className="col">
                    <p>
                      <b>EXONERADO BASE NEGATIVA {botoon_n2}</b>
                    </p>
                    {this.state.tipoUsuario === "1" ? (
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => this.Exonerado(id_cliente)}
                      >
                        <SyncIcon />
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="cont">
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      id="btn-nquote"
                      className="btn btn-success btn-sm w-100"
                      onClick={() => this.AbrirConyugue()}
                    >
                      CÓNYUGE O AVAL
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      id="btn-nquote"
                      className="btn btn-warning btn-sm w-100"
                      onClick={() => this.AbrirNegocio()}
                    >
                      INFORMACÓN DE NEGOCIO
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      id="btn-nquote"
                      className="btn btn-info btn-sm w-100"
                      onClick={() => this.AbrirFamiliar()}
                    >
                      INFORMACIÓN FAMILIAR
                    </button>
                  </div>
                </div>
                <br></br>
                <div className="row">
                  <div className="col">
                    <button
                      type="button"
                      id="btn-nquote"
                      className="btn btn-info btn-sm w-100"
                      onClick={() => this.AbrirDocumentos()}
                    >
                      DOCUMENTOS
                    </button>
                  </div>
                  <div className="col">
                    <button
                      type="button"
                      id="btn-nquote"
                      className="btn btn-success btn-sm w-100"
                      onClick={() => this.AbrirPrestamos()}
                    >
                      PRESTAMOS
                    </button>
                  </div>
                  <div className="col">
                    {this.state.tipoUsuario === "1" ? (
                      <Link
                        to={"/ClienteCastigar/" + this.state.id_cliente}
                        className="btn btn-danger w-100"
                      >
                        CASTIGAR
                      </Link>
                    ) : null}
                  </div>
                </div>
                <br></br>
                <div className="row">
                  <div className="col">
                    <Link
                      to={"/ClienteNotas/" + this.state.id_cliente}
                      className="btn btn-warning w-100"
                    >
                      OBSERVACIONES
                    </Link>
                  </div>
                  <div className="col"></div>
                  <div className="col"></div>
                </div>
              </div>
              {this.state.e_conyugue ? (
                <div className="cont">
                  <h4>DATOS DE CÓNYUGE O AVAL</h4>
                  <hr></hr>
                  <div className="row">
                    <div className="col">
                      <p>
                        <b>NOMBRE: </b>
                        {Datos.c_name}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>APELLIDO PATERNO: </b>
                        {Datos.c_ap1}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>APELLIDO MATERNO: </b>
                        {Datos.c_ap2}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>DNI: </b>
                        {Datos.c_dni}
                      </p>
                    </div>
                    <div className="col"></div>
                    <div className="col"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.e_negocio ? (
                <div className="cont">
                  <h4>INFORMACIÓN DE NEGOCIO</h4>
                  <hr></hr>
                  <div className="row">
                    <div className="col">
                      <p>
                        <b>SECTOR:</b>
                        {Datos.n_sector}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>ACTIVIDAD:</b>
                        {Datos.negocio}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>ANTIGUEDAD:</b>
                        {Datos.n_antig} años
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>CONDICIÓN DEL LOCAL:</b>
                        {Datos.n_condicion}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>
                        <b>DEPARTAMENTO:</b>
                        {Datos.n_dep}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>PROVINCIA:</b>
                        {Datos.n_pro}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>DISTRITO:</b>
                        {Datos.n_dis}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>TELEFONOS:</b>
                        {Datos.n_telefonos}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>
                        <b>INGRESO DÍARIO:</b>
                        entre {Datos.n_id1} y {Datos.n_id2}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>POSIBILIDAD DE PAGO SEMANAL:</b>
                        {Datos.n_pos_s}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>POSIBILIDAD DE PAGO DIARIO:</b>
                        {Datos.n_pos_d}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>INGRESO EXTRA:</b>
                        entre {Datos.in_ex1} y {Datos.in_ex2}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>
                        <b>DIRECCIÓN:</b>
                        {Datos.direccion}
                      </p>
                    </div>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="col"></div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.e_familiar ? (
                <div className="cont">
                  <h4>INFORMACIÓN FAMILIAR</h4>
                  <hr></hr>
                  <div className="row">
                    <div className="col">
                      <p>
                        <b>CONDICIÓN DE VIVIENDA: </b>
                        {Datos.v_condicion}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>MATERIAL DE VIVIENDA: </b>
                        {Datos.v_material}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>DIRECCIÓN: </b>
                        {Datos.direccion2}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>REFERENCIA: </b>
                        {Datos.direc_re}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>
                        <b>DEPARTAMENTO: </b>
                        {Datos.dep}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>PROVINCIA: </b>
                        {Datos.pro}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>DISTRITO: </b>
                        {Datos.dis}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>ESTADO CIVIL: </b>
                        {Datos.estado_civil}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>
                        <b>HIJOS DEPENDIENTES: </b>
                        {Datos.hijos_d}
                      </p>
                    </div>
                    <div className="col">
                      <p>
                        <b>HIJOS INDEPENDIENTES: </b>
                        {Datos.hijos_i}
                      </p>
                    </div>
                    <div className="col"></div>
                    <div className="col"></div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.e_documentos ? (
                <div className="cont">
                  <h4>DOCUMENTOS</h4>
                  <Link
                    to={"/clienteDocument/" + id_cliente}
                    className="btn btn-primary"
                  >
                    Añadir
                  </Link>
                  <hr></hr>
                  <div className="row">
                    <div className="col">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>FECHA</th>
                              <th>DETALLE</th>
                              <th>DOCUMENTO</th>
                              <th>ELIMINAR</th>
                            </tr>
                          </thead>
                          {d_estado ? (
                            <tbody>
                              {this.state.Documentos.map((Datos) => (
                                <tr className="text-center" key={Datos.id}>
                                  <td>{Datos.fecha}</td>
                                  <td>{Datos.name}</td>
                                  <td>
                                    <a
                                      className="btn btn-primary"
                                      href={
                                        "http://altrisa.site/" + Datos.ruta
                                      }
                                      target="_blank"
                                    >
                                      <DescriptionIcon />
                                    </a>
                                  </td>
                                  <td>
                                    <a
                                      className="btn btn-warning"
                                      onClick={() =>
                                        this.BorrarRegistro(Datos.id)
                                      }
                                    >
                                      <DeleteIcon />
                                    </a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <b>NO HAY DOCUMENTOS</b>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.e_prestamos ? (
                <div className="cont table-reponsive">
                  <h3>HISTORIAL DE CREDITOS</h3>
                  <table className="table">
                    <thead>
                      <tr>
                        <td>Fecha</td>
                        <td>Monto</td>
                        <td>Seguro</td>
                        <td>Coutas</td>
                        <td>Caja</td>
                        <td>Forma de Pago</td>
                        <td>Riesgo</td>
                        <td>Estado</td>
                        <td>Cronograma</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Prestamos.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.fecha}</td>
                          <td>{Datos.monto}</td>
                          <td>{Datos.desgrav}</td>
                          <td>
                            {Datos.cuotasr}/{Datos.cuotas}
                          </td>
                          <td>{Datos.caja_name}</td>
                          <td>{Datos.formapago}</td>
                          <td className="text-center">
                            {Riesgo(Datos.riesgo, Datos.formapago)}
                          </td>
                          <td>{EstadosPrestamos(Datos.estado, Datos.id)}</td>
                          <td>
                            <a
                              href={
                                "https://altrisa.site/apiold/oldreport/prestamos/descargarcronograma.php?id_pres=" +
                                Datos.id
                              }
                              target="_blank"
                              rel="noopener"
                              className="btn btn-success"
                              title="Cronograma"
                            >
                              <ArticleIcon />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                ""
              )}
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Cliente);
