export const columnsMoraList = [
  { header: "ID", key: "id", width: 15 },
  { header: "NOMBRE", key: "name", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape1", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape2", width: 25 },
  { header: "DNI", key: "dni", width: 15 },
  { header: "TELEFONOS", key: "telefonos", width: 15 },
  { header: "DIRECCION", key: "direccion", width: 50 },
  {
    header: "MONTO",
    key: "monto",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  { header: "PLAZO", key: "cuotas", width: 15 },
  {
    header: "PLAZO RESTANTE",
    key: "cuotasr",
    width: 15,

    alignment: "center",
  },
  {
    header: "DIAS DE MORA",
    key: "mora_dias",
    width: 15,

    alignment: "center",
  },
  {
    header: "MONTO DE MORA",
    key: "mora_monto",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "FECHA ULTIMO PAGO",
    key: "ultimo_pago",
    width: 15,
    format: "dd/mm/yyyy",
  },
  { header: "ASESOR", key: "asesor_name", width: 15 },
  { header: "CAJA", key: "caja_name", width: 15 },
  { header: "PERDIDA", key: "perdida", width: 10 },
];

export const columnsPrestamosList = [
  { header: "CÓDIGO", key: "id", width: 15 },
  { header: "RIESGO", key: "riesgo", width: 15 },
  { header: "FECHA", key: "fecha", width: 15 },
  { header: "NOMBRE", key: "name", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape1", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape2", width: 25 },
  {
    header: "MONTO",
    key: "monto",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "CA",
    key: "cartera_Activa",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  { header: "PLAZO", key: "cuotas", width: 15 },
  {
    header: "SEGURO",
    key: "desgrav",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "JUNTAS",
    key: "junta",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  { header: "TIPO", key: "formapago", width: 15 },
  { header: "ASESOR", key: "asesor_name", width: 15 },
  { header: "CAJA", key: "caja_name", width: 15 },
];

export const columnsClientesList = [
  { header: "ID", key: "id", width: 15 },
  { header: "FECH REGISTRO", key: "registro", width: 20 },
  { header: "NOMBRE", key: "name", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape1", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape2", width: 25 },
  { header: "DIRECCION", key: "direccion", width: 30 },
  { header: "DNI", key: "dni", width: 15 },
  { header: "TELEFONO", key: "telefonos", width: 15 },
  { header: "NEGOCIO", key: "negocio", width: 15 },
  {
    header: "PRESTAMOS ACTIVOS",
    key: "num_press",
    width: 25,
    style: { numFmt: "0" },
  },
  {
    header: "PRESTAMOS TERMINADOS",
    key: "press_terminados",
    width: 25,
    style: { numFmt: "0" },
  },
  {
    header: "JUNTA",
    key: "junta",
    width: 10,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "JUNTA PLAN",
    key: "junta_plan",
    width: 10,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  { header: "CUOTAS", key: "cuotas_press", width: 10 },
  {
    header: "MONTO",
    key: "monto_press",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  { header: "RIESGO", key: "riesgo_name", width: 20 },
  { header: "ASESOR", key: "asesor", width: 20 },
  {
    header: "ULTIMO PAGO",
    key: "ultimopago_press",
    width: 20,
    style: { numFmt: "dd/mm/yyyy hh:mm:ss" },
  },
  { header: "PERDIDA", key: "perdida", width: 10 },
];

export const columnsIngresos_FI = [
  { header: "ID", key: "id", width: 15 },
  { header: "NOMBRE", key: "name", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape1", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape2", width: 25 },
  { header: "DNI", key: "dni", width: 15 },
  { header: "PRESTAMO", key: "id_prestamo", width: 15 },
  {
    header: "INTERES",
    key: "dis_interes",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "MORA",
    key: "mora",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "SEGURO",
    key: "seguro",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "GASTOS ADMIN",
    key: "gastos_admin",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "FECHA",
    key: "fecha",
    width: 15,
    format: "dd/mm/yyyy",
  },
  { header: "ASESOR", key: "ase_name", width: 15 },
  { header: "CAJA", key: "ca_name", width: 15 },
];

export const columnsIngresos_FI_PO = [
  { header: "ID", key: "id", width: 15 },
  { header: "NOMBRE", key: "name", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape1", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape2", width: 25 },
  { header: "DNI", key: "dni", width: 15 },
  {
    header: "CAPITAL",
    key: "capital",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "INTERES",
    key: "interes",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "FECHA",
    key: "fecha",
    width: 15,
    format: "dd/mm/yyyy",
  },
  { header: "ASESOR", key: "ase_name", width: 15 },
  { header: "CAJA", key: "ca_name", width: 15 },
];

export const columnsRecuperaciones = [
  { header: "ID", key: "id", width: 15 },
  { header: "NOMBRE", key: "name", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape1", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape2", width: 25 },
  { header: "DNI", key: "dni", width: 15 },
  { header: "PRESTAMO", key: "id_prestamo", width: 15 },
  {
    header: "CAPITAL",
    key: "dis_capital",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "INTERES",
    key: "dis_interes",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "MORA",
    key: "mora",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "SEGURO",
    key: "seguro",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "GASTOS ADMIN",
    key: "gastos_admin",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "FECHA",
    key: "fecha",
    width: 15,
    format: "dd/mm/yyyy",
  },
  { header: "ASESOR", key: "ase_name", width: 15 },
  { header: "CAJA", key: "ca_name", width: 15 },
];
export const columnsRecuperacionesJunta = [
  { header: "ID", key: "id", width: 15 },
  { header: "NOMBRE", key: "name", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape1", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape2", width: 25 },
  { header: "DNI", key: "dni", width: 15 },
  {
    header: "MONTO",
    key: "monto",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "FECHA",
    key: "fecha",
    width: 15,
    format: "dd/mm/yyyy",
  },
  { header: "ASESOR", key: "ase_name", width: 15 },
  { header: "CAJA", key: "ca_name", width: 15 },
];

export const columnsJuntaList = [
  { header: "CODIGO", key: "id", width: 15 },
  { header: "NOMBRE CLIENTE", key: "nameClient", width: 25 },
  {
    header: "APELLIDO PATERNO",
    key: "ape1Client",
    width: 25,
  },
  {
    header: "APELLIDO MATERNO",
    key: "ap2Client",
    width: 25,
  },
  { header: "MONTO", key: "monto", width: 15 },
  { header: "NOMBRE ASESOR", key: "nameUser", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape1User", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape2User", width: 25 },
  { header: "INTERES", key: "interes", width: 10 },
];

export const columnsJuntaPlanList = [
  { header: "NOMBRE CLIENTE", key: "nameClient", width: 25 },
  {
    header: "APELLIDO PATERNO",
    key: "ape1Client",
    width: 25,
  },
  {
    header: "APELLIDO MATERNO",
    key: "ap2Client",
    width: 25,
  },
  { header: "DNI", key: "dniClient", width: 10 },
  { header: "CAJA", key: "nameCaja", width: 10 },
  { header: "NOMBRE ASESOR", key: "nameUser", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape1User", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape2User", width: 25 },
  { header: "MONTO", key: "monto", width: 15 },
  { header: "TIPO", key: "tipo", width: 10 },
  { header: "PLAN", key: "plan", width: 10 },
  { header: "CUOTAS", key: "coutas", width: 10 },
];

export const columnsColocaciones = [
  { header: "ID", key: "id", width: 15 },
  { header: "NOMBRE", key: "name", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape1", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape2", width: 25 },
  { header: "DNI", key: "dni", width: 15 },
  {
    header: "MONTO",
    key: "monto",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  { header: "PLAZO", key: "cuotas", width: 15 },
  {
    header: "FECHA",
    key: "fecha",
    width: 15,
    format: "dd/mm/yyyy",
  },
  { header: "ASESOR", key: "ase_name", width: 15 },
  { header: "CAJA", key: "ca_name", width: 15 },
];
export const columnsClientesActivos = [
  { header: "ID", key: "id", width: 15 },
  { header: "ID PRESS", key: "press_cod", width: 15 },
  { header: "NOMBRE", key: "name", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape1", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape2", width: 25 },
  { header: "DNI", key: "dni", width: 15 },
  { header: "ASESOR", key: "ase_name", width: 15 },
  { header: "CAJA", key: "ca_name", width: 15 },
];
export const columnsPagosOtros = [
  { header: "ID", key: "id", width: 15 },
  { header: "NOMBRE", key: "name", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape1", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape2", width: 25 },
  { header: "DNI", key: "dni", width: 15 },
  {
    header: "CAPITAL",
    key: "capital",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "INTERES",
    key: "interes",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  { header: "ASESOR", key: "ase_name", width: 15 },
  { header: "CAJA", key: "ca_name", width: 15 },
];
export const columnsCarteraActiva = [
  { header: "ID", key: "id", width: 15 },
  { header: "NOMBRE", key: "name", width: 25 },
  { header: "APELLIDO MATERNO", key: "ape1", width: 25 },
  { header: "APELLIDO PATERNO", key: "ape2", width: 25 },
  { header: "DNI", key: "dni", width: 15 },
  { header: "DIRECCIÓN", key: "direccion", width: 20 },
  { header: "DIRECCIÓN 2", key: "direccion2", width: 20 },
  { header: "COUTAS", key: "cuotas", width: 15 },
  {
    header: "MONTO",
    key: "monto",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  {
    header: "CAPITAL ACTIVO",
    key: "cartera_Activa",
    width: 15,
    style: { numFmt: "[$S/ ]#,##0.00;[Red]([$S/ ]#,##0.00)" },
  },
  { header: "ASESOR", key: "ase_name", width: 15 },
  { header: "CAJA", key: "ca_name", width: 15 },
  { header: "PERDIDA", key: "perdida", width: 10 },
];
export const columnsReporteInfocorp = [
  { header: "Mes de Reporte", key: "fecha_actual", width: 15 },
  { header: "Código Entidad", key: "vacio", width: 15 },
  { header: "Número de Crédito", key: "vacio", width: 25 },
  { header: "Tipo Documento Identidad", key: "tipo_documento", width: 25 },
  { header: "N° Documento Identidad", key: "dni", width: 25 },
  { header: "Razon Social", key: "razon_social", width: 25 },
  { header: "Apellido parterno", key: "ape1", width: 25 },
  { header: "Apellido maternos", key: "ape2", width: 25 },
  { header: "Nombres", key: "name", width: 25 },
  { header: "Tipo de persona", key: "tipo_persona", width: 25 },
  { header: "Tipo de credito", key: "tipo_credito", width: 25 },
  {
    header: "MN Deuda Directa Vigente",
    key: "mn_deuda_directa",
    width: 25,
  },
  {
    header: "MN Deuda Directa Refinanciada",
    key: "mn_deuda_directa_ref",
    width: 25,
  },
  {
    header: "MN Deuda Directa Vencida <= 30",
    key: "mn_deuda_directa_min30",
    width: 25,
  },
  {
    header: "MN Deuda Directa Vencida > 30",
    key: "mn_deuda_directa_max30",
    width: 25,
  },
  { header: "MN Deuda Directa Cobranza Judicial ", key: "vacio", width: 15 },
  {
    header: "MN Deuda Indirecta (avales,cartas fianza,credito) ",
    key: "vacio",
    width: 15,
  },
  { header: "MN Deuda Avalada ", key: "vacio", width: 15 },
  { header: "MN Linea de Crédito  ", key: "vacio", width: 15 },
  { header: "MN Creditos Castigados ", key: "vacio", width: 15 },
  { header: "ME Deuda Directa Vigente ", key: "vacio", width: 15 },
  { header: "ME Deuda Directa Refinanciada ", key: "vacio", width: 15 },
  { header: "ME Deuda Directa Vencida < = 30 ", key: "vacio", width: 15 },
  { header: "ME Deuda Directa Vencida > 30 ", key: "vacio", width: 15 },
  { header: "ME Deuda Directa Cobranza Judicial ", key: "vacio", width: 15 },
  {
    header: "ME Deuda Indirecta (avales,cartas fianza,credito) ",
    key: "vacio",
    width: 15,
  },
  { header: "ME Deuda Avalada", key: "vacio", width: 15 },
  { header: "ME Linea de Crédito ", key: "vacio", width: 15 },
  { header: "ME Creditos Castigados ", key: "vacio", width: 15 },
  { header: "Calificación SBS", key: "calificacion", width: 15 },
  { header: "Número días vencidos o morosos", key: "mora_dias", width: 15 },
  { header: "Dirección", key: "direccion", width: 15 },
  { header: "Distrito", key: "dis", width: 15 },
  { header: "Provincia", key: "pro", width: 15 },
  { header: "Departamento", key: "dep", width: 15 },
  { header: "Teléfono", key: "telefonos", width: 15 },
  { header: "Estado", key: "vacio", width: 15 },
  { header: "Fecha Vencimiento", key: "fecha_minima_pago", width: 25,numFmt: 'yyyy-mm-dd', },
];
