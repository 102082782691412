import React from "react";
import { Link } from "react-router-dom";
import api from "../servicios/api";
import { UserCod } from "../common/Function";

class BuscarPrestamos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codigo: "",
      Prestamos: 0,
      user_id: UserCod(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }
  handleSubmit(event) {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      api + "/Prestamos/filter.php?filter=" + this.state.codigo+"&user_id="+this.state.user_id,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            Prestamos: result.data,
          });
        } else {
          this.setState({
            Prestamos: [],
          });
        }
        console.log(result);
      })
      .catch((error) => console.log("error", error));
    event.preventDefault();
  }

  render() {
    const { Prestamos } = this.state;
    return (
      <div className="buscar-prestamo">
        <div className="row">
          <div className="w-100">
            <form className="row" onSubmit={this.handleSubmit}>
              <div className="col-9">
                <label>Buscar por Nombre o DNI</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por nombre,apellido , dni"
                  name="codigo"
                  onChange={this.handleChange}
                  value={this.state.codigo}
                  required
                ></input>
              </div>
              <div className="col-3">
                <br></br>
                <button className="btn btn-info w-100">Buscar</button>
              </div>
            </form>
            {Prestamos !== 0 ? (
              <div className="table-respone">
                <table className="table">
                  <tr>
                    <td></td>
                    <td>Monto</td>
                    <td>FP</td>
                    <td>Cliente</td>
                    <td>DNI</td>
                  </tr>
                  {Prestamos.map((Datos) => (
                    <tr className="text-center" key={Datos.id}>
                      <td>
                        {" "}
                        <Link
                          to={"/pagos/" + Datos.id}
                          className="btn btn-info"
                        >
                          Ir
                        </Link>{" "}
                      </td>
                      <td>{Datos.monto}</td>
                      <td>{Datos.formapago}</td>
                      <td>
                        {Datos.name + " " + Datos.ape1 + " " + Datos.ape2}
                      </td>
                      <td>{Datos.dni}</td>
                    </tr>
                  ))}
                </table>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default BuscarPrestamos;
