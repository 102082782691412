import React from "react";
import Aside from "../../comp/Aside";
import Footer from "../../comp/Footer";
import Navbar from "../../comp/Navbar";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import "../../css/table.css";

class CuadreCajaadd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Prestamos: [],
      error: null,
      success: null,
      vienen: 0,
      dep_real: 0,
      reti_cue: 0,
      recupe_di: 0,
      ingre_efe: 0,
      otros_in: 0,
      Recuperaciones: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }

  cargarDatos() {

    fetch(api + "Cuadre/?recuperaciones=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          var array_resp = datosRespuesta.data;
          var num_array  = array_resp.length - 1;
          var total = datosRespuesta.data[num_array].total;
          var TotalDevengado = total.replace(/,/g, "");
          //console.log(num_array);
          this.setState({ recupe_di: TotalDevengado });
        }
        
      })
      .catch(console.log);

    fetch(api + "Cuadre/?consulta_ult_s=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({ vienen: datosRespuesta.data[0].total });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const datosEnviar = {
      vienen: this.state.vienen,
      dep_real: this.state.dep_real,
      reti_cue: this.state.reti_cue,
      recupe_di: this.state.recupe_di,
      ingre_efe: this.state.ingre_efe,
      otros_in: this.state.otros_in,
    };
    console.log(datosEnviar);

    fetch(api + "Cuadre/?insertar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
          });
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);
    event.preventDefault();
  }

  render() {
    const { Prestamos, error, success,Recuperaciones } = this.state;

    //console.log(Recuperaciones.length);
    return (
      <div>
        <Navbar />
        <Aside />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>CUADRE DE CAJA DIARIO</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link className="nav-link" to="/cuadre-caja">
                        <AssignmentIcon />
                        Ultimos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        to="/cuadre-caja-agregar"
                      >
                        <PersonAddAltIcon />
                        Agregar
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="cont">
                  {this.state.success && (
                    <div className="alert alert-success" role="alert">
                      Se creo el usuario correctamente
                    </div>
                  )}
                  {this.state.error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                    </div>
                  )}
                  <form className="row formx" onSubmit={this.handleSubmit}>
                    <div className="col">
                      <label>VIENEN</label>
                      <input
                        type="number"
                        name="vienen"
                        placeholder="VIENEN"
                        className="form-control"
                        required
                        onChange={this.handleChange}
                        value={this.state.vienen}
                      />
                      <label>DEPOSITO REAL</label>
                      <input
                        type="number"
                        name="dep_real"
                        placeholder="DEPOSITO REAL"
                        className="form-control"
                        required
                        onChange={this.handleChange}
                        value={this.state.dep_real}
                      />
                      <label>RETIRO DE CUENTA MI CAJA</label>
                      <input
                        type="number"
                        name="reti_cue"
                        placeholder="RETIRO DE CUENTA MI CAJA"
                        className="form-control"
                        required
                        onChange={this.handleChange}
                        value={this.state.reti_cue}
                      />
                    </div>
                    <div className="col">
                      <label>RECUPERACIONES DEL DIA(SISTEMA)</label>
                      <input
                        type="number"
                        name="recupe_di"
                        placeholder="RECUPERACIONES DEL DIA"
                        className="form-control"
                        required
                        onChange={this.handleChange}
                        value={this.state.recupe_di}
                      />
                      <label>INGRESO EN EFECTIVO</label>
                      <input
                        type="number"
                        name="ingre_efe"
                        placeholder="INGRESO EN EFECTIVO"
                        className="form-control"
                        required
                        onChange={this.handleChange}
                        value={this.state.ingre_efe}
                      />
                      <label>OTROS INGRESOS</label>
                      <input
                        type="number"
                        name="otros_in"
                        placeholder="OTROS INGRESOS"
                        className="form-control"
                        required
                        onChange={this.handleChange}
                        value={this.state.otros_in}
                      />
                    </div>
                    <div className="fbtnx">
                      <button className="btn btn-primary">Actualizar</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default CuadreCajaadd;
