import React from "react";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import Cookies from "universal-cookie";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import WidgetsIcon from '@mui/icons-material/Widgets';
import "../../css/users.css";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Menu from "../../comp/Menu";
import ArticleIcon from "@mui/icons-material/Article";
import {
  UrlPost,
  UrlHeader,
  prestamosUrl,
  UserCod,
  OrdenarPorFecha,
  UrlGet,
  baseUrlOld,
  clienteUrl,
  userUrl,
} from "../../common/Function";
import { EstadosPrestamos, Riesgo } from "../Common/utils";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import GppGoodIcon from "@mui/icons-material/GppGood";
import PaymentsIcon from "@mui/icons-material/Payments";
import BlockIcon from "@mui/icons-material/Block";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class Prestamo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      estado: 15206,
      UserId: UserCod(),
      Prestamo: [],
      id_press: 1,
      error: null,
      success: null,
      error2: null,
      success2: null,
      error3: null,
      success3: null,
      name_asesor: "",
      fecha: "",
      name_cliente: "",
      dni: "",
      telefono: "",
      negocio: "",
      tip_press: "",
      monto: "",
      plazo: "",
      monto_c: "",
      ahorro: "",
      total_junta: "",
      total_juntap: "",
      cuotas_r: "",
      desgrav: "",
      formapago: "",
      tinteres: "",
      interes: "",
      id_cliente: 0,
      Prestamos: [],
      Documentos: [],
      d_estado: null,
      pdf_ruta: "",
      pdf_name: "",
      password: "",
      otros: 0,
      Simu_cronograma: [],
      Documentos_cli: [],
      c_riesgo: 0,
      box_cliente: null,
      box_cliente_doc: null,
      box_conyugue: null,
      box_negocio: null,
      box_info_familiar: null,
      box_prestamo: null,
      box_prestamo_doc: null,
      box_cronograma: null,
      hist_prestamos: null,
      castigado: 0,
      espera2: null,
      DatosCliente: [],
      DatosAsesor: [],
      tipoUsuario: localStorage.getItem("TIPUSER"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.handleSubmit3 = this.handleSubmit3.bind(this);
    this.conexion = false;
  }

  cargarDatos() {
    const IdPress = this.props.match.params.id;
    fetch(prestamosUrl + IdPress, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            Prestamo: result.data,
            id_press: IdPress,
            desgrav: result.data.desgrav,
          });
          this.cargarPrestamos(result.data.id_cliente);
          this.CargarDatosCliente(result.data.id_cliente);
          this.CargarDatosAsesor(result.data.id_cobrador);
        }
      })
      .catch((error) => console.log("error", error));
  }
  CargarDatosCliente(IdCliente) {
    fetch(clienteUrl + IdCliente, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            DatosCliente: result.data,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }
  CargarDatosAsesor(IdAsesor) {
    fetch(userUrl + IdAsesor, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            DatosAsesor: result.data,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }

  cargarDatos2() {
    const IdUser = this.props.match.params.id;
    fetch(api + "Cliente/documents.php?id_press=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            d_estado: 1,
            Documentos: datosRespuesta.data,
          });
        } else {
          this.setState({
            d_estado: null,
            Documentos: [],
          });
        }
      })
      .catch(console.log);
  }

  cargarSimuladorCronograma() {
    const Id = this.props.match.params.id;
    fetch(api + "Prestamos/simulacion_cronograma0.php?calcular=" + Id)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            Simu_cronograma: datosRespuesta.data,
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarDatos2();
    this.cargarSimuladorCronograma();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const IdUser = this.props.match.params.id;

    const datosEnviar = {
      id: IdUser,
      estado: this.state.estado,
    };
    //console.log(datosEnviar);

    fetch(api + "Users/?estado=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
            //estado: datosRespuesta.estado,
          });
          this.cargarDatos();
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);
    event.preventDefault();
  }

  handleSubmit2(event) {
    this.setState({ error2: null, success2: null, espera2: 1 });

    const datosEnviar = {
      desembolsar: true,
      password: this.state.password,
      desgrav: this.state.desgrav,
      otros: this.state.otros,
      id_press: this.state.id_press,
      id_user: this.state.UserId,
    };

    fetch(prestamosUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: UrlPost(datosEnviar),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({ error2: null, success2: 1, espera2: null });
        } else {
          this.setState({
            error2: result.error,
            success2: null,
            espera2: null,
          });
        }
        this.cargarDatos();
        console.log(result);
      })
      .catch((error) => console.log("error", error));
    event.preventDefault();
  }

  handleSubmit3(event) {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    const datosEnviar = {
      monto: this.state.monto,
      plazo: this.state.plazo,
      interes: this.state.interes,
      id_press: this.state.id_press,
      id_user: IdUser,
    };
    //console.log(datosEnviar);
    fetch(api + "Prestamos/?modificar=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({ success3: 1 });
        } else {
          this.setState({ error3: datosRespuesta.error });
        }
        this.cargarDatos();
        this.cargarSimuladorCronograma();
        //console.log(datosRespuesta);
      })
      .catch(console.log);
    event.preventDefault();
  }

  PAprobar = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Aprobar Prestamo?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          var formdata = {
            actualizar: true,
            id: id,
            estado: 1,
            userId: this.state.UserId,
          };

          var requestOptions = {
            method: "POST",
            headers: UrlHeader(),
            body: UrlPost(formdata),
            redirect: "follow",
          };

          fetch(prestamosUrl, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              if (result.success === true) {
                swalWithBootstrapButtons.fire(
                  "Aprobado!",
                  "Felicidades Aprobo el Prestamo.",
                  "success"
                );
                this.cargarDatos();
              } else {
                swalWithBootstrapButtons.fire(
                  "No autorizado",
                  result.msg,
                  "error"
                );
              }
            })
            .catch((error) => console.log("error", error));
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Quiza la proxima :)",
            "error"
          );
        }
      });

    this.cargarDatos();
  };

  PDesaprobar = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Desaprobar Solicitud?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          var formdata = {
            actualizar: true,
            id: id,
            estado: 2,
          };

          var requestOptions = {
            method: "POST",
            headers: UrlHeader(),
            body: UrlPost(formdata),
            redirect: "follow",
          };

          fetch(prestamosUrl, requestOptions)
            .then((response) => response.json())
            .then((result) => {
              this.cargarDatos();
            })
            .catch((error) => console.log("error", error));
          swalWithBootstrapButtons.fire(
            "Desaprobado!",
            "Su Prestamo fue desaprobado correctamente.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Cancelado", ":)", "error");
        }
      });

    this.cargarDatos();
  };

  cargarPrestamos(IdUser) {
    var data = { id_cliente: IdUser };
    fetch(prestamosUrl + UrlGet(data), {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Prestamos: OrdenarPorFecha(result.data),
        });
      })
      .catch((error) => console.log("error", error));
  }

  Extornar = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Desea Extornar Credito?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(api + "Prestamos/?extornar_desembolso=" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "Extornado!",
            "Su Prestamo fue extornado correctamente.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Cancelado", ":)", "error");
        }
      });

    this.cargarDatos();
  };

  PreAprobado = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Desea Volver a estado Pre-Aprobado?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(api + "Prestamos/?prestamo_pre_aprobado=" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "Extornado!",
            "El estado fue actualizado correctamente.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire("Cancelado", ":)", "error");
        }
      });

    this.cargarDatos();
  };

  BorrarRegistro = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, bórralo!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(api + "Cliente/documents.php?borrar=" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
              this.cargarDatos2();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "¡Eliminado!",
            "Su archivo ha sido eliminado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Tu archivo está a salvo :)",
            "error"
          );
        }
      });

    this.cargarDatos();
    this.cargarDatos2();
  };

  AbrirPrf = (ruta, name) => {
    var URL = "https://altrisa.site/";
    this.setState({
      pdf_name: name,
      pdf_ruta: URL + ruta,
    });
  };

  AbrirDatosCliente() {
    var boxes = this.ViewBoxes();
    if (this.state.box_cliente !== 1) {
      boxes.box_cliente = 1;
    }
    this.setState(boxes);
  }
  AbrirDocumentosCliente() {
    var boxes = this.ViewBoxes();
    if (this.state.box_cliente_doc !== 1) {
      boxes.box_cliente_doc = 1;
    }
    this.setState(boxes);
  }
  AbrirDatosPrestamo() {
    var boxes = this.ViewBoxes();
    if (this.state.box_prestamo !== 1) {
      boxes.box_prestamo = 1;
    }
    this.setState(boxes);
  }
  AbrirDocumentosPrestamo() {
    var boxes = this.ViewBoxes();
    if (this.state.box_prestamo_doc !== 1) {
      boxes.box_prestamo_doc = 1;
    }
    this.setState(boxes);
  }
  AbrirCronograma() {
    var boxes = this.ViewBoxes();
    if (this.state.box_cronograma !== 1) {
      boxes.box_cronograma = 1;
    }
    this.setState(boxes);
  }
  AbrirHistorialPrestamos() {
    var boxes = this.ViewBoxes();
    if (this.state.hist_prestamos !== 1) {
      boxes.hist_prestamos = 1;
    }
    this.setState(boxes);
  }

  ViewBoxes() {
    return {
      box_cliente: null,
      box_cliente_doc: null,
      box_conyugue: null,
      box_negocio: null,
      box_info_familiar: null,
      box_prestamo: null,
      box_prestamo_doc: null,
      box_cronograma: null,
      hist_prestamos: null,
    };
  }

  render() {
    const {
      monto,
      plazo,
      id_press,
      tinteres,
      interes,
      Prestamos,
      d_estado,
      Simu_cronograma,
      Prestamo,
      DatosAsesor,
      DatosCliente,
      tipoUsuario,
    } = this.state;

    /*CALCULAR CUOTA*/
    function pagoE(v_interes, v_i, v_cuotas, v_monto) {
      var resp = 0;
      v_interes = new Number(v_interes);
      v_monto = new Number(v_monto);
      v_cuotas = new Number(v_cuotas);
      v_i = new Number(v_i);
      resp = v_monto * (v_interes / 100);
      resp = resp / (1 - Math.pow(1 + v_interes / 100, -1 * v_cuotas * v_i));
      return resp;
    }

    function pagoE1(v_interes, v_cuotas, v_monto) {
      var resp = 0;
      v_interes = new Number(v_interes);
      v_monto = new Number(v_monto);
      v_cuotas = new Number(v_cuotas);
      resp = (v_monto + (v_monto * v_interes) / 100) / v_cuotas;
      return resp;
    }

    var monto_cuota = 0;

    if (tinteres === "2") {
      monto_cuota = pagoE(interes, 1, plazo, monto);
    } else {
      monto_cuota = pagoE1(interes, plazo, monto);
    }
    /*CARTERA */
    function name_carter(id_cartera) {
      if (id_cartera === "1") {
        return "WILMER";
      } else if (id_cartera === "2") {
        return "LIZA";
      } else if (id_cartera === "3") {
        return "RB";
      } else if (id_cartera === "4") {
        return "DIEGO";
      } else {
        return "[X] CARTERNA NO EXISTE EN LA FUNCION";
      }
    }

    return (
      <div id="prestamo">
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/prestamos-search">
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          {Prestamo.cuotas === Prestamo.cuotasr && tipoUsuario === "1" ? (
            <button
              className="btn btn-danger"
              onClick={() => this.Extornar(id_press)}
            >
              <CancelScheduleSendIcon />
              EXTORNAR
            </button>
          ) : null}{" "}
          {Prestamo.estado === "3" || Prestamo.estado === "4" ? (
            <a
              className="btn btn-primary"
              href={
                api +
                "oldreport/prestamos/descargarcronograma.php?id_pres=" +
                this.state.id_press
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <RequestPageIcon />
              CRONOGRAMA
            </a>
          ) : null}{" "}
          {Prestamo.estado === "3" || Prestamo.estado === "4" ? (
            <a
              className="btn btn-success"
              href={
                api +
                "oldreport/prestamos/descargardesgrav.php?id_pres=" +
                this.state.id_press
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <GppGoodIcon />
              SEGURO
            </a>
          ) : null}{" "}
          {Prestamo.estado === "3" || Prestamo.estado === "4" ? (
            <Link to={"/pagos/" + this.state.id_press} className="btn btn-info">
              <PaymentsIcon />
              PAGOS
            </Link>
          ) : null}{" "}
          {(Prestamo.estado === "3" || Prestamo.estado === "4") &&
            this.state.tipoUsuario === "1" ? (
            <Link
              to={"/prestamo-castigar/" + this.state.id_press}
              className="btn btn-danger"
            >
              <BlockIcon />
              CASTIGAR
            </Link>
          ) : null}{" "}
          {(Prestamo.estado === "3" || Prestamo.estado === "4") &&
            this.state.tipoUsuario === "1" ? (
            <Link
              to={"/prestamo-castigar/" + this.state.id_press}
              className="btn btn-success"
            >
              <CompareArrowsIcon />
              REASIGNAR
            </Link>
          ) : null}{" "}
          {Prestamo.cuotas === Prestamo.cuotasr && tipoUsuario === "1" ? (
            <Link
              to={"/prestamorcc/" + this.state.id_press}
              className="btn btn-warning"
            >
              <WidgetsIcon />
              RCC
            </Link>
          ) : null}{" "}
          <div className="cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <h1>DETALLE DE PRESTAMO</h1>
                </div>
              </div>
            </section>
            <hr></hr>
            <section className="row">
              <div className="col-4">
                <h4>
                  <b>ASESOR:</b>
                  {DatosAsesor.name}
                </h4>
              </div>
              <div className="col-4">
                <h4>
                  <b>FECHA:</b>
                  {Prestamo.fecha}
                </h4>
              </div>
              <div className="col-4">
                <h4>
                  <b>RIESGO</b>
                  {Riesgo(Prestamo.riesgo)}
                </h4>
              </div>
            </section>
            <section className="row">
              <div className="col-4">
                <button
                  type="button"
                  id="btn-nquote"
                  className="btn btn-success w-100 mb-2"
                  onClick={() => this.AbrirDatosCliente()}
                >
                  DATOS DE CLIENTE
                </button>
              </div>
              <div className="col-4">
                <button
                  type="button"
                  id="btn-nquote"
                  className="btn btn-success w-100 mb-2"
                  onClick={() => this.AbrirDocumentosCliente()}
                >
                  DOCUMENTOS DEL CLIENTE
                </button>
              </div>
              <div className="col-4">
                <button
                  type="button"
                  id="btn-nquote"
                  className="btn btn-success w-100 mb-2"
                  onClick={() => this.AbrirDatosPrestamo()}
                >
                  DATOS DEL PRESTAMO
                </button>
              </div>
              <div className="col-4">
                <button
                  type="button"
                  id="btn-nquote"
                  className="btn btn-success w-100 mb-2"
                  onClick={() => this.AbrirDocumentosPrestamo()}
                >
                  DOCUMENTOS DEL PRESTAMO
                </button>
              </div>
              <div className="col-4">
                <button
                  type="button"
                  id="btn-nquote"
                  className="btn btn-success w-100 mb-2"
                  onClick={() => this.AbrirCronograma()}
                >
                  CRONOGRAMA
                </button>
              </div>
              <div className="col-4">
                <button
                  type="button"
                  id="btn-nquote"
                  className="btn btn-success w-100 mb-2"
                  onClick={() => this.AbrirHistorialPrestamos()}
                >
                  HISTORIAL DE PRESTAMOS
                </button>
              </div>
            </section>
            {this.state.box_cliente ? (
              <div className="cont">
                <h4>DATOS GENERALES</h4>
                <hr></hr>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>NOMBRE DE CLIENTE:</b>{" "}
                      {DatosCliente.name +
                        " " +
                        DatosCliente.ape1 +
                        " " +
                        DatosCliente.ape2}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>DNI:</b> {DatosCliente.dni}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>DIRECCIÓN:</b> {DatosCliente.direccion}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>REFERENCIA:</b> {DatosCliente.direc_re}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>TELEFONO:</b> {DatosCliente.telefonos}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>CONDICIÓN VIVIENDA:</b> {DatosCliente.v_condicion}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>MATERIAL VIVIENDA:</b> {DatosCliente.v_material}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>DEPARTAMENTO:</b> {DatosCliente.dep}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>PROVINCIA:</b> {DatosCliente.pro}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>DISTRITO:</b> {DatosCliente.dis}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>ESTADO CIVIL:</b> {DatosCliente.estado_civil}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>HIJOS DEPENDIENTES:</b> {DatosCliente.hijos_d}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>HIJOS INDEPENDIENTES:</b> {DatosCliente.hijos_i}
                    </p>
                  </div>
                </div>
                <br></br>
                <h4>INFORMACIÓN DE NEGOCIO</h4>
                <hr></hr>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>SECTOR:</b> {DatosCliente.n_sector}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>ACTIVIDAD:</b> {DatosCliente.negocio}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>ANTIGUEDAD:</b> {DatosCliente.n_antig}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>CONDICION DEL LOCAL:</b> {DatosCliente.n_condicion}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>DISTRITO:</b> {DatosCliente.n_dis}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>PROVINCIA:</b> {DatosCliente.n_pro}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>DEPARTAMENTO:</b> {DatosCliente.n_dep}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>TELEFONO:</b> {DatosCliente.n_telefonos}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>INGRESOS DÍARIO:</b>{" "}
                      {DatosCliente.n_id1 + "/" + DatosCliente.n_id2}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>POSIBILIDAD DE PAGO:</b>{" "}
                      {DatosCliente.n_pos_d +
                        " día - " +
                        DatosCliente.n_pos_s +
                        " semana"}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>INGRESO EXTRA:</b>{" "}
                      {DatosCliente.in_ex1 + "/" + DatosCliente.in_ex2}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>DIRECCIÓN:</b> {DatosCliente.direccion2}
                    </p>
                  </div>
                </div>
                <br></br>
                <h4>EXTRAS</h4>
                <hr></hr>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>JUNTA:</b> {Prestamo.junta}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>JUNTA PLAN:</b> {Prestamo.junta_plan}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>COBERTURA: </b>{" "}
                      {(
                        (Number(Prestamo.junta) + Number(Prestamo.junta_plan)) /
                        Number(Prestamo.monto)
                      ).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.box_cliente_doc ? (
              <div className="cont">
                <Link
                  to={"/clienteDocument/" + Prestamo.id_cliente}
                  className="btn btn-primary"
                >
                  Añadir
                </Link>
                <hr></hr>
                <h4>DOCUMENTOS DE CLIENTE</h4>
                <hr></hr>
                <div className="row">
                  <div className="col">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>FECHA</th>
                            <th>DETALLE</th>
                            <th>DOCUMENTO</th>
                            <th>ELIMINAR</th>
                          </tr>
                        </thead>
                        {d_estado ? (
                          <tbody>
                            {this.state.Documentos_cli.map((Datos) => (
                              <tr className="text-center" key={Datos.id}>
                                <td>{Datos.fecha}</td>
                                <td>{Datos.name}</td>
                                <td>
                                  <a
                                    className="btn btn-primary"
                                    href={"https://altrisa.site/" + Datos.ruta}
                                    target="_blank"
                                  >
                                    <DescriptionIcon />
                                  </a>
                                </td>
                                <td>
                                  <a
                                    className="btn btn-warning"
                                    onClick={() =>
                                      this.BorrarRegistro(Datos.id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <b>NO HAY DOCUMENTOS</b>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.box_prestamo ? (
              <div className="cont">
                <div className="row">
                  <div className="col">
                    <h5>DATOS PRESTAMO</h5>
                    <hr></hr>
                    <p>
                      <b>TIPO PRESTAMO:</b>
                      {Prestamo.tip_press}
                    </p>
                    <p>
                      <b>MONTO SOLICIDATO:</b>
                      {Prestamo.monto}
                    </p>
                    <p>
                      <b>PLAZO SOLICITADO:</b>
                      {Prestamo.cuotas}
                    </p>
                    <p>
                      <b>MONTO DE CUOTAS:</b>
                      {Prestamo.monto_cuota}
                    </p>
                    <p>
                      <b>MONTO DE AHORRO:</b>
                      {Prestamo.junta + "/" + Prestamo.junta_plan}
                    </p>
                  </div>
                  <div className="col">
                    <h5>LIQUIDACIÓN DE CUOTAS PENDIENTES</h5>
                    <hr></hr>
                    <p>
                      <b>VALOR DE CUOTA:</b>
                    </p>
                    <p>
                      <b>CUOTA A DESCONTAR:</b>
                    </p>
                    <p>
                      <b>TOTAL A DESCONTAR:</b>
                    </p>
                    <p>
                      <b>MONTO NETO A PAGAR:</b>
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.box_prestamo_doc ? (
              <div className="cont">
                <Link
                  to={"/PrestamoDocument/" + this.state.id_press}
                  className="btn btn-primary"
                >
                  Añadir
                </Link>
                <hr></hr>
                <h4>DOCUMENTOS DE PRESTAMO</h4>
                <hr></hr>
                <div className="row">
                  <div className="col">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>FECHA</th>
                            <th>DETALLE</th>
                            <th>DOCUMENTO</th>
                            <th>ELIMINAR</th>
                          </tr>
                        </thead>
                        {d_estado ? (
                          <tbody>
                            {this.state.Documentos.map((Datos) => (
                              <tr className="text-center" key={Datos.id}>
                                <td>{Datos.fecha}</td>
                                <td>{Datos.name}</td>
                                <td>
                                  <a
                                    className="btn btn-primary"
                                    href={"https://altrisa.site/" + Datos.ruta}
                                    target="_blank"
                                  >
                                    <DescriptionIcon />
                                  </a>
                                </td>
                                <td>
                                  <a
                                    className="btn btn-warning"
                                    onClick={() =>
                                      this.BorrarRegistro(Datos.id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        ) : (
                          <b>NO HAY DOCUMENTOS</b>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.box_prestamo ? (
              <div className="cont">
                <h4>MODIFICAR PRESTAMO</h4>
                <hr></hr>
                {this.state.success3 && (
                  <div className="alert alert-success" role="alert">
                    DATOS MODIFICADOS CORRECTOS
                  </div>
                )}
                {this.state.error3 && (
                  <div className="alert alert-danger" role="alert">
                    {this.state.error3}
                  </div>
                )}
                <form onSubmit={this.handleSubmit3}>
                  <div className="row">
                    <div className="col">
                      <label>MONTO:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="MONTO"
                        required
                        name="monto"
                        onChange={this.handleChange}
                        value={this.state.monto}
                      ></input>
                    </div>
                    <div className="col">
                      <label>PLAZO:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="PLAZO"
                        required
                        name="plazo"
                        onChange={this.handleChange}
                        value={this.state.plazo}
                      ></input>
                    </div>
                    <div className="col">
                      <label>TASA:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="TASA"
                        required
                        name="interes"
                        onChange={this.handleChange}
                        value={this.state.interes}
                      ></input>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <br></br>
                      {tipoUsuario === "1" ? (
                        <button className="btn btn-success">MODIFICAR</button>
                      ) : null}{" "}
                    </div>
                  </div>
                </form>
              </div>
            ) : null}
            {this.state.box_cronograma ? (
              <div className="cont">
                <h4>CRONOGRAMA DE PRESTAMO</h4>
                <hr></hr>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>PERIODO</th>
                        <th>DÍA</th>
                        <th>CAPITAL</th>
                        <th>INTERES</th>
                        <th>CUOTA</th>
                        <th>SALDO</th>
                      </tr>
                    </thead>
                    <tfoot>
                      <tr>
                        <th>PERIODO</th>
                        <th>DÍA</th>
                        <th>CAPITAL</th>
                        <th>INTERES</th>
                        <th>CUOTA</th>
                        <th>SALDO</th>
                      </tr>
                    </tfoot>
                    <tbody>
                      {Simu_cronograma.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.num}</td>
                          <td>
                            {Datos.dia} , {Datos.fecha}
                          </td>
                          <td>{Datos.capital}</td>
                          <td>{Datos.interes}</td>
                          <td>{Datos.monto}</td>
                          <td>{Datos.saldo}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
            {this.state.box_cronograma ? (
              <div className="cont">
                <h4>MODIFICAR PRESTAMO</h4>
                <hr></hr>
                {this.state.success3 && (
                  <div className="alert alert-success" role="alert">
                    DATOS MODIFICADOS CORRECTOS
                  </div>
                )}
                {this.state.error3 && (
                  <div className="alert alert-danger" role="alert">
                    {this.state.error3}
                  </div>
                )}
                <form onSubmit={this.handleSubmit3}>
                  <div className="row">
                    <div className="col">
                      <label>MONTO:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="MONTO"
                        required
                        name="monto"
                        onChange={this.handleChange}
                        value={this.state.monto}
                      ></input>
                    </div>
                    <div className="col">
                      <label>PLAZO:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="PLAZO"
                        required
                        name="plazo"
                        onChange={this.handleChange}
                        value={this.state.plazo}
                      ></input>
                    </div>
                    <div className="col">
                      <label>TASA:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="TASA"
                        required
                        name="interes"
                        onChange={this.handleChange}
                        value={this.state.interes}
                      ></input>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <br></br>
                      {tipoUsuario === "1" ? (
                        <button className="btn btn-success">MODIFICAR</button>
                      ) : null}{" "}
                    </div>
                  </div>
                </form>
              </div>
            ) : null}
            {this.state.hist_prestamos ? (
              <div className="cont">
                <h4>HISTORIAL DE PRESTAMOS</h4>
                <hr></hr>
                <table className="table">
                  <thead>
                    <tr>
                      <td>Fecha</td>
                      <td>Monto</td>
                      <td>Cuotas</td>
                      <td>Forma de Pago</td>
                      <td>Cartera</td>
                      <td>Riesgo</td>
                      <td>Estado</td>
                    </tr>
                  </thead>
                  <tbody>
                    {Prestamos.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>{Datos.fecha}</td>
                        <td>{Datos.monto}</td>
                        <td>{Datos.cuotas}</td>
                        <td>{Datos.formapago}</td>
                        <td>{name_carter(Datos.cartera)}</td>
                        <td>{Riesgo(Datos.riesgo)}</td>
                        <td>
                          {EstadosPrestamos(Datos.estado, Datos.id)}
                          <a
                            href={
                              baseUrlOld +
                              "/oldreport/prestamos/descargarcronograma.php?id_pres=" +
                              Datos.id
                            }
                            target="_blank"
                            rel="noopener"
                            className="btn btn-success"
                            title="Cronograma"
                          >
                            <ArticleIcon />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : null}
            {Prestamo.estado === "0" &&
              (this.state.UserId === "3" || this.state.UserId === "30") ? (
              <div className="cont">
                <h4>Evaluar</h4>
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col-3">
                    <button
                      className="btn btn-success w-100 mb-2"
                      onClick={() => this.PAprobar(id_press)}
                    >
                      APROBAR
                    </button>
                  </div>
                  <div className="col-3">
                    <button
                      className="btn btn-danger w-100 mb-2"
                      onClick={() => this.PDesaprobar(id_press)}
                    >
                      NEGAR
                    </button>
                  </div>
                  <div className="col-3"></div>
                </div>
              </div>
            ) : null}
            {Prestamo.estado === "1" ? (
              <div>
                <div className="cont">
                  <h4>DESEMBOLSAR:</h4>
                  <hr></hr>
                  {this.state.success2 && (
                    <div className="alert alert-success" role="alert">
                      DESEMBOLSO CORRECTO
                    </div>
                  )}
                  {this.state.error2 && (
                    <div className="alert alert-danger" role="alert">
                      {this.state.error2}
                    </div>
                  )}
                  {this.state.espera2 && (
                    <div className="alert alert-info" role="alert">
                      Esperando respuesta del sevidor
                    </div>
                  )}
                  <form onSubmit={this.handleSubmit2}>
                    <div className="row">
                      <div className="col">
                        <label>CONTRASEÑA:</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="CONTRASEÑA"
                          required
                          name="password"
                          onChange={this.handleChange}
                          value={this.state.password}
                        ></input>
                      </div>
                      <div className="col">
                        <label>SEGURO:</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="SEGURO"
                          required
                          name="desgrav"
                          onChange={this.handleChange}
                          value={this.state.desgrav}
                        ></input>
                      </div>
                      <div className="col">
                        <label>OTROS:</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="OTROS"
                          required
                          name="otros"
                          onChange={this.handleChange}
                          value={this.state.otros}
                        ></input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <br></br>
                        <button className="btn btn-success">DESEMBOLSAR</button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="cont">
                  <div className="row">
                    <div className="col">
                      <h4>REGRESAR A PRE-APROBADO</h4>
                    </div>
                    <div className="col">
                      <button
                        className="btn btn-danger"
                        onClick={() => this.PreAprobado(id_press)}
                      >
                        REGRESAR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Prestamo);
