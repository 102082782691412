import { Routes, Route, HashRouter } from "react-router-dom";
import Calculadora from "./view/Calculadora/Calculadora";
import CuadreCajaadd from "./view/CuadreCaja/Caudrecajaadd";
import Cliente from "./view/Clientes/Cliente";
import ClienteCastigar from "./view/Clientes/ClienteCastigar";
import ClienteDocument from "./view/Clientes/ClienteDocument";
import ClienteEdit from "./view/Clientes/ClienteEdit";
import ClienteNotas from "./view/Clientes/ClienteNotas";
import Clientes from "./view/Clientes/Clientes";
import ClientesActivosD from "./view/Clientes/ClientesActivosDate";
import ClientesAsesorCastigados from "./view/Clientes/ClientesAsesorCastigados";
import ClientesRegistro from "./view/Clientes/ClientesRegistro";
import CobranzaDiaria from "./view/CobranzaDiaria/CobranzaDiaria";
import CuadreCaja from "./view/CuadreCaja/Cuadrecaja";
import Cuadrecajaview from "./view/CuadreCaja/Cuadrecajaview";
import Egresos from "./view/Egresos/Egresos";
import InicioPage from "./view/Inicio/Inicio";

import Junta from "./view/Junta/Junta";
import JuntaAdd from "./view/Junta/JuntaAdd";
import JuntaEdit from "./view/Junta/JuntaEdit";
import JuntaHoy from "./view/Junta/JuntaHoy";
import JuntaView from "./view/Junta/JuntaView";

import JuntaPlan from "./view/JuntaPlan/JuntaPlan";
import JuntaPlanAdd from "./view/JuntaPlan/JuntaPlanAdd";
import JuntaPlanEdit from "./view/JuntaPlan/JuntaPlanEdit";
import JuntaPlanView from "./view/JuntaPlan/JuntaPlanView";

import LoginPage from "./view/Inicio/LoginPage";
import NewPres from "./view/NewPres";
import NewPresList from "./view/NewPresList";
import PagosOtros from "./view/PagosOtros/PagosOtros";
import PagosOtrosList from "./view/PagosOtros/PagosOtrosList";

import PerfilPage from "./view/Usuarios/Perfil";
import PerfilEdit from "./view/Usuarios/PerfilEdit";
import PerfilEditPass from "./view/Usuarios/PerfilEditPass";
import Prestamo from "./view/Prestamos/Prestamo";
import PrestamoRcc from "./view/Prestamos/PrestamoRcc";
import PrestamoCastigar from "./view/Prestamos/PrestamoCastigar";
import Prestamos from "./view/Prestamos/Prestamos";
import PrestamosActivos from "./view/Prestamos/PrestamosActivos";
import PrestamosAdd from "./view/Prestamos/PrestamosAdd";
import PrestamosAll from "./view/Prestamos/PrestamosAll";
import PrestamosCanceladosHoy from "./view/Prestamos/PrestamosCancelados";
import PrestamosCastigados from "./view/Prestamos/PrestamosCastigados";
import PrestamosDocument from "./view/Prestamos/PrestamosDocument";
import PrestamosRegister from "./view/Prestamos/PrestamosRegister";
import ReportesAnual from "./view/Reportes/ReporteAnual";
import ReportesCarteraA from "./view/Reportes/ReporteCarteraActiva";
import ReportesCastigado from "./view/Reportes/ReporteCastigado";
import ReportesClienAct from "./view/Reportes/ReporteClienAct";
import ReportesMora from "./view/Reportes/ReporteMora";
import Reportes from "./view/Reportes/Reportes";
import ReportesCierreMes from "./view/Reportes/ReportesCierreMes";
import ReportesColocacion from "./view/Reportes/ReportesColocacion";
import ReportesInfoCont from "./view/Reportes/ReportesInfoCont";
import ReportesIngFi from "./view/Reportes/ReportesIngFi";
import ReportesJunta from "./view/Reportes/ReportesJunta";
import ReportesPagosO from "./view/Reportes/ReportesPagosO";
import ReportesRecu from "./view/Reportes/ReportesRecu";
import Usuarios from "./view/Usuarios/Users";

import Home from "./view/Inicio/Home";
import PrestamosSearch from "./view/Prestamos/PrestamosSearch";
import ReportesGeneral from "./view/Reportes/ReportesGeneral";
import PagosOtrosAdd from "./view/PagosOtros/PagosOtrosAgregar";
import PagosTodos from "./view/Pagos/PagosTodos";
import Pagos from "./view/Pagos/Pagos";
import PagoRealizar from "./view/Pagos/PagoRealizar";
import PagosMultiples from "./view/Pagos/PagosMultiples";
import ReportesCuadreCaja from "./view/Reportes/ReportesCuadreCaja";
import ReportesCuadreCajaNew from "./view/Reportes/ReportesCuadreCajaNew";
import ReportesCierreMesNew from "./view/Reportes/ReportesCierreMesNew";
import PagosHistorial from "./view/Pagos/PagosHistorial";
import PreEvaluacioCredito from "./view/PreEvaluacion/PreEvaluacion";
import ReporteMoraDetail from "./view/Reportes/ReporteMoraDetalle";
import PrestamosReasignar from "./view/Prestamos/PrestamosReasignar";
import ReportesCierreMesNewUser from "./view/Reportes/ReportesCierreMesNewUser";
import IngresosCaja from "./view/CuadreCaja/IngresosCaja";
import NotasCajas from "./view/CuadreCaja/NotasCaja";
import DepositosCaudre from "./view/CuadreCaja/DepositoCuadre";
import EntregaEfectivo from "./view/CuadreCaja/EntregaEfectivo";
import Sueldos from "./view/CuadreCaja/Sueldos";
import ReportesIngFiDetalle from "./view/Reportes/ReportesIngFiDetalle";
import ReportesRecuDetalle from "./view/Reportes/ReportesRecuDetalle";
import ReportesColocacionDetalle from "./view/Reportes/ReportesColocacionDetalle";
import ReportesClienActDetalle from "./view/Reportes/ReportesClienActDetalle";
import ReportesPagoODetalle from "./view/Reportes/ReportesPagoODetalle";
import ReportesCarteraActivaDetalle from "./view/Reportes/ReportesCarteraActivaDetalle";
import ReportesGeneralInfocorp from "./view/Reportes/ReportesGeneralInfocorp";
import PrestamosEditar from "./view/Prestamos/PrestamosEditar";
import Feriados from "./view/Feriados/Feriados";
function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/home" element={<Home />} />

        <Route path="/" element={<LoginPage />} />
        <Route path="/inicio" element={<InicioPage />} />
        <Route path="/miperfil" element={<PerfilPage />} />
        <Route path="/miperfiledit" element={<PerfilEdit />} />
        <Route path="/miperfileditpass" element={<PerfilEditPass />} />
        <Route path="/usuarios" element={<Usuarios />} />

        <Route path="/prestamos" element={<Prestamos />} />
        <Route path="/prestamos-search" element={<PrestamosSearch />} />
        <Route path="/prestamos-castigados" element={<PrestamosCastigados />} />
        <Route path="/prestamosall" element={<PrestamosAll />} />
        <Route path="/prestamosactivos" element={<PrestamosActivos />} />
        <Route
          path="/prestamos-cancelados-hoy"
          element={<PrestamosCanceladosHoy />}
        />
        <Route path="/prestamosadd" element={<PrestamosAdd />} />
        <Route path="/PrestamoRegister/:id" element={<PrestamosRegister />} />
        <Route path="/prestamo/:id" element={<Prestamo />} />
        <Route path="/prestamorcc/:id" element={<PrestamoRcc />} />
        <Route path="/prestamo-castigar/:id" element={<PrestamoCastigar />} />
        <Route path="/PrestamoDocument/:id" element={<PrestamosDocument />} />

        <Route path="/reportes" element={<Reportes />} />
        <Route
          path="/reportes-ingresos-financieros"
          element={<ReportesIngFi />}
        />
        <Route path="/reportes-recuperaciones" element={<ReportesRecu />} />
        <Route path="/reportes-junta" element={<ReportesJunta />} />
        <Route path="/reportes-cierre" element={<ReportesCierreMes />} />
        <Route path="/reportes-contadora" element={<ReportesInfoCont />} />
        <Route path="/reportes-anual" element={<ReportesAnual />} />
        <Route
          path="/reportes-clientes-activos"
          element={<ReportesClienAct />}
        />
        <Route
          path="/reportes-clientes-activos-date"
          element={<ClientesActivosD />}
        />
        <Route path="/reportes-pagos-otros" element={<ReportesPagosO />} />
        <Route path="/reporte-mora" element={<ReportesMora />} />
        <Route path="/reporte-cartera-activa" element={<ReportesCarteraA />} />
        <Route path="/reporte-castigado" element={<ReportesCastigado />} />
        <Route path="/reporte-colocacion" element={<ReportesColocacion />} />

        <Route path="/cuadre-caja" element={<CuadreCaja />} />
        <Route path="/cuadre-caja/:id" element={<Cuadrecajaview />} />
        <Route path="/cuadre-caja-agregar" element={<CuadreCajaadd />} />

        <Route path="/nuevos-prestamos" element={<NewPres />} />
        <Route path="/nuevos-prestamos/:id" element={<NewPresList />} />

        <Route path="/pagos-otros" element={<PagosOtros />} />
        <Route path="/pagos-otros-list" element={<PagosOtrosList />} />

        <Route path="/clientes" element={<Clientes />} />
        <Route path="/clientesRegistro" element={<ClientesRegistro />} />
        <Route path="/cliente/:id" element={<Cliente />} />
        <Route path="/clienteEdit/:id" element={<ClienteEdit />} />
        <Route path="/clienteDocument/:id" element={<ClienteDocument />} />
        <Route
          path="/clientes-asesor-castigados/:id"
          element={<ClientesAsesorCastigados />}
        />
        <Route path="/ClienteCastigar/:id" element={<ClienteCastigar />} />
        <Route path="/ClienteNotas/:id" element={<ClienteNotas />} />

        <Route path="/junta" element={<Junta />} />
        <Route path="/junta-hoy" element={<JuntaHoy />} />
        <Route path="/junta-editar/:id" element={<JuntaEdit />} />
        <Route path="/junta-ver/:id" element={<JuntaView />} />
        <Route path="/junta-agregar" element={<JuntaAdd />} />
        <Route path="/juntaplan" element={<JuntaPlan />} />
        <Route path="/juntaplan-editar/:id" element={<JuntaPlanEdit />} />
        <Route path="/juntaplan-ver/:id" element={<JuntaPlanView />} />
        <Route path="/junta-plan-agregar" element={<JuntaPlanAdd />} />

        <Route path="/egresos" element={<Egresos />} />
        <Route path="/calculadora" element={<Calculadora />} />
        <Route path="/cobranza-diaria" element={<CobranzaDiaria />} />

        <Route path="/pagos/:id" element={<Pagos />} />
        <Route path="/pagos-todos/:id" element={<PagosTodos />} />
        <Route path="/pagos-historial/:id" element={<PagosHistorial />} />

        <Route path="/realizar-pago/:id" element={<PagoRealizar />} />
        <Route path="/reportes-general" element={<ReportesGeneral />} />
        <Route path="/pagos-otros-agregar" element={<PagosOtrosAdd />} />
        <Route path="/pagos-varios/:id" element={<PagosMultiples />} />
        <Route path="/reporte-cuadre-caja" element={<ReportesCuadreCaja />} />
        <Route
          path="/reporte-cuadre-caja-new"
          element={<ReportesCuadreCajaNew />}
        />
        <Route path="/reporte-new-cierre" element={<ReportesCierreMesNew />} />
        <Route
          path="/pre-evaluacion-credito"
          element={<PreEvaluacioCredito />}
        />
        <Route path="/reportes-mora-detalle" element={<ReporteMoraDetail />} />
        <Route path="/prestamos-reasignar" element={<PrestamosReasignar />} />
        <Route path="/reporte-new-cierre/:id" element={<ReportesCierreMesNewUser />} />
        <Route path="/cuadre-caja/ingresos" element={<IngresosCaja />} />
        <Route path="/cuadre-caja/notas" element={<NotasCajas />} />
        <Route path="/cuadre-caja/deposito" element={<DepositosCaudre />} />
        <Route path="/cuadre-caja/entrega-efectivo" element={<EntregaEfectivo />} />
        <Route path="/cuadre-caja/sueldos" element={<Sueldos />} />

        <Route path="/reporte/ingresos-financieros/detalle" element={<ReportesIngFiDetalle />} />
        <Route path="/reporte/recuperaciones/detalle" element={<ReportesRecuDetalle />} />
        <Route path="/reporte/colocaiones/detalle" element={<ReportesColocacionDetalle />} />
        <Route path="/reporte/clientesActivos/detalle" element={<ReportesClienActDetalle />} />
        <Route path="/reporte/pagosOtros/detalle" element={<ReportesPagoODetalle />} />
        <Route path="/reporte/carteraActiva/detalle" element={<ReportesCarteraActivaDetalle />} />
        <Route path="/reporte/infocorp" element={<ReportesGeneralInfocorp />} />
        <Route path="/prestamo/edit/:id" element={<PrestamosEditar />} />

        <Route path="/feriados" element={<Feriados />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
