import React from "react";
import { Link } from "react-router-dom";
//import Swal from "sweetalert2";
import api from "../../servicios/api";
//import Cookies from "universal-cookie";
import "../../css/form.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Menu from "../../comp/Menu";
import Carga from "../Common/Carga";
import { QuitarRegistrosCero } from "../../common/Function";
class ReportesJunta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reporte: [],
      reporte_Ingresos: [],
      reporte_Egresos: [],
      reporte_IE_Total: [],
      reporte_Inicial: [],
      reporte_Final: [],
      reporte_Dia_I: [],
      reporte_Dia_E: [],
      reporte_Dia_T: [],
      asesor: "",
      date1: "",
      date2: "",
      error: null,
      success: null,
      carga: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const datosEnviar = {
      fechaini: this.state.fechaini,
    };
    this.setState({
      carga: 1,
    });
    fetch(api + "Reportes/?junta=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          success: 1,
          error: null,
          reporte: QuitarRegistrosCero(datosRespuesta.actual),
          reporte_Ingresos: QuitarRegistrosCero(datosRespuesta.ingresos),
          reporte_Egresos: QuitarRegistrosCero(datosRespuesta.egresos),
          reporte_IE_Total: QuitarRegistrosCero(datosRespuesta.ie_total),
          reporte_Inicial: QuitarRegistrosCero(datosRespuesta.inicial),
          reporte_Final: QuitarRegistrosCero(datosRespuesta.final),
          reporte_Dia_I: QuitarRegistrosCero(datosRespuesta.d_ingresos),
          reporte_Dia_E: QuitarRegistrosCero(datosRespuesta.d_egresos),
          reporte_Dia_T: QuitarRegistrosCero(datosRespuesta.d_total),
          date1: datosRespuesta.f_ini,
          date2: datosRespuesta.f_fin,
          carga: null,
        });
      })
      .catch(console.log);
    event.preventDefault();
  }

  render() {
    const {
      reporte,
      reporte_Ingresos,
      reporte_Egresos,
      reporte_IE_Total,
      reporte_Inicial,
      reporte_Final,
      reporte_Dia_I,
      reporte_Dia_T,
      reporte_Dia_E,
      date1,
      date2,
    } = this.state;

    const formatDate = (date) => {
      let formatted_date =
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
      return formatted_date;
    };

    var date12 = new Date(date1);
    var fecha1 = formatDate(date12);

    var date22 = new Date(date2);
    var fecha2 = formatDate(date22);
    return (
      <div>
        {this.state.carga ? <Carga /> : null}
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/reportes">
            <KeyboardBackspaceIcon /> Regresar
          </Link>

          <section className="cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>REPORTE DE JUNTA</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/#/inicio">Inicio</Link>
                      </li>
                      <li className="breadcrumb-item active">home</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <div className="container-fluid">
              <div className="reporte-form">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <label>Fecha:</label>
                      <input
                        className="form-control"
                        name="fechaini"
                        type="date"
                        required
                        onChange={this.handleChange}
                        value={this.state.fechaini}
                      ></input>
                    </div>
                    <div className="col">
                      <br></br>
                      <button className="btn btn-primary">Consultar</button>
                    </div>
                    <div className="col"></div>
                  </div>
                </form>
              </div>

              <div className="reporte-cont">
                <div className="table-responsive">
                  <table className="table">
                    <h4>Saldo Inicial ({fecha1})</h4>
                    <tbody>
                      {reporte_Inicial.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.asesor}</td>
                          <td>{Datos.wilmer}</td>
                          <td>{Datos.liza}</td>
                          <td>{Datos.rb}</td>
                          <td>{Datos.diego}</td>
                          <td>{Datos.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="reporte-cont">
                <div className="table-responsive">
                  <table className="table">
                    <h4>Ingresos del día ({fecha1})</h4>
                    <tbody>
                      {reporte_Dia_I.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.asesor}</td>
                          <td>{Datos.wilmer}</td>
                          <td>{Datos.liza}</td>
                          <td>{Datos.rb}</td>
                          <td>{Datos.diego}</td>
                          <td>{Datos.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="reporte-cont">
                <div className="table-responsive">
                  <table className="table">
                    <h4>Egresos del día ({fecha1})</h4>
                    <tbody>
                      {reporte_Dia_E.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.asesor}</td>
                          <td>{Datos.wilmer}</td>
                          <td>{Datos.liza}</td>
                          <td>{Datos.rb}</td>
                          <td>{Datos.diego}</td>
                          <td>{Datos.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="reporte-cont">
                <div className="table-responsive">
                  <table className="table">
                    <h4>Total de Ingresos y Egresos ({fecha1})</h4>
                    <tbody>
                      {reporte_Dia_T.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.asesor}</td>
                          <td>{Datos.wilmer}</td>
                          <td>{Datos.liza}</td>
                          <td>{Datos.rb}</td>
                          <td>{Datos.diego}</td>
                          <td>{Datos.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="reporte-cont">
                <div className="table-responsive">
                  <table className="table">
                    <h4>Saldo Final del día ({fecha1})</h4>
                    <tbody>
                      {reporte_Final.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.asesor}</td>
                          <td>{Datos.wilmer}</td>
                          <td>{Datos.liza}</td>
                          <td>{Datos.rb}</td>
                          <td>{Datos.diego}</td>
                          <td>{Datos.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="reporte-cont">
                <div className="table-responsive">
                  <table className="table">
                    <h4>Total Ingresos desde el ({fecha2}) hasta hoy</h4>
                    <tbody>
                      {reporte_Ingresos.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.asesor}</td>
                          <td>{Datos.wilmer}</td>
                          <td>{Datos.liza}</td>
                          <td>{Datos.rb}</td>
                          <td>{Datos.diego}</td>
                          <td>{Datos.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="reporte-cont">
                <div className="table-responsive">
                  <table className="table">
                    <h4>Total Egresos desde el ({fecha2}) hasta hoy</h4>
                    <tbody>
                      {reporte_Egresos.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.asesor}</td>
                          <td>{Datos.wilmer}</td>
                          <td>{Datos.liza}</td>
                          <td>{Datos.rb}</td>
                          <td>{Datos.diego}</td>
                          <td>{Datos.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="reporte-cont">
                <div className="table-responsive">
                  <table className="table">
                    <h4>
                      Suma de egresos e ingresos desde el ({fecha2}) hasta hoy
                    </h4>
                    <tbody>
                      {reporte_IE_Total.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.asesor}</td>
                          <td>{Datos.wilmer}</td>
                          <td>{Datos.liza}</td>
                          <td>{Datos.rb}</td>
                          <td>{Datos.diego}</td>
                          <td>{Datos.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="reporte-cont">
                <div className="table-responsive">
                  <table className="table">
                    <h4>Saldo Actual</h4>
                    <tbody>
                      {reporte.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.asesor}</td>
                          <td>{Datos.wilmer}</td>
                          <td>{Datos.liza}</td>
                          <td>{Datos.rb}</td>
                          <td>{Datos.diego}</td>
                          <td>{Datos.total}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ReportesJunta;
