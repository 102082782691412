import React from "react";
class Carga extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div id="carga">
        <div className="logo">
          <div className="carga">
            <div className="loader">
              <img src="/img/progress.gif" alt="cargar progreso"></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Carga;
