import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/form.css";
import { QuitarRegistrosCero } from "../../common/Function";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
class ReportesColocacion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reporte: [],
      asesor: "",
      fechaini: "",
      fechafin: "",
      error: null,
      success: null,

      Pres_Ase: [],
      Pres_Ase_Monto: [],
      Pres_Caja: [],
      Pres_Caja_Monto: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    var f1 = this.state.fechaini;
    var f2 = this.state.fechafin;

    this.cargarPresAsesorTip(f1, f2);
    this.cargarDatosMontos(f1, f2);
    this.cargarDatosCaja(f1, f2);
    this.cargarDatosCajaMonto(f1, f2);

    event.preventDefault();
  }

  cargarPresAsesorTip(f1, f2) {
    fetch(
      api + "Reportes/new_prestamos.php?tip_and_as=1&fini=" + f1 + "&ffin=" + f2
    )
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({ Pres_Ase: QuitarRegistrosCero(datosRespuesta.data) });
        } else {
          this.setState({ Pres_Ase: [] });
        }
      })
      .catch(console.log);
  }

  cargarDatosMontos(f1, f2) {
    fetch(
      api +
        "Reportes/new_prestamos.php?tip_and_as_monto=1&fini=" +
        f1 +
        "&ffin=" +
        f2
    )
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          Pres_Ase_Monto: QuitarRegistrosCero(datosRespuesta.data),
        });
      })
      .catch(console.log);
  }

  cargarDatosCaja(f1, f2) {
    fetch(
      api + "Reportes/new_prestamos.php?tip_and_ca=1&fini=" + f1 + "&ffin=" + f2
    )
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta.data)
        this.setState({ Pres_Caja: QuitarRegistrosCero(datosRespuesta.data) });
      })
      .catch(console.log);
  }

  cargarDatosCajaMonto(f1, f2) {
    fetch(
      api +
        "Reportes/new_prestamos.php?tip_and_ca_monto=1&fini=" +
        f1 +
        "&ffin=" +
        f2
    )
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          Pres_Caja_Monto: QuitarRegistrosCero(datosRespuesta.data),
        });
      })
      .catch(console.log);
  }

  render() {
    const { Pres_Ase, Pres_Ase_Monto, Pres_Caja, Pres_Caja_Monto } = this.state;

    const currency = function (number) {
      return new Intl.NumberFormat("es-PE", {
        style: "currency",
        currency: "PEN",
        minimumFractionDigits: 2,
      }).format(number);
    };

    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/reportes">
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          <Link className="btn btn-primary" to="/reporte/colocaiones/detalle">
            <TextSnippetIcon /> Detalle
          </Link>
          <section className="cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>COLOCACIONES</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/#/inicio">Inicio</Link>
                      </li>
                      <li className="breadcrumb-item active">home</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <div className="container-fluid">
              <div className="reporte-form">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <label>Fecha Inicio:</label>
                      <input
                        className="form-control"
                        name="fechaini"
                        type="date"
                        required
                        onChange={this.handleChange}
                        value={this.state.fechaini}
                      ></input>
                    </div>
                    <div className="col">
                      <label>Fecha Fin:</label>
                      <input
                        className="form-control"
                        name="fechafin"
                        type="date"
                        required
                        onChange={this.handleChange}
                        value={this.state.fechafin}
                      ></input>
                    </div>
                    <div className="col">
                      <br></br>
                      <button className="btn btn-primary">Consultar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="cont">
                <div className="report-table">
                  <div className="table table-responsive">
                    <table className="table">
                      <h2>NÚMERO DE PRESTAMOS EN ESTE MES</h2>
                      <tbody>
                        {Pres_Ase.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.asesor}</td>
                            <td>{Datos.NUEVO}</td>
                            <td>{Datos.RENOVACIÓN}</td>
                            <td>{Datos.AMPLIACIÓN}</td>
                            <td>{Datos.PARALELO}</td>
                            <td>{Datos.REFINANCIAMIENTO}</td>
                            <td>{Datos.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="cont">
                <div className="report-table">
                  <div className="table table-responsive">
                    <table className="table">
                      <h2>MONTO DE PRESTAMOS EN ESTE MES</h2>
                      <tbody>
                        {Pres_Ase_Monto.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.asesor}</td>
                            <td>{Datos.NUEVO}</td>
                            <td>{Datos.RENOVACIÓN}</td>
                            <td>{Datos.AMPLIACIÓN}</td>
                            <td>{Datos.PARALELO}</td>
                            <td>{Datos.REFINANCIAMIENTO}</td>
                            <td>{currency(Datos.total)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="cont">
                <div className="report-table">
                  <div className="table table-responsive">
                    <table className="table">
                      <h2>NÚMERO DE PRESTAMOS EN ESTE MES</h2>
                      <tbody>
                        {Pres_Caja.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.asesor}</td>
                            <td>{Datos.NUEVO}</td>
                            <td>{Datos.RENOVACIÓN}</td>
                            <td>{Datos.AMPLIACIÓN}</td>
                            <td>{Datos.PARALELO}</td>
                            <td>{Datos.REFINANCIAMIENTO}</td>
                            <td>{Datos.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="cont">
                <div className="report-table">
                  <div className="table table-responsive">
                    <table className="table">
                      <h2>MONTO DE PRESTAMOS EN ESTE MES</h2>
                      <tbody>
                        {Pres_Caja_Monto.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.asesor}</td>
                            <td>{Datos.NUEVO}</td>
                            <td>{Datos.RENOVACIÓN}</td>
                            <td>{Datos.AMPLIACIÓN}</td>
                            <td>{Datos.PARALELO}</td>
                            <td>{Datos.REFINANCIAMIENTO}</td>
                            <td>{currency(Datos.total)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ReportesColocacion;
