import React, { useState } from "react";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

export default function BuscarListaPagos() {
  const [inputValues, setInputValues] = useState({
    codigo: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  return (
    <div className="buscar-prestamo">
      <div className="row">
        <div className="w-100">
          <form className="row">
            <div className="col-9">
              <label>Ir a otra lista de Pagos</label>
              <input
                type="number"
                className="form-control"
                placeholder="Codigo de prestamo"
                name="codigo"
                onChange={handleChange}
                value={inputValues.codigo}
                required
              ></input>
            </div>
            <div className="col-3">
              <br></br>

              <Link
                className="btn btn-success w-100"
                //to={}
                to={
                  inputValues.codigo === ""
                    ? "#"
                    : "/pagos/" + inputValues.codigo
                }
              >
                <SearchIcon />
                BUSCAR
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
