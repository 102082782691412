import React from "react";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import "../../css/users.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { UrlGet, UrlHeader, UserCod, juntaUrl } from "../../common/Function";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { exportToExcel } from "../Common/MaterialExportToExel";
import { columnsJuntaList } from "../../common/ExportColums";
import { tiposUsuariosPermitidos } from "../Common/utils";
class Junta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos: [],
      currentPage: 1,
      starat: 0,
      endat: 8,
      total: 0,
      total_paginas: 0,
      totalPages: [],
      busqueda: "",
      user_id: UserCod(),
      estado: "ACTIVOS",
      tipoUsuario: localStorage.getItem("TIPUSER"),
    };
    this.handleChange = this.handleChange.bind(this);
    this.filterPagina = this.filterPagina.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  cargarDatos(pagine, filter) {
    var fitlerUrl = {
      starat: pagine,
      endat: this.state.endat,
      user_id: this.state.user_id,
      cantidad: this.state.estado,
    };
    if (filter !== null) {
      fitlerUrl.filter = filter;
    }
    fetch(juntaUrl + UrlGet(fitlerUrl), {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Datos: result.data,
          total: result.total,
          total_paginas: Math.ceil(result.total / this.state.endat),
        });
        this.NumeroPaginas(Math.ceil(result.total / this.state.endat));
      })
      .catch((error) => console.log("error", error));
  }

  componentDidMount() {
    this.cargarDatos(this.state.starat, null);
  }

  NumeroPaginas(numPages) {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push({ id: i - 1, name: i });
    }
    this.setState({
      totalPages: pages,
    });
  }

  CambiarPagina(page) {
    this.setState({ starat: page });
    page = page * this.state.endat;
    this.cargarDatos(page, this.state.busqueda);
  }

  filterPagina(event) {
    if (this.state.busqueda === "") {
      this.setState({ startat: 0 });
      this.cargarDatos(0, "");
    } else {
      this.cargarDatos(0, this.state.busqueda);
    }
    event.preventDefault();
  }

  LimpiarFormulario() {
    this.setState({
      busqueda: "",
      estado: "ACTIVOS",
    });
  }

  DatosForm() {
    var data = {
      starat: this.state.starat,
      endat: this.state.endat,
      user_id: this.state.user_id,
      cantidad: this.state.estado,
    };

    if (this.state.busqueda !== null || this.state.busqueda !== "") {
      data.filter = this.state.busqueda;
    }

    return data;
  }

  DescargarDatos() {
    this.setState({
      carga: 1,
    });
    var data = this.DatosForm();
    data.endat = null;
    data.starat = null;

    fetch(juntaUrl + UrlGet(data), {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.success === true) {
          exportToExcel(result.data, columnsJuntaList, "Junta_Lista");
        }
        this.setState({
          carga: null,
        });
      })
      .catch((error) => console.log("error", error));
  }

  render() {
    const { Datos } = this.state;

    return (
      <div id="junta">
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/home">
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          {tiposUsuariosPermitidos.btnJuntaAgregar.includes(
            this.state.tipoUsuario
          ) && (
            <Link className="btn btn-primary" to="/junta-agregar">
              <PersonAddAltIcon />
              Agregar Junta
            </Link>
          )}
          <div className="content-wrapper cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <h1>JUNTA</h1>
                </div>
              </div>
            </section>
            <section className="row">
              <div className="col-9">
                <div className="row">
                  <div className="col-4">
                    <input
                      type="search"
                      placeholder="Búsqueda por Nombre."
                      className="form-control inputBuscar"
                      name="busqueda"
                      onChange={this.handleChange}
                      value={this.state.busqueda}
                    ></input>
                  </div>
                  <div className="col-4">
                    <select
                      className="form-control"
                      required
                      onChange={this.handleChange}
                      name="estado"
                      value={this.state.estado}
                    >
                      <option value="ACTIVOS">ACTIVOS</option>
                      <option value="INACTIVOS">INACTIVOS</option>
                      <option value="TODOS">TODOS</option>
                    </select>
                  </div>
                  <div className="col-4"></div>
                </div>
              </div>
              <div className="col-3">
                <div>
                  <button
                    className="btn btn-info w-100 mb-1"
                    onClick={this.filterPagina}
                  >
                    <SearchIcon />
                    Buscar
                  </button>
                  {tiposUsuariosPermitidos.btnJuntaExportar.includes(
                    this.state.tipoUsuario
                  ) && (
                    <button
                      className="btn btn-success w-100 mb-1"
                      onClick={() => this.DescargarDatos()}
                    >
                      <FileDownloadIcon /> Exportar
                    </button>
                  )}
                  <button
                    className="btn btn-danger w-100 mb-1"
                    onClick={() => this.LimpiarFormulario()}
                  >
                    <CleaningServicesIcon />
                    Limpiar
                  </button>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="table-responsive">
                  <table className="table table-striped my-4">
                    <thead className="bg-info">
                      <tr>
                        <th className="text-center">CODIGO</th>
                        <th className="text-center">Apellidos y Nombres</th>
                        <th className="text-center">Monto</th>
                        <th className="text-center">Caja</th>
                        <th className="text-center">Asesor</th>
                        <th className="text-center">Interes</th>
                        <th className="text-center">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Datos.map((data) => (
                        <tr className="text-center" key={data.id}>
                          <td className="text-center">{data.id}</td>
                          <td className="text-center">
                            {data.nameClient} {data.ape1Client} {data.ap2Client}
                          </td>
                          <td className="text-center">{data.monto}</td>
                          <td className="text-center">{data.nameCaja}</td>
                          <td className="text-center">
                            {data.nameUser} {data.ape1User} {data.ape2User}
                          </td>
                          <td className="text-center">
                            {data.interes ? data.interes : 0}
                          </td>
                          <td className="text-center">
                            <Link
                              className="btn btn-primary m-2"
                              title="Detalle"
                              to={"/junta-ver/" + data.id}
                            >
                              <RemoveRedEyeIcon />
                            </Link>
                            {this.state.tipoUsuario === "1" ? (
                              <Link
                                className="btn btn-warning m-2"
                                title="Editar"
                                to={"/junta-editar/" + data.id}
                              >
                                <EditIcon />
                              </Link>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li
                      className={
                        this.state.starat === 0
                          ? "page-item disabled"
                          : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        onClick={() =>
                          this.CambiarPagina(this.state.starat - 1)
                        }
                      >
                        <NavigateBeforeIcon />
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link">
                        {this.state.starat + 1 + "/" + this.state.total_paginas}
                      </a>
                    </li>
                    <li
                      className={
                        this.state.starat === this.state.total_paginas - 1
                          ? "page-item disabled"
                          : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        onClick={() =>
                          this.CambiarPagina(this.state.starat + 1)
                        }
                      >
                        <NavigateNextIcon />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </section>
          </div>
        </div>
        {/*----------------MODAL EDITAR USUARIO--------------------*/}
        <div className="modal fade" id="AgregarJunta">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Agregar Junta</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Aquí puedes agregar contenido personalizado para el modal.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <button type="button" className="btn btn-primary">
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*--------------------------------------------*/}
      </div>
    );
  }
}

export default Junta;
