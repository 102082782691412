import React from "react";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "../../css/table.css";
import Menu from "../../comp/Menu";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  clienteUrl,
  apiToken,
  UrlGet,
  prestamosUrl,
} from "../../common/Function";
import { EstadosPrestamos, Riesgo } from "../Common/utils";

class PrestamosAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Prestamos: [],
      currentPage: 1,
      starat: 0,
      endat: 10,
      total: 0,
      total_paginas: 0,

      error: null,
      success: null,
      vienen: 0,
      dep_real: 0,
      reti_cue: 0,
      recupe_di: 0,
      ingre_efe: 0,
      otros_in: 0,
      dni: "",
      Data: [],
      id_cliente: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }

  cargarDatos(data) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", apiToken);
    fetch(prestamosUrl + UrlGet(data), {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Prestamos: result.data,
          total: result.total,
          total_paginas: Math.ceil(result.total / this.state.endat),
        });
      })
      .catch((error) => console.log("error", error));
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", apiToken);

    var formdata = new FormData();
    formdata.append("validar", "true");
    formdata.append("dni", this.state.dni);

    fetch(clienteUrl, {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            success: 1,
            error: null,
            Data: result.data,
            id_cliente: result.data.id,
          });
          this.BuscarDatos(result.data.id);
        } else {
          this.setState({ error: result.msg, success: null });
        }
      })
      .catch((error) => console.log("error", error));

    event.preventDefault();
  }
  DatosForm() {
    var data = {
      starat: this.state.starat,
      endat: this.state.endat,
      id_cliente: this.state.id_cliente,
    };
    return data;
  }
  BuscarDatos(id_cliente) {
    var data = this.DatosForm();
    data.id_cliente = id_cliente;
    this.cargarDatos(data);
  }
  CambiarPagina(page) {
    this.setState({ starat: page });
    page = page * this.state.endat;
    var data = this.DatosForm();
    data.starat = page;
    this.cargarDatos(data);
  }
  componentDidMount() {}

  render() {
    const { Prestamos, Data } = this.state;
    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/prestamos-search">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          <div className="content-wrapper cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <h1>NUEVO PRESTAMO</h1>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="">
                    {this.state.success && (
                      <div className="alert alert-success" role="alert">
                        El usuario esta apto para credito.
                      </div>
                    )}
                    {this.state.error && (
                      <div className="alert alert-danger" role="alert">
                        {this.state.error}
                      </div>
                    )}
                    <form className="row formx" onSubmit={this.handleSubmit}>
                      <div className="col">
                        <label>DNI</label>
                        <input
                          type="text"
                          name="dni"
                          placeholder="Validar Cliente"
                          className="form-control"
                          required
                          onChange={this.handleChange}
                          value={this.state.dni}
                        />
                      </div>
                      <div className="fbtnx">
                        <button className="btn btn-primary">CONSULTAR</button>
                      </div>
                    </form>
                  </div>
                </div>
                {this.state.success && (
                  <div className="cont">
                    <div className="table-responsive">
                      <h3>DATOS DE CLIENTE</h3>
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>
                              <b>Apellidos:</b>
                            </td>
                            <td>
                              {Data.ape1} {Data.ape2}
                            </td>
                            <td>
                              <b>Nombres:</b>
                            </td>
                            <td>{Data.name}</td>
                            <td>
                              <b>DNI:</b>
                            </td>
                            <td>{Data.dni}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Telefonos:</b>
                            </td>
                            <td>{Data.telefonos}</td>
                            <td>
                              <b>Negocio:</b>
                            </td>
                            <td>{Data.negocio}</td>
                            <td>
                              <b>Dirección:</b>
                            </td>
                            <td>{Data.direccion}</td>
                          </tr>
                        </tbody>
                      </table>
                      <hr></hr>
                      <h3>DATOS DE CÓNYUGE O AVAL</h3>
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>
                              <b>Apellidos:</b>
                            </td>
                            <td>
                              {Data.c_ap1} {Data.c_ap2}
                            </td>
                            <td>
                              <b>Nombres:</b>
                            </td>
                            <td>{Data.c_name}</td>
                            <td>
                              <b>DNI:</b>
                            </td>
                            <td>{Data.c_dni}</td>
                          </tr>
                        </tbody>
                      </table>
                      <hr></hr>
                      <h3>HISTORIAL DE CREDITOS</h3>
                      <table className="table">
                        <thead>
                          <tr>
                            <td>Fecha</td>
                            <td>Monto</td>
                            <td>Coutas</td>
                            <td>Forma de Pago</td>
                            <td>Riesgo</td>
                            <td>Estado</td>
                          </tr>
                        </thead>
                        <tbody>
                          {Prestamos.map((Datos) => (
                            <tr className="text-center" key={Datos.id}>
                              <td>{Datos.fecha}</td>
                              <td>{Datos.monto}</td>
                              <td>
                                {Datos.cuotasr}/{Datos.cuotas}
                              </td>
                              <td>{Datos.formapago}</td>
                              <td className="text-center">
                                {Riesgo(Datos.riesgo, Datos.formapago)}
                              </td>
                              <td>{EstadosPrestamos(Datos.estado)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <nav aria-label="Page navigation example">
                      <ul className="pagination justify-content-center">
                        <li
                          className={
                            this.state.starat === 0
                              ? "page-item disabled"
                              : "page-item"
                          }
                        >
                          <a
                            className="page-link"
                            onClick={() =>
                              this.CambiarPagina(this.state.starat - 1)
                            }
                          >
                            <NavigateBeforeIcon />
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link">
                            {this.state.starat +
                              1 +
                              "/" +
                              this.state.total_paginas}
                          </a>
                        </li>
                        <li
                          className={
                            this.state.starat === this.state.total_paginas - 1
                              ? "page-item disabled"
                              : "page-item"
                          }
                        >
                          <a
                            className="page-link"
                            onClick={() =>
                              this.CambiarPagina(this.state.starat + 1)
                            }
                          >
                            <NavigateNextIcon />
                          </a>
                        </li>
                      </ul>
                    </nav>
                    <Link
                      to={"/PrestamoRegister/" + Data.id}
                      className="btn btn-success"
                    >
                      Registrar Prestamo
                    </Link>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default PrestamosAdd;
