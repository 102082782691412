import React, { useState } from "react";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import SearchIcon from "@mui/icons-material/Search";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import AssignmentIcon from "@mui/icons-material/Assignment";
import GroupIcon from "@mui/icons-material/Group";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PersonIcon from "@mui/icons-material/Person";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CalculateIcon from "@mui/icons-material/Calculate";
import PaymentsIcon from "@mui/icons-material/Payments";

export default function Aside() {
  const cookie = new Cookies();
  const IdNombre = cookie.get("UserName");
  const TipUser = cookie.get("TipUser");
  const [nombre, setNombre] = useState(IdNombre);
  const [cargo, setCargo] = useState(TipUser);
  function showCerrarSesion() {
    Swal.fire({
      title: "¿Estás seguro de que quieres cerrar la sesión?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        //Cerrar Sesion
        cookie.remove("TipUser", { path: "/" });
        cookie.remove("UserName", { path: "/" });
        cookie.remove("UserId", { path: "/" });
        window.location.href = "./";
        //Swal.fire("Saved!", "sdss", "success");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  }

  function ValidarSesion() {
    const cookie = new Cookies();
    const IdUser = cookie.get("UserId");
    if (IdUser) {
      //console.log("existe");
    } else {
      window.location.href = "./#/";
      //console.log("no existe ");
    }
  }
  ValidarSesion();
  console.log(cargo);
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <Link to="/inicio" className="brand-link">
        <img
          src="http://altrisa.site/img/logo.png"
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light">ALTRISA</span>
      </Link>

      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="http://altrisa.site/img/perfil.png"
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <a href="#" className="d-block">
              {nombre}
            </a>
          </div>
        </div>
        {/* SidebarSearch Form */}
        <div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input
              className="form-control form-control-sidebar"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />

            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <SearchIcon />
              </button>
            </div>
          </div>
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2">
          {cargo === "1" ? (
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
              <li className="nav-item">
                <Link to="/inicio" className="nav-link">
                  <HomeIcon />
                  <p> Home</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/clientes" className="nav-link">
                  <PersonIcon />
                  <p> Clientes</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/prestamos" className="nav-link">
                  <AssignmentIcon />
                  <p> Prestamos</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/calculadora" className="nav-link">
                  <CalculateIcon />
                  <p> Calculadora</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/cobranza-diaria" className="nav-link">
                  <PaymentsIcon />
                  <p> Cobranza Diaria</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/reportes" className="nav-link">
                  <QueryStatsIcon />
                  <p> Reportes</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/pagos-otros-list" className="nav-link">
                  <CurrencyExchangeIcon />
                  <p> Pagos Otros</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/cuadre-caja" className="nav-link">
                  <PointOfSaleIcon />
                  <p> Cuadre de Caja</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/junta" className="nav-link">
                  <CreditCardIcon />
                  <p> Junta</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/juntaplan" className="nav-link">
                  <CreditScoreIcon />
                  <p> Junta Plan</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/egresos" className="nav-link">
                  <PriceChangeIcon />
                  <p> Egresos</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/usuarios" className="nav-link">
                  <GroupIcon />
                  <p> Usuarios</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/miperfil" className="nav-link">
                  <AccountCircleIcon />
                  <p> Mi Perfil</p>
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={showCerrarSesion}>
                  <HighlightOffIcon />
                  <p> Cerrar Sesión</p>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" role="button">
                  <MenuIcon />
                </a>
              </li>
            </ul>
          ) : (
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
              <li className="nav-item">
                <Link to="/inicio" className="nav-link">
                  <HomeIcon />
                  <p> Home</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/clientes" className="nav-link">
                  <PersonIcon />
                  <p> Clientes</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/calculadora" className="nav-link">
                  <CalculateIcon />
                  <p> Calculadora</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/pagos-otros-list" className="nav-link">
                  <CurrencyExchangeIcon />
                  <p> Pagos Otros</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/junta" className="nav-link">
                  <CreditCardIcon />
                  <p> Junta</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/juntaplan" className="nav-link">
                  <CreditScoreIcon />
                  <p> Junta Plan</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/egresos" className="nav-link">
                  <PriceChangeIcon />
                  <p> Egresos</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/miperfil" className="nav-link">
                  <AccountCircleIcon />
                  <p> Mi Perfil</p>
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={showCerrarSesion}>
                  <HighlightOffIcon />
                  <p> Cerrar Sesión</p>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" role="button">
                  <MenuIcon />
                </a>
              </li>
            </ul>
          )}
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}
