import React from "react";
import Aside from "../../comp/Aside";
import Footer from "../../comp/Footer";
import Navbar from "../../comp/Navbar";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import GroupIcon from "@mui/icons-material/Group";
import "../../css/estilos.css";
//import AssignmentIcon from "@mui/icons-material/Assignment";
//import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import PriceChangeIcon from "@mui/icons-material/PriceChange";

import BuscarPrestamo from "../../comp/BuscarPrestamo";
import BuscarPrestamos from "../../comp/BuscarPrestamos";
import ReportesResumen from "../../comp/ReportesResumen";
import GraficaNuevosPrestamosSemanal from "../../Graficas/NuevosPrestamosSemanal";
import GraficaIngresosFinancierosSemanal from "../../Graficas/IngresosFinancierosSemanal";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AssessmentIcon from "@mui/icons-material/Assessment";
import api from "../../servicios/api";

class InicioPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TipUser: "",
      UserName: "",
      UserId: "",
    };
  }

  cargarDatos() {
    const cookie = new Cookies();
    const TipUser = cookie.get("TipUser");
    const UserName = cookie.get("UserName");
    const UserId = cookie.get("UserId");

    this.setState({
      TipUser: TipUser,
      UserName: UserName,
      UserId: UserId,
    });
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    return (
      <div>
        <Navbar />
        <Aside />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Bienvenid@ {this.state.UserName}</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <BuscarPrestamo />
                </div>
                <div className="col">
                <BuscarPrestamos />
                </div>
              </div>
              {this.state.TipUser === "1" ? (
                <div>
                  <ReportesResumen />
                  <div className="row">
                    <div className="col">
                      <GraficaNuevosPrestamosSemanal />
                    </div>
                    <div className="col">
                      <GraficaIngresosFinancierosSemanal />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="row">
                <div className="col">
                  <a
                    href={
                      api +
                      "DescargarPdf/Reportes/descargardiario.php?id_user=" +
                      this.state.UserId
                    }
                    className="card text-white bg-info mb-3 card-menu"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="card-body card-s-menu">
                      <CloudDownloadIcon className="card-title" />
                      <h4 className="card-text">COBRANZA DIARIA</h4>
                    </div>
                  </a>
                </div>
                <div className="col">
                  <a
                    href={
                      api +
                      "DescargarPdf/Reportes/descargardetalle.php?id_user=" +
                      this.state.UserId
                    }
                    className="card text-white bg-success mb-3 card-menu"
                  >
                    <div className="card-body card-s-menu">
                      <AssessmentIcon className="card-title" />
                      <h4 className="card-text">REPORTE COBRANZA DIARIA</h4>
                    </div>
                  </a>
                </div>
                <div className="col">
                  <Link
                    to={"/clientes-asesor/" + this.state.UserId}
                    className="card text-white bg-warning mb-3 card-menu"
                  >
                    <div className="card-body card-s-menu">
                      <GroupIcon className="card-title" />
                      <h4 className="card-text">MIS CLILENTES</h4>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="">
                <div className="row">
                  <div className="col">
                    <Link
                      to="/junta"
                      className="card text-white bg-warning mb-3 card-menu"
                    >
                      <div className="card-body card-s-menu">
                        <CreditCardIcon className="card-title" />
                        <h4 className="card-text">JUNTA</h4>
                      </div>
                    </Link>
                  </div>
                  <div className="col">
                    <Link
                      to="/juntaplan"
                      className="card text-white bg-dark mb-3 card-menu"
                    >
                      <div className="card-body card-s-menu">
                        <CreditScoreIcon className="card-title" />
                        <h4 className="card-text">JUNTA P</h4>
                      </div>
                    </Link>
                  </div>
                  <div className="col">
                    <Link
                      to="/egresos"
                      className="card text-white bg-primary mb-3 card-menu"
                    >
                      <div className="card-body card-s-menu">
                        <PriceChangeIcon className="card-title" />
                        <h4 className="card-text">EGRESOS</h4>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default InicioPage;
