import React, { useEffect, useState } from "react";
import api from "../servicios/api";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

export default function GraficaIngresosFinancierosSemanal() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const ConsultaReporte = async () => {
      const response = await fetch(
        api + "Reportes/reportes_charts.php?ingresos_financieros=1"
      );
      const json = await response.json();
      setData(json.data);
    };

    ConsultaReporte();
  }, []);

  const IngresosFinancierosSemanalTable = {
    labels: data.map((data) => data.d_resume),
    datasets: [
      {
        label: "Ingreso Financiero",
        data: data.map((data) => data.resumen.ingresos_financieros),
        backgroundColor: [
          "rgba(75,192,192,1)",
          "#ecf0f1",
          "#50AF95",
          "#f3ba2f",
          "#2a71d0",
        ],
        borderColor: "black",
        borderWidth: 2,
      },
    ],
  };

  return (
    <div className="Grafica">
      <div className="cont">
        <h4>INGRESOS FINANCIEROS SEMANAL</h4>
        <hr></hr>
        <Bar data={IngresosFinancierosSemanalTable} />
      </div>
    </div>
  );
}
