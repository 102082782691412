import React, { Component } from "react";

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      options: [],
    };
    this.inputRef = React.createRef();
  }

  handleChange = (event) => {
    const searchText = event.target.value;
    // Realiza el filtrado de opciones según el searchText
    const filteredOptions = this.props.allOptions.filter((option) =>
      option.name.toLowerCase().startsWith(searchText.toLowerCase())
    );
    this.setState({
      searchText,
      options: filteredOptions,
    });
  };

  handleSelect = (selectedOption) => {
    this.setState({
      searchText: selectedOption.name,
      options: [],
    });
    this.props.onSelect(selectedOption.id);
  };

  clearAutocomplete = () => {
    this.setState({
      searchText: "",
      options: [],
    });
    this.inputRef.current.value = "";
  };

  render() {
    const { searchText, options } = this.state;

    return (
      <div>
        <input
          type="text"
          className="form-control"
          value={searchText}
          onChange={this.handleChange}
          ref={this.inputRef}
          placeholder="AUTOCOMPLETE"
        />
        <ul className="autocomplete-options">
          {options.map((option, index) => (
            <li key={index} onClick={() => this.handleSelect(option)}>
              {option.name}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Autocomplete;
