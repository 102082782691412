import React from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/table.css";
import Menu from "../../comp/Menu";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {
  FechaActual,
  UrlPost,
  UrlHeader,
  prestamosUrl,
} from "../../common/Function";
import { tiposUsuariosPermitidos } from "../Common/utils";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class PrestamosRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Prestamos: [],
      error: null,
      success: null,
      error2: null,
      success2: null,
      vienen: 0,
      dep_real: 0,
      reti_cue: 0,
      recupe_di: 0,
      ingre_efe: 0,
      otros_in: 0,
      dni: null,
      Data: [],
      Cliente: [],
      fecha: FechaActual(),
      formapago: "dia",
      inter: 1,
      cli_id: null,
      Rutas: [],
      Caja: [],
      Asesor: [],
      monto: 0,
      cuotas: 0,
      ruta: 1,
      caja: 1,
      junta: 0,
      tip_press: 0,
      asesor: 26,
      nota: "",
      interes: 0,
      interes2: 0,
      seguro: 0,
      Cronograma: [],
      estado_form: null,
      gastos_admin: 0.2,
      tipoUsuario: localStorage.getItem("TIPUSER"),
      btnStatusPreAprobar: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmit2 = this.handleSubmit2.bind(this);
    this.conexion = true;
  }

  cargarDatos() {
    const IdUser = this.props.match.params.id;
    const datosEnviar = {
      dni: this.state.dni,
    };

    fetch(api + "Cliente/?consultar=" + IdUser, {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          Cliente: datosRespuesta[0],
        });
        //console.log(datosRespuesta);
      })
      .catch(console.log);

    //--------- CARTERA ----------
    fetch(api + "Cartera/?activo=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            Caja: datosRespuesta.data,
          });
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);

    //--------- CAJA ----------
    fetch(api + "Rutas/", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            Rutas: datosRespuesta.data,
          });
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);

    //--------- ASESORES ----------
    fetch(api + "Users/?user_asesor=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          Asesor: datosRespuesta,
        });
        //console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });

    if (t_name === "formapago") {
      if (target.value === "dia") {
        this.setState({
          gastos_admin: 0.2,
        });
      } else {
        this.setState({
          gastos_admin: 0.4,
        });
      }
    }
  }

  handleSubmit(event) {
    function CalcularInteres(formapago, monto) {
      if (formapago === "dia") {
        if (Number(monto) < 1000) {
          return 10;
        } else if (Number(monto) < 3000) {
          return 9;
        } else if (Number(monto) < 4000) {
          return 8.5;
        } else if (Number(monto) < 5000) {
          return 8.2;
        } else {
          return 8;
        }
      } else {
        if (Number(monto) < 1000) {
          return 3.7;
        } else if (Number(monto) < 5000) {
          return 3.55;
        } else if (Number(monto) < 10000) {
          return 3.2;
        } else if (Number(monto) < 15000) {
          return 3.1;
        } else {
          return 3;
        }
      }
    }
    function CalcularSeguro(formapago, monto, cuotas) {
      var raw = 0;
      if (formapago === "dia") {
        if (Number(monto) <= 1000) {
          return 5;
        } else if (Number(monto) <= 1500) {
          return 7.5;
        } else if (Number(monto) <= 2000) {
          return 10;
        } else if (Number(monto) <= 3000) {
          return 15;
        } else if (Number(monto) <= 4000) {
          return 20;
        } else if (Number(monto) <= 5000) {
          return 25;
        } else if (Number(monto) <= 6000) {
          return 28;
        } else if (Number(monto) <= 7000) {
          return 30;
        } else if (Number(monto) <= 8000) {
          return 33;
        } else if (Number(monto) <= 9000) {
          return 36;
        } else {
          return 40;
        }
      } else {
        if (Number(monto) < 5000) {
          raw = 0.0008;
        } else {
          raw = 0.0007;
        }
        var seguro = Number(monto) * raw * Number(cuotas);
        if (seguro < 5) {
          seguro = 5;
        }
        return seguro;
      }
    }

    if (this.state.interes === 0) {
      var d_interes = CalcularInteres(this.state.formapago, this.state.monto);
      this.setState({ interes: d_interes });
    } else {
      var d_interes = this.state.interes;
    }

    var d_interes2 = CalcularInteres(this.state.formapago, this.state.monto);
    this.setState({ interes2: d_interes2 });

    var d_seguro = CalcularSeguro(
      this.state.formapago,
      this.state.monto,
      this.state.cuotas
    );
    this.setState({ seguro: d_seguro });

    const datosEnviar = {
      fechapago: this.state.fecha,
      inter: this.state.inter,
      formapago: this.state.formapago,
      monto: this.state.monto,
      cuotas: this.state.cuotas,
      caja: this.state.caja,
      interes: d_interes,
      calcular: true,
    };

    fetch(prestamosUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: UrlPost(datosEnviar),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            Cronograma: result.data,
            estado_form: 1,
          });
        } else {
          this.setState({ Cronograma: [], estado_form: null });
        }
      })
      .catch((error) => console.log("error", error));

    event.preventDefault();
  }

  handleSubmit2(event) {
    this.setState({
      btnStatusPreAprobar: null,
    });
    const IdUser = this.props.match.params.id;
    function CalcularInteres(formapago, monto) {
      if (formapago === "dia") {
        if (Number(monto) < 1000) {
          return 10;
        } else if (Number(monto) < 3000) {
          return 9;
        } else if (Number(monto) < 4000) {
          return 8.5;
        } else if (Number(monto) < 5000) {
          return 8.2;
        } else {
          return 8;
        }
      } else {
        if (Number(monto) < 1000) {
          return 3.7;
        } else if (Number(monto) < 5000) {
          return 3.55;
        } else if (Number(monto) < 10000) {
          return 3.2;
        } else if (Number(monto) < 15000) {
          return 3.1;
        } else {
          return 3;
        }
      }
    }
    function CalcularSeguro(formapago, monto, cuotas) {
      if (formapago === "dia") {
        if (Number(monto) <= 1000) {
          return 5;
        } else if (Number(monto) <= 1500) {
          return 7.5;
        } else if (Number(monto) <= 2000) {
          return 10;
        } else if (Number(monto) <= 3000) {
          return 15;
        } else if (Number(monto) <= 4000) {
          return 20;
        } else if (Number(monto) <= 5000) {
          return 25;
        } else if (Number(monto) <= 6000) {
          return 28;
        } else if (Number(monto) <= 7000) {
          return 30;
        } else if (Number(monto) <= 8000) {
          return 33;
        } else if (Number(monto) <= 9000) {
          return 36;
        } else {
          return 40;
        }
      } else {
        if (Number(monto) < 5000) {
          var raw = 0.0008;
        } else {
          var raw = 0.0007;
        }
        var seguro = Number(monto) * raw * Number(cuotas);
        if (seguro < 5) {
          seguro = 5;
        }
        return seguro;
      }
    }
    function TipoInteres(formapago, inter) {
      if (formapago === "dia") {
        if (Number(inter) > 7) {
          return 2;
        } else {
          return 1;
        }
      } else {
        return 2;
      }
    }

    if (this.state.interes === 0) {
      var d_interes = CalcularInteres(this.state.formapago, this.state.monto);
      this.setState({ interes: d_interes });
    } else {
      var d_interes = this.state.interes;
    }
    var d_interes2 = CalcularInteres(this.state.formapago, this.state.monto);
    this.setState({ interes2: d_interes2 });

    var d_seguro = CalcularSeguro(
      this.state.formapago,
      this.state.monto,
      this.state.cuotas
    );
    this.setState({ seguro: d_seguro });

    var tipint = TipoInteres(this.state.formapago, this.state.inter);

    const datosEnviar = {
      monto: this.state.monto,
      tip_press: this.state.tip_press,
      gastos_admin: this.state.gastos_admin,
      id_user: 3,
      id_cobrador: this.state.asesor,
      id_cliente: IdUser,
      interes: d_interes,
      tinteres: tipint,
      cuotas: this.state.cuotas,
      cuotasr: this.state.cuotas,
      formapago: this.state.formapago,
      fechapago: this.state.fecha,
      cartera: this.state.caja,
      desgrav: d_seguro,
      id_ruta: this.state.ruta,
      inter: this.state.inter,
      d_junta: this.state.junta,
      nota: this.state.nota,
    };

    fetch(prestamosUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: UrlPost(datosEnviar),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            success2: 1,
            error2: null,
          });
        } else {
          this.setState({ success2: null, error2: 1 });
        }
        console.log(result);
      })
      .catch((error) => console.log("error", error));

    event.preventDefault();
  }

  render() {
    const { Cliente, Rutas, Caja, Asesor, Cronograma } = this.state;
    //console.log(Asesor);
    return (
      <div>
        <Menu />
        <section className="content">
          <br></br>
          <Link className="btn btn-light" to="/prestamosadd">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          <div className="container-fluid">
            <div className="row">
              <div className="cont">
                <section className="content-header">
                  <div className="container-fluid">
                    <div className="d-flex justify-content-center">
                      <h1>NUEVO PRESTAMO</h1>
                    </div>
                  </div>
                </section>
                {this.state.success && (
                  <div className="alert alert-success" role="alert">
                    El usuario esta apto para credito.
                  </div>
                )}
                {this.state.error && (
                  <div className="alert alert-danger" role="alert">
                    {this.state.error}
                  </div>
                )}
                <form className="formx" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <label>CLIENTE</label>
                      <input
                        type="hidden"
                        name="cli_id"
                        placeholder="Validar Cliente"
                        className="form-control"
                        required
                        onChange={this.handleChange}
                        value={Cliente.id}
                      />
                      <p>
                        {Cliente.ape1 + " " + Cliente.ape2 + " " + Cliente.name}
                      </p>
                    </div>
                    <div className="col">
                      <label>FECHA PAGO</label>
                      <input
                        type="date"
                        name="fecha"
                        placeholder="Fecha de pago"
                        className="form-control"
                        required
                        onChange={this.handleChange}
                        value={this.state.fecha}
                      />
                    </div>
                    <div className="col">
                      <div>
                        <label htmlFor="formapago">Forma de Pago:</label>
                        <select
                          className="form form-control"
                          name="formapago"
                          id="formapago"
                          required
                          onChange={this.handleChange}
                          value={this.state.formapago}
                        >
                          <option value="dia">Díario</option>
                          <option value="sem">Semanal</option>
                          <option value="qui">Quincenal</option>
                          <option value="men">Mensual</option>
                        </select>
                      </div>
                    </div>
                    <div className="col">
                      <div>
                        <label htmlFor="inter">Intervalo:</label>
                        <input
                          type="number"
                          name="inter"
                          id="inter"
                          className="form form-control"
                          placeholder="Intervalo"
                          onChange={this.handleChange}
                          value={this.state.inter}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label htmlFor="monto">Monto:</label>
                      <input
                        type="number"
                        name="monto"
                        id="monto"
                        className="form form-control"
                        placeholder="Monto"
                        step="0.01"
                        onChange={this.handleChange}
                        value={this.state.monto}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="cuotas">Cuotas:</label>
                      <input
                        type="number"
                        name="cuotas"
                        defaultValue
                        id="cuotas"
                        className="form form-control"
                        placeholder="Cuotas"
                        onChange={this.handleChange}
                        value={this.state.cuotas}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="ruta">Ruta:</label>
                      <select
                        className="form form-control"
                        name="ruta"
                        id="ruta"
                        required
                        onChange={this.handleChange}
                      >
                        {Rutas.map((Datos) => (
                          <option value={Datos.id} key={Datos.id}>
                            {Datos.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col">
                      <label htmlFor="caja">Caja:</label>
                      <select
                        className="form form-control"
                        name="caja"
                        id="caja"
                        required
                        onChange={this.handleChange}
                        value={this.state.caja}
                      >
                        {Caja.map((Datos) => (
                          <option value={Datos.id} key={Datos.id}>
                            {Datos.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label htmlFor="junta">Junta Personalizada:</label>
                      <input
                        type="number"
                        name="junta"
                        defaultValue
                        id="junta"
                        className="form form-control"
                        placeholder="Junta"
                        step="0.10"
                        required
                        onChange={this.handleChange}
                        value={this.state.junta}
                      />
                    </div>
                    <div className="col">
                      <label htmlFor="tip_press">Tipo de Prestamo: </label>
                      <select
                        className="formf form-control"
                        name="tip_press"
                        id="tip_press"
                        onChange={this.handleChange}
                        value={this.state.tip_press}
                      >
                        <option value={0}>Nuevo</option>
                        <option value={1}>Renovación</option>
                        <option value={2}>Ampliación</option>
                        <option value={3}>Paralelo</option>
                        <option value={4}>Refinanciamiento</option>
                      </select>
                    </div>
                    <div className="col">
                      <label htmlFor="asesor">Asesor:</label>
                      <select
                        className="form form-control"
                        name="asesor"
                        id="asesor"
                        required
                        onChange={this.handleChange}
                        value={this.state.asesor}
                      >
                        {Asesor.map((Datos) => (
                          <option value={Datos.id} key={Datos.id}>
                            {Datos.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col">
                      <label htmlFor="nota">NOTA: (Opcional)</label>
                      <input
                        type="text"
                        name="nota"
                        id="nota"
                        onChange={this.handleChange}
                        value={this.state.nota}
                        placeholder="Nota"
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <label htmlFor="interes">Interes: </label>
                      <input
                        type="number"
                        name="interes"
                        id="interes"
                        onChange={this.handleChange}
                        value={this.state.interes}
                        placeholder="Interes"
                        className="form-control"
                      ></input>
                    </div>
                    <div className="col">
                      <br></br>
                      <b>Calculadora: {this.state.interes2}%</b>
                    </div>
                    <div className="col">
                      <label htmlFor="gastos_admin">
                        Gastos Administrativos:{" "}
                      </label>
                      <input
                        type="number"
                        name="gastos_admin"
                        id="gastos_admin"
                        onChange={this.handleChange}
                        value={this.state.gastos_admin}
                        placeholder="Gastos Administrativos"
                        className="form-control"
                      ></input>
                    </div>
                    <div className="col"></div>
                  </div>
                  <div className="fbtnx">
                    <button className="btn btn-primary">CALCULAR</button>
                  </div>
                </form>
              </div>
              <div className="cont">
                <h4>CRONOGRAMA DE REFERENCIA</h4>
                <hr></hr>
                <div className="row">
                  <div className="col">
                    <b>SEGURO:</b> {this.state.seguro}
                  </div>
                  <div className="col">
                    <b>INTERES:</b> {this.state.interes}
                  </div>
                  <div className="col">
                    <b>JUNTA:</b> {this.state.junta}
                  </div>
                  <div className="col">
                    <b>GASTOS ADMINISTRATIVOS:</b> {this.state.gastos_admin}
                  </div>
                </div>
                <div className="row">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <th>PERIODO</th>
                        <th>DÍA</th>
                        <th>CAPITAL</th>
                        <th>INTERES</th>
                        <th>CUOTA</th>
                        <th>SALDO</th>
                      </thead>
                      <tbody>
                        {Cronograma.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.num}</td>
                            <td>
                              {Datos.dia} , {Datos.fecha}
                            </td>
                            <td>{Datos.capital}</td>
                            <td>{Datos.interes}</td>
                            <td>{Datos.monto}</td>
                            <td>{Datos.saldo}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                {this.state.success2 && (
                  <div className="alert alert-success" role="alert">
                    Prestamo registrado correctamente{" "}
                    <Link to="/prestamos" className="btn btn-info">
                      Ver prestamos
                    </Link>
                  </div>
                )}
                {this.state.error2 && (
                  <div className="alert alert-danger" role="alert">
                    Error de Registro de prestamo
                  </div>
                )}
                {this.state.estado_form || this.state.btnStatusPreAprobar  ? (
                  <div className="row">
                    <form onSubmit={this.handleSubmit2}>
                      <button
                        className="btn btn-success"
                      >
                        PRE APROBAR PRESTAMO
                      </button>
                    </form>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(PrestamosRegister);
