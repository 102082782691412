import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Link } from "react-router-dom";
import HowToRegIcon from "@mui/icons-material/HowToReg";

import "../../css/form.css";
import Menu from "../../comp/Menu";
import { UrlHeader, UrlPost, clienteUrl } from "../../common/Function";

class PreEvaluacioCredito extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      error1: null,
      success: null,
      success1: null,
      dni: "",
      plazo: "",
      forma: "",
      cuota: 0,
      interes: 0,
      gatos_admin: 0,
      msg: "",
      estado: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    this.setState({ error: null, success: null });
    const datosEnviar = {
      dni: this.state.dni,
      evaluarcredito: 1,
    };

    fetch(clienteUrl, {
      method: "POST",
      body: UrlPost(datosEnviar),
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        if (datosRespuesta.success === true) {
          this.setState({
            msg: datosRespuesta.msg,
            estado: datosRespuesta.success,
          });
        } else {
          this.setState({
            msg: datosRespuesta.msg,
            estado: datosRespuesta.success,
          });
        }
      })
      .catch(console.log);

    event.preventDefault();
  }
  componentDidMount() {}

  render() {
    const { error } = this.state;

    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/home">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          <section className="">
            <div className="container-fluid">
              <div className="cont">
                <div className="">
                  {this.state.estado === true && (
                    <div className="alert alert-success" role="alert">
                      {this.state.msg}
                    </div>
                  )}
                  {this.state.estado === false && (
                    <div className="alert alert-danger" role="alert">
                      {this.state.msg}
                    </div>
                  )}
                  <div className="row">
                    <form className="formx" onSubmit={this.handleSubmit}>
                      <h4>PRE EVALUACIÓN DE CREDITO</h4>
                      <hr></hr>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="monto">DNI PARA EVALUACIÓN:</label>
                          <input
                            type="number"
                            name="dni"
                            id="dni"
                            className="form form-control"
                            placeholder="DNI CLIENTE"
                            required
                            onChange={this.handleChange}
                            value={this.state.dni}
                          />
                        </div>
                      </div>
                      <div className="fbtnx">
                        <button className="btn btn-primary w-100">
                          <HowToRegIcon />
                          EVALUAR
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default PreEvaluacioCredito;
