import React from "react";
import Aside from "../../comp/Aside";
import Footer from "../../comp/Footer";
import Navbar from "../../comp/Navbar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../servicios/api";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ListIcon from "@mui/icons-material/List";
import ErrorIcon from "@mui/icons-material/Error";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ClassIcon from "@mui/icons-material/Class";
import "../../css/table.css";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SearchIcon from "@mui/icons-material/Search";

class PrestamosActivos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Prestamos: [],
      total_table: 0,
      pagina_actual: 1,
      pagina_todas: 0,
      estado: null,
      busqueda: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    var busqueda = "bus" + this.state.busqueda;

    this.cargarDatos(busqueda);
    this.TotalRegistros(busqueda);

    event.preventDefault();
  }

  cargarDatos(estado) {
    fetch(api + "Prestamos/?id_estado=" + estado + "&Desde=0&NumRegistros=10")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({ Prestamos: datosRespuesta.data, estado: 1 });
        }
      })
      .catch(console.log);
  }

  TotalRegistros(estado) {
    fetch(api + "Prestamos/?id_estado_total=" + estado)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          let total_pagination = Math.round(datosRespuesta.data[0].total / 10);
          this.setState({
            total_table: datosRespuesta.data[0].total,
            pagina_todas: total_pagination,
          });
        } else {
          this.setState({ total_table: 0, pagina_todas: 0 });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos(3);
    this.TotalRegistros(3);
  }

  Evaluar = (id) => {
    Swal.fire({
      title: "Evalución de Prestamo",
      input: "select",
      inputOptions: {
        SRB: "Serbia",
        UKR: "Ukraine",
        HRV: "Croatia",
      },
      inputPlaceholder: "Seleciona un Asesor",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Aprobar",
      denyButtonText: `Desaprobar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        //Cerrar Sesion
        Swal.fire("Aprobado!", "Felicidades, aprobo este credito.", "success");
        console.log("aprobado" + id);
      } else if (result.isDenied) {
        Swal.fire("Desaprobado!", "Quizá la proxima.", "warning");
        console.log("Desaprobado" + id);
      }

      this.cargarDatos();
    });
  };

  Detalle = (id) => {
    console.log(id);
    fetch(api + "Prestamos/?consultar=" + id)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          Swal.fire({
            title: "<strong>SOLICITUD DE CREDITO</strong>",
            icon: "info",
            html:
              "<div><div class='table-responsive'><table class='table'> " +
              "<tr><td><b>Asesor</b></td><td>" +
              datosRespuesta.data[0].id_user +
              "</td>" +
              "<td><b>Fecha</b></td><td>" +
              datosRespuesta.data[0].fecha +
              "</td></tr>" +
              "<tr><td><b>Nombre de cliente</b></td><td>" +
              datosRespuesta.data[0].cliente_name +
              " " +
              datosRespuesta.data[0].cliente_ape1 +
              "</td>" +
              "<td><b>DNI</b></td><td>" +
              datosRespuesta.data[0].cliente_dni +
              " </td></tr>" +
              "<tr><td><b>DIRECCION DE NEGOCIO</b></td><td>" +
              datosRespuesta.data[0].cliente_name +
              " " +
              datosRespuesta.data[0].cliente_ape1 +
              "</td>" +
              "<td><b>CELULAR</b></td><td>" +
              datosRespuesta.data[0].cliente_dni +
              " </td></tr>" +
              "</table></div></div>",
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Estupendo!!",
            confirmButtonAriaLabel: "Thumbs up, great!",
            cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
            cancelButtonAriaLabel: "Thumbs down",
          });
        }
      })
      .catch(console.log);
  };

  Pagination = (pagina, funcion) => {
    var pagina_todas = this.state.pagina_todas;
    var new_pagina = pagina;
    if (funcion === "next") {
      new_pagina += 1;
      if (new_pagina <= pagina_todas) {
        this.setState({ pagina_actual: new_pagina });
      }
    } else if (funcion === "previous") {
      new_pagina -= 1;
      if (new_pagina !== 0) {
        this.setState({ pagina_actual: new_pagina });
      }
    }
    if (new_pagina !== 0 && new_pagina <= pagina_todas) {
      var desde = (new_pagina - 1) * 10;
      fetch(api + "Prestamos/?id_estado=3&Desde=" + desde + "&NumRegistros=10")
        .then((respuesta) => respuesta.json())
        .then((datosRespuesta) => {
          if (datosRespuesta.success === true) {
            this.setState({ Prestamos: datosRespuesta.data, estado: 1 });
          }
        })
        .catch(console.log);
    }
  };

  render() {
    const { Prestamos, pagina_todas, pagina_actual } = this.state;
    function estado_prestamo(estado) {
      if (estado === "0") {
        return <button className="btn btn-secondary">Pre Aprobado</button>;
      } else if (estado === "1") {
        return <button className="btn btn-warning">Por Desembolsar</button>;
      } else if (estado === "2") {
        return <button className="btn btn-danger">Negado</button>;
      } else if (estado === "3") {
        return <button className="btn btn-success">Activo</button>;
      } else if (estado === "4") {
        return <button className="btn btn-info">Cancelado</button>;
      }
    }

    return (
      <div>
        <Navbar />
        <Aside />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>PRESTAMOS</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link className="nav-link" to="/prestamos">
                        <AssignmentIcon />
                        Pendientes
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link active" to="/prestamosactivos">
                        <PlaylistAddCheckIcon />
                        Activos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/prestamosadd">
                        <PersonAddAltIcon />
                        Agregar
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/prestamos-cancelados-hoy">
                        <FormatListNumberedIcon />
                        Cancelados Hoy
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="table-responsive cont-table cont">
                  <div className="barra-busqueda">
                    <form
                      onSubmit={this.handleSubmit}
                      className="containerInput"
                    >
                      <input
                        type="search"
                        placeholder="Búsqueda por Codigo."
                        className="form-control inputBuscar"
                        name="busqueda"
                        onChange={this.handleChange}
                        value={this.state.busqueda}
                      ></input>
                      <button className="btn btn-success">
                        <SearchIcon />
                      </button>
                    </form>
                  </div>
                  <table className="table table-striped">
                    <thead>
                      <tr className="bg-info">
                        <th>Codigo Prestamo</th>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Monto</th>
                        <th>Detalle</th>
                        <th>Riesgo</th>
                        <th>Pagos</th>
                        <th>Juntas</th>
                        <th>Tipo</th>
                        <th>Evaluar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.estado ? (
                        Prestamos.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.id}</td>
                            <td>{Datos.fecha}</td>
                            <td>
                              {Datos.cliente_ape1} {Datos.cliente_ape2}{" "}
                              {Datos.cliente_name}{" "}
                            </td>
                            <td>s/{Datos.monto}</td>
                            <td>
                              <a
                                className="btn btn-primary"
                                onClick={() => this.Detalle(Datos.id)}
                              >
                                <ListAltIcon />
                              </a>
                            </td>
                            <td>
                              <a className="btn btn-danger">
                                <ErrorIcon /> {Datos.c_riesgo}
                              </a>
                            </td>
                            <td>
                              <Link
                                to={"/pagos/" + Datos.id}
                                className="btn btn-info"
                              >
                                <ListIcon /> Pagos
                              </Link>
                            </td>
                            <td>
                              <LocalAtmIcon /> {Datos.j_total}/{Datos.jp_total}{" "}
                              <br></br>Cobertura:{" "}
                              {(
                                (Number(Datos.j_total) +
                                  Number(Datos.jp_total)) /
                                Number(Datos.monto)
                              ).toFixed(2)}
                            </td>
                            <td>
                              <ClassIcon /> {Datos.p_tip}
                            </td>
                            <td>
                              <Link to={"/prestamo/" + Datos.id}>
                                {estado_prestamo(Datos.estado)}
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>
                            <img
                              src="http://altrisa.site/img/cargando.gif"
                              className="img-cargando"
                              alt="Cargando"
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  {pagina_todas > 1 ? (
                    <div className="pagination">
                      <div className="row">
                        <div
                          className={
                            pagina_actual === 1
                              ? "col btn"
                              : "col btn btn-secondary"
                          }
                          onClick={() =>
                            this.Pagination(pagina_actual, "previous")
                          }
                        >
                          <SkipPreviousIcon />
                        </div>
                        <div className="col btn ">
                          {pagina_actual + "/" + pagina_todas}
                        </div>
                        <div
                          className={
                            pagina_actual === pagina_todas
                              ? "col btn "
                              : "col btn btn-secondary"
                          }
                          onClick={() => this.Pagination(pagina_actual, "next")}
                        >
                          <SkipNextIcon />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PrestamosActivos;
