import React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/form.css";
import { UserCod } from "../../common/Function";

class ReportesClienAct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clientes_dia: [],
      clientes_sem: [],
      clientes_men: [],
      error: null,
      success: null,
      userId: UserCod(),
    };
  }

  cargarDatos() {

    fetch(api + "Reportes/?clientes_activos=dia")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ clientes_dia: datosRespuesta.data });
      })
      .catch(console.log);

    fetch(api + "Reportes/?clientes_activos=sem")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ clientes_sem: datosRespuesta.data });
      })
      .catch(console.log);

    fetch(api + "Reportes/?clientes_activos=men")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ clientes_men: datosRespuesta.data });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    const { clientes_dia, clientes_sem, clientes_men } = this.state;

    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/reportes">
            <KeyboardBackspaceIcon /> Regresar
          </Link>
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>REPORTE CLIENTES ACTIVOS</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section className="content table-list">
            <div className="table-responsive">
              <h2>Prestanos Diarios</h2>
              <table className="table">
                <thead>
                  <tr>
                    <td>Apellidos y nombre</td>
                    <td>DNI</td>
                    <td>Monto</td>
                    <td>Fecha</td>
                    <td>Ultimo Pago</td>
                  </tr>
                </thead>
                <tbody>
                  {clientes_dia.map((Datos) => (
                    <tr className="text-center" key={Datos.id}>
                      <td>
                        {Datos.ape1} {Datos.ape2} {Datos.name}
                      </td>
                      <td>{Datos.dni}</td>
                      <td>{Datos.monto}</td>
                      <td>{Datos.fecha}</td>
                      <td>{Datos.ultimo_pago}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

          <section className="content table-list">
            <div className="table-responsive">
              <h2>Prestanos Semanales</h2>
              <table className="table">
                <thead>
                  <tr>
                    <td>Apellidos y nombre</td>
                    <td>DNI</td>
                    <td>Monto</td>
                    <td>Fecha</td>
                    <td>Ultimo Pago</td>
                  </tr>
                </thead>
                <tbody>
                  {clientes_sem.map((Datos) => (
                    <tr className="text-center" key={Datos.id}>
                      <td>
                        {Datos.ape1} {Datos.ape2} {Datos.name}
                      </td>
                      <td>{Datos.dni}</td>
                      <td>{Datos.monto}</td>
                      <td>{Datos.fecha}</td>
                      <td>{Datos.ultimo_pago}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>

          <section className="content table-list">
            <div className="table-responsive">
              <h2>Prestanos Mensuales</h2>
              <table className="table">
                <thead>
                  <tr>
                    <td>Apellidos y nombre</td>
                    <td>DNI</td>
                    <td>Monto</td>
                    <td>Fecha</td>
                    <td>Ultimo Pago</td>
                  </tr>
                </thead>
                <tbody>
                  {clientes_men.map((Datos) => (
                    <tr className="text-center" key={Datos.id}>
                      <td>
                        {Datos.ape1} {Datos.ape2} {Datos.name}
                      </td>
                      <td>{Datos.dni}</td>
                      <td>{Datos.monto}</td>
                      <td>{Datos.fecha}</td>
                      <td>{Datos.ultimo_pago}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ReportesClienAct;
