import React from "react";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/form.css";
import Menu from "../../comp/Menu";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { QuitarRegistrosCero } from "../../common/Function";

class ReportesRecu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reporte: [],
      asesor: "",
      fechaini: "",
      fechafin: "",
      error: null,
      success: null,
      estado_cargano: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    this.setState({
      estado_cargano: 1,
      reporte: [],
    });
    const datosEnviar = {
      fechaini: this.state.fechaini,
      fechafin: this.state.fechafin,
    };
    fetch(api + "Reportes/?recuperaciones=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          success: 1,
          error: null,
          reporte: QuitarRegistrosCero(datosRespuesta),
          estado_cargano: null,
        });
      })
      .catch(console.log);
    event.preventDefault();
  }

  render() {
    const { reporte } = this.state;
    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/reportes">
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          <Link
            className="btn btn-primary"
            to="/reporte/recuperaciones/detalle"
          >
            <TextSnippetIcon /> Detalle
          </Link>
          <section className="cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>RECUPERACIONES</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/#/inicio">Inicio</Link>
                      </li>
                      <li className="breadcrumb-item active">home</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <div className="container-fluid">
              <div className="reporte-form">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col">
                      <label>Fecha Inicio:</label>
                      <input
                        className="form-control"
                        name="fechaini"
                        type="date"
                        required
                        onChange={this.handleChange}
                        value={this.state.fechaini}
                      ></input>
                    </div>
                    <div className="col">
                      <label>Fecha Fin:</label>
                      <input
                        className="form-control"
                        name="fechafin"
                        type="date"
                        required
                        onChange={this.handleChange}
                        value={this.state.fechafin}
                      ></input>
                    </div>
                    <div className="col">
                      <br></br>
                      <button className="btn btn-primary">Consultar</button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="reporte-cont">
                <div className="table-responsive">
                  {this.state.estado_cargano ? (
                    <img src="/img/progress.gif" alt="cargando"></img>
                  ) : (
                    <table className="table">
                      <tbody>
                        {reporte.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.asesor}</td>
                            <td>{Datos.wilmer}</td>
                            <td>{Datos.liza}</td>
                            <td>{Datos.rb}</td>
                            <td>{Datos.diego}</td>
                            <td>{Datos.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default ReportesRecu;
