import React from "react";
import Aside from "../../comp/Aside";
import Footer from "../../comp/Footer";
import Navbar from "../../comp/Navbar";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../servicios/api";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ErrorIcon from "@mui/icons-material/Error";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import ClassIcon from "@mui/icons-material/Class";
import SearchIcon from "@mui/icons-material/Search";
import "../../css/table.css";

class PrestamosCastigados extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Prestamos: [],
      Datos: [],
      tablaBusqueda: [],
      busqueda: "",
      pres_num: 0,
      pres_monto: 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  cargarDatos() {
    fetch(api + "Prestamos/?id_estado=negados")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            Datos: datosRespuesta.data,
            tablaBusqueda: datosRespuesta.data,
          });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  cargarDatos2() {
    //console.log("ddata");
    fetch(api + "Prestamos/?count_prescastigados=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          pres_num: datosRespuesta.data.num_press,
          pres_monto: datosRespuesta.data.monto,
        });
        //console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarDatos2();
  }

  Detalle = (id) => {
    console.log(id);
    fetch(api + "Prestamos/?consultar=" + id)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          Swal.fire({
            title: "<strong>SOLICITUD DE CREDITO</strong>",
            icon: "info",
            html:
              "<div><div class='table-responsive'><table class='table'> " +
              "<tr><td><b>Asesor</b></td><td>" +
              datosRespuesta.data[0].id_user +
              "</td>" +
              "<td><b>Fecha</b></td><td>" +
              datosRespuesta.data[0].fecha +
              "</td></tr>" +
              "<tr><td><b>Nombre de cliente</b></td><td>" +
              datosRespuesta.data[0].cliente_name +
              " " +
              datosRespuesta.data[0].cliente_ape1 +
              "</td>" +
              "<td><b>DNI</b></td><td>" +
              datosRespuesta.data[0].cliente_dni +
              " </td></tr>" +
              "<tr><td><b>DIRECCION DE NEGOCIO</b></td><td>" +
              datosRespuesta.data[0].cliente_name +
              " " +
              datosRespuesta.data[0].cliente_ape1 +
              "</td>" +
              "<td><b>CELULAR</b></td><td>" +
              datosRespuesta.data[0].cliente_dni +
              " </td></tr>" +
              "</table></div></div>",
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Estupendo!!",
            confirmButtonAriaLabel: "Thumbs up, great!",
            cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
            cancelButtonAriaLabel: "Thumbs down",
          });
        }
        console.log(datosRespuesta);
      })
      .catch(console.log);
  };

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
    this.filtrar(target.value);
    //console.log(target.value);
  }

  filtrar(termino) {
    //console.log(termino);
    var resultadoBusqueda = this.state.tablaBusqueda.filter((elemento) => {
      if (
        elemento.cliente_name
          .toString()
          .toLowerCase()
          .includes(termino.toLowerCase()) ||
        elemento.cliente_ape1
          .toString()
          .toLowerCase()
          .includes(termino.toLowerCase()) ||
        elemento.cliente_ape2
          .toString()
          .toLowerCase()
          .includes(termino.toLowerCase()) ||
        elemento.cliente_dni
          .toString()
          .toLowerCase()
          .includes(termino.toLowerCase())
      ) {
        return elemento;
      }
    });
    this.setState({
      Datos: resultadoBusqueda,
    });
  }

  render() {
    const { Prestamos, Datos } = this.state;

    function estado_prestamo(estado) {
      if (estado === "0") {
        return <button className="btn btn-secondary">Pre Aprobado</button>;
      } else if (estado === "1") {
        return <button className="btn btn-warning">Por Desembolsar</button>;
      } else if (estado === "2") {
        return <button className="btn btn-danger">Negado</button>;
      } else if (estado === "3") {
        return <button className="btn btn-success">Activo</button>;
      } else if (estado === "4") {
        return <button className="btn btn-info">Cancelado</button>;
      }
    }
    return (
      <div>
        <Navbar />
        <Aside />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>PRESTAMOS</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link className="nav-link" to="/prestamos">
                        <AssignmentIcon />
                        Pendientes
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/prestamosactivos">
                        <PlaylistAddCheckIcon />
                        Activos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/prestamosadd">
                        <PersonAddAltIcon />
                        Agregar
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link active"
                        to="/prestamos-castigados"
                      >
                        <AssignmentIcon />
                        Castigados
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/prestamos-cancelados-hoy">
                        <FormatListNumberedIcon />
                        Cancelados Hoy
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="table-responsive cont-table cont">
                  <div className="barra-totales">
                    <div>
                      <h5>TOTAL: {this.state.pres_num}</h5>
                    </div>
                    <div>
                      <h5>MONTO TOTAL: {this.state.pres_monto}</h5>
                    </div>
                  </div>
                  <div className="barra-busqueda">
                    <form
                      onSubmit={this.handleSubmit}
                      className="containerInput"
                    >
                      <input
                        type="search"
                        placeholder="Búsqueda por Codigo,Nombre, Apellido, DNI."
                        className="form-control inputBuscar"
                        name="busqueda"
                        onChange={this.handleChange}
                        value={this.state.busqueda}
                      ></input>
                      <button className="btn btn-success">
                        <SearchIcon />
                      </button>
                    </form>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Codigo Prestamo</th>
                        <th>Cliente</th>
                        <th>Monto</th>
                        <th>Castigado</th>
                        <th>Detalle</th>
                        <th>Riesgo</th>
                        <th>Juntas</th>
                        <th>Tipo</th>
                        <th>Ver</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Datos.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.id}</td>
                          <td>
                            {Datos.cliente_ape1} {Datos.cliente_ape2}{" "}
                            {Datos.cliente_name}{" "}
                          </td>
                          <td>s/{Datos.monto}</td>
                          <td>s/{Datos.monto_castigo}</td>
                          <td>
                            <a
                              className="btn btn-primary"
                              onClick={() => this.Detalle(Datos.id)}
                            >
                              <ListAltIcon />
                            </a>
                          </td>
                          <td>
                            <a className="btn btn-danger">
                              <ErrorIcon /> {Datos.c_riesgo}
                            </a>
                          </td>
                          <td>
                            <LocalAtmIcon /> {Datos.j_total}/{Datos.jp_total}{" "}
                            <br></br>Cobertura:{" "}
                            {(
                              (Number(Datos.j_total) + Number(Datos.jp_total)) /
                              Number(Datos.monto)
                            ).toFixed(2)}
                          </td>
                          <td>
                            <ClassIcon /> {Datos.p_tip}
                          </td>
                          <td>
                            <Link to={"/prestamo/" + Datos.id}>
                              {estado_prestamo(Datos.estado)}
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default PrestamosCastigados;
