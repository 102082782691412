import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/form.css";
import Menu from "../../comp/Menu";

class JuntaAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos: [],
      Caja: [],
      Asesor: [],
      fecha: null,
      id_caja: null,
      id_clie: 0,
      id_ases: null,
      capital: 0,
      interes: 0,
      error: null,
      success: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.conexion = false;
  }

  cargarDatos() {
    this.setState({
      fecha: "2022-08-15",
    });
    const datosEnviar = {};

    fetch(api + "Cartera/?activo=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            Caja: datosRespuesta.data,
          });
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);

    //--------- ASESORES ----------
    fetch(api + "Users/?user_asesor=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          Asesor: datosRespuesta,
        });
        console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const datosEnviar = {
      id_caja: this.state.id_caja,
      id_clie: this.state.id_clie,
      id_ases: this.state.id_ases,
    };

    fetch(api + "Junta/?insertar_junta=1", {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
          });
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    const { Asesor, Caja, error } = this.state;
    return (
      <div>
        <Menu />
        <div className="content">
        <br></br>
          <Link to={"/junta"} className="btn btn-light">
            <ChevronLeftIcon /> Volver
          </Link>
          <section className="cont">
            <div className="container-fluid">
              <section className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-6">
                      <h1>Junta Agregar</h1>
                    </div>
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item">
                          <Link to="/#/inicio">Inicio</Link>
                        </li>
                        <li className="breadcrumb-item active">home</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>
              <div className="">
                <div className="">
                  <div className="row">
                    {this.state.success && (
                      <div className="alert alert-success" role="alert">
                        SE REGISTRO PAGO CORRECTAMENTE
                      </div>
                    )}
                    {this.state.error && (
                      <div className="alert alert-danger" role="alert">
                        {error}
                      </div>
                    )}
                    <form className="formx" onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col">
                          <label htmlFor="id_clie">Codigo de cliente:</label>
                          <input
                            type="number"
                            name="id_clie"
                            id="id_clie"
                            className="form form-control"
                            placeholder="Codigo de Cliente"
                            onChange={this.handleChange}
                            value={this.state.id_clie}
                          />
                        </div>
                        <div className="col">
                          <label htmlFor="id_caja">Caja:</label>
                          <select
                            className="form form-control"
                            name="id_caja"
                            id="id_caja"
                            required
                            onChange={this.handleChange}
                            value={this.state.id_caja}
                          >
                            <option></option>
                            {Caja.map((Datos) => (
                              <option value={Datos.id} key={Datos.id}>
                                {Datos.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col">
                          <label htmlFor="id_ases">Asesor:</label>
                          <select
                            className="form form-control"
                            name="id_ases"
                            id="id_ases"
                            required
                            onChange={this.handleChange}
                            value={this.state.id_ases}
                          >
                            <option></option>
                            {Asesor.map((Datos) => (
                              <option value={Datos.id} key={Datos.id}>
                                {Datos.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="fbtnx">
                        <button className="btn btn-primary">REGISTRAR</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default JuntaAdd;
