import React from "react";
import Menu from "../../comp/Menu";
import Navbar from "../../comp/Navbar";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import KeyIcon from '@mui/icons-material/Key';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import "../../css/perfil.css";
import { UrlHeader, userUrl, UserCod, UrlPost } from "../../common/Function";
import Swal from "sweetalert2";

class Perfil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      apellido1: "",
      apellido2: "",
      dni: "",
      phone: "",
      direccion: "",
      email: "",
      cumple: "",
      cargo: "",
      UserId: UserCod(),
    };
  }

  cargarDatos() {
    const IdUser = this.state.UserId;

    fetch(userUrl + IdUser, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          const userData = datosRespuesta.data;

          this.setState({
            nombre: userData.name,
            ape1: userData.apellido1,
            ape2: userData.apellido2,
            dni: userData.dni,
            phone: userData.phone,
            direccion: userData.direccion,
            email: userData.email,
            cumple: userData.cumple,
            cargo: userData.tip_user
          });
        } else {
          console.error("La solicitud no fue exitosa");
        }
      })
      .catch((error) => {
        console.error("Error al cargar datos:", error);
      });
  }



  handleSubmit = (event) => {
    event.preventDefault();

    const datosEnviar = {
      actualizar: "1",
      id: this.state.UserId,
      name: this.state.nombre,
      ape1: this.state.ape1,
      ape2: this.state.ape2,
      dni: this.state.dni,
      phone: this.state.phone,
      address: this.state.direccion,
      email: this.state.email,
      birthday: this.state.cumple,
    };

    const formdata = UrlPost(datosEnviar);

    fetch(userUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: formdata,
      redirect: "follow",
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Datos actualizados correctamente.",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          console.error("Error al actualizar los datos");
        }
      })
      .catch(error => {
        console.error("Error de red:", error);
      });
  }


  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmitPassword = (event) => {
    event.preventDefault();

    const { pass1, pass2, pass3 } = this.state;

    if (pass2 !== pass3) {
      console.error("Las contraseñas nuevas no coinciden.");
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Las contraseñas nuevas no coinciden.',
      });
      return;
    }

    const datosEnviar = {
      actualizar: "1",
      id: this.state.UserId,
      pass1: pass1,
      pass2: pass2
    };

    const formdata = UrlPost(datosEnviar);

    fetch(userUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: formdata,
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Contraseña actualizada correctamente.",
            showConfirmButton: false,
            timer: 1500,
          });
          this.setState({
            pass1: "",
            pass2: "",
            pass3: ""
          });
        } else {
          console.error("Error al actualizar la contraseña");
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Hubo un error al actualizar la contraseña',
          });
        }
      })
      .catch(error => {
        console.error("Error de red:", error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Hubo un error de red al intentar actualizar la contraseña',
        });
      });
  }

  componentDidMount() {
    this.cargarDatos();
  }
  render() {
    const { nombre, ape1, ape2, dni, phone, email, direccion, cumple, cargo } =
      this.state;
    var name_cargo = "";
    if (parseInt(cargo) === 1) {
      name_cargo = "ADMINISTRATOR";
    } else {
      name_cargo = "ASESOR";
    }
    return (
      <div id="perfil">
        <Menu />
        <Navbar />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <Link className="btn btn-link rounded-0 border border-dark my-2 d-flex justify-content-center align-items-center col-auto col-md" to="/home">
                <KeyboardBackspaceIcon />
                <span className="ml-2 d-none d-md-block">Regresar</span>
              </Link>
              <div className="d-flex justify-content-center">
                <h1>Mi Perfil</h1>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="d-flex justify-content-center mt-3">
              <ul className="nav nav-tabs">
                <li className="nav-item mx-3">
                  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#EditarPerfil">
                    <EditIcon />
                    Modificar mi Perfil
                  </button>
                </li>
                <li className="nav-item mx-3">
                  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#CambiarPass">
                    <KeyIcon />
                    Cambiar Contraseña
                  </button>
                </li>
              </ul>
            </div>
            <div className="container-fluid">
              <div className="row">

                <div className="cont">
                  <div className="perfil-header">
                    <div className="perfil-logo">
                      <img
                        src="../img/avatar.png"
                        alt="perfil de ususario"
                      ></img>
                      <div className="btn btn-danger">{name_cargo}</div>
                    </div>
                  </div>
                  <div className="table-responsive cont">
                    <table className="table cont2">
                      <tbody>
                        <tr>
                          <td>
                            <b>NOMBRES:</b>
                          </td>
                          <td>{nombre}</td>
                          <td>
                            <b>APELLIDOS:</b>
                          </td>
                          <td>{ape1} {ape2}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>DNI:</b>
                          </td>
                          <td>{dni}</td>
                          <td>
                            <b>TELEFONO:</b>
                          </td>
                          <td>{phone}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>DIRECCIÓN:</b>
                          </td>
                          <td>{direccion}</td>
                          <td>
                            <b>EMAIL:</b>
                          </td>
                          <td>{email}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>CUMPLEAÑOS:</b>
                          </td>
                          <td>{cumple}</td>
                          <td>
                            <b>CARGO:</b>
                          </td>
                          <td>{name_cargo}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/*----------------MODAL EDITAR PERFIL --------------------*/}
        <div className="modal fade" id="EditarPerfil" tabindex="-1" role="dialog" aria-labelledby="EditarPerfilTitle" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="EditarPerfilTitle">Editar Perfil</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">

                <form className="row" onSubmit={this.handleSubmit}>
                  <div className="col">
                    <label>Nombre:</label>
                    <input
                      type="text"
                      name="nombre"
                      placeholder="Nombre"
                      required
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.nombre}
                    ></input>
                    <label>Apellido Paterno:</label>
                    <input
                      type="text"
                      name="ape1"
                      placeholder="Apellido Paterno"
                      required
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.ape1}
                    ></input>
                    <label>Apellido Materno:</label>
                    <input
                      type="text"
                      name="ape2"
                      placeholder="Apellido Materno"
                      required
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.ape2}
                    ></input>
                    <label>DNI:</label>
                    <input
                      type="number"
                      name="dni"
                      placeholder="DNI"
                      required
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.dni}
                    ></input>
                  </div>
                  <div className="col">
                    <label>Telefono:</label>
                    <input
                      type="number"
                      name="phone"
                      placeholder="Número de telefono"
                      required
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.phone}
                    ></input>
                    <label>DIRECCIÓN:</label>
                    <input
                      type="text"
                      name="direccion"
                      placeholder="Dirección"
                      required
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.direccion}
                    ></input>
                    <label>EMAIL:</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Correo electronico"
                      required
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.email}
                    ></input>
                    <label>CUMPLEAÑOS:</label>
                    <input
                      type="date"
                      name="cumple"
                      placeholder="Fecha de cumpleaños"
                      required
                      className="form-control"
                      onChange={this.handleChange}
                      value={this.state.cumple}
                    ></input>
                  </div>
                  <hr></hr>
                  <div className="boton-actualizar">
                    <button className="btn btn-success">Actualizar</button>
                  </div>
                </form>

              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
          </div>
        </div>

        {/*----------------MODAL EDITAR CONTRASEÑA --------------------*/}
        <div className="modal fade" id="CambiarPass" tabIndex="-1" role="dialog" aria-labelledby="CambiarPassTitle" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="CambiarPassTitle">Modificar Contraseña</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="row" onSubmit={this.handleSubmitPassword}>
                  <div className="col">
                    <label>Contraseña Actual:</label>
                    <div className="input-group">
                      <input
                        type={this.state.showCurrentPassword ? "text" : "password"}
                        name="pass1"
                        placeholder="Contraseña Actual"
                        required
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.pass1}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={() => this.setState((prevState) => ({ showCurrentPassword: !prevState.showCurrentPassword }))}
                        >
                          {this.state.showCurrentPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </button>
                      </div>
                    </div>
                    <hr />
                    <label>Nueva Contraseña:</label>
                    <div className="input-group">
                      <input
                        type={this.state.showNewPassword ? "text" : "password"}
                        name="pass2"
                        placeholder="Nueva Contraseña"
                        required
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.pass2}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={() => this.setState((prevState) => ({ showNewPassword: !prevState.showNewPassword }))}
                        >
                          {this.state.showNewPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </button>
                      </div>
                    </div>
                    <label>Verificar Contraseña:</label>
                    <div className="input-group">
                      <input
                        type={this.state.showConfirmPassword ? "text" : "password"}
                        name="pass3"
                        placeholder="Verificar Contraseña"
                        required
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.pass3}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-secondary"
                          type="button"
                          onClick={() => this.setState((prevState) => ({ showConfirmPassword: !prevState.showConfirmPassword }))}
                        >
                          {this.state.showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </button>
                      </div>
                    </div>
                    {this.state.passError && <div className="text-danger">Las contraseñas nuevas no coinciden.</div>}
                  </div>
                  <hr />
                  <div className="boton-actualizar">
                    <button className="btn btn-success">Actualizar</button>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
              </div>
            </div>
          </div>
        </div>

      </div >
    );
  }
}

export default Perfil;
