import React from "react";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "../../css/table.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Menu from "../../comp/Menu";
import {
  UrlGet,
  UrlHeader,
  clienteUrl,
  FechaActual,
  UrlPost,
  userUrl,
  cajaUrl,
} from "../../common/Function";
import { Riesgo, TipoRiesgo, tiposUsuariosPermitidos } from "../Common/utils";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { exportToExcel } from "../Common/MaterialExportToExel";
import { columnsClientesList } from "../../common/ExportColums";
import Carga from "../Common/Carga";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import Swal from "sweetalert2";
import Autocomplete from "../Common/MaterialAutocomplete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
class Clientes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos: [],
      clientCreditos: [],
      tablaBusqueda: [],
      busqueda: "",
      total: 0,
      total_paginas: 0,
      starat: 0,
      endat: 8,
      fechaini: FechaActual(),
      fechafin: FechaActual(),
      press_activos: "",
      press_terminados: "",
      filter: "",
      carga: null,
      riesgovalor: null,
      con_prestamos: null,
      showProgressBar: false,
      progressWidth: 0,
      progressMsg: "",
      perdida: "",
      estado_ultimo_pago: "",
      ultimo_pago: "",
      Asesores: [],
      Carteras: [],
      asesor: "",
      cartera: "",
      totalCli: 0,
      estados_pago: [
        { label: "Pre Aprobado", value: "0" },
        { label: "Por Desembolsar", value: "1" },
        { label: "Negado", value: "2" },
        { label: "Activo", value: "3" },
        { label: "Cancelado", value: "4" },
      ],
      tipoUsuario: localStorage.getItem("TIPUSER"),
    };
    this.handleChange = this.handleChange.bind(this);

    this.AutocompleteAsesores = this.AutocompleteAsesores.bind(this);
    this.AutocompleteCarteras = this.AutocompleteCarteras.bind(this);
    this.autocompleteRefAsesores = React.createRef();
    this.autocompleteRefCarteras = React.createRef();
  }
  AutocompleteAsesores(event) {
    this.setState({
      asesor: event,
    });
  }
  AutocompleteCarteras(event) {
    this.setState({
      cartera: event,
    });
  }
  cargarDatos(data) {
    this.setState({
      carga: true,
    });
    fetch(clienteUrl + UrlGet(data), {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            Datos: result.data,
            tablaBusqueda: result.data,
            total: result.total,
            total_paginas: Math.ceil(result.total / this.state.endat),
            carga: null,
          });
        } else {
          this.setState({
            Datos: [],
            tablaBusqueda: [],
            total: 0,
            total_paginas: 0,
            carga: null,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }

  cargarClientesCreditos() {
    Swal.fire({
      title: "Confirmación",
      text: "¿Deseas realizar la revisión y ejecutar los cambios?. Esto puede tardar varios minutos",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, ejecutar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ carga: 1 });

        fetch(clienteUrl, {
          method: "POST",
          headers: UrlHeader(),
          body: UrlPost({
            evaluar_base_negativa: 1,
          }),
          redirect: "follow",
        })
          .then((response) => response.json())
          .then((result) => {
            this.setState({ carga: null });
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Evaluación completa",
              showConfirmButton: false,
              timer: 2000,
            });
          })
          .catch(console.log);
      }
    });
  }

  CambiarPagina(page) {
    this.setState({ starat: page });

    page = page * this.state.endat;
    var data = this.DatosForm();
    data.starat = page;
    this.cargarDatos(data);
  }

  componentDidMount() {
    /*this.cargarDatos(this.state.starat, null);*/
    this.cargaInicial();
    this.BuscarDatos();
  }

  DatosForm(exportar) {
    var data = {
      starat: this.state.starat,
      endat: this.state.endat,
      filter: this.state.filter,
      fechaini: this.state.fechaini,
      fechafin: this.state.fechafin,
      press_activos: this.state.press_activos,
      press_terminados: this.state.press_terminados,
      riesgovalor: null,
      con_prestamos: this.state.con_prestamos,
      perdida: this.state.perdida,
      asesor: this.state.asesor,
      cartera: this.state.cartera,
      estado_ultimo_pago: this.state.estado_ultimo_pago,
      ultimo_pago: this.state.ultimo_pago,
    };

    if (exportar === 1) {
      data.riesgovalor = this.state.riesgovalor;
    }

    return data;
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  BuscarDatos() {
    var data = this.DatosForm(0);
    this.cargarDatos(data);
  }

  LimpiarFormulario() {
    this.setState({
      Prestamos: [],
      total: 0,
      total_paginas: 0,
      fechaini: "",
      fechafin: "",
      filter: "",
      press_activos: "",
      press_terminados: "",
      riesgovalor: "",
      con_prestamos: "",
      perdida: "",
      estado_ultimo_pago: "",
      ultimo_pago: "",
    });
    this.autocompleteRefAsesores.current.clearAutocomplete();
    this.autocompleteRefCarteras.current.clearAutocomplete();
  }

  DescargarDatos() {
    this.setState({
      carga: 1,
    });
    var data = this.DatosForm(1);
    data.endat = null;
    data.starat = null;

    fetch(clienteUrl + UrlGet(data), {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          exportToExcel(result.data, columnsClientesList, "Clientes_Lista");
        }
        this.setState({
          carga: null,
        });
      })
      .catch((error) => console.log("error", error));
  }

  CambiarBaseNegativa(creditos) {
    this.setState({ progressWidth: 100, progressMsg: "Datos cargados" });

    let cambiadosAUno = 0;
    let cambiadosACero = 0;

    creditos.forEach((credito) => {
      let necesitaCambio = false;
      let nuevoBNegra = credito.b_negra;

      // Comprobamos el riesgo y credito.b_negra según las condiciones
      if (
        TipoRiesgo(credito.riesgo, credito.formapago) === "Muy Malo" &&
        credito.b_negra === 0
      ) {
        necesitaCambio = true;
        nuevoBNegra = 1;
        cambiadosAUno++;
      } else if (
        TipoRiesgo(credito.riesgo, credito.formapago) === "Moderado" &&
        credito.b_negra === 1
      ) {
        necesitaCambio = true;
        nuevoBNegra = 0;
        cambiadosACero++;
      }

      if (necesitaCambio) {
        this.setState({
          progressMsg: `El crédito ${
            credito.id
          } necesita cambio: riesgo ${TipoRiesgo(
            credito.riesgo,
            credito.formapago
          )} - b_negra ${credito.b_negra} => ${nuevoBNegra}`,
        });

        var vardata = {
          actualizar: 1,
          id: credito.id,
          b_negra: nuevoBNegra,
        };

        var requestOptions = {
          method: "POST",
          headers: UrlHeader(),
          body: UrlPost(vardata),
          redirect: "follow",
        };

        fetch(clienteUrl, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result.success === true) {
              this.setState({
                success: 1,
                error: null,
                carga: null,
              });
            } else {
              this.setState({ error: result.error, success: null });
            }
            //console.log(result);
          })
          .catch(console.log);
      }
    });

    Swal.fire({
      title: "Proceso completado",
      text: `Se agregaron ${cambiadosAUno} a base negativa y ${cambiadosACero} salieron de base negativa`,
      icon: "success",
    });

    this.setState({ showProgressBar: false });
  }
  cargaInicial() {
    this.cargarUser();
    this.carterasUser();
  }
  cargarUser() {
    fetch(userUrl + "?estado=1", {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Asesores: result.data,
        });
      })
      .catch((error) => console.log("error", error));
  }

  carterasUser() {
    fetch(cajaUrl + "?estado=1", {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Carteras: result.data,
        });
      })
      .catch((error) => console.log("error", error));
  }

  render() {
    const { Datos } = this.state;

    return (
      <div>
        {this.state.carga ? <Carga /> : null}
        <Menu />
        <div className="content">
          <br></br>
          <div
            className="d-flex justify-content-start"
            style={{ marginBottom: "10px" }}
          >
            <Link to="/" className="btn btn-light mr-2">
              <ChevronLeftIcon /> VOLVER
            </Link>
            {tiposUsuariosPermitidos.btnClienteAgregar.includes(
              this.state.tipoUsuario
            ) && (
              <Link to="/clientesRegistro" className="btn btn-primary mr-2">
                <AddCircleIcon /> REGISTRAR
              </Link>
            )}{" "}
            {tiposUsuariosPermitidos.btnClienteRevisarBase.includes(
              this.state.tipoUsuario
            ) && (
              <button
                className="btn btn-warning"
                style={{ fontSize: "14px", padding: "5px 10px" }}
                onClick={() => this.cargarClientesCreditos()}
              >
                <CompareArrowsIcon /> Revisar base negativa
              </button>
            )}{" "}
          </div>
          {this.state.showProgressBar && (
            <div className="progress">
              <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${this.state.progressWidth}%` }}
              ></div>
            </div>
          )}
          {this.state.showProgressBar && (
            <div className="text-center mt-2 text-muted">
              {this.state.progressMsg}
            </div>
          )}
          <section className="cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>TODOS LOS CLIENTES ({this.state.total}) </h1>
                  </div>

                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/#/inicio">Inicio</Link>
                      </li>
                      <li className="breadcrumb-item active">home</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <div className="container-fluid">
              <div className="row">
                <div className="table-responsive cont-table ">
                  <section className="row">
                    <div className="col-9">
                      <div className="row">
                        <div className="col-4">
                          <label>Busqueda:</label>
                          <input
                            type="search"
                            placeholder="Búsqueda por Codigo,Nombre, Apellido, DNI."
                            className="form-control inputBuscar"
                            name="filter"
                            onChange={this.handleChange}
                            value={this.state.filter || ""}
                          ></input>
                        </div>
                        <div className="col-4">
                          <label>Desde:</label>
                          <input
                            type="date"
                            name="fechaini"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.fechaini || ""}
                          ></input>
                        </div>
                        <div className="col-4">
                          <div>
                            <label>Hasta:</label>
                            <input
                              type="date"
                              name="fechafin"
                              className="form-control"
                              onChange={this.handleChange}
                              value={this.state.fechafin || ""}
                            ></input>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-4">
                          <label>PRESTAMOS ACTIVOS:</label>
                          <input
                            type="number"
                            name="press_activos"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.press_activos || ""}
                          />
                        </div>
                        <div className="col-4">
                          <label>PRESTAMOS TERMINADOS:</label>
                          <input
                            type="number"
                            name="press_terminados"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.press_terminados || ""}
                          />
                        </div>
                        <div className="col-4">
                          <label>
                            RIESGO:
                            <span style={{ opacity: 0.5, marginLeft: "5px" }}>
                              (filtro solo para exportar)
                            </span>
                          </label>
                          <select
                            name="riesgovalor"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.riesgovalor || ""}
                          >
                            <option value="">Selecciona una opción</option>
                            <option value="1">Normal</option>
                            <option value="2">Bajo</option>
                            <option value="3">Moderado</option>
                            <option value="4">Malo</option>
                            <option value="5">Muy malo</option>
                          </select>
                        </div>
                        <div className="col-4">
                          <label>CON PRESTAMOS:</label>
                          <select
                            name="con_prestamos"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.con_prestamos || ""}
                          >
                            <option value="">Todo</option>
                            <option value="1">Con créditos</option>
                          </select>
                        </div>
                        <div className="col-4">
                          <label>CON PERDIDA (Negros):</label>
                          <select
                            name="perdida"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.perdida || ""}
                          >
                            <option value="">Selecciona una opción</option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                          </select>
                        </div>
                        <div className="col-4">
                          <label>Asesor:</label>
                          <Autocomplete
                            allOptions={this.state.Asesores}
                            onSelect={this.AutocompleteAsesores}
                            ref={this.autocompleteRefAsesores}
                          />
                        </div>
                        <div className="col-4">
                          <label>Cartera:</label>
                          <Autocomplete
                            allOptions={this.state.Carteras}
                            onSelect={this.AutocompleteCarteras}
                            ref={this.autocompleteRefCarteras}
                          />
                        </div>
                        <div className="col-4">
                          <label>ESTADO ULTIMO PAGO:</label>
                          <select
                            name="estado_ultimo_pago"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.estado_ultimo_pago || ""}
                          >
                            <option value="">Selecciona un estado</option>
                            {this.state.estados_pago.map((estado, index) => (
                              <option key={index} value={estado.value}>
                                {estado.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-4">
                          <label>ULTIMO PAGO:</label>
                          <input
                            type="date"
                            name="ultimo_pago"
                            className="form-control"
                            onChange={this.handleChange}
                            value={this.state.ultimo_pago || ""}
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div>
                        <button
                          className="btn btn-info w-100 mb-1"
                          onClick={() => this.BuscarDatos()}
                        >
                          <SearchIcon />
                          Buscar
                        </button>
                        {tiposUsuariosPermitidos.btnPrestamoExportar.includes(
                          this.state.tipoUsuario
                        ) && (
                          <button
                            className="btn btn-success w-100 mb-1"
                            onClick={() => this.DescargarDatos()}
                          >
                            <FileDownloadIcon /> Exportar
                          </button>
                        )}

                        <button
                          className="btn btn-danger w-100 mb-1"
                          onClick={() => this.LimpiarFormulario()}
                        >
                          <CleaningServicesIcon />
                          Limpiar
                        </button>
                      </div>
                    </div>
                  </section>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Codigo Cliente</th>
                        <th>Fecha Registro</th>
                        <th>Apellidos y Nombre</th>
                        <th>DIRECCIÓN</th>
                        <th>DNI</th>
                        <th>TELEFONO</th>
                        <th>NEGOCIO</th>
                        <th>PRESTAMOS ACTIVOS/TERMINADOS</th>
                        <th>Ult Asesor</th>
                        <th>JUNTA/JUNTA PLAN</th>
                        <th>CUOTAS</th>
                        <th>MONTO</th>
                        <th>PERDIDA</th>
                        <th>ULTIMO PAGO</th>
                        <th>RIESGO</th>
                        <th>Ver</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Datos.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>{Datos.id}</td>
                          <td>{Datos.registro}</td>
                          <td>
                            {Datos.ape1} {Datos.ape2} {Datos.name}
                          </td>
                          <td>{Datos.direccion}</td>
                          <td>{Datos.dni}</td>
                          <td>{Datos.telefonos}</td>
                          <td>{Datos.negocio}</td>
                          <td>
                            {Datos.num_press} / {Datos.press_terminados}
                          </td>
                          <td>{Datos.asesor}</td>
                          <td>
                            {Datos.junta} / {Datos.junta_plan}
                          </td>
                          <td>{Datos.cuotas_press}</td>
                          <td>{Datos.monto_press}</td>
                          <td>{Datos.perdida}</td>
                          <td>{Datos.ultimopago_press}</td>
                          <td>{Riesgo(Datos.riesgo, Datos.formapago)}</td>
                          <td>
                            <Link
                              className="btn btn-success"
                              to={"/cliente/" + Datos.id}
                            >
                              {" "}
                              <VisibilityIcon />{" "}
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                      <li
                        className={
                          this.state.starat === 0
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          onClick={() =>
                            this.CambiarPagina(this.state.starat - 1)
                          }
                        >
                          <NavigateBeforeIcon />
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link">
                          {this.state.starat +
                            1 +
                            "/" +
                            this.state.total_paginas}
                        </a>
                      </li>
                      <li
                        className={
                          this.state.starat === this.state.total_paginas - 1
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          onClick={() =>
                            this.CambiarPagina(this.state.starat + 1)
                          }
                        >
                          <NavigateNextIcon />
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Clientes;
