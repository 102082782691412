import React from "react";
import "../../css/login.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { RespuestasConsultas } from "../Common/utils";
import { sessionUrl, apiToken } from "../../common/Function";
import CryptoJS from "crypto-js";
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showPass: null,
      error: null,
      wait: null,
      success: null,
      msg: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  BtnShowPass() {
    if (this.state.showPass === 1) {
      this.setState({
        showPass: null,
      });
    } else {
      this.setState({
        showPass: 1,
      });
    }
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    this.setState({
      error: null,
      wait: true,
      success: null,
      msg: "",
    });

    var myHeaders = new Headers();
    myHeaders.append("Authorization", apiToken);

    var formdata = new FormData();
    formdata.append("user", this.state.email);
    formdata.append("password", this.state.password);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(sessionUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            error: null,
            wait: null,
            success: true,
            msg: result.msg,
          });
          const idsession = result.data;
          const encryptedSessionId = CryptoJS.AES.encrypt(
            idsession,
            "my-secret-key"
          ).toString();
          localStorage.setItem("IDSESSION", encryptedSessionId);
          this.setVariableWithExpiry(
            "SESSIONDATA",
            "DFSAGAFKJNSDAKJNAKJSFBAFAKSN",
            14 * 60 * 60 * 1000
          );
          this.props.cargarPagina();
        } else {
          this.setState({
            error: true,
            wait: null,
            success: null,
            msg: result.msg,
          });
        }
      })
      .catch((error) => console.log("error", error));
    event.preventDefault();
  }
  setVariableWithExpiry(key, value, expiryTime) {
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + expiryTime,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  render() {
    return (
      <div className="row login">
        <div className="col">
          <div className="col1">
            <div className="col-header">
              <img src="../img/logo.png" alt="logo Altrisa"></img>
              <i>Sistema Financiero.</i>
            </div>
            <div className="col-body">
              <form onSubmit={this.handleSubmit}>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Username"
                  required
                  name="email"
                  autoComplete="off"
                  onChange={this.handleChange}
                  value={this.state.email}
                ></input>
                <div className="input-wrapper">
                  <input
                    type={this.state.showPass ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    required
                    name="password"
                    autoComplete="off"
                    onChange={this.handleChange}
                    value={this.state.password}
                  ></input>
                  <div className="show-pass" onClick={() => this.BtnShowPass()}>
                    {this.state.showPass ? (
                      <VisibilityOffIcon />
                    ) : (
                      <RemoveRedEyeIcon />
                    )}
                  </div>
                </div>
                <RespuestasConsultas
                  error={this.state.error}
                  success={this.state.success}
                  wait={this.state.wait}
                  msg={this.state.msg}
                />
                <button className="btn btn-primary boton">
                  Iniciar Sesión
                </button>
              </form>
              <i>La liquidez es el oxígeno de un sistema financiero.</i>
            </div>
          </div>
        </div>
        <div className="col" id="avatar">
          <div className="col2">
            <img src="../img/avatar.png" alt="Avatar altrisa"></img>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
