import React from "react";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
import "../../css/users.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SearchIcon from "@mui/icons-material/Search";
import {
  UrlHeader,
  FechaActual,
  userUrl,
  cajaUrl,
  UrlPost,
  reportesUrl,
} from "../../common/Function";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArticleIcon from "@mui/icons-material/Article";
import Autocomplete from "../Common/MaterialAutocomplete";
import { exportToExcel } from "../Common/MaterialExportToExel";
import Carga from "../Common/Carga";
import { columnsMoraList } from "../../common/ExportColums";

class ReporteMoraDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Prestamos: [],
      currentPage: 1,
      starat: 0,
      endat: 10,
      total: 0,
      total_paginas: 0,
      totalPages: [],
      busqueda: "",
      Asesores: [],
      Carteras: [],

      name: "",
      email: "",
      cargo: "",
      pass1: "",
      pass2: "",

      asesor: "",
      cartera: "",
      filter: "",
      formapago: "",
      estado: "3",
      castigado: "",
      fechafin: FechaActual(),
      filer_dias_mora: 1,
      carga: null,
      perdida: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.filterPagina = this.filterPagina.bind(this);
    this.AutocompleteAsesores = this.AutocompleteAsesores.bind(this);
    this.AutocompleteCarteras = this.AutocompleteCarteras.bind(this);
    this.autocompleteRefAsesores = React.createRef();
    this.autocompleteRefCarteras = React.createRef();
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  AutocompleteAsesores(event) {
    this.setState({
      asesor: event,
    });
  }
  AutocompleteCarteras(event) {
    this.setState({
      cartera: event,
    });
  }

  cargarDatos(data) {
    this.setState({
      carga: 1,
    });
    fetch(reportesUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: UrlPost(data),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Prestamos: result.data,
          total: result.total,
          total_paginas: Math.ceil(result.total / this.state.endat),
          carga: null,
        });
      })
      .catch((error) => console.log("error", error));
  }

  CambiarPagina(page) {
    this.setState({ starat: page });
    page = page * this.state.endat;
    var data = this.DatosForm();
    data.starat = page;
    this.cargarDatos(data);
  }

  filterPagina(event) {
    this.BuscarDatos();
    event.preventDefault();
  }
  componentDidMount() {
    this.cargaInicial();
    //this.BuscarDatos();
  }

  DatosForm() {
    var data = {
      mora_detail: 1,
      starat: this.state.starat,
      endat: this.state.endat,
      asesor_id: this.state.asesor,
      caja_id: this.state.cartera,
      castigado: this.state.castigado,
      fecha_fin: this.state.fechafin,
      filer_dias_mora: this.state.filer_dias_mora,
      perdida: this.state.perdida,
    };
    return data;
  }

  BuscarDatos() {
    this.setState({
      Prestamos: [],
      total: 0,
      total_paginas: 0,
    });
    var data = this.DatosForm();
    this.cargarDatos(data);
  }
  LimpiarFormulario() {
    this.setState({
      Prestamos: [],
      total: 0,
      total_paginas: 0,
      fechafin: "",
      filter: "",
      formapago: "",
      estado: "TODOS",
      castigado: "",
      filer_dias_mora: 1,
      asesor: "",
      cartera: "",
    });

    this.autocompleteRefAsesores.current.clearAutocomplete();
    this.autocompleteRefCarteras.current.clearAutocomplete();
  }

  cargaInicial() {
    this.cargarUser();
    this.carterasUser();
  }
  cargarUser() {
    fetch(userUrl + "?estado=1", {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Asesores: result.data,
        });
      })
      .catch((error) => console.log("error", error));
  }

  carterasUser() {
    fetch(cajaUrl + "?estado=1", {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Carteras: result.data,
        });
      })
      .catch((error) => console.log("error", error));
  }

  DescargarDatos() {
    this.setState({
      carga: 1,
    });
    var data = this.DatosForm();
    data.endat = null;
    data.starat = null;

    fetch(reportesUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: UrlPost(data),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          exportToExcel(result.data, columnsMoraList, "Mora_Lista");
        }
        this.setState({
          carga: null,
        });
      })
      .catch((error) => console.log("error", error));
  }

  render() {
    const { Prestamos } = this.state;
    return (
      <div id="prestamos">
        {this.state.carga ? <Carga /> : null}
        <Menu />

        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/reporte-mora">
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          <div className="content-wrapper cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <h1>REPORTE MORA</h1>
                </div>
              </div>
            </section>

            <section className="row">
              <div className="col-9">
                <div className="row">
                  <div className="col-4">
                    <div>
                      <label>Fecha de Consulta:</label>
                      <input
                        type="date"
                        name="fechafin"
                        className="form-control"
                        onChange={this.handleChange}
                        value={this.state.fechafin}
                      ></input>
                    </div>
                  </div>
                  <div className="col-4">
                    <label>Castigados</label>
                    <select
                      className="form-control"
                      value={this.state.castigado}
                      name="castigado"
                      onChange={this.handleChange}
                    >
                      <option value={null}>Todos</option>
                      <option value={1}>Castigados</option>
                      <option value={0}>No castigados</option>
                    </select>
                  </div>
                  <div className="col-4">
                    <label>Dias de retraso:</label>
                    <input
                      className="form-control"
                      name="filer_dias_mora"
                      type="number"
                      placeholder="Dias de retraso"
                      required
                      onChange={this.handleChange}
                      value={this.state.filer_dias_mora}
                    ></input>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <label>Asesor:</label>
                    <Autocomplete
                      allOptions={this.state.Asesores}
                      onSelect={this.AutocompleteAsesores}
                      ref={this.autocompleteRefAsesores}
                    />
                  </div>
                  <div className="col-4">
                    <label>Cartera:</label>
                    <Autocomplete
                      allOptions={this.state.Carteras}
                      onSelect={this.AutocompleteCarteras}
                      ref={this.autocompleteRefCarteras}
                    />
                  </div>
                  <div className="col-4">
                    <label>Con perdida:</label>
                    <select
                      className="form-control"
                      name="perdida"
                      onChange={this.handleChange}
                      defaultValue={this.state.perdida}
                      required
                    >
                      <option></option>
                      <option value="SI" selected={"SI" === this.state.perdida}>
                        SI
                      </option>
                      <option value="NO" selected={"NO" === this.state.perdida}>
                        NO
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div>
                  <button
                    className="btn btn-info w-100 mb-1"
                    onClick={() => this.BuscarDatos()}
                  >
                    <SearchIcon />
                    Buscar
                  </button>
                  <button
                    className="btn btn-success w-100 mb-1"
                    onClick={() => this.DescargarDatos()}
                  >
                    <FileDownloadIcon /> Exportar
                  </button>
                  <button
                    className="btn btn-danger w-100 mb-1"
                    onClick={() => this.LimpiarFormulario()}
                  >
                    <CleaningServicesIcon />
                    Limpiar
                  </button>
                </div>
              </div>
            </section>
            <br></br>
            <section className="content-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead className="bg-info">
                    <tr>
                      <th>CÓDIGO</th>
                      <th>FECHA</th>
                      <th>CLIENTE</th>
                      <th>MONTO</th>
                      <th>PLAZO</th>
                      <th>MORA DÍAS</th>
                      <th>MONTO MORA</th>
                      <th>ASESOR</th>
                      <th>CARTERA</th>
                      <th>PERDIDA</th>
                      <th>ACCIONES</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Prestamos.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td className="text-center">{Datos.id}</td>
                        <td className="text-center">{Datos.ultimo_pago}</td>
                        <td className="text-center">
                          {Datos.name + " " + Datos.ape1 + " " + Datos.ape2}
                        </td>
                        <td className="text-center">{Datos.monto}</td>
                        <td className="text-center">
                          {Datos.cuotasr + "/" + Datos.cuotas}
                        </td>
                        <td className="text-center">{Datos.mora_dias}</td>
                        <td className="text-center">{Datos.mora_monto}</td>
                        <td className="text-center">{Datos.asesor_name}</td>
                        <td className="text-center">{Datos.caja_name}</td>
                        <td className="text-center">{Datos.perdida}</td>
                        <td className="text-center">
                          {Datos.estado === "3" || Datos.estado === "4" ? (
                            <Link
                              to={"/pagos/" + Datos.id}
                              className="btn btn-warning"
                              title="Pagos"
                            >
                              <PointOfSaleIcon />
                            </Link>
                          ) : null}
                          <a
                            href={
                              "https://altrisa.site/apiold/oldreport/prestamos/descargarcronograma.php?id_pres=" +
                              Datos.id
                            }
                            target="_blank"
                            rel="noopener"
                            className="btn btn-success"
                            title="Cronograma"
                          >
                            <ArticleIcon />
                          </a>
                          <Link
                            to={"/prestamo/" + Datos.id}
                            className="btn btn-info"
                            title="detalle de prestamo"
                          >
                            <RemoveRedEyeIcon />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={
                      this.state.starat === 0
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      onClick={() => this.CambiarPagina(this.state.starat - 1)}
                    >
                      <NavigateBeforeIcon />
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link">
                      {this.state.starat + 1 + "/" + this.state.total_paginas}
                    </a>
                  </li>
                  <li
                    className={
                      this.state.starat === this.state.total_paginas - 1
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      onClick={() => this.CambiarPagina(this.state.starat + 1)}
                    >
                      <NavigateNextIcon />
                    </a>
                  </li>
                </ul>
              </nav>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default ReporteMoraDetail;
