import React from "react";
import Aside from "../comp/Aside";
import Footer from "../comp/Footer";
import Navbar from "../comp/Navbar";
import { Link } from "react-router-dom";
import api from "../servicios/api";
import "../css/table.css";

class NewPres extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      p_new: 0,
      p_ren: 0,
      p_amp: 0,
      p_par: 0,
      p_ref: 0,

      m_new: 0,
      m_ren: 0,
      m_amp: 0,
      m_par: 0,
      m_ref: 0,

      Pres_Ase: [],
      Pres_Ase_Monto: [],
      Pres_Caja: [],
      Pres_Caja_Monto: [],
    };
  }

  cargarDatos() {

    fetch(api + "Reportes/new_prestamos.php?total_tip=0")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta);
        this.setState({
          p_new: datosRespuesta.data[0].total,
          p_ren: datosRespuesta.data[1].total,
          p_amp: datosRespuesta.data[2].total,
          p_par: datosRespuesta.data[3].total,
          p_ref: datosRespuesta.data[4].total,

          m_new: datosRespuesta.data[0].monto,
          m_ren: datosRespuesta.data[1].monto,
          m_amp: datosRespuesta.data[2].monto,
          m_par: datosRespuesta.data[3].monto,
          m_ref: datosRespuesta.data[4].monto,
        });
        //console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  cargarPresAsesorTip() {
    fetch(api + "Reportes/new_prestamos.php?tip_and_as=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ Pres_Ase: datosRespuesta.data });
      })
      .catch(console.log);
  }

  cargarDatosMontos() {
    fetch(api + "Reportes/new_prestamos.php?tip_and_as_monto=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ Pres_Ase_Monto: datosRespuesta.data });
      })
      .catch(console.log);
  }

  cargarDatosCaja() {
    fetch(api + "Reportes/new_prestamos.php?tip_and_ca=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta.data)
        this.setState({ Pres_Caja: datosRespuesta.data });
      })
      .catch(console.log);
  }

  cargarDatosCajaMonto() {
    fetch(api + "Reportes/new_prestamos.php?tip_and_ca_monto=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({ Pres_Caja_Monto: datosRespuesta.data });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarPresAsesorTip();
    this.cargarDatosMontos();
    this.cargarDatosCaja();
    this.cargarDatosCajaMonto();
  }

  render() {
    const {
      p_new,
      p_ren,
      p_amp,
      p_par,
      p_ref,
      Pres_Ase,
      m_new,
      m_ren,
      m_amp,
      m_par,
      m_ref,
      Pres_Ase_Monto,
      Pres_Caja,
      Pres_Caja_Monto,
    } = this.state;
    console.log(Pres_Caja);
    const currency = function (number) {
      return new Intl.NumberFormat("es-PE", {
        style: "currency",
        currency: "PEN",
        minimumFractionDigits: 2,
      }).format(number);
    };
    var m_new2 = currency(m_new);
    var m_ren2 = currency(m_ren);
    var m_amp2 = currency(m_amp);
    var m_par2 = currency(m_par);
    var m_ref2 = currency(m_ref);

    //console.log(Pres_Ase);
    return (
      <div>
        <Navbar />
        <Aside />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>PRESTAMOS EN ESTE MES</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <Link to="/nuevos-prestamos/0">
                    <div
                      className="card text-white bg-primary mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-header">NUEVO</div>
                      <div className="card-body">
                        <h2 className="">{p_new}</h2>
                        <p className="card-text">
                          {" "}
                          <i>{m_new2}</i>{" "}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col">
                  <Link to="/nuevos-prestamos/1">
                    <div
                      className="card text-white bg-primary mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-header">RENOVACIÓN</div>
                      <div className="card-body">
                        <h2 className="">{p_ren}</h2>
                        <p className="card-text">
                          <i>{m_ren2}</i>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col">
                  <Link to="/nuevos-prestamos/2">
                    <div
                      className="card text-white bg-primary mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-header">AMPLIACIÓN</div>
                      <div className="card-body">
                        <h2 className="">{p_amp}</h2>
                        <p className="card-text">
                          <i>{m_amp2}</i>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col">
                  <Link to="/nuevos-prestamos/3">
                    <div
                      className="card text-white bg-primary mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-header">PARALELO</div>
                      <div className="card-body">
                        <h2 className="">{p_par}</h2>
                        <p className="card-text">
                          <i>{m_par2}</i>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col">
                  <Link to="/nuevos-prestamos/4">
                    <div
                      className="card text-white bg-primary mb-3"
                      style={{ maxWidth: "18rem" }}
                    >
                      <div className="card-header">REFINANCIAMIENTO</div>
                      <div className="card-body">
                        <h2 className="">{p_ref}</h2>
                        <p className="card-text">
                          <i>{m_ref2}</i>
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="cont">
                <div className="report-table">
                  <div className="table table-responsive">
                    <table className="table">
                      <h2>NÚMERO DE PRESTAMOS EN ESTE MES</h2>
                      <tbody>
                        {Pres_Ase.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.asesor}</td>
                            <td>{Datos.NUEVO}</td>
                            <td>{Datos.RENOVACIÓN}</td>
                            <td>{Datos.AMPLIACIÓN}</td>
                            <td>{Datos.PARALELO}</td>
                            <td>{Datos.REFINANCIAMIENTO}</td>
                            <td>{Datos.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="cont">
                <div className="report-table">
                  <div className="table table-responsive">
                    <table className="table">
                      <h2>MONTO DE PRESTAMOS EN ESTE MES</h2>
                      <tbody>
                        {Pres_Ase_Monto.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.asesor}</td>
                            <td>{Datos.NUEVO}</td>
                            <td>{Datos.RENOVACIÓN}</td>
                            <td>{Datos.AMPLIACIÓN}</td>
                            <td>{Datos.PARALELO}</td>
                            <td>{Datos.REFINANCIAMIENTO}</td>
                            <td>{currency(Datos.total)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="cont">
                <div className="report-table">
                  <div className="table table-responsive">
                    <table className="table">
                      <h2>NÚMERO DE PRESTAMOS EN ESTE MES</h2>
                      <tbody>
                        {Pres_Caja.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.asesor}</td>
                            <td>{Datos.NUEVO}</td>
                            <td>{Datos.RENOVACIÓN}</td>
                            <td>{Datos.AMPLIACIÓN}</td>
                            <td>{Datos.PARALELO}</td>
                            <td>{Datos.REFINANCIAMIENTO}</td>
                            <td>{Datos.total}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="content">
            <div className="container-fluid">
              <div className="cont">
                <div className="report-table">
                  <div className="table table-responsive">
                    <table className="table">
                      <h2>MONTO DE PRESTAMOS EN ESTE MES</h2>
                      <tbody>
                        {Pres_Caja_Monto.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.asesor}</td>
                            <td>{Datos.NUEVO}</td>
                            <td>{Datos.RENOVACIÓN}</td>
                            <td>{Datos.AMPLIACIÓN}</td>
                            <td>{Datos.PARALELO}</td>
                            <td>{Datos.REFINANCIAMIENTO}</td>
                            <td>{currency(Datos.total)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}

export default NewPres;
