import React from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../servicios/api";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "../../css/table.css";
import Menu from "../../comp/Menu";

class PagosOtrosList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
    };
  }

  cargarDatos() {
    fetch(api + "PagosOtros/")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({ Data: datosRespuesta.data });
        }
        console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  Evaluar = (id) => {
    Swal.fire({
      title: "Evalución de Prestamo",
      input: "select",
      inputOptions: {
        SRB: "Serbia",
        UKR: "Ukraine",
        HRV: "Croatia",
      },
      inputPlaceholder: "Seleciona un Asesor",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Aprobar",
      denyButtonText: `Desaprobar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        //Cerrar Sesion
        Swal.fire("Aprobado!", "Felicidades, aprobo este credito.", "success");
        console.log("aprobado" + id);
      } else if (result.isDenied) {
        Swal.fire("Desaprobado!", "Quizá la proxima.", "warning");
        console.log("Desaprobado" + id);
      }

      this.cargarDatos();
    });
  };

  BorrarRegistro = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí, bórralo!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          fetch(api + "PagosOtros/?borrar=" + id)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              //console.log(datosRespuesta);
              this.cargarDatos();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "¡Eliminado!",
            "Su archivo ha sido eliminado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Tu archivo está a salvo :)",
            "error"
          );
        }
      });

    this.cargarDatos();
  };

  render() {
    const { Data } = this.state;
    return (
      <div>
        <Menu />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>PAGOS OTROS</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <Link className="nav-link active" to="/pagos-otros-list">
                        <AssignmentIcon />
                        Pagos
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/pagos-otros">
                        <PersonAddAltIcon />
                        Agregar
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="cont">
                  <div className="table-responsive cont-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Fecha</th>
                          <th>Capital</th>
                          <th>Interes</th>
                          <th>Cliente</th>
                          <th>Asesor</th>
                          <th>Caja</th>
                          <th>Eliminar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Data.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td>{Datos.fecha}</td>
                            <td>{Datos.capital}</td>
                            <td>{Datos.interes}</td>
                            <td>{Datos.cli_name}</td>
                            <td>{Datos.ase_name}</td>
                            <td>{Datos.caja_name}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-warning"
                                onClick={() => this.BorrarRegistro(Datos.id)}
                              >
                                <DeleteForeverIcon />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default PagosOtrosList;
