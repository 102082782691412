import React from "react";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import "../../css/users.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { userUrl, apiToken, UrlPost, UrlHeader } from "../../common/Function";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SearchIcon from "@mui/icons-material/Search";
import { RespuestasConsultas } from "../Common/utils";
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import Swal from 'sweetalert2';
class Usuarios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Usuarios: [],
      TipoUsuarios: [],
      currentPage: 1,
      starat: 0,
      endat: 8,
      total: 0,
      total_paginas: 0,
      totalPages: [],
      busqueda: "",
      estado: 1,
      name: "",
      dni: "",
      email: "",
      cargo: "",
      pass1: "",
      pass2: "",
      error: null,
      wait: null,
      success: null,
      msg: "",
      selectedUserId: null,
      selectedUser: {
        dni: "",
        name: "",
        apellido1: "",
        apellido2: "",
        cargo: "",
        phone: "",
        birthday: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.filterPagina = this.filterPagina.bind(this);
    this.handleEditInputChange = this.handleEditInputChange.bind(this);
  }

  selectUserForEdit(user) {
    const selectedCargo = this.state.TipoUsuarios.find((cargo) => cargo.name === user.name_tip);
    this.setState({
      selectedUserId: user.id,
      selectedUser: {
        dni: user.dni,
        name: user.name,
        cargo: selectedCargo.id,
        phone: user.phone,
        apellido1: user.apellido1,
        apellido2: user.apellido2,
        birthday: user.cumple
      },
    });
  };

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleEditInputChange(event) {
    const target = event.target;
    const t_name = target.name;

    this.setState((prevState) => ({
      selectedUser: {
        ...prevState.selectedUser,
        [t_name]: target.value,
      },
    }));
  };


  Actualizar() {

    const { selectedUser } = this.state;

    let vardata = {
      actualizar: 1,
      id: this.state.selectedUserId,
      name: selectedUser.name,
      ape1: selectedUser.apellido1,
      ape2: selectedUser.apellido2,
      dni: selectedUser.dni,
      phone: selectedUser.phone,
      typeUser: selectedUser.cargo,
      birthday: selectedUser.birthday
    }

    var requestOptions = {
      method: 'POST',
      headers: UrlHeader(),
      body: UrlPost(vardata),
      redirect: 'follow'
    };

    fetch(userUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            success: true,
            error: null,
            wait: null,
            msg: result.msg,
          });

          Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: 'Sus datos fueron actualizados correctamente',
          });

          this.cargarDatos(this.state.starat, this.state.busqueda, this.state.estado);
        } else {
          this.setState({
            success: null,
            error: true,
            wait: null,
            msg: result.msg,
          });
        }
      })
      .catch((error) => {
        this.setState({
          success: null,
          error: true,
          wait: null,
          msg: 'Error al enviar la solicitud de actualización.',
        });
        console.error('Error en la solicitud POST:', error);
      });
  }

  cargarDatos(pagine, filter, estado) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", apiToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    var BeUrl = userUrl + "?starat=" + pagine + "&endat=" + this.state.endat;
    if (filter !== null && filter !== "") {
      BeUrl =
        BeUrl +
        "&filter=" +
        filter;
    }
    if (estado !== null && estado !== "") {
      BeUrl =
        BeUrl +
        "&estado=" +
        estado;
    }



    console.log(BeUrl)

    fetch(BeUrl, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Usuarios: result.data,
          total: result.total,
          total_paginas: Math.ceil(result.total / this.state.endat),
        });
        console.log(result)
        this.NumeroPaginas(Math.ceil(result.total / this.state.endat));
      })
      .catch((error) => console.log("error", error));
  }

  NumeroPaginas(numPages) {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push({ id: i - 1, name: i });
    }
    this.setState({
      totalPages: pages,
    });
  }

  CambiarPagina(page) {

    this.setState({ starat: page });
    page = page * this.state.endat;
    this.cargarDatos(page, this.state.busqueda, this.state.estado);
  }

  filterPagina(event) {
    const busquedaEnviar = this.state.busqueda === "" ? null : this.state.busqueda;
    const estadoEnviar = this.state.estado === "" ? null : this.state.estado;

    this.cargarDatos(0, busquedaEnviar, estadoEnviar);

    event.preventDefault();
  }


  CambiarEstado(estado, id) {
    Swal.fire({
      title: 'Cambiar estado',
      text: '¿Estás seguro de que deseas cambiar el estado?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append('actualizar', '1');
        formData.append('id', id);
        formData.append('state', estado);

        fetch(userUrl, {
          method: 'POST',
          headers: {
            Authorization: apiToken,
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.success === true) {
              this.setState({
                success: true,
                error: null,
                wait: null,
                msg: result.msg,
              });
              this.cargarDatos(this.state.starat, this.state.busqueda, this.state.estado);
            } else {
              this.setState({
                success: null,
                error: true,
                wait: null,
                msg: result.msg,
              });
            }
          })
          .catch((error) => {
            this.setState({
              success: null,
              error: true,
              wait: null,
              msg: 'Error al enviar la solicitud de cambiar estado.',
            });
            console.error('Error en la solicitud POST:', error);
          });
      } else {
      }
    });
  }

  CargarTipUser() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", apiToken);

    var formdata = new FormData();
    formdata.append("tipos_user", "1");

    fetch(userUrl, {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          TipoUsuarios: result.data,
        });
      })
      .catch((error) => console.log("error", error));
  }

  Registrar() {
    this.setState({
      error: null,
      wait: true,
      success: null,
      msg: "",
    });
    var myHeaders = new Headers();
    myHeaders.append("Authorization", apiToken);

    var formdata = new FormData();
    formdata.append("name", this.state.name);
    formdata.append("dni", this.state.dni);
    formdata.append("email", this.state.email);
    formdata.append("pass1", this.state.pass1);
    formdata.append("pass2", this.state.pass2);
    formdata.append("bossId", "3");
    formdata.append("typeUser", this.state.cargo);
    formdata.append("walletId", "1");

    formdata.append("apellido1", "");
    formdata.append("apellido2", "");
    formdata.append("phone", "");
    formdata.append("address", "");
    formdata.append("token", "5645645646");
    formdata.append("branch", "");
    formdata.append("birthday", "");

    fetch(userUrl, {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            error: null,
            wait: null,
            success: true,
            msg: result.msg,
          });
        } else {
          this.setState({
            error: true,
            wait: null,
            success: null,
            msg: result.msg,
          });
        }
      })
      .catch((error) => console.log("error", error));
    this.cargarDatos(this.state.starat, this.state.busqueda, this.state.estado);
  }
  componentDidMount() {
    this.cargarDatos(this.state.starat, this.state.busqueda, this.state.estado);
    this.CargarTipUser();
  }
  render() {
    const { Usuarios, totalPages, TipoUsuarios } = this.state;

    return (
      <div id="Users">
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/home">
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          <button
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#AgregarUsuario"
          >
            <PersonAddAltIcon />
            Agregar Usuario
          </button>
          <div className="content-wrapper cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <h1>USUARIOS</h1>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div>
                  <div className="table-responsive">
                    <div className="barra-busqueda">
                      <form className="containerInput">
                        <input
                          type="search"
                          placeholder="Búsqueda por Nombre o DNI."
                          className="form-control inputBuscar"
                          name="busqueda"
                          onChange={this.handleChange}
                          value={this.state.busqueda}
                        />
                        <select
                          className="form-control"
                          name="estado"
                          onChange={this.handleChange}
                          value={this.state.estado}
                        >
                          <option value="">Todos</option>
                          <option value="1">Activos</option>
                          <option value="0">Inactivos</option>
                        </select>
                        <button className="btn btn-success" onClick={this.filterPagina}>
                          <SearchIcon />
                        </button>
                      </form>
                    </div>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th className="text-center">Apellidos y Nombres</th>
                          <th className="text-center">DNI</th>
                          <th className="text-center">Telefono</th>
                          <th className="text-center">Email</th>
                          <th className="text-center">cumpleaños</th>
                          <th className="text-center">Cargo</th>
                          <th className="text-center">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Usuarios.map((Datos) => (
                          <tr className="text-center" key={Datos.id}>
                            <td className="text-center">
                              {Datos.name} <br />
                              {Datos.apellido1} {Datos.apellido2}
                            </td>
                            <td className="text-center">{Datos.dni}</td>
                            <td className="text-center">{Datos.phone}</td>
                            <td className="text-center">{Datos.email}</td>
                            <td className="text-center">{Datos.cumple}</td>
                            <td className="text-center">{Datos.name_tip}</td>
                            <td className="text-center">
                              {Datos.estado === "1" ? (
                                <div
                                  className="btn btn-success"
                                  onClick={() => this.CambiarEstado("0", Datos.id)}
                                >
                                  <CheckIcon />
                                </div>
                              ) : (
                                <div
                                  className="btn btn-danger"
                                  onClick={() => this.CambiarEstado("1", Datos.id)}
                                >
                                  <BlockIcon />
                                </div>
                              )}
                              <div
                                data-toggle="modal"
                                data-target="#EditarUsuario"
                                className="btn btn-warning m-2"
                                title="Editar"
                                onClick={() => this.selectUserForEdit(Datos)}
                              >
                                <EditIcon />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                      <li
                        className={
                          this.state.starat === 0
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          onClick={() =>
                            this.CambiarPagina(this.state.starat - 1)
                          }
                        >
                          <NavigateBeforeIcon />
                        </a>
                      </li>
                      {totalPages.map((Datos) => (
                        <li
                          className={
                            Datos.id === this.state.starat
                              ? "page-item active"
                              : "page-item"
                          }
                          key={Datos.id}
                        >
                          <a
                            className="page-link"
                            onClick={() => this.CambiarPagina(Datos.id)}
                          >
                            {Datos.name}
                          </a>
                        </li>
                      ))}
                      <li
                        className={
                          this.state.starat === this.state.total_paginas - 1
                            ? "page-item disabled"
                            : "page-item"
                        }
                      >
                        <a
                          className="page-link"
                          onClick={() =>
                            this.CambiarPagina(this.state.starat + 1)
                          }
                        >
                          <NavigateNextIcon />
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </section>
          </div>
        </div>
        {/*----------------MODAL AGREGAR USUARIO--------------------*/}
        <div
          className="modal fade"
          id="AgregarUsuario"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="AgregarUsuarioTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="AgregarUsuarioTitle">
                  Agregar Usuario
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="row formx">
                  <div className="col">
                    <label>DNI</label>
                    <input
                      type="number"
                      name="dni"
                      placeholder="DNI"
                      className="form-control"
                      required
                      onChange={this.handleChange}
                      value={this.state.dni}
                    />
                    <label>NOMBRE</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Nombre"
                      className="form-control"
                      required
                      onChange={this.handleChange}
                      value={this.state.name}
                    />
                    <label>CONTRASEÑA</label>
                    <input
                      type="password"
                      name="pass1"
                      placeholder="Contraseña"
                      className="form-control"
                      required
                      onChange={this.handleChange}
                      value={this.state.pass1}
                    />
                  </div>
                  <div className="col">
                    <label>CARGO</label>
                    <select
                      className="form-control"
                      required
                      name="cargo"
                      onChange={this.handleChange}
                      value={this.state.cargo}
                    >
                      <option value="">Seleccione un Cargo</option>
                      {TipoUsuarios.map((Datos) => (
                        <option value={Datos.id} key={Datos.id}>
                          {Datos.name}
                        </option>
                      ))}
                    </select>

                    <label>EMAIL</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Correo Electronico"
                      className="form-control"
                      required
                      onChange={this.handleChange}
                      value={this.state.email}
                    />
                    <label>VERIFICAR CONTRASEÑA</label>
                    <input
                      type="password"
                      name="pass2"
                      placeholder="Verificar contraseña"
                      className="form-control"
                      required
                      onChange={this.handleChange}
                      value={this.state.pass2}
                    />
                  </div>
                  <div className="fbtnx">
                    <RespuestasConsultas
                      error={this.state.error}
                      success={this.state.success}
                      wait={this.state.wait}
                      msg={this.state.msg}
                    />
                    <div
                      className="btn btn-primary"
                      onClick={() => this.Registrar()}
                    >
                      Añadir
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/*----------------MODAL EDITAR USUARIO--------------------*/}
        <div
          className="modal fade"
          id="EditarUsuario"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="EditarUsuarioTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="EditarUsuarioTitle">
                  Editar Usuario
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form">
                  <div>
                    <label>DNI</label>
                    <input
                      type="number"
                      name="dni"
                      placeholder="DNI"
                      className="form-control"
                      required
                      onChange={this.handleEditInputChange}
                      value={this.state.selectedUser.dni}
                    />
                    <label>NOMBRE</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Nombre"
                      className="form-control"
                      required
                      onChange={this.handleEditInputChange}
                      value={this.state.selectedUser.name}
                    />
                    <label>PRIMER APELLIDO</label>
                    <input
                      type="text"
                      name="apellido1"
                      placeholder="Primer Apellido"
                      className="form-control"
                      required
                      onChange={this.handleEditInputChange}
                      value={this.state.selectedUser.apellido1}
                    />
                    <label>SEGUNDO APELLIDO</label>
                    <input
                      type="text"
                      name="apellido2"
                      placeholder="Segundo Apellido"
                      className="form-control"
                      required
                      onChange={this.handleEditInputChange}
                      value={this.state.selectedUser.apellido2}
                    />
                    <label>CUMPLEAÑOS</label>
                    <input
                      type="date"
                      name="birthday"
                      placeholder="Cumpleaños"
                      className="form-control"
                      required
                      onChange={this.handleEditInputChange}
                      value={this.state.selectedUser.birthday !== null ? this.state.selectedUser.birthday : ''}
                    />

                    <label>NUMERO</label>
                    <input
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      className="form-control"
                      required
                      onChange={this.handleEditInputChange}
                      value={this.state.selectedUser.phone}
                    />
                    <label>CARGO</label>
                    <select
                      className="form-control"
                      required
                      name="cargo"
                      onChange={this.handleEditInputChange}
                      value={this.state.selectedUser.cargo}
                    >
                      {TipoUsuarios.map((Datos) => (
                        <option value={Datos.id} key={Datos.id}>
                          {Datos.name}
                        </option>
                      ))}
                    </select>

                  </div>
                  <button type="submit" className="btn btn-primary" onClick={() => this.Actualizar()} data-dismiss="modal">Actualizar</button>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
        {/*--------------------------------------------*/}
      </div>
    );
  }
}

export default Usuarios;
