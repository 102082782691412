import React from "react";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../../css/users.css";
import {
  UrlHeader,
  UserCod,
  juntaPlanUrl,
  UrlGet,
} from "../../common/Function";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { exportToExcel } from "../Common/MaterialExportToExel";
import { columnsJuntaPlanList } from "../../common/ExportColums";
import { tiposUsuariosPermitidos } from "../Common/utils";
class JuntaPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Datos: [],
      currentPage: 1,
      starat: 0,
      endat: 5,
      total: 0,
      total_paginas: 0,
      totalPages: [],
      busqueda: "",
      user_id: UserCod(),
      estado: "ACTIVOS",
      tipoUsuario: localStorage.getItem("TIPUSER"),
    };
    this.handleChange = this.handleChange.bind(this);
    this.filterPagina = this.filterPagina.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  cargarDatos(pagine, filter) {
    var fitlerUrl = {
      starat: pagine,
      endat: this.state.endat,
      user_id: this.state.user_id,
      cantidad: this.state.estado,
    };
    if (filter !== null) {
      fitlerUrl.filter = filter;
    }
    fetch(juntaPlanUrl + UrlGet(fitlerUrl), {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Datos: result.data,
          total: result.total,
          total_paginas: Math.ceil(result.total / this.state.endat),
        });
        this.NumeroPaginas(Math.ceil(result.total / this.state.endat));
      })
      .catch((error) => console.log("error", error));
  }

  componentDidMount() {
    this.cargarDatos(this.state.starat, null);
  }

  NumeroPaginas(numPages) {
    const pages = [];
    for (let i = 1; i <= numPages; i++) {
      pages.push({ id: i - 1, name: i });
    }
    this.setState({
      totalPages: pages,
    });
  }

  CambiarPagina(page) {
    this.setState({ starat: page });
    page = page * this.state.endat;
    this.cargarDatos(page, this.state.busqueda);
  }

  filterPagina(event) {
    if (this.state.busqueda === "") {
      this.cargarDatos(0, null);
    } else {
      this.cargarDatos(0, this.state.busqueda);
    }
    event.preventDefault();
  }

  LimpiarFormulario() {
    this.setState({
      busqueda: "",
      estado: "ACTIVOS",
    });
  }

  DatosForm() {
    var data = {
      starat: this.state.starat,
      endat: this.state.endat,
      user_id: this.state.user_id,
      cantidad: this.state.estado,
    };

    if (this.state.busqueda !== null || this.state.busqueda !== "") {
      data.filter = this.state.busqueda;
    }

    return data;
  }

  DescargarDatos() {
    this.setState({
      carga: 1,
    });
    var data = this.DatosForm();
    data.endat = null;
    data.starat = null;

    fetch(juntaPlanUrl + UrlGet(data), {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          exportToExcel(result.data, columnsJuntaPlanList, "JuntaPlan_Lista");
        }
        this.setState({
          carga: null,
        });
      })
      .catch((error) => console.log("error", error));
  }

  render() {
    const { Datos } = this.state;

    return (
      <div id="juntaPlan">
        <Menu />
        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/home">
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          {tiposUsuariosPermitidos.btnJuntaPlanAgregar.includes(
            this.state.tipoUsuario
          ) && (
            <Link className="btn btn-primary" to={"/junta-plan-agregar"}>
              <PersonAddAltIcon />
              Agregar Junta Plan
            </Link>
          )}
          <div className="content-wrapper cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <h1>JUNTA PLAN</h1>
                </div>
              </div>
            </section>
            <section className="content">
              <div className="container-fluid">
                <div className="table-responsive table-stilos">
                  <section className="row">
                    <div className="col-9">
                      <div className="row">
                        <div className="col-4">
                          <input
                            type="search"
                            placeholder="Búsqueda por Nombre o DNI."
                            className="form-control inputBuscar"
                            name="busqueda"
                            onChange={this.handleChange}
                            value={this.state.busqueda}
                          ></input>
                        </div>
                        <div className="col-4">
                          <select
                            className="form-control"
                            required
                            onChange={this.handleChange}
                            name="estado"
                            value={this.state.estado}
                          >
                            <option value="ACTIVOS">ACTIVOS</option>
                            <option value="INACTIVOS">INACTIVOS</option>
                            <option value="TODOS">TODOS</option>
                          </select>
                        </div>
                        <div className="col-4"></div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div>
                        <button
                          className="btn btn-info w-100 mb-1"
                          onClick={this.filterPagina}
                        >
                          <SearchIcon />
                          Buscar
                        </button>
                        {tiposUsuariosPermitidos.btnJuntaPlanExportar.includes(
                          this.state.tipoUsuario
                        ) && (
                          <button
                            className="btn btn-success w-100 mb-1"
                            onClick={() => this.DescargarDatos()}
                          >
                            <FileDownloadIcon /> Exportar
                          </button>
                        )}
                        <button
                          className="btn btn-danger w-100 mb-1"
                          onClick={() => this.LimpiarFormulario()}
                        >
                          <CleaningServicesIcon />
                          Limpiar
                        </button>
                      </div>
                    </div>
                  </section>
                  <table className="table table-striped my-4">
                    <thead className="bg-info">
                      <tr>
                        <td>Apellidos y Nombres</td>
                        <td>DNI</td>
                        <td>CAJA</td>
                        <td>ASESOR</td>
                        <td>MONTO</td>
                        <td>TIPO</td>
                        <td>PLAN</td>
                        <td>CUOTAS</td>
                        <td>Acciones</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Datos.map((Datos) => (
                        <tr className="text-center" key={Datos.id}>
                          <td>
                            {Datos.ape1Client} {Datos.ap2Client}{" "}
                            {Datos.nameClient}
                          </td>
                          <td>{Datos.dniClient}</td>
                          <td>{Datos.nameCaja}</td>
                          <td>
                            {Datos.nameUser} {Datos.ape1User} {Datos.ape2User}
                          </td>
                          <td>s/ {Datos.monto}</td>
                          <td>{Datos.tipo}</td>
                          <td>{Datos.plan ? Datos.plan : 0}</td>
                          <td>
                            {Datos.cuotasCount}/
                            {Datos.coutas ? Datos.coutas : 0}
                          </td>
                          <td>
                            <Link
                              to={"/juntaplan-ver/" + Datos.id}
                              className="btn btn-primary m-2"
                            >
                              <RemoveRedEyeIcon />
                            </Link>
                            {tiposUsuariosPermitidos.btnJuntaPlanExportar.includes(
                              this.state.tipoUsuario
                            ) && (
                              <Link
                                to={"/juntaplan-editar/" + Datos.id}
                                className="btn btn-warning m-2"
                              >
                                <EditIcon />
                              </Link>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li
                      className={
                        this.state.starat === 0
                          ? "page-item disabled"
                          : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        onClick={() =>
                          this.CambiarPagina(this.state.starat - 1)
                        }
                      >
                        <NavigateBeforeIcon />
                      </a>
                    </li>
                    <li className="page-item">
                      <a className="page-link">
                        {this.state.starat + 1 + "/" + this.state.total_paginas}
                      </a>
                    </li>
                    <li
                      className={
                        this.state.starat === this.state.total_paginas - 1
                          ? "page-item disabled"
                          : "page-item"
                      }
                    >
                      <a
                        className="page-link"
                        onClick={() =>
                          this.CambiarPagina(this.state.starat + 1)
                        }
                      >
                        <NavigateNextIcon />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </section>
          </div>
        </div>
        {/*----------------MODAL EDITAR USUARIO--------------------*/}
        <div className="modal fade" id="AgregarJuntaPlan">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Agregar Junta Plan</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Aquí puedes agregar contenido personalizado para el modal.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Cerrar
                </button>
                <button type="button" className="btn btn-primary">
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*--------------------------------------------*/}
      </div>
    );
  }
}

export default JuntaPlan;
