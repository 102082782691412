import React from "react";
import { UserCod, userUrl, apiToken } from "../../common/Function";
import Carga from "../Common/Carga";
import Login from "./Login";
import Home from "./Home";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sesion: null,
      carga: null,
    };
    this.CargarPagina = this.CargarPagina.bind(this);
  }

  CargarPagina() {
    var sessionId = UserCod();
    if (sessionId !== null) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", apiToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(userUrl + sessionId, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            this.setState({
              sesion: 1,
            });
            sessionStorage.setItem("name", result.data.name);
            sessionStorage.setItem("ape1", result.data.ape2);
            sessionStorage.setItem("dni", result.data.dni);
            sessionStorage.setItem("email", result.data.email);
            sessionStorage.setItem("phone", result.data.phone);
          } else {
            this.setState({
              sesion: null,
            });
          }
        })
        .catch((error) => console.log("error", error));
    }
  }

  componentDidMount() {
    this.CargarPagina();
    setTimeout(() => {
      this.setState({
        carga: 1,
      });
    }, 300);
  }

  render() {
    return (
      <div>
        {this.state.carga ? this.state.sesion ? (
          <Home />
        ) : (
          <Login cargarPagina={this.CargarPagina} />
        ) : <div className="content"><Carga /></div>}
      </div>
    );
  }
}

export default LoginPage;
