import CryptoJS from "crypto-js";

//const baseUrl = "http://localhost/BE-Altrisa";
const baseUrl = "https://altrisa.site/api";
export const baseUrlOld = "https://altrisa.site/apiold";
export const api = baseUrl;
export const userUrl = `${baseUrl}/User/`;
export const juntaUrl = `${baseUrl}/Junta/`;
export const juntaPlanUrl = `${baseUrl}/JuntaPlan/`;
export const prestamosUrl = `${baseUrl}/Prestamos/`;
export const pagosOtrosUrl = `${baseUrl}/PagosOtros/`;
export const sessionUrl = `${baseUrl}/Session/`;
export const reportesUrl = `${baseUrl}/Reportes/`;
export const clienteUrl = `${baseUrl}/Client/`;
export const reniecUrl = `${baseUrl}/Reniec/`;
export const pagosUrl = `${baseUrl}/Pagos/`;
export const historialPagosUrl = `${baseUrl}/HistorialPagos/`;
export const cuadreDiarioUrl = `${baseUrl}/CuadreDiario/`;
export const resumenDiarioUrl = `${baseUrl}/Resumen/`;
export const cajaUrl = `${baseUrl}/Caja/`;
export const apiToken = "7W3zeGQ*ByKzBFyEL6LEVfqDi*Ut/CcEvhRD+2qpTKd+3icJ[p";

export function UserCod() {
  var id_sesion = null;
  if (localStorage.getItem("IDSESSION") !== null) {
    id_sesion = localStorage.getItem("IDSESSION");
    const bytes = CryptoJS.AES.decrypt(id_sesion, "my-secret-key");
    id_sesion = bytes.toString(CryptoJS.enc.Utf8);
  }
  return id_sesion;
}
export function MonedaPen(monto) {
  if (monto === null) {
    return "0.00";
  }

  var valorEnSoles = parseFloat(monto);
  if (isNaN(valorEnSoles)) {
    return "0.00";
  }

  var formatoMoneda = valorEnSoles.toLocaleString("es-PE", {
    style: "currency",
    currency: "PEN",
  });
  return formatoMoneda;
}

export function FechaActual() {
  const fechaActual = new Date();
  const anio = fechaActual.getFullYear();
  const mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0");
  const dia = fechaActual.getDate().toString().padStart(2, "0");
  const fechaEnFormato = `${anio}-${mes}-${dia}`;

  return fechaEnFormato;
}

export function HoraActual() {
  const fechaActual = new Date();
  const horas = fechaActual.getHours().toString().padStart(2, "0");
  const minutos = fechaActual.getMinutes().toString().padStart(2, "0");
  const segundos = fechaActual.getSeconds().toString().padStart(2, "0");
  const horaActual = `${horas}:${minutos}:${segundos}`;

  return horaActual;
}

export function FechaActualCompleta() {
  const fechaActual = new Date();
  const anio = fechaActual.getFullYear();
  const mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0");
  const dia = fechaActual.getDate().toString().padStart(2, "0");
  const horas = fechaActual.getHours().toString().padStart(2, "0");
  const minutos = fechaActual.getMinutes().toString().padStart(2, "0");
  const segundos = fechaActual.getSeconds().toString().padStart(2, "0");
  const fechaEnFormato = `${anio}-${mes}-${dia} ${horas}:${minutos}:${segundos}`;

  return fechaEnFormato;
}
export function UrlGet(data) {
  const data2 = {};
  for (const prop in data) {
    if (data.hasOwnProperty(prop) && data[prop] !== null && data[prop] !== "") {
      data2[prop] = data[prop];
    }
  }
  const searchParams = new URLSearchParams(data2);
  const queryString = "?" + searchParams.toString();
  return queryString;
}
export function UrlPost(datosEnviar) {
  const formdata = new FormData();
  for (const key in datosEnviar) {
    if (datosEnviar[key] !== null && datosEnviar[key] !== "") {
      formdata.append(key, datosEnviar[key]);
    }
  }
  return formdata;
}

export function UrlHeader() {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", apiToken);
  return myHeaders;
}
export function MisDatos() {
  var id_user = UserCod();
  var retornar = {};
  fetch(userUrl + id_user, {
    method: "GET",
    headers: UrlHeader(),
    redirect: "follow",
  })
    .then((response) => response.json())
    .then((result) => {
      if (result.success === true) {
        return result.data;
      }
    })
    .catch((error) => console.log("error", error));
  return retornar;
}
export function OrdenarPorFecha(array) {
  array.sort(function (a, b) {
    const dateA = new Date(a.fecha);
    const dateB = new Date(b.fecha);
    return dateB - dateA;
  });
  return array;
}
export function QuitarRegistrosCero(data) {
  var result = [];

  for (var i = 0; i < data.length; i++) {
    if (
      data[i].total !== "0" &&
      data[i].total !== 0 &&
      data[i].total !== "0.00"
    ) {
      result.push(data[i]);
    }
  }
  return result;
}
export function ValidarSesionActiva() {
  if (localStorage.getItem("SESSIONDATA")) {
    //console.log("mantener sesion");
  } else {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "./";
  }
}
export function checkValidarSesionActiva() {
  const itemStr = localStorage.getItem("SESSIONDATA");
  if (itemStr) {
    const item = JSON.parse(itemStr);
    if (new Date().getTime() > item.expiry) {
      localStorage.removeItem("SESSIONDATA");
    }
  }
}
export function esFechaHoy(fecha) {
  const fechaHoy = new Date();
  const diaHoy = fechaHoy.getDate();
  const mesHoy = fechaHoy.getMonth() + 1;
  const añoHoy = fechaHoy.getFullYear();

  const fechaObjeto = new Date(fecha);
  const diaFecha = fechaObjeto.getDate();
  const mesFecha = fechaObjeto.getMonth() + 1;
  const añoFecha = fechaObjeto.getFullYear();
  if (diaHoy === diaFecha && mesHoy === mesFecha && añoHoy === añoFecha) {
    return true;
  } else {
    return false;
  }
}
export function redondearADosDecimales(numero) {
  const numeroRedondeado = Math.round(numero * 10) / 10;
  const numeroFormateado = numeroRedondeado.toFixed(2);
  return numeroFormateado;
}
export function estadosPrestamoName(estado) {
  var nameEstado = "null";
  if (estado == "0" || estado == 0) {
    nameEstado = "PRE APROBADO";
  }else if (estado == "1" || estado == 1) {
    nameEstado = "POR DESEMBOLAR";
  }else if (estado == "2" || estado == 2) {
    nameEstado = "NEGADO";
  }else if (estado == "3" || estado == 3) {
    nameEstado = "ACTIVO";
  }else if (estado == "4" || estado == 4) {
    nameEstado = "CANCELADO";
  }else{
    nameEstado = "null";
  }
  return nameEstado;
}
