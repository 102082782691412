import React from "react";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../css/users.css";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BuscarListaPagos from "../../comp/BuscarListaPagos";
import BuscarPrestamos from "../../comp/BuscarPrestamos";
import Menu from "../../comp/Menu";
import {
  UrlHeader,
  UrlPost,
  UserCod,
  pagosUrl,
  historialPagosUrl,
  prestamosUrl,
} from "../../common/Function";
import { tiposUsuariosPermitidos } from "../Common/utils";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}
class PagoRealizar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TipUser: 5,
      id_user: UserCod(),
      Pago: [],
      Prestamo: [],
      id_press: 0,
      p_monto: 0,
      p_mora: 0,
      p_junta: 0,
      p_gastos: 0,
      success: null,
      error: null,
      espera: null,
      Hist_Pagos: [],
      estado: "2",
      falta_pagar: 0,
      p_sin_gastos: null,
      p_sin_interes: null,
      UrlReturn: null,
      tipoUsuario: localStorage.getItem("TIPUSER"),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const IdParam = this.props.match.params.id;
    const datosEnviar = {
      pagar: 1,
      id_pago: IdParam,
      monto: this.state.p_monto,
      mora: this.state.p_mora,
      junta: this.state.p_junta,
      id_asesor: this.state.id_user,
      gatos_admin: this.state.p_gastos,
      pago_sin_interes: this.state.p_sin_interes,
      pago_sin_gatos_admin: this.state.p_sin_gastos,
    };

    Swal.fire({
      title: "Esta seguro(a) de realizar el pago?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(pagosUrl, {
          method: "POST",
          headers: UrlHeader(),
          body: UrlPost(datosEnviar),
          redirect: "follow",
        })
          .then((response) => response.json())
          .then((datosRespuesta) => {
            if (datosRespuesta.success === true) {
              this.setState({ error: null, espera: null, success: 1 });
            } else {
              this.setState({
                error: datosRespuesta.msg,
                espera: null,
                success: null,
              });
            }
            this.cargarDatos();
            this.cargarPagos();
          })
          .catch((error) => console.log("error", error));
        Swal.fire("Pago realizado correctamente", "", "success");
      }
    });

    event.preventDefault();
  }

  cargarDatos() {
    const IdParam = this.props.match.params.id;

    fetch(pagosUrl + IdParam, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            Pago: result.data,
            id_press: result.data.prestamo,
            p_mora: result.data.mora_pagar,
            p_monto:
              parseFloat(result.data.cuota_restante) +
              parseFloat(result.data.gastos_admin_falta),
            p_junta: result.data.junta ? result.data.junta : 0,
            estado: result.data.estado,
            p_gastos: result.data.gastos_admin_falta,
            falta_pagar: result.data.falta,
          });
          this.cargarPrestamo(result.data.prestamo);
        }
      })
      .catch((error) => console.log("error", error));
  }
  cargarPrestamo(Id_prestam) {
    fetch(prestamosUrl + Id_prestam, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            Prestamo: result.data,
          });
        }
      })
      .catch((error) => console.log("error", error));
  }

  cargarPagos() {
    const IdParam = this.props.match.params.id;
    fetch(historialPagosUrl + "?id_pago=" + IdParam, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            Hist_Pagos: datosRespuesta.data,
          });
        } else {
          this.setState({
            Hist_Pagos: [],
          });
        }
      })
      .catch((error) => console.log("error", error));
  }
  CargarNavigate() {
    let Url = sessionStorage.getItem("navigate");
    if (Url !== null) {
      this.setState({
        UrlReturn: Url,
      });
    }
  }
  componentDidMount() {
    this.cargarDatos();
    this.cargarPagos();
    this.CargarNavigate();
  }

  Borrar(Id_Pago) {
    Swal.fire({
      title: "Esta seguro(a) de eliminar el pago?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Pago eliminado correctamente", "", "success");

        var data = { cancelarpagos: 1, id_pago: Id_Pago };

        fetch(pagosUrl, {
          method: "POST",
          headers: UrlHeader(),
          body: UrlPost(data),
          redirect: "follow",
        })
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            this.cargarDatos();
            this.cargarPagos();
          })
          .catch((error) => console.log("error", error));
      }
    });
  }
  SinInteres() {
    Swal.fire({
      title: "Esta seguro(a) de eliminar el interes del pago?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          p_sin_interes: 1,
          p_monto: this.state.p_monto - this.state.Pago.interes,
        });
      }
    });
  }
  SinPagosAdmin() {
    Swal.fire({
      title: "Esta seguro(a) de eliminar el gasto administrativo del pago?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      var newMonto =
        parseFloat(this.state.p_monto) - parseFloat(this.state.p_gastos);
      if (result.isConfirmed) {
        this.setState({
          p_sin_gastos: 1,
          p_monto: newMonto,
          p_gastos: 0,
        });
      }
    });
  }
  render() {
    const { Pago, Hist_Pagos, estado, Prestamo } = this.state;

    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link
            to={
              this.state.UrlReturn
                ? this.state.UrlReturn
                : "/pagos/" + this.state.id_press
            }
            className="btn btn-light"
          >
            <ChevronLeftIcon /> VOLVER A PAGOS
          </Link>
          <section className="cont">
            <div className="container-fluid">
              <br></br>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <BuscarPrestamos />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <BuscarListaPagos />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 p-3 mb-2 bg-light text-white col-sm-12 col-lg-6">
                  <div className="">
                    <h4>DATOS DE PAGO</h4>
                    <hr></hr>
                    <div className="table-responsive">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>
                              <b>CLIENTE:</b>
                            </td>
                            <td>
                              <i>
                                {Prestamo.ape1 +
                                  " " +
                                  Prestamo.ape2 +
                                  " " +
                                  Prestamo.name}
                              </i>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>DÍA DE PAGO:</b>
                            </td>
                            <td>
                              <i>{Pago.fecha_pago}</i>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>CAPITAL:</b>
                            </td>
                            <td>
                              <i>{Pago.capital}</i>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>INTERES:</b>
                            </td>
                            <td>
                              <i>{Pago.interes}</i>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>GASTOS ADMINISTRATIVOS:</b>
                            </td>
                            <td>
                              <i>{Pago.gastos_admin}</i>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>PAGADO:</b>
                            </td>
                            <td>
                              <i>{Pago.pago}</i>
                            </td>
                          </tr>

                          <tr className="p-3 mb-2 bg-secondary text-dark">
                            <td>
                              <b>MONTO CUOTA POR PAGAR:</b>
                            </td>
                            <td>
                              <i>{Pago.cuota_restante}</i>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>GASTOS ADMINISTRATIVOS PAGADO:</b>
                            </td>
                            <td>
                              <i>{Pago.gastos_admin_pagado}</i>
                            </td>
                          </tr>
                          <tr className="p-3 mb-2 bg-secondary text-dark">
                            <td>
                              <b>GASTOS ADMINISTRATIVOS POR PAGAR:</b>
                            </td>
                            <td>
                              <i>{Pago.gastos_admin_falta}</i>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>JUNTA POR CUOTA:</b>
                            </td>
                            <td>
                              <i>{Pago.junta ? Pago.junta : "0.00"}</i>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 p-3 mb-2 bg-dark text-white col-sm-12 col-lg-6">
                  {this.state.p_sin_interes ? (
                    <div className="p-3 mb-2 bg-info text-white">
                      El pago esta SIN INTERES. Si desea cancelar recarge la
                      pagina.
                    </div>
                  ) : null}
                  {this.state.p_sin_gastos ? (
                    <div className="p-3 mb-2 bg-info text-white">
                      El pago esta SIN GASTOS ADMINISTRATIVOS. Si desea cancelar
                      recarge la pagina.
                    </div>
                  ) : null}
                  <div className="">
                    <h4>REALIZAR PAGO</h4>
                    <hr></hr>
                    {estado === "2" ? (
                      <div className="alert alert-danger" role="alert">
                        Este pago ya fue cancelado
                      </div>
                    ) : (
                      <form onSubmit={this.handleSubmit}>
                        <label>MONTO:</label>
                        <input
                          type="text"
                          name="p_monto"
                          className="form-control"
                          placeholder="MONTO"
                          onChange={this.handleChange}
                          value={this.state.p_monto}
                        ></input>
                        <label>MORA:</label>
                        <input
                          type="text"
                          name="p_mora"
                          className="form-control"
                          placeholder="Mora"
                          onChange={this.handleChange}
                          value={this.state.p_mora}
                        ></input>
                        <label>JUNTA:</label>
                        <input
                          type="text"
                          name="p_junta"
                          className="form-control"
                          placeholder="Junta"
                          onChange={this.handleChange}
                          value={this.state.p_junta}
                        ></input>

                        <br></br>
                        {tiposUsuariosPermitidos.btnCuadrePagosEliminarInteresGastos.includes(
                          this.state.tipoUsuario
                        ) && (
                        <div className="row">
                          <div className="col-6">
                            <div
                              className="btn btn-warning w-100"
                              onClick={() => this.SinInteres()}
                            >
                              QUITAR INTERES
                            </div>
                          </div>
                          <div className="col-6">
                            <div
                              className="btn btn-warning w-100"
                              onClick={() => this.SinPagosAdmin()}
                            >
                              QUITAR PAGOS ADMIN
                            </div>
                          </div>
                        </div>
                        )}
                        <br></br>
                        <div className="p-3 mb-2 bg-info text-white">
                          SUB TOTAL:
                          {(
                            parseFloat(this.state.p_monto) +
                            parseFloat(this.state.p_mora) +
                            parseFloat(this.state.p_junta)
                          ).toFixed(2)}
                        </div>
                        <button className="btn btn-primary w-100">
                          REALIZAR PAGO
                        </button>
                      </form>
                    )}
                    <br></br>
                    {this.state.espera ? (
                      <div className="alert alert-info" role="alert">
                        Su pago esta siendo procesado
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.error ? (
                      <div className="alert alert-danger" role="alert">
                        {this.state.error}
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.success ? (
                      <div className="alert alert-success" role="alert">
                        Pago echo correctamente
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="cont">
                    <h4>HISTORIAL DE PAGO</h4>
                    <hr></hr>
                    <div className="table table-responsive">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>FECHA</td>
                            <td>MONTO</td>
                            <td>MORA</td>
                            <td>JUNTA</td>
                            <td>GASTOS ADMIN</td>
                            {tiposUsuariosPermitidos.btnCuadrePagosEliminarPagos.includes(
                          this.state.tipoUsuario
                        ) && (
                            <td></td>
                            )}
                          </tr>
                          {Hist_Pagos.map((Datos) => (
                            <tr className="text-center" key={Datos.id}>
                              <td>{Datos.fecha}</td>
                              <td>{Datos.monto}</td>
                              <td>{Datos.mora}</td>
                              <td>{Datos.junta}</td>
                              <td>{Datos.gastos_admin}</td>
                              {tiposUsuariosPermitidos.btnCuadrePagosEliminarPagos.includes(
                          this.state.tipoUsuario
                        ) && (
                              <td>
                                <button
                                  className="btn btn-danger"
                                  onClick={() => this.Borrar(Datos.id)}
                                >
                                  <DeleteIcon />
                                </button>
                              </td>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(PagoRealizar);
