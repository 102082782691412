import React from "react";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";

import PersonIcon from "@mui/icons-material/Person";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalculateIcon from "@mui/icons-material/Calculate";
import PaymentsIcon from "@mui/icons-material/Payments";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import GroupIcon from "@mui/icons-material/Group";
import BuscarListaPagos from "../../comp/BuscarListaPagos";
import BuscarPrestamos from "../../comp/BuscarPrestamos";
import { UrlHeader, UserCod, userUrl } from "../../common/Function";
import { tiposUsuariosPermitidos } from "../Common/utils";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      TipUser: "",
      UserName: "",
      UserId: UserCod(),
    };
  }

  cargarDatos() {
    fetch(userUrl + this.state.UserId, {
      method: "GET",
      headers: UrlHeader(),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          this.setState({
            TipUser: result.data.tip_user,
            UserName: result.data.name,
          });
          localStorage.setItem("TIPUSER", result.data.tip_user);
          localStorage.setItem("NAMEUSER", result.data.name);
        }
      })
      .catch((error) => console.log("error", error));
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    return (
      <div id="home">
        <Menu />
        <div className="content">
          <div className="text-center">
            <br></br>
            <h1>Bienvenido(a) {this.state.UserName}</h1>
            <p className="text-muted lead">¿Qué vamos a hacer hoy?</p>
          </div>
          {tiposUsuariosPermitidos.btnCuadreCaja.includes(
            this.state.TipUser
          ) && (
            <div className="row -cont">
              <Link className="btn btn-info" to="/reporte-cuadre-caja-new">
                <OpenInNewIcon /> CUADRAR CAJA
              </Link>
            </div>
          )}
          <div className="row cont">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <BuscarPrestamos />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <BuscarListaPagos />
            </div>
          </div>
          <div className="row m-0 p-4">
            {tiposUsuariosPermitidos.moduloClientes.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/clientes"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <PersonIcon />
                  CLIENTES
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloPrestamos.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/prestamos-search"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <AssignmentIcon />
                  PRESTAMO
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloCalculadora.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/calculadora"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <CalculateIcon />
                  CALCULADORA
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloCobranzaDiaria.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/cobranza-diaria"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <PaymentsIcon />
                  COBRANZA DIARIA
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloReportes.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/reportes"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <QueryStatsIcon />
                  REPORTES
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloPagosOtros.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/pagos-otros"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <CurrencyExchangeIcon />
                  PAGOS OTROS
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloCuadreCaja.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4">
                <Link
                  to="/reporte-cuadre-caja"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <PointOfSaleIcon className="mr-2" />
                  CUADRE DE CAJA
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloJunta.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/junta"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <CreditCardIcon />
                  JUNTA
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloJuntaPlan.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/juntaplan"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <GroupIcon />
                  JUNTA PLAN
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloEgresos.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/egresos"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <GroupIcon />
                  EGRESOS
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloUsuarios.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/Usuarios"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <GroupIcon />
                  USUARIOS
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloPreEvaluacion.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/pre-evaluacion-credito"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <GroupIcon />
                  PRE EVALUACIÓN
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloCuadreCaja.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/cuadre-caja/ingresos"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <PointOfSaleIcon className="mr-2" />
                  CUADRE DE CAJA INGRESOS
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloCuadreCaja.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/cuadre-caja/notas"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <PointOfSaleIcon className="mr-2" />
                  CUADRE DE CAJA NOTAS
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloCuadreCaja.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/cuadre-caja/deposito"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <PointOfSaleIcon className="mr-2" />
                  CUADRE DE CAJA DEPOSITOS
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloCuadreCaja.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/cuadre-caja/entrega-efectivo"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <PointOfSaleIcon className="mr-2" />
                  CUADRE DE CAJA ENTREGA EFECTIVO
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloCuadreCaja.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/cuadre-caja/sueldos"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <PointOfSaleIcon className="mr-2" />
                  CUADRE DE CAJA SUELDOS
                </Link>
              </div>
            )}
            {tiposUsuariosPermitidos.moduloFeriados.includes(
              this.state.TipUser
            ) && (
              <div className="col-sm-4 mb-3">
                <Link
                  to="/feriados"
                  className="btn btn-outline-primary btn-lg btn-block rounded-pill p-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <PointOfSaleIcon className="mr-2" />
                  FERIADOS
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
