import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../servicios/api";

export default function ReportesResumen() {
  const [nuevosprestamos, setNuevosprestamos] = useState([]);
  const [ingresosFinancieros, setIngresosFinancieros] = useState([]);

  useEffect(() => {
    const ConsultaReporteNuevosPrestamos = async () => {
      const response = await fetch(api + "Reportes/new_prestamos.php?total=0");
      const json = await response.json();
      setNuevosprestamos(json.data[0]);
    };
    const ConsultaReporteIngresosFinancierosPromedio = async () => {
      const response = await fetch(api + "Reportes/new_prestamos.php?monto_total_ie=1");
      const json = await response.json();
      setIngresosFinancieros(json.data);
    };

    ConsultaReporteNuevosPrestamos();
    ConsultaReporteIngresosFinancierosPromedio();
  }, []);

  const currency = function (number) {
    return new Intl.NumberFormat("es-PE", {
      style: "currency",
      currency: "PEN",
      minimumFractionDigits: 2,
    }).format(number);
  };

  const PromedioFinancieros = function (data) {
    const fecha = new Date();
    var dia = fecha.getDate();
    var position = Number(ingresosFinancieros.length) - 1;
    var Prom_IF = 0;
    if (position > 0) {
      Prom_IF = ingresosFinancieros[position].total;
      Prom_IF = Prom_IF.replace(/,/g, "");
      Prom_IF = Number(Prom_IF) / dia;
    }
    return currency(Prom_IF);
  }

  return (
    <div className="reportes-resumen">
      <div className="row">
        <div className="col">
          <Link to="/nuevos-prestamos">
            <div
              className="card text-white bg-primary mb-3"
              style={{ maxWidth: "18rem" }}
            >
              <div className="card-header">NUEVOS PRESTAMOS</div>
              <div className="card-body">
                <h2 className="">{nuevosprestamos.total}</h2>
                <p className="card-text">
                  <i>{currency(nuevosprestamos.monto)}</i>
                </p>
              </div>
            </div>
          </Link>
        </div>
        <div className="col">
          <Link to="/reportes-ingresos-financieros">
            <div
              className="card text-white bg-primary mb-3"
              style={{ maxWidth: "18rem" }}
            >
              <div className="card-header">INGRESOS FINANCIEROS</div>
              <div className="card-body">
                <h2 className="">{PromedioFinancieros(ingresosFinancieros)}</h2>
                <p className="card-text">
                  <i>Promedio de ingresos financieros.</i>
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
