import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/users.css";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Menu from "../../comp/Menu";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class JuntaPlanEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre: "",
      dni: "",
      monto: "",
      asesor: "",
      Asesor: [],
      asesor_id: 26,
      error: null,
      success: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  cargarDatos() {
    const IdUser = this.props.match.params.id;
    this.setState({
      id_cliente: IdUser,
    });

    fetch(api + "JuntaPlan/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta.data[0]);
        this.setState({
          nombre:
            datosRespuesta.data[0].name +
            " " +
            datosRespuesta.data[0].ape1 +
            " " +
            datosRespuesta.data[0].ape2,
          dni: datosRespuesta.data[0].dni,
          monto: datosRespuesta.data[0].monto,
          asesor:
            datosRespuesta.data[0].as_name +
            " " +
            datosRespuesta.data[0].as_ape1,
        });
      })
      .catch(console.log);

    //--------- ASESORES ----------
    fetch(api + "Users/?user_asesor=1", {
      method: "POST",
      body: JSON.stringify(),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        this.setState({
          Asesor: datosRespuesta,
        });
        //console.log(datosRespuesta);
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  BaseNegativa = (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "¡Sí!",
        cancelButtonText: "¡No, cancela!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          var valor = this.state.base;
          if (valor === "0") {
            valor = 1;
          } else {
            valor = 0;
          }
          console.log(valor);
          fetch(api + "Cliente/?base_nega=" + id + "&valor=" + valor)
            .then((respuesta) => respuesta.json())
            .then((datosRespuesta) => {
              console.log(datosRespuesta);
              this.cargarDatos();
            })
            .catch(console.log);
          swalWithBootstrapButtons.fire(
            "Actualizado!",
            "Su estado fue actualizado.",
            "success"
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            "Cancelado",
            "Solicitud cancelada :)",
            "error"
          );
        }
      });

    this.cargarDatos();
  };

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const IdJunta = this.props.match.params.id;
    const datosEnviar = {
      asesor_id: this.state.asesor_id,
    };
    //console.log(datosEnviar);

    fetch(api + "JuntaPlan/?actualizar_as=" + IdJunta, {
      method: "POST",
      body: JSON.stringify(datosEnviar),
    })
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            success: 1,
            error: null,
          });
          this.cargarDatos();
        } else {
          this.setState({ error: datosRespuesta.error, success: null });
        }
        //console.log(datosRespuesta);
      })
      .catch(console.log);

    event.preventDefault();
  }

  render() {
    const { asesor, dni, nombre, monto, Asesor } = this.state;
    //console.log(base);

    return (
      <div>
        <Menu />
        <div className="content">
          <br></br>
          <Link to={"/juntaplan"} className="btn btn-light">
            <ChevronLeftIcon /> Volver
          </Link>
          <section className="cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>JUNTA PLAN EDITAR</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <Link to="/#/inicio">Inicio</Link>
                      </li>
                      <li className="breadcrumb-item active">home</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
            <div className="container-fluid">
              <div className="">
                <h4>DATOS DE JUNTA</h4>
                <hr></hr>
                <div className="row">
                  <div className="col">
                    <h4>DATOS DE CLIENTE</h4>
                    <hr></hr>
                    <p>
                      <b>NOMBRE:</b> {nombre}
                    </p>
                    <p>
                      <b>DNI:</b> {dni}
                    </p>
                  </div>
                  <div className="col">
                    <h4>DATOS DE JUNTA</h4>
                    <hr></hr>
                    <p>
                      <b>MONTO:</b> {monto}
                    </p>
                    <p>
                      <b>ASESOR:</b> {asesor}
                    </p>
                  </div>
                </div>
              </div>
              <div className="cont">
                {this.state.success && (
                  <div className="alert alert-success" role="alert">
                    ASESOR CAMBIADO CORRECTAMENTE
                  </div>
                )}
                {this.state.error && (
                  <div className="alert alert-danger" role="alert">
                    ERROR
                  </div>
                )}
                <form className="row formx" onSubmit={this.handleSubmit}>
                  <div className="col">
                    <label htmlFor="asesor_id">Caja:</label>
                    <select
                      className="form form-control"
                      name="asesor_id"
                      id="asesor_id"
                      required
                      onChange={this.handleChange}
                    >
                      {Asesor.map((Datos) => (
                        <option value={Datos.id} key={Datos.id}>
                          {Datos.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="fbtnx">
                    <button className="btn btn-primary">CONSULTAR</button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(JuntaPlanEdit);
