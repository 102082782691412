import React from "react";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "../../css/users.css";
import { useParams } from "react-router-dom";
import Menu from "../../comp/Menu";
import { UrlHeader, UrlPost, UserCod, pagosUrl } from "../../common/Function";
import Swal from "sweetalert2";

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class PagosMultiples extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      espera: null,
      success: null,
      Datos_Prestamo: [],
      id_press: 0,
      Pagos: [],
      seleccionados: [],
      p_monto: 0,
      p_junta: 0,
      p_mora: 0,
      id_user: UserCod(),
      p_sin_interes: null,
      p_sin_gastos: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }

  handleSubmit(event) {
    const IdParam = this.props.match.params.id;
    const datosEnviar = {
      pagarmultiple: 1,
      id_prestamo: IdParam,
      monto: this.state.p_monto,
      mora: this.state.p_mora,
      junta: this.state.p_junta,
      id_asesor: this.state.id_user,
      gatos_admin: this.state.p_gastos,
      pago_sin_interes: this.state.p_sin_interes,
      pago_sin_gatos_admin: this.state.p_sin_gastos,
    };
    console.log(datosEnviar);

    Swal.fire({
      title: "Esta seguro(a) de realizar el pago?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(pagosUrl, {
          method: "POST",
          headers: UrlHeader(),
          body: UrlPost(datosEnviar),
          redirect: "follow",
        })
          .then((response) => response.json())
          .then((datosRespuesta) => {
            console.log(datosRespuesta);
            if (datosRespuesta.success === true) {
              this.setState({ error: null, espera: null, success: 1 });
            } else {
              this.setState({
                error: datosRespuesta.msg,
                espera: null,
                success: null,
              });
            }
            this.cargarDatos();
            this.cargarPagos();
          })
          .catch((error) => console.log("error", error));
        Swal.fire("Pago realizado correctamente", "", "success");
      }
    });

    event.preventDefault();
  }
  SinInteres() {
    Swal.fire({
      title: "Esta seguro(a) de eliminar el interes del pago?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({
          p_sin_interes: 1,
        });
      }
    });
  }
  SinPagosAdmin() {
    Swal.fire({
      title: "Esta seguro(a) de eliminar el gasto administrativo del pago?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      var newMonto =
        parseFloat(this.state.p_monto) - parseFloat(this.state.p_gastos);
      if (result.isConfirmed) {
        this.setState({
          p_sin_gastos: 1,
        });
      }
    });
  }

  cargarDatos() {
    const IdUser = this.props.match.params.id;

    fetch(api + "Prestamos/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        console.log(datosRespuesta.data[0]);
        if (datosRespuesta.success === true) {
          this.setState({
            Datos_Prestamo: datosRespuesta.data[0],
            id_press: IdUser,
          });
        }
      })
      .catch(console.log);
  }

  cargarPagos() {
    const IdParam = this.props.match.params.id;

    fetch(api + "Pagos/?lista_pagos=" + IdParam + "&estado=1")
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        if (datosRespuesta.success === true) {
          this.setState({
            Pagos: datosRespuesta.data,
          });
        }
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
    this.cargarPagos();
  }

  render() {
    const { Datos_Prestamo, Pagos } = this.state;
    function BtnPago(estado, pago) {
      if (estado === "2") {
        return (
          <Link to={"/realizar-pago/" + pago} className="btn btn-danger">
            Pagado
          </Link>
        );
      } else {
        return (
          <Link to={"/realizar-pago/" + pago} className="btn btn-info">
            Pagar
          </Link>
        );
      }
    }

    return (
      <div>
        <Menu />
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>PAGOS DEL PRESTAMO</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/#/inicio">Inicio</Link>
                    </li>
                    <li className="breadcrumb-item active">home</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <Link
                    to={"/prestamo/" + this.state.id_press}
                    className="btn btn-info"
                  >
                    <ChevronLeftIcon /> IR AL PRESTAMO
                  </Link>
                </div>
              </div>
              <br></br>

              <div className="">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <Link
                      className="nav-link "
                      to={"/pagos/" + this.state.id_press}
                    >
                      POR PAGAR
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={"/pagos-todos/" + this.state.id_press}
                    >
                      TODOS LOS PAGOS
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      to={"/pagos-varios/" + this.state.id_press}
                    >
                      PAGAR VARIAS CUOTAS
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={"/pagos-historial/" + this.state.id_press}
                    >
                      HISTORIAL
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="cont">
                {this.state.p_sin_interes ? (
                  <div className="p-3 mb-2 bg-info text-white">
                    El pago esta SIN INTERES. Si desea cancelar recarge la
                    pagina.
                  </div>
                ) : null}
                {this.state.p_sin_gastos ? (
                  <div className="p-3 mb-2 bg-info text-white">
                    El pago esta SIN GASTOS ADMINISTRATIVOS. Si desea cancelar
                    recarge la pagina.
                  </div>
                ) : null}
                <form onSubmit={this.handleSubmit}>
                  <label>MONTO:</label>
                  <input
                    type="text"
                    name="p_monto"
                    className="form-control"
                    placeholder="MONTO"
                    onChange={this.handleChange}
                    value={this.state.p_monto}
                  ></input>
                  <label>MORA:</label>
                  <input
                    type="text"
                    name="p_mora"
                    className="form-control"
                    placeholder="Mora"
                    onChange={this.handleChange}
                    value={this.state.p_mora}
                  ></input>
                  <label>JUNTA:</label>
                  <input
                    type="text"
                    name="p_junta"
                    className="form-control"
                    placeholder="Junta"
                    onChange={this.handleChange}
                    value={this.state.p_junta}
                  ></input>

                  <br></br>
                  <div className="row">
                    <div className="col-6">
                      <div
                        className="btn btn-warning w-100"
                        onClick={() => this.SinInteres()}
                      >
                        QUITAR INTERES
                      </div>
                    </div>
                    <div className="col-6">
                      <div
                        className="btn btn-warning w-100"
                        onClick={() => this.SinPagosAdmin()}
                      >
                        QUITAR PAGOS ADMIN
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="p-3 mb-2 bg-info text-white">
                    SUB TOTAL:
                    {(
                      parseFloat(this.state.p_monto) +
                      parseFloat(this.state.p_mora) +
                      parseFloat(this.state.p_junta)
                    ).toFixed(2)}
                  </div>
                  <button className="btn btn-primary w-100">
                    REALIZAR PAGO
                  </button>
                </form>
              </div>
              <div className="cont table-responsive">
                <h2>PAGOS POR PAGAR</h2>
                <table className="table table-striped">
                  <thead>
                    <tr className="table-primary">
                      <td>Periodo</td>
                      <td>Día</td>
                      <td>Capital</td>
                      <td>Interes</td>
                      <td>Cuota</td>
                      <td>Pagado</td>
                      <td>Por Pagar</td>
                      <td>Mora</td>
                      <td>Junta</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {Pagos.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>{Datos.periodo}</td>
                        <td>{Datos.fecha_pago2}</td>
                        <td>{Datos.capital}</td>
                        <td>{Datos.interes}</td>
                        <td>{Datos.monto}</td>
                        <td>{Datos.pago}</td>
                        <td>{Datos.pago_res}</td>
                        <td>{Datos.mora}</td>
                        <td>{Datos.junta}</td>
                        <td>{BtnPago(Datos.estado, Datos.id)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="table-primary">
                      <td>Periodo</td>
                      <td>Día</td>
                      <td>Capital</td>
                      <td>Interes</td>
                      <td>Cuota</td>
                      <td>Pagado</td>
                      <td>Por Pagar</td>
                      <td>Mora</td>
                      <td>Junta</td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="cont">
                <h2>DETALLE:</h2>
                <div className="table-responsive">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <b>ASESOR:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.asesor_name}</i>
                        </td>
                        <td>
                          <b>FECHA:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.fecha}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>CLIENTE:</b>
                        </td>
                        <td>
                          <i>
                            {Datos_Prestamo.cliente_name +
                              " " +
                              Datos_Prestamo.cliente_ape1 +
                              " " +
                              Datos_Prestamo.cliente_ape2}
                          </i>
                        </td>
                        <td>
                          <b>DNI</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.cliente_dni}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>DIECCIÓN</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.negocio}</i>
                        </td>
                        <td>
                          <b>CELULAR</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.cliente_phone}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>JUNTA:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.j_total}</i>
                        </td>
                        <td>
                          <b>JUNTA PLAN:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.jp_total}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>MONTO:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.monto}</i>
                        </td>
                        <td>
                          <b>PLAZO:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.cuotas}</i>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>TIPO:</b>
                        </td>
                        <td>
                          <i>{Datos_Prestamo.formapago}</i>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(PagosMultiples);
