import React from "react";
import Menu from "../../comp/Menu";
import { Link } from "react-router-dom";
import "../../css/users.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  UrlHeader,
  MisDatos,
  FechaActual,
  HoraActual,
  cuadreDiarioUrl,
  UrlPost,
  esFechaHoy,
} from "../../common/Function";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Carga from "../Common/Carga";
import SaveIcon from "@mui/icons-material/Save";
import Swal from "sweetalert2";
import { tiposUsuariosPermitidos } from "../Common/utils";
class NotasCajas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Prestamos: [],
      currentPage: 1,
      starat: 0,
      endat: 10,
      total: 0,
      total_paginas: 0,
      totalPages: [],
      busqueda: "",
      Asesores: [],
      Carteras: [],

      descrip: "",
      monto: "",
      fecha: FechaActual(),
      type: "",
      tipoUsuario: localStorage.getItem("TIPUSER"),
    };
    this.handleChange = this.handleChange.bind(this);
    this.filterPagina = this.filterPagina.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
  }
  handleSubmit(event) {
    this.setState({
      error: null,
      wait: true,
      success: null,
      msg: "",
    });
    let dataForm = {
      notas_registrar: 1,
      descrip: this.state.descrip,
      monto: this.state.monto,
      fecha: `${this.state.fecha} ${HoraActual()}`,
      tipo: this.state.type,
    };

    fetch(cuadreDiarioUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: UrlPost(dataForm),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Registro creado correctamente",
            showConfirmButton: false,
            timer: 1500,
          });
          this.setState({
            descrip: "",
            monto: "",
            fecha: FechaActual(),
            tipo: "",
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Ocurrio un error",
            showConfirmButton: false,
            timer: 1500,
          });
        }

        this.CambiarPagina(0);
        console.log(result);
      })
      .catch((error) => console.log("error", error));
    event.preventDefault();
  }
  BorrarRegistro(id) {
    let dataForm = {
      notas_eliminar: 1,
      id: id,
    };
    Swal.fire({
      title: "Esta seguro de eliminar este registro?",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: "Si",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(cuadreDiarioUrl, {
          method: "POST",
          headers: UrlHeader(),
          body: UrlPost(dataForm),
          redirect: "follow",
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.success === true) {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Registro eliminado correctamente",
                showConfirmButton: false,
                timer: 1500,
              });
              this.setState({
                descrip: "",
                monto: "",
                fecha: FechaActual(),
                tipo: "",
              });
            } else {
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Ocurrio un error",
                showConfirmButton: false,
                timer: 1500,
              });
            }

            this.CambiarPagina(0);
          })
          .catch((error) => console.log("error", error));
      }
    });
  }
  cargarDatos(data) {
    fetch(cuadreDiarioUrl, {
      method: "POST",
      headers: UrlHeader(),
      body: UrlPost(data),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        this.setState({
          Prestamos: result.data,
          total: result.pagination.totalRegister,
          total_paginas: Math.ceil(
            result.pagination.totalRegister / this.state.endat
          ),
        });
      })
      .catch((error) => console.log("error", error));
  }

  CambiarPagina(page) {
    this.setState({ starat: page });
    page = page * this.state.endat;
    var data = this.DatosForm();
    data.starat = page;
    this.cargarDatos(data);
  }
  filterPagina(event) {
    this.BuscarDatos();
    event.preventDefault();
  }
  componentDidMount() {
    this.BuscarDatos();
    var data = MisDatos();
  }
  DatosForm() {
    var data = {
      notas_listar: 1,
      starat: this.state.starat,
      endat: this.state.endat,
      filter: this.state.filter,
      fechaini: this.state.fechaini,
      fechafin: this.state.fechafin,
    };
    return data;
  }
  BuscarDatos() {
    var data = this.DatosForm();
    this.cargarDatos(data);
  }

  render() {
    const { Prestamos } = this.state;
    return (
      <div id="prestamos">
        {this.state.carga ? <Carga /> : null}
        <Menu />

        <div className="content">
          <br></br>
          <Link className="btn btn-light" to="/home">
            <KeyboardBackspaceIcon /> Regresar
          </Link>{" "}
          <div className="cont">
            <h4>CUADRE DE CAJA DIARIO NOTAS</h4> <hr></hr>
            <form className="row" onSubmit={this.handleSubmit}>
              <div className="col-3">
                <label>DESCRIPCIÓN</label>
                <input
                  type="text"
                  className="form-control"
                  name="descrip"
                  placeholder="DESCRIPCIÓN"
                  onChange={this.handleChange}
                  value={this.state.descrip}
                  required
                ></input>
              </div>
              <div className="col-3">
                <label>MONTO</label>
                <input
                  type="number"
                  className="form-control"
                  name="monto"
                  placeholder="Monto"
                  onChange={this.handleChange}
                  value={this.state.monto}
                  step="0.01"
                  required
                />
              </div>
              <div className="col-3">
                <label>FECHA</label>
                <input
                  type="date"
                  className="form-control"
                  name="fecha"
                  onChange={this.handleChange}
                  value={this.state.fecha}
                  required
                />
              </div>
              <div className="col-3">
                <br></br>
                <button className="btn btn-primary w-100">
                  <SaveIcon /> GUARDAR
                </button>
              </div>
            </form>
          </div>
          <div className="content-wrapper cont">
            <section className="content-header">
              <div className="container-fluid">
                <div className="d-flex justify-content-center">
                  <h1>PRESTAMOS</h1>
                </div>
              </div>
            </section>
            <br></br>
            <section className="content-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead className="bg-info">
                    <tr className="bg-success">
                      <th>FECHA</th>
                      <th>DESCRIPCIÓN</th>
                      <th>MONTO</th>
                      {tiposUsuariosPermitidos.btnCuadreCajaNotasEliminarPagos.includes(
                        this.state.tipoUsuario
                      ) && <th>ACCIONES</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {Prestamos.map((Datos) => (
                      <tr className="text-center" key={Datos.id}>
                        <td>{Datos.fecha}</td>
                        <td>{Datos.descrip}</td>
                        <td>{Datos.monto}</td>
                        {tiposUsuariosPermitidos.btnCuadreCajaNotasEliminarPagos.includes(
                          this.state.tipoUsuario
                        ) && (
                          <td>
                            <button
                              type="button"
                              className="btn btn-warning"
                              onClick={() => this.BorrarRegistro(Datos.id)}
                            >
                              <DeleteForeverIcon />
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={
                      this.state.starat === 0
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      onClick={() => this.CambiarPagina(this.state.starat - 1)}
                    >
                      <NavigateBeforeIcon />
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link">
                      {this.state.starat + 1 + "/" + this.state.total_paginas}
                    </a>
                  </li>
                  <li
                    className={
                      this.state.starat === this.state.total_paginas - 1
                        ? "page-item disabled"
                        : "page-item"
                    }
                  >
                    <a
                      className="page-link"
                      onClick={() => this.CambiarPagina(this.state.starat + 1)}
                    >
                      <NavigateNextIcon />
                    </a>
                  </li>
                </ul>
              </nav>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default NotasCajas;
