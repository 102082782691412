import React from "react";
import Aside from "../../comp/Aside";
import Footer from "../../comp/Footer";
import Navbar from "../../comp/Navbar";
import { Link } from "react-router-dom";
import api from "../../servicios/api";
import "../../css/users.css";
import axios from "axios";
import { useParams } from "react-router-dom";
//import SyncIcon from "@mui/icons-material/Sync";
//import Swal from "sweetalert2";
import Menu from "../../comp/Menu";
export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    return <Children {...props} match={match} />;
  };
}

class ClienteDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id_cliente: 0,
      estado: null,
      error: null,
      Datos: [],
      nombre: "",
      dni: "",
      telefono: "",
      direccion: "",
      email: "",
      negocio: "",
      fecha: "",
      detalle: "",
      c_name: "",
      c_dni: "",
      base: 0,
      exo: 0,
      ruta: "",
      name: "",
      estado2: null,
      error2: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const t_name = target.name;
    this.setState({
      [t_name]: target.value,
    });
    if (target.name === "ruta") {
      this.setState({ ruta: target.files[0] });
    }
  }

  handleSubmit(event) {
    const IdTip = this.props.match.params.id;

    //Adding files to the formdata
    const formData = new FormData();
    formData.append("id_cliente", IdTip);
    formData.append("id_press", "");
    formData.append("ruta", this.state.ruta);
    formData.append("name", this.state.name);

    //console.log(this.state.ruta);
    //inicio
    const esto = this;
    axios({
      url: api + "Cliente/documents.php?insertar=1",
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          esto.setState({
            estado2: 1,
            error2: null,
          });
        } else {
          esto.setState({
            error2: 1,
            estado2: null,
          });
        }
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    //fin

    event.preventDefault();
  }

  cargarDatos() {
    const IdUser = this.props.match.params.id;
    this.setState({
      id_cliente: IdUser,
    });

    fetch(api + "Cliente/?consultar=" + IdUser)
      .then((respuesta) => respuesta.json())
      .then((datosRespuesta) => {
        //console.log(datosRespuesta);
        this.setState({
          estado: datosRespuesta[0].estado,
          Datos: datosRespuesta[0],
          nombre:
            datosRespuesta[0].name +
            " " +
            datosRespuesta[0].ape1 +
            " " +
            datosRespuesta[0].ape2,
          dni: datosRespuesta[0].dni,
          telefono: datosRespuesta[0].telefonos,
          direccion: datosRespuesta[0].direccion,
          email: datosRespuesta[0].email,
          negocio: datosRespuesta[0].negocio,
          fecha: datosRespuesta[0].registro,
          detalle: datosRespuesta[0].descrip,
          c_name:
            datosRespuesta[0].c_name +
            " " +
            datosRespuesta[0].c_ape1 +
            " " +
            datosRespuesta[0].c_ape2,
          c_dni: datosRespuesta[0].c_dni,
          base: datosRespuesta[0].b_negra,
          exo: datosRespuesta[0].exo,
        });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.cargarDatos();
  }

  render() {
    const {
      nombre,
      dni,
      telefono,
      direccion,
      email,
      negocio,
      fecha,
      detalle,
      id_cliente,
    } = this.state;
    //console.log(base);
    console.log(this.state.estado);
    return (
      <div>
        <Menu />
        <div className="content-wrapper">
          <section className="content">
            <hr></hr>
            <Link to={"/cliente/" + id_cliente} className="btn btn-primary">
              REGRESAR
            </Link>
            <div className="container-fluid">
              <div className="cont">
                <h4>DATOS DE USUARIO</h4>
                <hr></hr>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>NOMBRE: </b>
                      {nombre}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>DNI: </b>
                      {dni}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>TELEFONO: </b>
                      {telefono}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>DIRECCIÓN: </b>
                      {direccion}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <p>
                      <b>EMAIL: </b>
                      {email}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>NEGOCIO: </b>
                      {negocio}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>FECHA DE REGISTRO: </b>
                      {fecha}
                    </p>
                  </div>
                  <div className="col">
                    <p>
                      <b>DETALLE: </b>
                      {detalle}
                    </p>
                  </div>
                </div>
              </div>
              <div className="cont">
                {this.state.estado2 && (
                  <div className="alert alert-success" role="alert">
                    Registrado Correctamente
                  </div>
                )}
                {this.state.error2 && (
                  <div className="alert alert-danger" role="alert">
                    Error de Registro
                  </div>
                )}
                <h4>SUBIR DOCUMENTO</h4>
                <hr></hr>
                <form onSubmit={this.handleSubmit} className="form">
                  <div className="row">
                    <div className="col">
                      <label>Vigencia de Poder: </label>
                      <input
                        type="file"
                        required
                        name="ruta"
                        accept="application/pdf"
                        className="form-control-file"
                        onChange={this.handleChange}
                      ></input>
                    </div>
                    <div className="col">
                      <label>Nombre</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Nombre"
                        onChange={this.handleChange}
                        required
                      ></input>
                    </div>
                  </div>
                  <hr></hr>
                  <button type="submit" className="btn btn-success">
                    Cargar
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(ClienteDocument);
