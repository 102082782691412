import React from "react";

export default function Footer() {
  return (
    <footer className="main-footer">
      <strong>Copyright © 2021-2022. </strong>
       Reservados todos los derechos.
      <div className="float-right d-none d-sm-inline-block">
        <b>Version</b> 3.0
      </div>
    </footer>
  );
}
